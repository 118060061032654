import produce from "immer";
import { useEffect, useState } from "react";

import { configs } from "@/configs";
import type { BookingStatus } from "@/models";

export function manualPaginate<TData>(data: TData[], page: number): TData[] {
  const start = (page - 1) * configs.limitPerPage;
  const end = start + configs.limitPerPage;

  return data.slice(start, end);
}

export function useTableCheckable<Item extends { id: number }>(
  data: Item[],
  total: number,
  status?: BookingStatus
) {
  const [checkedIds, setCheckedIds] = useState<number[]>([]);

  useEffect(() => {
    setCheckedIds([]);
  }, [status]);

  function handleCheck(data: Item, checked: boolean) {
    if (checked) {
      setCheckedIds(
        produce((ids) => {
          ids.push(data.id);
        })
      );
      return;
    }

    setCheckedIds(
      produce((ids) => {
        const index = ids.indexOf(data.id);

        ids.splice(index, 1);
      })
    );
  }

  function handleCheckAll() {
    if (isIndeterminate || isAllChecked) {
      setCheckedIds([]);
      return;
    }

    setCheckedIds(data.map((item) => item.id) ?? {});
  }

  const allCheckedIds = checkedIds.flat();
  const isAllChecked = allCheckedIds.length === total;
  const isIndeterminate = !isAllChecked && allCheckedIds.length > 0;

  return {
    checkedIds,
    isAllChecked,
    isIndeterminate,
    handleCheck,
    handleCheckAll,
    exportTitle: isIndeterminate
      ? "Export Selected to CSV"
      : "Export all to CSV",
  };
}

export function calculateDiscount(price: number, percentage: number) {
  return (price * percentage) / 100;
}

type LineItem =
  | {
      membership: { price: number };
      discountBaht?: number;
    }
  | { packagePT: { price: number } | null; discountBaht?: number };

export function calculatePurchaseOrderSummary(
  ...lineItems: (LineItem | null)[]
) {
  return lineItems.reduce(
    (summary, item) => {
      if (item === null) return summary;

      const price =
        "membership" in item
          ? item.membership.price
          : item.packagePT?.price ?? 0;
      const discount = item.discountBaht ?? 0;

      summary.subTotal += price;
      summary.discount += discount;
      summary.grandTotal = summary.subTotal - summary.discount;

      return summary;
    },
    {
      subTotal: 0,
      discount: 0,
      grandTotal: 0,
    }
  );
}

// TODO: remove and reactor this
// export function useDownloadReservationCSV(
//   type: "class" | "pt",
//   reservationIds: string[],
//   memberId: string,
//   bookingStatus?: BookingStatus
// ) {
//   const linkRef = useRef(document.createElement("a"));

//   const { data: member } = useQuery<
//     GetMemberForDownloadReservationListQuery,
//     GetMemberForDownloadReservationListQueryVariables
//   >(
//     gql`
//       query GetMemberForDownloadReservationList($id: String!) {
//         getMember(id: $id) {
//           memberId
//           userId
//         }
//       }
//     `,
//     {
//       variables: { id: memberId },
//     }
//   );

//   const memberCode = member?.getMember.memberId ?? "";
//   const userId = member?.getMember.userId;

//   useEffect(() => {
//     linkRef.current.removeAttribute("href");
//     linkRef.current.download = `${memberCode}_${type}_reservation.csv`;
//   }, [type, memberCode]);

//   async function download() {
//     if (!userId) {
//       return;
//     }

//     linkRef.current.href = await getReservationCSVAsObjectURL(
//       type,
//       reservationIds,
//       "remove-this-store-id",
//       userId,
//       bookingStatus
//     );

//     linkRef.current.click();
//   }

//   return download;
// }

// async function getReservationCSVAsObjectURL(
//   type: "class" | "pt",
//   reservationIds: string[],
//   storeId: string,
//   userId: string,
//   bookingStatus?: BookingStatus
// ) {
//   const token = await getIdToken();

//   const params = new URLSearchParams([
//     ["userId", userId],
//     ["bookingStatus", bookingStatus ?? ""],
//     ...reservationIds.map((id) => ["bookingIds[]", id]),
//   ]);

//   const response = await fetch(
//     `${downloadReservationListURL}?${params.toString()}`,
//     {
//       headers: {
//         Authorization: token ? `Bearer ${token}` : "",
//         "x-store-id": storeId,
//       },
//     }
//   );

//   return URL.createObjectURL(await response.blob());
// }
