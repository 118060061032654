import {
  Box,
  Divider,
  Stack,
  TableContainer,
  Table,
  TableHead,
  Typography,
} from "@mui/material";

import { DataTableBody } from "@/components/DataTableBody";

import {
  MemberPackageTableRowHeader,
  MemberPackageTableRow,
} from "./MemberPackageTableRow";
import { VerifyMember } from "./VerifyMember";

import { mapOptional } from "@/utils";
import { Fragment } from "react";
import { ConfirmDialog } from "@/components/ConfirmDialog";
import { Member } from "@/models";

export type PackageItem = {
  id: number;
  name: string;
  expireDate: string;
  price: number;
};

export function MemberVerifyDialog({
  open,
  title,
  onClose,
  data,
}: {
  open: boolean;
  title: string;
  onClose: () => void;
  data?: Member;
}) {
  if (!data) return <></>;

  const { id, code, profile } = data;
  const { phoneNo, firstName, lastName } = profile;

  const packages =
    mapOptional(
      data,
      ({ memberships: membershipList, personalTrainingQuota }) => {
        const memberships: PackageItem[] = membershipList.map((membership) => ({
          id: membership.id,
          name: membership.product.name,
          expireDate: membership.endedAt.toString(),
          price: membership.product.price,
        }));
        const packages: PackageItem[] = [];
        for (const pt of personalTrainingQuota) {
          if (
            packages.find((pack) => pack.expireDate === pt.endedAt.toString())
          )
            continue;
          packages.push({
            id: pt.id,
            name: pt.product.name,
            expireDate: pt.endedAt.toString(),
            price: pt.product.price,
          });
        }

        return memberships.concat(packages);
      }
    ) ?? [];

  const body = [
    { label: "หมายเลขสมาชิก", value: code },
    { label: "ชื่อ", value: `${firstName} ${lastName}` },
    { label: "หมายเลขโทรศัพท์", value: phoneNo },
  ];

  return (
    <ConfirmDialog
      title={title}
      open={open}
      onClose={onClose}
      onConfirm={onClose}
      confirmMessage="ตกลง"
      maxWidth="sm"
    >
      <Stack gap={3}>
        <VerifyMember id={id} />
        <Box
          display="grid"
          gridTemplateColumns="150px 1fr"
          gap={3}
          alignItems="baseline"
        >
          {body.map(({ label, value }) => (
            <Fragment key={label}>
              <Typography
                variant="body1"
                color="text.disabled"
                fontWeight={500}
              >
                {label}
              </Typography>
              <Typography variant="body2">{value}</Typography>
            </Fragment>
          ))}
        </Box>
        <Divider sx={{ m: 1.25 }} />
        <TableContainer sx={{ height: "400px", overflowX: "hidden" }}>
          <Table stickyHeader>
            <TableHead>
              <MemberPackageTableRowHeader />
            </TableHead>
            <DataTableBody loading={false} data={packages}>
              {packages.map((item) => (
                <MemberPackageTableRow key={item.id} data={item} />
              ))}
            </DataTableBody>
          </Table>
        </TableContainer>
      </Stack>
    </ConfirmDialog>
  );
}
