import { api } from "@/client/api";
import { Branch } from "@/models";
import { configs } from "@/configs";

type CreateBranchInput = Omit<
  Branch,
  "id" | "code" | "hasDoorAccessControl"
> & { code: string };

type UpdateBranchInput = Partial<Branch> & { id: number };

export async function getBranches() {
  const { data } = await api.get<Branch[]>(`${configs.apiUrl}/branches`);
  return data;
}

export async function getBranch(id: string): Promise<Branch> {
  const response = await api.get(`${configs.apiUrl}/branches/${id}`);
  return response.data as Branch;
}

export async function createBranch(data: CreateBranchInput): Promise<Branch> {
  const response = await api.post(`${configs.apiUrl}/branches`, data);
  return response.data as Branch;
}

export async function updateBranch({
  id,
  ...data
}: UpdateBranchInput): Promise<Branch> {
  const response = await api.patch(`${configs.apiUrl}/branches/${id}`, data);
  return response.data as Branch;
}
