import { Box, Dialog, IconButton, Typography, styled } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CloseIcon from "@mui/icons-material/Close";

const ArrowButton = styled("button")(({ theme }) => ({
  cursor: "pointer",
  position: "fixed",
  backgroundColor: "transparent",
  border: "none",
  width: 120,
  height: "100%",
  color: theme.palette.common.white,
}));

export function MediaViewerDialog({
  open,
  onClose,
  onChangeCurrentIndex,
  mediaList,
  currentIndex,
}: {
  open: boolean;
  onClose: () => void;
  onChangeCurrentIndex: (currentIndex: number) => void;
  mediaList: { url: string }[];
  currentIndex: number;
}) {
  const labels = `${currentIndex + 1}/${mediaList.length}`;
  const renderArrowNext =
    currentIndex + 1 === mediaList.length ? "hidden" : "visible";

  const onClickArrow: JSX.IntrinsicElements["button"]["onClick"] = (event) => {
    if (!event.currentTarget.dataset.index) {
      return;
    }
    onChangeCurrentIndex(+event.currentTarget.dataset.index);
  };

  const onClickClose: JSX.IntrinsicElements["button"]["onClick"] = (event) => {
    event.stopPropagation();
    onClose();
  };

  const prevIndex = Math.max(currentIndex - 1, 0);
  const hidePrevButton = currentIndex === 0;
  const nextIndex = Math.min(currentIndex + 1, mediaList.length);
  const hideNextButton = currentIndex === mediaList.length;

  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <Box
        display="grid"
        width="100vw"
        height="100vh"
        justifyContent="center"
        sx={{ backgroundColor: "common.black", alignContent: "center" }}
      >
        <Box
          component="img"
          src={mediaList[currentIndex]?.url}
          sx={{ maxWidth: "100%", maxHeight: "100vh" }}
        />
      </Box>
      <Typography
        variant="h6"
        color="common.white"
        sx={{ position: "fixed", left: "50%", right: "50%", top: 40 }}
      >
        {labels}
      </Typography>
      <ArrowButton
        data-index={prevIndex}
        hidden={hidePrevButton}
        onClick={onClickArrow}
        sx={{
          left: 0,
        }}
      >
        <ArrowBackIosIcon color="inherit" />
      </ArrowButton>
      <ArrowButton
        data-index={nextIndex}
        hidden={hideNextButton}
        onClick={onClickArrow}
        sx={{
          right: 0,
          visibility: renderArrowNext,
        }}
      >
        <ArrowForwardIosIcon color="inherit" />
      </ArrowButton>
      <IconButton
        onClick={onClickClose}
        sx={{
          position: "fixed",
          top: 0,
          right: 0,
          backgroundColor: "transparent",
          border: "none",
          mr: 6.5,
          mt: 6.5,
        }}
      >
        <CloseIcon sx={{ color: "common.white" }} />
      </IconButton>
    </Dialog>
  );
}
