import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";

import { configs } from "@/configs";
import { createStaff } from "@/services/staff";

import { useStaffEditorForm } from "./StaffEditor";
import { StaffEditorDialog } from "./StaffEditorDialog";

import type { StaffInput } from "@/models";

export function AddStaffDialog({
  open: isOpen,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, reset } = useStaffEditorForm();

  const { mutate: add, isLoading } = useMutation(createStaff, {
    onSuccess: async () => {
      enqueueSnackbar("สร้างพนักงานสำเร็จ", { variant: "success" });
      await queryClient.refetchQueries("staffs");
      close();
    },
    onError: (error) => {
      console.error(error);
      enqueueSnackbar(configs.unknownErrorMessage, {
        variant: "error",
      });
    },
  });
  const queryClient = useQueryClient();

  function close() {
    reset();
    onClose();
  }

  const onSubmit = handleSubmit((form) => {
    const data: StaffInput = {
      profile: {
        idCard: form.nationalIdCard,
        firstName: form.firstName,
        lastName: form.lastName,
        nickname: form.nickname,
        birthDate: form.dateOfBirth,
        email: form.email,
        gender: form.gender,
        phoneNo: form.phone,
        emergencyPerson: form.emergencyContact.name,
        emergencyPhoneNo: form.emergencyContact.phone,
        emergencyRelation: form.emergencyContact.relationship,
        address: form.currentAddress.line,
        district: form.currentAddress.district,
        subdistrict: form.currentAddress.subdistrict,
        province: form.currentAddress.province,
        postCode: form.currentAddress.postcode,
      },
      roles: form.position !== 99 ? [form.position, ...form.roles] : form.roles,
      branches: form.branches.map((branch) => branch.id),
    };
    add(data);
  });
  const title = "เพิ่มพนักงาน";

  return (
    <StaffEditorDialog
      open={isOpen}
      title={title}
      onClose={close}
      onSubmit={onSubmit}
      loading={isLoading}
      control={control}
    />
  );
}
