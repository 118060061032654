import {
  Card,
  CardContent,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";

import { getIsFollowUpEditable } from "@/utils";

import { FollowUpTableRow, FollowUpTableRowHeader } from "./FollowUpTableRow";
import { AddFollowUpDialog, EditFollowUpDialog } from "../../components";

import type { Training } from "@/models";

type FollowUpListProps = {
  data: Training;
  fetchKeys: string[];
};

export function FollowUpList({ data, fetchKeys }: FollowUpListProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const dialog = searchParams.get("dialog");
  const id = searchParams.get("id");

  const {
    id: trainingId,
    startedAt,
    endedAt,
    trainingFollowUps,
    status,
  } = data;

  const onClose = () => {
    searchParams.delete("dialog");
    searchParams.delete("id");
    setSearchParams(searchParams);
  };

  const followUp = trainingFollowUps.find(
    (trainingFollowUp) => trainingFollowUp.id.toString() === id
  );

  const followUpNo = trainingFollowUps.length;

  const isFollowUpEditable = getIsFollowUpEditable({ status, endedAt });

  const addFollowUpDialog = {
    open: dialog === "follow-up-add",
    followUpNo,
    trainingId,
    startedAt: followUp?.followedAt,
    endedAt,
    onClose,
    fetchKeys,
  };

  const editFollowUpDialog = {
    open: dialog === "follow-up-edit",
    id: id ?? "",
    followUpNo,
    startedAt,
    endedAt,
    followUp,
    onClose,
    fetchKeys,
  };

  return (
    <Card>
      <CardContent>
        <Stack gap={3}>
          <Typography variant="h6">การติดตามผลการเทรน</Typography>
          <TableContainer sx={{ height: 794, overflowX: "hidden" }}>
            <Table stickyHeader>
              <TableHead>
                <FollowUpTableRowHeader isEditable={isFollowUpEditable} />
              </TableHead>
              <TableBody>
                {trainingFollowUps.map((followUp, index) => (
                  <FollowUpTableRow
                    key={followUp.id}
                    followUpNo={trainingFollowUps.length - index}
                    data={followUp}
                    isLastFollowUp={index === 0}
                    training={{ status, endedAt }}
                    fetchKeys={fetchKeys}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </CardContent>
      <AddFollowUpDialog {...addFollowUpDialog} />
      <EditFollowUpDialog {...editFollowUpDialog} />
    </Card>
  );
}
