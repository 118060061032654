import { Box, Typography } from "@mui/material";

import { formatNumber } from "@/formatter";

type SummaryPriceDetailProps = {
  subTotal: number;
  discount: number;
  grandTotal: number;
};

export function SummaryPriceDetail({
  subTotal,
  discount,
  grandTotal,
}: SummaryPriceDetailProps) {
  return (
    <Box
      display="grid"
      gridTemplateColumns="1fr 90px 30px"
      textAlign="right"
      alignItems="baseline"
      gap={1}
    >
      <Typography variant="labelLarge" gridColumn="1/-1">
        สรุปราคา
      </Typography>
      <Typography variant="body1" color="text.disabled">
        ราคาทั้งหมด (รวมภาษีมูลค่าเพิ่ม 7%)
      </Typography>
      <Typography variant="body1">{formatNumber(subTotal)}</Typography>
      <Typography variant="body1">บาท</Typography>
      <Typography variant="body1" color="text.disabled">
        ส่วนลดทั้งหมด
      </Typography>
      <Typography variant="body1">{formatNumber(discount * -1 + 0)}</Typography>
      <Typography variant="body1">บาท</Typography>
      <Typography variant="body1" color="text.disabled">
        ราคาสุทธิ
      </Typography>
      <Typography variant="h6" color="primary.main">
        {formatNumber(grandTotal)}
      </Typography>
      <Typography variant="body1">บาท</Typography>
    </Box>
  );
}
