import { CircularProgress, Stack } from "@mui/material";

import type { StackProps } from "@mui/material";

export function CircularLoading(props: StackProps) {
  return (
    <Stack alignItems="center" justifyContent="center" {...props}>
      <CircularProgress />
    </Stack>
  );
}
