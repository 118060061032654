import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useForm, useWatch } from "react-hook-form";
import { useMutation } from "react-query";
import { useSnackbar } from "notistack";

import { FormConfirmDialog } from "@/components/FormConfirmDialog";
import { datetime } from "@/lib/yup/datetime";
import { Section } from "@/components/Section";
import { FormDatePicker } from "@/components/DatePicker";
import { configs } from "@/configs";
import { createTrainingsCsv } from "@/services/training";

import type { AxiosErrorWithData } from "@/client/api";
import type { InferType } from "yup";

type CreateTrainingEditorState = InferType<typeof schema>;

export type CreateTrainingCsvDialogProps = {
  open: boolean;
  onClose: () => void;
};

export function CreateTrainingCsvDialog({
  open,
  onClose,
}: CreateTrainingCsvDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { control, reset, handleSubmit } = useForm<CreateTrainingEditorState>({
    resolver,
  });
  const startedAt = useWatch({ control, name: "startedAt" });
  const endedAt = useWatch({ control, name: "endedAt" });

  const { mutate: create, isLoading } = useMutation(createTrainingsCsv, {
    onSuccess: () => {
      enqueueSnackbar("ดาวน์โหลดผลการเทรนสำเร็จ", { variant: "success" });
      close();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(
        error.response?.data.message ?? configs.unknownErrorMessage,
        { variant: "error" }
      );
    },
  });

  function close() {
    onClose();
    reset();
  }

  const onSubmit = handleSubmit((form) => {
    create({
      startedAt: form.startedAt,
      endedAt: form.endedAt,
    });
  });

  const title = "ดาวน์โหลดผลการเทรน";

  return (
    <FormConfirmDialog
      maxWidth="sm"
      control={control}
      title={title}
      loading={isLoading}
      open={open}
      onClose={close}
      onConfirm={onSubmit}
      confirmMessage="ดาวน์โหลด"
    >
      <Section columns={2} label="เลือกช่วงเวลาเริ่มต้นการเทรนที่ต้องการ">
        <FormDatePicker
          label="จากวันที่"
          name="startedAt"
          control={control}
          maxDate={endedAt}
          TextFieldProps={{ required: true }}
        />
        <FormDatePicker
          label="ถึงวันที่"
          name="endedAt"
          control={control}
          minDate={startedAt}
          TextFieldProps={{ required: true }}
        />
      </Section>
    </FormConfirmDialog>
  );
}

const schema = yup.object({
  startedAt: datetime().label("จากวันที่").required(),
  endedAt: datetime().label("ถึงวันที่").required(),
});

const resolver = yupResolver(schema);
