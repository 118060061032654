import { configs } from "@/configs";
import {
  Category,
  CatergoryData,
  CreateCategoryInput,
  UpdateCategoryInput,
} from "@/models";
import { api } from "@/client/api";

type GetCategories = {
  data: CatergoryData[];
  total: number;
};

export async function getCategories(query = "") {
  const { data } = await api.get<GetCategories>(
    `${configs.apiUrl}/categories?${query}`
  );
  return data;
}

export async function getCategory(id: string) {
  const { data } = await api.get<Category>(
    `${configs.apiUrl}/categories/${id}`
  );
  return data;
}

export async function createCategory(body: CreateCategoryInput) {
  const { data } = await api.post<Category>(
    `${configs.apiUrl}/categories`,
    body
  );
  return data;
}

export async function updateCategory({ id, data: body }: UpdateCategoryInput) {
  const { data } = await api.patch<Category>(
    `${configs.apiUrl}/categories/${id}`,
    body
  );
  return data;
}

export async function deleteCategory(id: string) {
  const { data } = await api.delete<Category>(
    `${configs.apiUrl}/categories/${id}`
  );
  return data;
}
