import { IconButton, Stack, TableCell, TableRow, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useSearchParams } from "react-router-dom";
import React from "react";

import { TextEllipsis } from "@/components/TextEllipsis";
import { useCurrentUser } from "@/features/authentication/contexts/AuthenticationContext";

import { ClassStatusSwitch } from "../../components/ClassStatusSwitch";

import type { Class } from "@/models";
import type { MouseEventHandler } from "react";

const columnsWidth = [216, 176, 300, 120, 176, 96];

export type ClassTableRowProps = {
  data: Class;
  fetchKeys?: string[];
};

export function ClassTableRowHeader() {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]}>ชื่อ</TableCell>
      <TableCell width={columnsWidth[1]}>ประเภท</TableCell>
      <TableCell width={columnsWidth[2]}>รายละเอียด</TableCell>
      <TableCell width={columnsWidth[3]} align="center">
        ตารางเวลา
      </TableCell>
      <TableCell width={columnsWidth[4]} align="center">
        สถานะ
      </TableCell>
      <TableCell width={columnsWidth[5]} />
    </TableRow>
  );
}

export function ClassTableRow({ data, fetchKeys = [] }: ClassTableRowProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { isAdmin } = useCurrentUser();
  const navigate = useNavigate();

  const id = data.id.toString();
  const name = data.name;
  const categories = data.categories.map(({ name }) => name).join(",");
  const description = data.description;
  const numberOfSchedules = data.numberOfSchedules.toLocaleString();

  function setDialog(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    dialogName: string
  ) {
    event.stopPropagation();
    searchParams.set("dialog", dialogName);
    searchParams.set("id", id);
    setSearchParams(searchParams);
  }

  const onClickEditClass: MouseEventHandler<HTMLButtonElement> = (event) => {
    setDialog(event, "class-edit");
  };

  const onClickRemoveClass: MouseEventHandler<HTMLButtonElement> = (event) => {
    setDialog(event, "class-remove");
  };

  const onClickTableRow: MouseEventHandler<HTMLTableRowElement> = () => {
    navigate(id);
  };

  return (
    <TableRow hover tabIndex={-1} role="link" onClick={onClickTableRow}>
      <TableCell>
        <TextEllipsis variant="body2">{name}</TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis variant="body2">{categories}</TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis variant="body2" width={columnsWidth[2]}>
          {description}
        </TextEllipsis>
      </TableCell>
      <TableCell align="center">{numberOfSchedules}</TableCell>
      <TableCell sx={{ px: 0 }} align="center">
        <ClassStatusSwitch data={data} fetchKeys={fetchKeys} />
      </TableCell>
      <TableCell sx={{ px: 0 }}>
        {isAdmin && (
          <Stack direction="row" gap={1}>
            <Tooltip title="แก้ไข">
              <IconButton onClick={onClickEditClass}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="ลบ">
              <IconButton onClick={onClickRemoveClass}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        )}
      </TableCell>
    </TableRow>
  );
}
