import { yupResolver } from "@hookform/resolvers/yup";
import { Divider, Stack } from "@mui/material";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useEffect } from "react";
import * as yup from "yup";

import { FormDatePicker } from "@/components/DatePicker";
import { FormConfirmDialog } from "@/components/FormConfirmDialog";
import { FormTextField } from "@/components/FormTextField";
import { Section } from "@/components/Section";
import { configs } from "@/configs";
import { datetime } from "@/lib/yup";
import { refetchQueries } from "@/utils";
import { now } from "@/lib/dateTime";
import { updateFollowUp } from "@/services/followUp";
import { FollowUp, FollowUpItem } from "@/models";

import type { AxiosErrorWithData } from "@/client/api";

import type { InferType } from "yup";

export type EditFollowUpEditorState = InferType<typeof schema>;

type EditFollowUpDialogProps = {
  open: boolean;
  followUpNo: number;
  id: string;
  startedAt: DateTime;
  endedAt: DateTime;
  followUp?: FollowUpItem | FollowUp;
  onClose: () => void;
  fetchKeys: string[];
};

export function EditFollowUpDialog({
  open: isOpen,
  followUpNo,
  id,
  startedAt,
  endedAt,
  followUp,
  onClose,
  fetchKeys,
}: EditFollowUpDialogProps) {
  const { control, handleSubmit, reset } = useEditFollowUpEditorForm();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate: update, isLoading } = useMutation(updateFollowUp, {
    onSuccess: async () => {
      enqueueSnackbar("แก้ไขการติดตามผลสำเร็จ", { variant: "success" });
      await refetchQueries({ queryClient, fetchKeys });
      close();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(
        error.response?.data.message ?? configs.unknownErrorMessage,
        { variant: "error" }
      );
    },
  });

  useEffect(() => {
    if (isOpen && followUp) {
      reset({ followedAt: followUp.followedAt, remark: followUp.remark ?? "" });
    }
  }, [followUp, isOpen, reset]);

  const close = () => {
    reset();
    onClose();
  };

  const onSubmit = handleSubmit((form) => {
    update({ id, ...form });
  });

  const tomorrow = now().plus({ day: 1 });
  const minDate = startedAt > now() ? startedAt.plus({ day: 1 }) : tomorrow;

  const title = `แก้ไขการติดตามผล ${followUpNo}`;

  return (
    <FormConfirmDialog
      control={control}
      title={title}
      loading={isLoading}
      open={isOpen}
      onClose={close}
      onConfirm={onSubmit}
    >
      <Stack gap={3}>
        <Section columns={1} label="การติดตามผลครั้งถัดไป">
          <FormDatePicker
            label="วันที่ติดตามผล"
            name="followedAt"
            control={control}
            minDate={minDate}
            maxDate={endedAt}
            TextFieldProps={{ required: true }}
          />
          <FormTextField
            label="หมายเหตุ"
            name="remark"
            control={control}
            multiline
            rows={4}
          />
        </Section>
        <Divider />
        <Section columns={1} label="เหตุผลในการแก้ไข">
          <FormTextField
            label="เหตุผล"
            name="reason"
            control={control}
            multiline
            rows={4}
          />
        </Section>
      </Stack>
    </FormConfirmDialog>
  );
}

export function useEditFollowUpEditorForm() {
  return useForm({
    resolver,
    defaultValues,
  });
}

const schema = yup.object({
  followedAt: datetime().required().label("วันที่ติดตามผล"),
  remark: yup.string().label("หมายเหตุ"),
  reason: yup.string().label("เหตุผล"),
});

const resolver = yupResolver(schema);

const defaultValues = {} as EditFollowUpEditorState;
