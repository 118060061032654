import * as yup from "yup";
import { useFieldArray } from "react-hook-form";
import {
  Box,
  Button,
  Stack,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import { FormTextField } from "@/components/FormTextField";

import type { Control } from "react-hook-form";
import type { TrainerEditorState } from "./TrainerEditor";
import type { StackProps } from "@mui/material";

export function TrainerExperienceEditor({
  name,
  control,
  label,
  min = 0,
  disableYear = false,
  ...props
}: {
  name: "skills" | "certificates" | "experience";
  control: Control<TrainerEditorState> | undefined;
  label: string;
  min?: number;
  disableYear?: boolean;
} & StackProps) {
  const { fields, append, remove } = useFieldArray({ name, control });

  const isAddButtonDisable = fields.length >= 3;

  function onAdd() {
    append({ description: "" });
  }

  function onRemove(index: number) {
    remove(index);
  }

  return (
    <Stack {...props} gap={2.5}>
      <Box
        display="grid"
        gridTemplateColumns="100px 100px"
        gap={2.5}
        alignItems="center"
      >
        <Typography variant="labelLarge">{label}</Typography>
        <Button
          variant="outlined"
          size="small"
          onClick={onAdd}
          disabled={isAddButtonDisable}
        >
          เพิ่ม
        </Button>
      </Box>
      {fields.map((field, index) => {
        const itemLabel = `${label} ${index + 1}`;
        const buttonVisibility = index < min ? "hidden" : "visible";

        return (
          <Box
            key={field.id}
            display="grid"
            gridTemplateColumns={disableYear ? "1fr 60px" : "1fr 124px 60px"}
            gap={2.5}
          >
            <FormTextField
              name={`${name}.${index}.description`}
              control={control}
              label={itemLabel}
              fullWidth
              required
            />
            {/* {!disableYear && (
              <FormSelect
                name={`${name}.${index}.year`}
                control={control}
                label="ปี"
                required
              >
                {yearList.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </FormSelect>
            )} */}
            <Tooltip title="ลบ" sx={{ visibility: buttonVisibility }}>
              <IconButton onClick={() => onRemove(index)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        );
      })}
    </Stack>
  );
}

const mapper: Record<string, string> = {
  skills: "ความเชี่ยวชาญ",
  certificates: "ใบรับรอง",
  experience: "ประวัติการศึกษา",
};

const schema = yup.object({
  description: yup.string().required((params: { path: string }) => {
    const label = mapper[params.path.substring(0, params.path.indexOf("["))];
    const index = parseInt(params.path.replaceAll(/\D/g, ""), 10) + 1;

    return `ระบุ${label} ${index}`;
  }),
});

TrainerExperienceEditor.schema = yup.array().of(schema);
