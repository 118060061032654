import { IconButton, Stack, TableCell, TableRow, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useSearchParams } from "react-router-dom";

import { TextEllipsis } from "@/components/TextEllipsis";
import {
  formatPrice,
  formatProductStatus,
  formatProductStatusColor,
} from "@/formatter";
import { calculateColumnWidth } from "@/utils";

import { Product } from "@/models";
import { useCurrentUser } from "@/features/authentication/contexts/AuthenticationContext";

import type { MouseEventHandler } from "react";

const columnsWidth = [368, 400, 120, 100, 96].map((item) =>
  calculateColumnWidth(item)
);

export type ProductTableRowProps = {
  data: Product;
};

export function ProductTableRowHeader() {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]}>ชื่อ</TableCell>
      <TableCell width={columnsWidth[1]}>รายละเอียด</TableCell>
      <TableCell width={columnsWidth[2]}>ราคา</TableCell>
      <TableCell width={columnsWidth[3]}>สถานะ</TableCell>
      <TableCell width={columnsWidth[4]} />
    </TableRow>
  );
}

export function ProductTableRow({ data }: ProductTableRowProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const currentUser = useCurrentUser();

  const { id, name, description, publishedAt } = data;
  const price = formatPrice(data.price);

  const idString = id.toString();

  const onClickRemove: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    searchParams.set("dialog", "product-remove");
    searchParams.set("id", idString);
    setSearchParams(searchParams);
  };

  const onClickEdit: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    searchParams.set("dialog", "product-edit");
    searchParams.set("id", idString);
    setSearchParams(searchParams);
  };

  const onClickTableRow: MouseEventHandler<HTMLTableRowElement> = () => {
    navigate(idString);
  };

  return (
    <TableRow hover tabIndex={-1} role="link" onClick={onClickTableRow}>
      <TableCell>
        <TextEllipsis variant="body2" line={2}>
          {name}
        </TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis variant="body2" line={2}>
          {description}
        </TextEllipsis>
      </TableCell>
      <TableCell>{price}</TableCell>
      <TableCell>
        <TextEllipsis color={formatProductStatusColor(!!publishedAt)}>
          {formatProductStatus(!!publishedAt)}
        </TextEllipsis>
      </TableCell>
      <TableCell sx={{ px: 0 }}>
        {currentUser.isAdmin && (
          <Stack direction="row" gap={1}>
            <Tooltip title="แก้ไข">
              <IconButton onClick={onClickEdit}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="ลบ">
              <IconButton onClick={onClickRemove}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        )}
      </TableCell>
    </TableRow>
  );
}
