import { useController } from "react-hook-form";
import { TextField } from "@mui/material";
import { useEffect } from "react";
import { useQuery } from "react-query";

import type { Control } from "react-hook-form";
import type { MemberProfileEditorState } from "./MemberProfileEditor";
import { getMemberCode } from "@/services/member";

export function MemberIdFormTextField({
  control,
}: {
  control?: Control<MemberProfileEditorState>;
}) {
  const { field } = useController({ name: "memberId", control });

  const { data, isFetching } = useQuery(["memberCode"], () => getMemberCode(), {
    enabled: !field.value,
    cacheTime: 0,
  });

  const proxy = field.value
    ? field.value
    : isFetching
    ? "Loading..."
    : data ?? "";

  useEffect(() => {
    if (data) {
      field.onChange(data);
    }
  }, [data, field]);

  return <TextField value={proxy} label="หมายเลขสมาชิก" disabled />;
}
