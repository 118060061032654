import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Divider, Stack } from "@mui/material";

import { FormConfirmDialog } from "@/components/FormConfirmDialog";
import {
  FormMemberAutocomplete,
  MemberAutocomplete,
} from "@/components/MemberAutocomplete";
import { createOpportunity } from "@/services/opportunity";
import { PurchaseType } from "@/models";

import { FollowingEditor } from "../../components/FollowingEditor";
import { InterestEditor } from "../../components/InterestEditor";

import { getApiErrorMessage, refetchQueries, useRequireParams } from "@/utils";

import type { AxiosErrorWithData } from "@/client/api";
import type { InterestEditorState } from "../../components/InterestEditor";
import type { FollowingEditorState } from "../../components/FollowingEditor";
import type { Control } from "react-hook-form";
import type { InferType } from "yup";

type AddOpportunityEditorState = InferType<typeof schema>;

export type AddOpportunityDialogProps = {
  open: boolean;
  onClose: () => void;
  fetchKeys?: string[];
};

export function AddOpportunityDialog({
  open: isOpen,
  onClose,
  fetchKeys = [],
}: AddOpportunityDialogProps) {
  const { control, handleSubmit, reset } = useAddOpportunityEditorForm();
  const { enqueueSnackbar } = useSnackbar();
  const { saleId } = useRequireParams(["saleId"]);
  const queryClient = useQueryClient();

  const { mutate: add, isLoading } = useMutation(createOpportunity, {
    onSuccess: async () => {
      enqueueSnackbar("เพิ่มเสนอการขายสำเร็จ", { variant: "success" });
      await refetchQueries({ queryClient, fetchKeys });
      close();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(getApiErrorMessage(error), { variant: "error" });
    },
  });

  function close() {
    onClose();
    reset();
  }

  const onSubmit = handleSubmit(
    (form) => {
      const productIds = form.products
        .map(({ type, membership, packagePT }) =>
          type === PurchaseType.Membership
            ? membership?.productId
            : packagePT?.productId
        )
        .filter(Boolean) as number[];

      add({
        memberId: form.member.id,
        percentageChance: form.percentageChance,
        totalChance: form.totalChance,
        expectedClosingAt: form.expectedClosingAt,
        following: {
          remark: form.following.remark ?? null,
          followAt: form.following.date,
        },
        productIds,
        staffId: +saleId,
      });
    },
    (err) => {
      console.error(err);
    }
  );

  const title = "เพิ่มเสนอการขาย";

  return (
    <FormConfirmDialog
      maxWidth="md"
      control={control}
      title={title}
      loading={isLoading}
      open={isOpen}
      onClose={close}
      onConfirm={onSubmit}
    >
      <Stack gap={4}>
        <FormMemberAutocomplete
          name="member"
          control={control}
          label="หมายเลขสมาชิกหรือชื่อ"
          required
        />
        <Divider />
        <InterestEditor
          control={control as unknown as Control<InterestEditorState>}
        />
        <Divider />
        <FollowingEditor
          isSingleForm
          control={control as unknown as Control<FollowingEditorState>}
        />
      </Stack>
    </FormConfirmDialog>
  );
}

function useAddOpportunityEditorForm() {
  return useForm({
    resolver,
    defaultValues,
  });
}

const schema = yup
  .object({
    member: MemberAutocomplete.schema.required().label("หมายเลขสมาชิกหรือชื่อ"),
  })
  .concat(InterestEditor.schema)
  .concat(FollowingEditor.schema);

const resolver = yupResolver(schema);

const defaultValues = {
  ...InterestEditor.defaultValues,
  ...FollowingEditor.defaultValues,
} as AddOpportunityEditorState;
