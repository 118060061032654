export const reds = {
  50: "#FBE5E4",
  100: "#F6BEBB",
  200: "#F0928D",
  300: "#EA665F",
  400: "#E6463D",
  500: "#E1251B",
  600: "#DD2118",
  700: "#D91B14",
  800: "#D51610",
  900: "#CD0D08",
  A100: "#FFF7F6",
  A200: "#FFC4C3",
  A400: "#FF9290",
  A700: "#FF7977",
};
