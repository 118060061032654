import { MenuItem, styled } from "@mui/material";
import { useController } from "react-hook-form";
import QueryBuilder from "@mui/icons-material/QueryBuilder";

import { Select } from "@/components/Select";

import type { FieldValues, Control } from "react-hook-form";
import type { SelectProps } from "@/components/Select";
import type { FieldStringPath } from "@/custom";

const hours = Array.from({ length: 24 }).map((_, index) => index);
const minutes = [
  "00",
  "05",
  "10",
  "15",
  "20",
  "25",
  "30",
  "35",
  "40",
  "45",
  "50",
  "55",
];

const options = hours.flatMap((hour) =>
  minutes.map((minute) => `${hour}:${minute}`)
);

// value cannot take Generic Type Parameter so we need to use this type
// instead of styled(Select<string>) or styled<typeof Select<string>>(Select)
type TimeSelectProxyType = (props: SelectProps<string>) => JSX.Element;

const StyledSelect = styled(Select as TimeSelectProxyType)({
  "& .MuiSvgIcon-root.MuiSelect-icon.MuiSelect-iconOpen": {
    transform: "unset",
  },
});

export function TimeSelect({ MenuProps, ...props }: SelectProps<string>) {
  return (
    <StyledSelect
      {...props}
      MenuProps={{ sx: { maxHeight: 220, ...MenuProps?.sx }, ...MenuProps }}
      IconComponent={QueryBuilder}
    >
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </StyledSelect>
  );
}

export type FormTimeSelectProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldStringPath<TFieldValues> = FieldStringPath<TFieldValues>
> = { control?: Control<TFieldValues>; name: TName } & Omit<
  SelectProps<string>,
  "name" | "value"
>;

export function FormTimeSelect<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldStringPath<TFieldValues> = FieldStringPath<TFieldValues>
>({
  name,
  control: _control,
  helperText,
  onChange,
  ...props
}: FormTimeSelectProps<TFieldValues, TName>) {
  const control = _control as unknown as Control<Record<string, string>>;

  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <TimeSelect
      FormControlProps={{ error: fieldState.invalid }}
      inputRef={field.ref}
      onChange={(event, child) => {
        onChange?.(event, child);
        field.onChange(event);
      }}
      onBlur={field.onBlur}
      value={field.value}
      helperText={fieldState.error?.message ?? helperText}
      {...props}
    />
  );
}
