import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import { useEffect } from "react";
import { Divider, Stack } from "@mui/material";
import { useController, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { configs } from "@/configs";
import { refetchQueries } from "@/utils";
import { createTraining } from "@/services/training";
import { Section } from "@/components/Section";
import { FormDatePicker } from "@/components/DatePicker";
import { FormTextField } from "@/components/FormTextField";
import { now } from "@/lib/dateTime";
import { datetime } from "@/lib/yup";
import { FormConfirmDialog } from "@/components/FormConfirmDialog";

import { TrainingEditor } from "./TrainingEditor";

import type { AxiosErrorWithData } from "@/client/api";
import type { Profile, TrainingGoal } from "@/models";
import type { Control } from "react-hook-form";
import type { InferType } from "yup";
import type { TrainingEditorState } from "./TrainingEditor";

export type AddTrainingEditorState = InferType<typeof schema>;

type AddTrainingDialogProps = {
  open: boolean;
  onClose: () => void;
  trainer?: { id: number; profile: Profile };
  fetchKeys?: string[];
};

export function AddTrainingDialog({
  open: isOpen,
  onClose,
  trainer,
  fetchKeys = [],
}: AddTrainingDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, reset } = useAddTrainingEditorForm();
  const queryClient = useQueryClient();

  const startedAt = useWatch({ control, name: "startedAt" });
  const endedAt = useWatch({ control, name: "endedAt" });
  const followedAt = useWatch({ control, name: "followUp.followedAt" });

  const minFollowDate = (startedAt ?? now()).plus({ day: 1 });

  const {
    field: { onChange: onChangeFollowedAt },
  } = useController({ control, name: "followUp.followedAt" });

  useEffect(() => {
    const lessThanOrEqualStart = startedAt && startedAt >= followedAt;
    const moreThanEnd = endedAt && endedAt < followedAt;

    if (lessThanOrEqualStart || moreThanEnd) {
      onChangeFollowedAt(undefined);
    }
  }, [endedAt, followedAt, onChangeFollowedAt, startedAt]);

  const { mutate: add, isLoading } = useMutation(createTraining, {
    onSuccess: async () => {
      enqueueSnackbar("เพิ่มเป้าหมายการเทรนสำเร็จ", { variant: "success" });
      await refetchQueries({ queryClient, fetchKeys });
      close();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(
        error.response?.data.message ?? configs.unknownErrorMessage,
        { variant: "error" }
      );
    },
  });

  useEffect(() => {
    if (trainer) {
      reset({ trainer, goals: [] });
    }
  }, [reset, trainer]);

  function close() {
    reset();
    onClose();
  }

  const onSubmit = handleSubmit((form) => {
    const { member, trainer, ...input } = form;
    add({
      staffId: trainer.id,
      memberId: member.id,
      ...input,
    });
  });

  const title = "เพิ่มเป้าหมายการเทรน";

  return (
    <FormConfirmDialog
      control={control}
      title={title}
      loading={isLoading}
      open={isOpen}
      onClose={close}
      onConfirm={onSubmit}
    >
      <Stack gap={3}>
        <TrainingEditor
          control={control as unknown as Control<TrainingEditorState>}
          openByTrainer={!!trainer}
        />
        <Divider />
        <Section columns={4} label="การติดตามผลครั้งถัดไป">
          <FormDatePicker
            label="วันที่ติดตามผล"
            name="followUp.followedAt"
            control={control}
            minDate={minFollowDate}
            maxDate={endedAt}
            TextFieldProps={{ required: true }}
          />
          <FormTextField
            label="หมายเหตุ"
            name="followUp.remark"
            control={control}
            multiline
            rows={4}
            sx={{ gridColumn: "1/span 4" }}
          />
        </Section>
      </Stack>
    </FormConfirmDialog>
  );
}

export function useAddTrainingEditorForm() {
  return useForm({
    resolver,
    defaultValues,
  });
}

const schema = yup
  .object({
    followUp: yup.object({
      followedAt: datetime().required().label("วันที่ติดตามผล"),
      remark: yup.string().label("หมายเหตุ"),
    }),
  })
  .concat(TrainingEditor.schema);

const resolver = yupResolver(schema);

const defaultValues = {
  goals: [] as TrainingGoal[],
} as AddTrainingEditorState;
