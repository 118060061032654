import {
  Divider,
  FormControl,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";

import { Section } from "@/components/Section";
import { FormCheckbox } from "@/components/FormCheckbox";

import { PurchaseMembershipEditor } from "../../components/PurchaseMembershipEditor";
import { PurchaseProductPTEditor } from "../../components/PurchaseProductPTEditor";
import { FormSummaryPriceDetail } from "../../components/FormSummaryPriceDetail";
import { SaleEditor } from "../../components/SaleAutocompleteEditor";

import type { Control, UseFormReset } from "react-hook-form";
import type { PurchaseMembershipEditorState } from "../../components/PurchaseMembershipEditor";
import type { PurchaseProductPTEditorState } from "../../components/PurchaseProductPTEditor";
import type { SaleEditorState } from "../../components/SaleAutocompleteEditor";

type PurchaseProductEditorProps = {
  saleControl: Control<SaleEditorState>;
  membershipControl: Control<PurchaseMembershipEditorState>;
  packagePTControl: Control<PurchaseProductPTEditorState>;
  packagePTReset: UseFormReset<PurchaseProductPTEditorState>;
};

export function PurchaseProductEditor({
  saleControl,
  membershipControl,
  packagePTControl,
  packagePTReset,
}: PurchaseProductEditorProps) {
  return (
    <Stack gap={4}>
      <Section columns={1} label={"พนักงานขายที่ดูแล"}>
        <SaleEditor control={saleControl} />
      </Section>
      <Section columns={1} label={`ซื้อแพ็กเกจสมาชิก`}>
        <PurchaseMembershipEditor control={membershipControl} />
      </Section>
      <Divider />
      <Stack gap={2.5}>
        <Stack direction="row" gap={1.25} alignItems="center">
          <Typography variant="labelLarge">ซื้อแพ็กเกจเทรนเนอร์</Typography>
          <FormControl sx={{ width: 140 }}>
            <FormControlLabel
              control={
                <FormCheckbox name="required" control={packagePTControl} />
              }
              label="ต้องการซื้อ"
              labelPlacement="end"
            />
          </FormControl>
        </Stack>
        <PurchaseProductPTEditor
          control={packagePTControl}
          reset={packagePTReset}
        />
      </Stack>
      <Divider />
      <FormSummaryPriceDetail
        membershipControl={membershipControl}
        packagePTControl={packagePTControl}
      />
    </Stack>
  );
}
