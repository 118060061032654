import { useState } from "react";
import { TableRow, TableCell, Stack, Button } from "@mui/material";
import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";

import { ConfirmDialog } from "@/components/ConfirmDialog";
import { TextEllipsis } from "@/components/TextEllipsis";
import { formatDate, formatTimeRange } from "@/formatter";
import { acceptTrainerBooking, denyTrainerBooking } from "@/services/trainer";
import { calculateColumnWidth, getApiErrorMessage } from "@/utils";

import type { Booking } from "@/models";
import type { AxiosErrorWithData } from "@/client/api";

const columnsWidth = [266, 266, 120, 160, 272].map((item) =>
  calculateColumnWidth(item)
);

export function PendingBookingTableRowHeader() {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]}>ชื่อสมาชิก</TableCell>
      <TableCell width={columnsWidth[1]}>ชื่อเทรนเนอร์</TableCell>
      <TableCell width={columnsWidth[2]}>วันที่จอง</TableCell>
      <TableCell width={columnsWidth[3]}>เวลา</TableCell>
      <TableCell width={columnsWidth[4]}></TableCell>
    </TableRow>
  );
}

type ConfirmBookingTableRowProps = {
  data: Booking;
  fetchKey: string;
};

export function PendingBookingTableRow({
  data,
  fetchKey,
}: ConfirmBookingTableRowProps) {
  const { id, member, schedule } = data;
  const { startedAt, endedAt, scheduleTrainers } = schedule;

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [isAcceptDialogOpen, setIsAcceptDialogOpen] = useState(false);
  const [isDenyDialogOpen, setIsDenyDialogOpen] = useState(false);

  const { mutate: accept, isLoading: isAccepting } = useMutation(
    acceptTrainerBooking,
    {
      onSuccess: async () => {
        enqueueSnackbar("ยอมรับการจองสำเร็จ", { variant: "success" });
        await queryClient.refetchQueries(fetchKey);
      },
      onError: (error: AxiosErrorWithData) => {
        enqueueSnackbar(getApiErrorMessage(error), { variant: "error" });
      },
    }
  );

  const { mutate: deny, isLoading: isDenying } = useMutation(
    denyTrainerBooking,
    {
      onSuccess: async () => {
        enqueueSnackbar("ปฏิเสธการจองสำเร็จ", { variant: "success" });
        await queryClient.refetchQueries(fetchKey);
      },
      onError: (error: AxiosErrorWithData) => {
        enqueueSnackbar(getApiErrorMessage(error), { variant: "error" });
      },
    }
  );

  const trainerName = `${scheduleTrainers[0].staff.profile.firstName} ${scheduleTrainers[0].staff.profile.lastName}`;

  return (
    <>
      <TableRow hover sx={{ ":hover": { cursor: "default!important" } }}>
        <TableCell>
          <TextEllipsis variant="body2">
            {member.profile.firstName} {member.profile.lastName}
          </TextEllipsis>
        </TableCell>
        <TableCell>
          <TextEllipsis variant="body2">{trainerName}</TextEllipsis>
        </TableCell>
        <TableCell>
          <TextEllipsis variant="body2">{formatDate(startedAt)}</TextEllipsis>
        </TableCell>
        <TableCell>{formatTimeRange(startedAt, endedAt)}</TableCell>
        <TableCell>
          <Stack direction="row" gap={3} justifyContent="center">
            <Button
              variant="outlined"
              size="small"
              color="success"
              sx={{ px: 3.5 }}
              onClick={() => setIsAcceptDialogOpen(true)}
            >
              ยอมรับ
            </Button>
            <Button
              variant="outlined"
              size="small"
              color="error"
              sx={{ px: 3.5 }}
              onClick={() => setIsDenyDialogOpen(true)}
            >
              ปฏิเสธ
            </Button>
          </Stack>
        </TableCell>
      </TableRow>
      <ConfirmDialog
        title="คุณต้องการยอมรับการจองนี้หรือไม่"
        confirmMessage="ยอมรับ"
        maxWidth="xs"
        open={isAcceptDialogOpen}
        loading={isAccepting}
        onConfirm={() =>
          accept({ trainerId: scheduleTrainers[0].staff.id, bookingId: id })
        }
        onCancel={() => setIsAcceptDialogOpen(false)}
      />
      <ConfirmDialog
        reverse
        title="คุณต้องการปฏิเสธการจองนี้ใช่หรือไม่"
        confirmMessage="ใช่"
        cancelMessage="ไม่ใช่"
        maxWidth="xs"
        open={isDenyDialogOpen}
        loading={isDenying}
        onConfirm={() =>
          deny({ trainerId: scheduleTrainers[0].staff.id, bookingId: id })
        }
        onCancel={() => setIsDenyDialogOpen(false)}
      />
    </>
  );
}
