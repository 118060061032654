import { TableCell, TableRow, Typography } from "@mui/material";

import { calculateColumnWidth, getMemberStatus } from "@/utils";
import { formatDateTime } from "@/formatter";

import { PackageExpiredDateDisplay } from "@/features/member/pages/MemberListPage/MemberTableRow";

import type { CheckIn } from "@/models";
import { fromISO } from "@/lib/dateTime";

const columnsWidth = [224, 130, 220, 200, 140, 170].map((item) =>
  calculateColumnWidth(item)
);

export function CheckInTableRowHeader() {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]}>วันที่และเวลา</TableCell>
      <TableCell width={columnsWidth[1]}>หมายเลขสมาชิก</TableCell>
      <TableCell width={columnsWidth[2]}>ชื่อ</TableCell>
      <TableCell width={columnsWidth[3]}>อีเมล</TableCell>
      <TableCell width={columnsWidth[4]}>เบอร์โทรศัพท์</TableCell>
      <TableCell width={columnsWidth[5]} align="center">
        วันสิ้นสุดสมาชิก
      </TableCell>
    </TableRow>
  );
}

type DataCheckIn = {
  data: CheckIn;
};

export function CheckInTableRow({ data }: DataCheckIn) {
  const { member, createdAt } = data;
  const { firstName, lastName, email, phoneNo } = member.profile;
  const { code, membership } = member;

  const status = getMemberStatus(member);
  return (
    <TableRow>
      <TableCell>
        <Typography variant="body2">
          {formatDateTime(fromISO(createdAt.toString()))}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{code}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          {firstName} {lastName}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{email}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{phoneNo}</Typography>
      </TableCell>
      <TableCell align="center">
        <PackageExpiredDateDisplay
          status={status}
          date={membership?.endedAt.toString()}
        />
      </TableCell>
    </TableRow>
  );
}
