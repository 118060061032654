import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Link as MuiLink,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";

import { DataSection } from "@/components/DataSection";
import {
  formatDate,
  formatDateTime,
  formatGender,
  formatOpportunityStatus,
  formatPrice,
} from "@/formatter";
import { mapOptional, useRequireParams } from "@/utils";
import { OpportunityStatus } from "@/models";

import { OpportunityStatusItem } from "./OpportunityStatusItem";
import { EditInterestDialog } from "./EditInterestDialog";

import type { Opportunity } from "@/models";

type OpportunityDetailProps = {
  data: Opportunity;
  queryKey: string;
};

export function OpportunityDetail({ data, queryKey }: OpportunityDetailProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { saleId } = useRequireParams(["saleId"]);

  const {
    id,
    status,
    member: {
      id: memberId,
      code,
      profile: { firstName, lastName, nickname, gender, phoneNo, email },
    },
    createdAt,
    closeLoseAt,
    receipt,
  } = data;

  const cardHeader = {
    title: <OpportunityStatusItem status={status} />,
  };

  const statusStamps = [{ date: createdAt, status: OpportunityStatus.Open }];

  if (closeLoseAt) {
    statusStamps.push({
      date: closeLoseAt,
      status: OpportunityStatus.CloseLose,
    });
  }

  const columns = [
    [
      {
        title: "ข้อมูลลูกค้า",
        data: [
          {
            label: "เลขสมาชิก",
            key: memberId.toString(),
            value: (
              <MuiLink
                component={Link}
                color="text.secondary"
                to={`/sales/${saleId}/opportunities/${id}/members/${memberId}`}
                variant="body2"
              >
                {code}
              </MuiLink>
            ),
          },
          { label: "ชื่อ", value: firstName },
          { label: "นามสกุล", value: lastName },
          { label: "ชื่อเล่น", value: nickname },
          { label: "เพศ", value: formatGender(gender) },
          { label: "เบอร์โทรศัพท์", value: phoneNo },
          { label: "อีเมล", value: email },
        ],
      },
    ],
    [
      {
        title: "ข้อมูลอื่นๆ",
        data: [
          {
            label: "บันทึกเวลา",
            key: `${data.id}-status-stamps`,
            value: (
              <Stack gap={1}>
                {statusStamps.map(({ date, status }, index) => (
                  <Typography
                    key={`${date.toISO()}-${index}`}
                    variant="body2"
                    display="list-item"
                    ml={2}
                  >
                    {`${formatDate(date)} (${formatOpportunityStatus(status)})`}
                  </Typography>
                ))}
              </Stack>
            ),
          },
        ],
      },
    ],
  ];

  const isCloseLose = status === OpportunityStatus.CloseLose;

  const dialog = searchParams.get("dialog");
  function onCloseDialog() {
    searchParams.delete("dialog");
    setSearchParams(searchParams);
  }

  const editInterestDialog = {
    data,
    open: dialog === "opportunity-interest-edit",
    onClose: onCloseDialog,
    fetchKeys: [queryKey],
  };

  return (
    <Card>
      <CardHeader {...cardHeader} />
      <CardContent>
        <Stack gap={4}>
          <DataSection
            columns={columns}
            boxProps={{ gridTemplateColumns: "120px 1fr", columnGap: 1 }}
          />
          <Divider />
          <InterestDetail data={data} />
          {isCloseLose && <CloseLoseDetail data={data} />}
          {receipt && <PurchaseDetail receipt={receipt} />}
        </Stack>
      </CardContent>
      <EditInterestDialog {...editInterestDialog} />
    </Card>
  );
}

type InterestDetailProps = {
  data: Opportunity;
};
function InterestDetail({ data }: InterestDetailProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    opportunityInterests: interests,
    percentageChance,
    totalChance,
    expectedClosingAt,
    status,
  } = data;

  const isShowEditButton = status === OpportunityStatus.Open;

  const columns = [
    [
      {
        title: "ความสนใจ",
        data: interests.map(
          ({
            product: { productMembership, productPersonalTraining, name },
          }) => ({
            label: productMembership
              ? "แพ็กเกจสมาชิก"
              : productPersonalTraining
              ? "แพ็กเกจเทรนเนอร์"
              : "แพ็กเกจ",
            value: name,
          })
        ),
      },
    ],
    [
      {
        title: "โอกาสที่คาดว่าจะปิดได้",
        data: [
          {
            label: "จำนวน",
            value: `${percentageChance} %`,
          },
          {
            label: "ยอด",
            value: formatPrice(totalChance),
          },
          {
            label: "วันที่คาดว่าจะปิด",
            value: formatDate(expectedClosingAt),
          },
        ],
      },
    ],
  ];

  function openEditInterestDialog() {
    searchParams.set("dialog", "opportunity-interest-edit");
    setSearchParams(searchParams);
  }

  return (
    <Stack position="relative">
      <DataSection
        columns={columns}
        boxProps={{ gridTemplateColumns: "120px 1fr", columnGap: 1 }}
      />
      {isShowEditButton && (
        <Tooltip title="แก้ไข">
          <IconButton
            size="small"
            sx={{ position: "absolute", right: 0 }}
            onClick={openEditInterestDialog}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
}

type CloseLoseDetailProps = {
  data: Opportunity;
};

function CloseLoseDetail({
  data: { closeLoseRemark, closeLoseAt },
}: CloseLoseDetailProps) {
  return (
    <>
      <Divider />
      <Stack gap={2}>
        <Typography variant="subtitle1" color="error.main">
          เหตุผลที่ขายไม่สำเร็จ
        </Typography>
        <Stack gap={0.5}>
          <Typography variant="caption" color="text.secondary">
            {mapOptional(closeLoseAt, formatDateTime) ?? "-"}
          </Typography>
          <Typography variant="body2">{closeLoseRemark}</Typography>
        </Stack>
      </Stack>
    </>
  );
}

type PurchaseDetailProps = Required<Pick<Opportunity, "receipt">>;

function PurchaseDetail({
  receipt: { receiptItems, total, createdAt },
}: PurchaseDetailProps) {
  const columns = [
    [
      {
        title: "สินค้าที่ซื้อ",
        data: receiptItems.map(
          ({
            product: { productMembershipsId, productPersonalTrainingsId, name },
          }) => ({
            label: productMembershipsId
              ? "แพ็กเกจสมาชิก"
              : productPersonalTrainingsId
              ? "แพ็กเกจเทรนเนอร์"
              : "แพ็กเกจ",
            value: name,
          })
        ),
      },
    ],
    [
      {
        title: "ยอดที่ปิดได้",
        data: [
          {
            label: "ยอด",
            value: formatPrice(total),
          },
          {
            label: "วันที่ปิดการขาย",
            value: formatDate(createdAt),
          },
        ],
      },
    ],
  ];

  return (
    <>
      <Divider />
      <DataSection
        columns={columns}
        boxProps={{ gridTemplateColumns: "120px 1fr", columnGap: 1 }}
      />
    </>
  );
}
