import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";

import { formatDate } from "@/formatter";
import { now } from "@/lib/dateTime";
import { CustomerStatus } from "@/models";

import type { Customer } from "@/models";

type CustomerFollowingProps = {
  data: Customer;
};

export function CustomerFollowing({ data }: CustomerFollowingProps) {
  const { followings, status } = data;

  const isFollowingCustomer = [
    CustomerStatus.Lead,
    CustomerStatus.Prospect,
  ].includes(status);

  return (
    <Card sx={{ height: "max-content" }}>
      <CardHeader
        title="การติดตามผล"
        sx={{ ".MuiCardHeader-action": { m: 0 } }}
      />
      <CardContent>
        <Divider sx={{ mb: 3 }} />
        <Stepper orientation="vertical">
          {followings.map(({ followAt, remark, createdAt }, index) => (
            <Step
              key={createdAt.toISO()}
              active
              completed={index !== 0 || !isFollowingCustomer}
            >
              <StepLabel
                StepIconProps={{ icon: followings.length }}
                sx={{ gap: 2 }}
              >
                ติดตามครั้งที่ {followings.length - index}
              </StepLabel>
              <StepContent sx={{ pl: 4.5 }}>
                <Stack>
                  <Typography variant="caption" color="text.secondary">
                    วันที่บันทึก : {formatDate(createdAt)}
                  </Typography>
                  <Typography
                    variant="caption"
                    color={
                      isFollowingCustomer && index === 0 && followAt <= now()
                        ? "primary.main"
                        : "text.secondary"
                    }
                    mb={1}
                  >
                    วันที่ติดตาม : {formatDate(followAt)}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    หมายเหตุ : {remark || "-"}
                  </Typography>
                </Stack>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </CardContent>
    </Card>
  );
}
