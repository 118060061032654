import {
  Breadcrumbs as MuiBreadcrumbs,
  Link as MuiLink,
  SxProps,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

type Item = {
  to: string;
  label: string;
};

type BreadcrumbsProps = {
  items: Item[];
  sx?: SxProps;
};

export function Breadcrumbs({ items, sx }: BreadcrumbsProps) {
  return (
    <MuiBreadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      sx={sx}
    >
      {items.map(({ to, label }, index) => {
        const key = `${label}-${index}`;
        if (index + 1 === items.length) {
          return (
            <Typography key={key} color="text.primary">
              {label}
            </Typography>
          );
        }
        return (
          <MuiLink key={key} component={Link} color="text.secondary" to={to}>
            {label}
          </MuiLink>
        );
      })}
    </MuiBreadcrumbs>
  );
}
