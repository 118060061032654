import { useState } from "react";
import {
  IconButton,
  Link,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import { useSearchParams } from "react-router-dom";

import { calculateColumnWidth } from "@/utils";
import { transformISOToDateTime } from "@/lib/dateTime";
import { formatBookingStatus, formatParticipantStatusColor } from "@/formatter";
import { BookingStatus } from "@/models";

import type { Participant } from "@/models";
import type { IconButtonProps } from "@mui/material";

const columnsWidth = [180, 242, 140, 232, 140, 100, 47].map((width) =>
  calculateColumnWidth(width)
);

export function ParticipantTableRowHeader() {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]} align="center">
        วันที่และเวลาที่จอง
      </TableCell>
      <TableCell width={columnsWidth[1]}>ชื่อ</TableCell>
      <TableCell width={columnsWidth[2]}>ชื่อเล่น</TableCell>
      <TableCell width={columnsWidth[3]}>อีเมล</TableCell>
      <TableCell width={columnsWidth[4]}>เบอร์โทร</TableCell>
      <TableCell width={columnsWidth[5]} align="center">
        สถานะ
      </TableCell>
      <TableCell width={columnsWidth[6]} />
    </TableRow>
  );
}

export type ParticipantTableRowProps = { data: Participant };

export function ParticipantTableRow({ data }: ParticipantTableRowProps) {
  const {
    status,
    member: {
      profile: { nickname, firstName, lastName, email, phoneNo },
    },
    createdAt,
  } = data;

  const name = `${firstName} ${lastName}`;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const onClickMenu: IconButtonProps["onClick"] = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onClose = () => {
    setAnchorEl(null);
  };
  const [searchParams, setSearchParams] = useSearchParams();

  const isAttend = status === BookingStatus.Attended;
  const statusToUpdate = isAttend
    ? BookingStatus.Absent
    : BookingStatus.Attended;

  const updateStatusLabel = formatBookingStatus(statusToUpdate);

  const onClickUpdateStatus = () => {
    searchParams.set("dialog", `updateStatus`);
    searchParams.set("statusToUpdate", statusToUpdate);
    searchParams.set("bookingId", data.bookingId.toString());
    setSearchParams(searchParams);
    onClose();
  };

  return (
    <TableRow>
      <TableCell align="center">
        {transformISOToDateTime(createdAt.toString())}
      </TableCell>
      <TableCell>
        <Link
          color="inherit"
          target="_blank"
          href={`/members/${data.member.id}?status=BOOKED#booking`}
        >
          {name}
        </Link>
      </TableCell>
      <TableCell>{nickname}</TableCell>
      <TableCell>{email}</TableCell>
      <TableCell>{phoneNo}</TableCell>
      <TableCell align="center">
        <Typography
          variant="subtitle2"
          color={formatParticipantStatusColor(status)}
        >
          {formatBookingStatus(status)}
        </Typography>
      </TableCell>
      <TableCell sx={{ px: 0 }} align="center">
        <>
          <Tooltip title="เมนู">
            <IconButton onClick={onClickMenu}>
              <MoreHoriz />
            </IconButton>
          </Tooltip>
          <Menu anchorEl={anchorEl} open={open} onClose={onClose}>
            <MenuItem onClick={onClickUpdateStatus}>
              เปลี่ยนสถานะเป็น{updateStatusLabel}
            </MenuItem>
          </Menu>
        </>
      </TableCell>
    </TableRow>
  );
}
