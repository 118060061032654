import {
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  CircularProgress,
  Link as MuiLink,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { useState } from "react";

import { ParticipantList } from "@/features/class/components/ParticipantList";
import { getScheduleStatus, mapOptional, useRequireParams } from "@/utils";
import { getClassSchedule } from "@/services/class";
import { before15Minutes, now } from "@/lib/dateTime";
import { BookingDisplayMode } from "@/models";

import { ClassScheduleDetail } from "../../components/ClassScheduleDetail";
import {
  CLASS_BOOKINGS_QUERY_KEY,
  CLASS_BOOKINGS_STATUS_QUERY_KEY,
  ReservationList,
} from "../../components/ReservationList";

import type { TabsProps } from "@mui/material";

const QUERY_KEY = "classSchedule";

export function ClassScheduleDetailPage() {
  const { id, scheduleId } = useRequireParams(["id", "scheduleId"]);
  const [tab, setTab] = useState(BookingDisplayMode.Reservation);

  const { data, isFetching } = useQuery(QUERY_KEY, () =>
    getClassSchedule(id, scheduleId)
  );

  const isLoaded = !isFetching && data;
  const classDetailPath = `/classes/${id}`;

  const status = mapOptional(data, getScheduleStatus);

  const onChangeTab: TabsProps["onChange"] = (_, value: BookingDisplayMode) => {
    setTab(value);
  };

  const participantCount =
    data?.bookings.filter(
      ({ cancelledAt, isPending, isWaiting }) =>
        !cancelledAt && !isPending && !isWaiting
    ).length ?? 0;

  const isClassStart = data ? before15Minutes(data.startedAt) <= now() : false;

  const tabMapper = {
    [BookingDisplayMode.Reservation]: (
      <ReservationList
        scheduleId={scheduleId}
        scheduleStatus={status}
        capacity={data?.capacity}
        fetchKeys={[QUERY_KEY]}
      />
    ),
    [BookingDisplayMode.Participant]: (
      <ParticipantList
        scheduleId={scheduleId}
        participantCount={participantCount}
        isClassStart={isClassStart}
      />
    ),
  };

  return (
    <Box>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ mb: 2 }}
      >
        <MuiLink component={Link} color="text.secondary" to="/classes/calendar">
          คลาส
        </MuiLink>
        <MuiLink component={Link} color="text.secondary" to="/classes">
          จัดการคลาส
        </MuiLink>
        <MuiLink component={Link} color="text.secondary" to={classDetailPath}>
          รายละเอียดคลาส
        </MuiLink>
        <Typography color="text.primary">รายละเอียดตารางเวลา</Typography>
      </Breadcrumbs>
      <Card sx={{ mb: 2.5 }}>
        {isLoaded ? (
          <CardContent>
            <ClassScheduleDetail
              data={data}
              // TODO: refactor fetch keys
              fetchKeys={[
                QUERY_KEY,
                CLASS_BOOKINGS_QUERY_KEY,
                CLASS_BOOKINGS_STATUS_QUERY_KEY,
              ]}
            />
          </CardContent>
        ) : (
          <Box display="grid" sx={{ placeItems: "center" }} height={306}>
            <CircularProgress disableShrink />
          </Box>
        )}
      </Card>
      <Box mb={3}>
        <Tabs value={tab} onChange={onChangeTab}>
          <Tab
            label="รายการจอง"
            value={BookingDisplayMode.Reservation}
            sx={{ width: 180 }}
          />
          <Tab
            label="รายการผู้เข้าร่วม"
            value={BookingDisplayMode.Participant}
            sx={{ width: 180 }}
          />
        </Tabs>
      </Box>
      {tabMapper[tab]}
    </Box>
  );
}
