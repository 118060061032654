import { Autocomplete, TextField } from "@mui/material";
import { useController } from "react-hook-form";
import { useQuery } from "react-query";

import { getProductPTs } from "@/services/product";
import { transformDurationISOtoObject } from "@/lib/duration";

import type { ControllerProps, FieldPath, FieldValues } from "react-hook-form";
import type { AutocompleteProps as MuiAutocompleteProps } from "@mui/material/Autocomplete/Autocomplete";
import type { TextFieldProps } from "@mui/material";
import type { DurationObjectUnits } from "luxon";

export type ProductPTAutocompleteValue = {
  id: number;
  name: string;
  quota: number;
  duration: DurationObjectUnits;
  price: number;
};

export type ProductPTAutocompleteProps = {
  label?: string;
  TextFieldProps?: Partial<TextFieldProps>;
} & Omit<
  MuiAutocompleteProps<ProductPTAutocompleteValue, false, false, false>,
  "renderInput" | "options"
>;

function useProductPTs() {
  return useQuery("product-pts", () => getProductPTs("limit=1000"));
}

export function ProductPTAutocomplete({
  label,
  TextFieldProps,
  ...props
}: ProductPTAutocompleteProps) {
  const { data: raw, isFetching } = useProductPTs();
  const data = raw?.data ?? [];
  const options = data.map(
    ({ name, price, productPersonalTraining, branches, id }) => ({
      id: productPersonalTraining.id,
      productId: id,
      name,
      quota: productPersonalTraining.quota,
      duration: transformDurationISOtoObject(
        productPersonalTraining.durationIso
      ),
      price,
      quotaUnit: productPersonalTraining.quotaUnit,
      branches,
    })
  );

  return (
    <Autocomplete
      options={options}
      loading={isFetching}
      getOptionLabel={({ name }) => name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField label={label} {...params} {...TextFieldProps} />
      )}
      {...props}
    />
  );
}

export function FormProductPTAutocomplete<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  required = false,
  ...props
}: { required?: boolean } & Pick<
  ControllerProps<TFieldValues, TName>,
  "name" | "control"
> &
  Omit<
    ProductPTAutocompleteProps,
    "value" | "name" | "TextFieldProps" | "onChange"
  >) {
  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <ProductPTAutocomplete
      value={field.value ?? null}
      onChange={(_, newValue) => field.onChange(newValue)}
      TextFieldProps={{
        inputRef: field.ref,
        onBlur: field.onBlur,
        error: fieldState.invalid,
        helperText: fieldState.error?.message,
        required,
      }}
      {...props}
    />
  );
}
