import { DateTime } from "luxon";
import { Schema } from "yup";

import { fromISO } from "@/lib/dateTime";
import { datetime as locale } from "./locales";

import type { Ref, Thunk, Maybe, AnyObject, Flags } from "yup";

export class DateTimeSchema<
  TType extends Maybe<DateTime> = Maybe<DateTime>,
  TContext = AnyObject,
  TDefault = undefined,
  TFlags extends Flags = ""
> extends Schema<TType, TContext, TDefault, TFlags> {
  constructor() {
    super({
      type: "datetime",
      check(v: unknown): v is NonNullable<TType> {
        return v instanceof DateTime && v.isValid;
      },
    });

    this.withMutation(() => {
      this.transform((value: unknown, _raw, ctx) => {
        if (!ctx.spec.coarce || ctx.isType(value)) return value as DateTime;

        return fromISO(value as string);
      });
    });
  }

  min(min: DateTime | Ref<DateTime>, message = locale.min) {
    return this.test({
      message,
      name: "min",
      exclusive: true,
      params: { min: min instanceof DateTime ? min : min.key },
      skipAbsent: true,
      test(value) {
        return value ? value >= this.resolve(min as DateTime) : false;
      },
    });
  }

  max(max: DateTime | Ref<DateTime>, message = locale.max) {
    return this.test({
      message,
      name: "max",
      exclusive: true,
      params: { max: max instanceof DateTime ? max : max.key },
      skipAbsent: true,
      test(value) {
        return value ? value <= this.resolve(max as DateTime) : false;
      },
    });
  }
}

export interface DateTimeSchema<
  TType extends Maybe<DateTime> = Maybe<DateTime>,
  TContext = AnyObject,
  TDefault = undefined,
  TFlags extends Flags = ""
> extends Schema<TType, TContext, TDefault, TFlags> {
  default<D extends Maybe<TType>>(def: Thunk<D>): DateTimeSchema<TType>;
  concat<TOther extends DateTimeSchema<DateTime>>(schema: TOther): TOther;
  required(msg?: string): DateTimeSchema<NonNullable<TType>>;
}

export function datetime() {
  return new DateTimeSchema();
}
