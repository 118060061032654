import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";

import { useClassEditorForm } from "@/features/class/pages/ClassListPage/ClassEditor";
import { ClassEditorDialog } from "@/features/class/pages/ClassListPage/ClassEditorDialog";
import { updateClass } from "@/services/class";
import { configs } from "@/configs";
import { refetchQueries } from "@/utils";

import type { Class } from "@/models";
import type { AxiosErrorWithData } from "@/client/api";

export type EditClassDialogProps = {
  open: boolean;
  data?: Class;
  onClose: () => void;
  id: string;
  fetchKeys?: string[];
};

export function EditClassDialog({
  open: isOpen,
  onClose,
  id,
  data,
  fetchKeys = [],
}: EditClassDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, reset } = useClassEditorForm();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isOpen && data) {
      reset({
        name: data.name,
        categories: data.categories.map(({ id, name, description }) => ({
          id,
          name,
          description,
        })),
        youtubeLink: data.youtubeUrl ?? "",
        description: data.description,
        images: data.thumbnailUrls.map((url) => ({
          id: url,
          url,
        })),
      });
    }
  }, [isOpen, data, reset]);

  function close() {
    onClose();
  }

  const { mutate: update, isLoading } = useMutation(updateClass, {
    onSuccess: async () => {
      enqueueSnackbar("บันทึกคลาสสำเร็จ", { variant: "success" });
      await refetchQueries({ queryClient, fetchKeys });
      close();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(
        error.response?.data.message ?? configs.unknownErrorMessage,
        { variant: "error" }
      );
    },
  });

  const onSubmit = handleSubmit((form) => {
    const input = {
      id: +id,
      name: form.name,
      description: form.description ?? "",
      categoryIds: form.categories.map(({ id }) => id),
      youtubeUrl: form.youtubeLink ?? "",
      thumbnailUrls: form.images.map((image) => image.url),
    };

    update(input);
  });

  const title = "แก้ไขคลาส";

  return (
    <ClassEditorDialog
      open={isOpen}
      title={title}
      onClose={close}
      onSubmit={onSubmit}
      loading={isLoading}
      control={control}
    />
  );
}
