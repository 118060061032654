import {
  signInWithEmailAndPassword as authSignInWithEmailAndPassword,
  getAuth,
  signOut as authSignOut,
  getIdToken as authGetIdToken,
  onAuthStateChanged as authOnAuthStateChanged,
  sendPasswordResetEmail as passwordResetEmail,
} from "@firebase/auth";
import type { User, NextFn, NextOrObserver } from "@firebase/auth";
import { mapOptional } from "@/utils";
import { FirebaseError } from "@firebase/util";
import { AxiosError } from "axios";

let user: User | null;

export async function signInWithEmailAndPassword(
  email: string,
  password: string
) {
  const result = await authSignInWithEmailAndPassword(
    getAuth(),
    email,
    password
  );

  user = result.user;

  return result;
}

export function signOut() {
  return authSignOut(getAuth());
}

export function getIdToken() {
  return mapOptional(user, authGetIdToken);
}

export function getFirebaseUserId() {
  return user?.uid;
}

export function onAuthStateChanged(next: NextOrObserver<User | null>) {
  return authOnAuthStateChanged(getAuth(), (u) => {
    user = u;

    (next as NextFn<User | null>)(u);
  });
}

export function isFirebaseError(error: unknown): error is FirebaseError {
  return (error as FirebaseError).name === "FirebaseError";
}

export function isAxiosError(error: unknown): error is AxiosError {
  return (error as AxiosError).name === "AxiosError";
}

export function sendPasswordResetEmail(email: string) {
  return passwordResetEmail(getAuth(), email);
}
