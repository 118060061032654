import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";

import { DataSection } from "@/components/DataSection";
import {
  formatDate,
  formatFacilityBookingStatus,
  formatFacilityBookingStatusColor,
  formatTimeRange,
} from "@/formatter";
import { updateBookingRequest } from "@/services/facility";
import { configs } from "@/configs";
import { refetchQueries } from "@/utils";
import { FacilityBookingStatus } from "@/models";

import type { AxiosErrorWithData } from "@/client/api";
import type { FacilityBooking } from "@/models";

export type FacilityBookingDialogProps = {
  open: boolean;
  onClose: () => void;
  data?: FacilityBooking;
  fetchKeys?: string[];
};

enum ButtonName {
  Confirm = "confirm",
  Reject = "reject",
}

export function FacilityBookingDialog({
  open: isOpen,
  onClose,
  data,
  fetchKeys = [],
}: FacilityBookingDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [clickedButton, setClickedButton] = useState<ButtonName>();

  const { mutate: update, isLoading: isUpdating } = useMutation(
    updateBookingRequest,
    {
      onSuccess: async () => {
        enqueueSnackbar("อัพเดทสถานะการจอง สำเร็จ", { variant: "success" });
        await refetchQueries({ queryClient, fetchKeys });
        onClose();
      },
      onError: (error: AxiosErrorWithData) => {
        console.error(error);
        enqueueSnackbar(
          error.response?.data.message ?? configs.unknownErrorMessage,
          { variant: "error" }
        );
      },
    }
  );

  if (!data) return <></>;

  const {
    id,
    status,
    startedAt,
    endedAt,
    paymentSlipUrl,
    members: {
      code,
      profile: { firstName, lastName },
    },
  } = data;

  const handleUpdateStatus = (status: FacilityBookingStatus) =>
    update({ bookingId: id.toString(), status });

  const onClickReject = () => {
    setClickedButton(ButtonName.Reject);
    handleUpdateStatus(FacilityBookingStatus.Rejected);
  };

  const onClickConfirm = () => {
    setClickedButton(ButtonName.Confirm);

    const updateStatus =
      status === FacilityBookingStatus.Pending
        ? FacilityBookingStatus.Approved
        : FacilityBookingStatus.Cancelled;

    handleUpdateStatus(updateStatus);
  };

  const title = `${firstName} ${lastName}`;

  const confirmMessage =
    status === FacilityBookingStatus.Approved ? "ยกเลิกการจอง" : "ยืนยันการจอง";

  const isRejectButtonLoading =
    clickedButton === ButtonName.Reject && isUpdating;
  const isConfirmButtonLoading =
    clickedButton === ButtonName.Confirm && isUpdating;

  const showRejectButton = status === FacilityBookingStatus.Pending;
  const showConfirmButton = [
    FacilityBookingStatus.Approved,
    FacilityBookingStatus.Pending,
  ].includes(status);

  const columns = [
    [
      {
        title: "",
        data: [
          { label: "เลขสมาชิก", value: code },
          { label: "วันที่จอง", value: formatDate(startedAt) },
          { label: "เวลาที่จอง", value: formatTimeRange(startedAt, endedAt) },
          {
            label: "สถานะการจอง",
            key: status,
            value: (
              <Typography
                sx={{ color: formatFacilityBookingStatusColor(status) }}
              >
                {formatFacilityBookingStatus(status)}
              </Typography>
            ),
          },
        ],
      },
    ],
  ];

  return (
    <Dialog open={isOpen} title={title} onClose={onClose} maxWidth="sm">
      <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DataSection columns={columns} />
        <Typography variant="body2" color="text.disabled" my={2}>
          หลักฐานการชำระเงิน
        </Typography>
        {paymentSlipUrl ? (
          <PaymentImage url={paymentSlipUrl} />
        ) : (
          <EmptyPayment />
        )}
      </DialogContent>
      <DialogActions>
        {showRejectButton && (
          <LoadingButton
            loading={isRejectButtonLoading}
            disabled={isConfirmButtonLoading}
            color="inherit"
            variant="contained"
            onClick={onClickReject}
          >
            ไม่อนุมัติ
          </LoadingButton>
        )}
        {showConfirmButton && (
          <LoadingButton
            loading={isConfirmButtonLoading}
            disabled={isRejectButtonLoading}
            color="primary"
            variant="contained"
            onClick={onClickConfirm}
          >
            {confirmMessage}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
}

function EmptyPayment() {
  return (
    <Stack
      width={280}
      height={352}
      bgcolor="#0000000A"
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="subtitle2" color="text.disabled">
        ไม่พบหลักฐานการชำระเงิน
      </Typography>
    </Stack>
  );
}

function PaymentImage({ url }: { url: string }) {
  return (
    <Box component="img" width={280} height={352} src={url} alt="payment" />
  );
}
