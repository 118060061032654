import { Grid, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";

import { DatePicker } from "@/components/DatePicker";
import { now } from "@/lib/dateTime";
import { getMemberGoalCountSummary } from "@/services/dashboard";
import { TrainingStatus } from "@/models";

import { MemberCountPieChart } from "./MemberCountPieChart";
import { GoalCountBarChart } from "./GoalCountBarChart";
import { CompareGoalCountBarChart } from "./CompareGoalCountBarChart";
import { TrainerTrainingSummaryTable } from "./TrainerTrainingSummaryTable";
import { SummarizeTrainingSummaryTable } from "./SummarizeTrainingSummaryTable";

import type { DateTime } from "luxon";

export function TrainingSummary() {
  const currentStart = now().startOf("month");
  const currentEnd = now().endOf("month");
  const [startedAt, setStartedAt] = useState(currentStart);
  const [endedAt, setEndedAt] = useState(currentEnd);

  const handleChangeStart = (value: DateTime | null) => {
    const newValue = value ?? currentStart;
    setStartedAt(newValue);
  };

  const handleChangeEnd = (value: DateTime | null) => {
    const newValue = value ?? currentEnd;
    setEndedAt(newValue.endOf("month"));
  };

  const { data, isFetching } = useQuery(
    ["getMemberGoalCountSummary", startedAt.toISO(), endedAt.toISO()],
    () => getMemberGoalCountSummary({ startedAt, endedAt })
  );

  const memberGoalCount = { data, isFetching };
  const dateRange = { startedAt, endedAt };

  return (
    <Stack gap={3.5}>
      <Stack direction="row" gap={2} alignItems="center">
        <DatePicker
          disableFuture
          onChange={handleChangeStart}
          value={startedAt}
          views={["month", "year"]}
          maxDate={endedAt}
          inputFormat="MMMM yyyy"
          openTo="month"
        />
        <Typography>-</Typography>
        <DatePicker
          disableFuture
          onChange={handleChangeEnd}
          value={endedAt}
          views={["month", "year"]}
          minDate={startedAt}
          inputFormat="MMMM yyyy"
          openTo="month"
        />
      </Stack>
      <Grid container spacing={3}>
        <Grid item xs={12} xl={6}>
          <MemberCountPieChart {...memberGoalCount} />
        </Grid>
        <Grid item xs={12} xl={6}>
          <GoalCountBarChart {...memberGoalCount} />
        </Grid>
        <Grid item xs={12}>
          <GoalCountBarChart
            fullWidth
            showDataStatus={TrainingStatus.InProgress}
            {...memberGoalCount}
          />
        </Grid>
        <Grid item xs={12} xl={6}>
          <GoalCountBarChart
            showDataStatus={TrainingStatus.Success}
            {...memberGoalCount}
          />
        </Grid>
        <Grid item xs={12} xl={6}>
          <GoalCountBarChart
            showDataStatus={TrainingStatus.Failed}
            {...memberGoalCount}
          />
        </Grid>
        <Grid item xs={12}>
          <CompareGoalCountBarChart {...memberGoalCount} />
        </Grid>
        <Grid item xs={12}>
          <TrainerTrainingSummaryTable {...dateRange} />
        </Grid>
        <Grid item xs={12}>
          <SummarizeTrainingSummaryTable {...dateRange} />
        </Grid>
      </Grid>
    </Stack>
  );
}
