import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";

import {
  ProductEditorDialog,
  useProductEditorForm,
} from "@/features/product/components/ProductEditorDialog";
import { updateProduct } from "@/services/product";
import { configs } from "@/configs";
import { refetchQueries } from "@/utils";

import type { Product } from "@/models";
import type { AxiosErrorWithData } from "@/client/api";

export type EditProductDialogProps = {
  open: boolean;
  data?: Product;
  onClose: () => void;
  id: string;
  fetchKeys?: string[];
};

export function EditProductDialog({
  open: isOpen,
  onClose,
  id,
  data,
  fetchKeys = [],
}: EditProductDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, reset } = useProductEditorForm();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isOpen && data) {
      reset({
        name: data.name,
        price: data.price,
        description: data.description,
      });
    }
  }, [isOpen, data, reset]);

  function close() {
    onClose();
  }

  const { mutate: update, isLoading } = useMutation(updateProduct, {
    onSuccess: async () => {
      enqueueSnackbar("บันทึกสินค้าสำเร็จ", { variant: "success" });
      await refetchQueries({ queryClient, fetchKeys });
      close();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(
        error.response?.data.message ?? configs.unknownErrorMessage,
        { variant: "error" }
      );
    },
  });

  const onSubmit = handleSubmit((form) => {
    const input = {
      id: +id,
      name: form.name,
      price: form.price,
      description: form.description,
    };

    update(input);
  });

  const title = "แก้ไขสินค้า";

  return (
    <ProductEditorDialog
      open={isOpen}
      title={title}
      onClose={close}
      onSubmit={onSubmit}
      loading={isLoading}
      control={control}
    />
  );
}
