import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Stack,
  Table,
  TableContainer,
  TableHead,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";

import { QueryTextField } from "@/components/QueryTextField";
import { mapOptional, pickListTableParams, useRequireParams } from "@/utils";
import { formatOpportunityStatus } from "@/formatter";
import { getOpportunities } from "@/services/opportunity";
import { ItemsTotal } from "@/components/ItemsTotal";
import { DataTableBody } from "@/components/DataTableBody";
import { SearchParamsPagination } from "@/components/SearchParamsPagination";
import { OpportunityStatus } from "@/models";

import {
  OpportunityTableRow,
  OpportunityTableRowHeader,
} from "../../components/OpportunityTableRow";
import { AddOpportunityDialog } from "./AddOpportunityDialog";

const QUERY_KEY = "opportunities";

export function OpportunityListPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const query = pickListTableParams(searchParams);
  const { saleId: id } = useRequireParams(["saleId"]);
  const { data: raw, isFetching } = useQuery([QUERY_KEY, query], () =>
    getOpportunities({ saleId: id, query })
  );

  const data = raw?.data ?? [];
  const total = raw?.total;

  const dialog = searchParams.get("dialog");

  const setStatus = (s: OpportunityStatus | null) => () => {
    if (s) {
      searchParams.set("status", s);
    } else {
      searchParams.delete("status");
    }

    searchParams.delete("page");
    setSearchParams(searchParams);
  };

  const status = searchParams.get("status") as OpportunityStatus | undefined;

  function add() {
    searchParams.set("dialog", "opportunity-add");
    setSearchParams(searchParams);
  }

  function onCloseDialog() {
    searchParams.delete("dialog");
    setSearchParams(searchParams, { replace: true });
  }

  const addOpportunityDialog = {
    open: dialog === "opportunity-add",
    onClose: onCloseDialog,
    fetchKeys: [QUERY_KEY],
  };

  return (
    <Box>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap={2}
            mb={2}
          >
            <QueryTextField
              placeholder="ค้นหาด้วยหมายเลขสมาชิกหรือชื่อ"
              sx={{ width: 424 }}
            />
            <ButtonGroup variant="contained" size="large" color="inherit">
              {[
                null,
                OpportunityStatus.Open,
                OpportunityStatus.CloseWon,
                OpportunityStatus.CloseLose,
              ].map((s) => (
                <Button
                  key={s}
                  onClick={setStatus(s)}
                  sx={{ bgcolor: s === status ? "grey.100" : "grey.300" }}
                >
                  {mapOptional(s, formatOpportunityStatus) ?? "ทั้งหมด"}
                </Button>
              ))}
            </ButtonGroup>
            <Button variant="contained" size="large" onClick={add}>
              เพิ่มเสนอการขาย
            </Button>
          </Stack>
          <ItemsTotal count={total} />
          <TableContainer sx={{ height: "600px", overflowX: "hidden" }}>
            <Table stickyHeader>
              <TableHead>
                <OpportunityTableRowHeader />
              </TableHead>
              <DataTableBody loading={isFetching} data={data}>
                {data.map((item) => (
                  <OpportunityTableRow key={item.id} data={item} />
                ))}
              </DataTableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <SearchParamsPagination total={total} />
      <AddOpportunityDialog {...addOpportunityDialog} />
    </Box>
  );
}
