import { useSearchParams, useNavigate } from "react-router-dom";
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { Helmet } from "react-helmet-async";
import { LoadingButton } from "@mui/lab";

import {
  formatDateTimeRange,
  formatDateTime,
  formatProfiles,
} from "@/formatter";
import {
  getDocumentTitle,
  getScheduleStatus,
  mapOptional,
  useRequireParams,
} from "@/utils";
import { ScheduleStatus } from "@/models";
import { now } from "@/lib/dateTime";

import { ScheduleStatusLabel } from "./ScheduleStatusLabel";
import { RemoveScheduleDialog } from "./RemoveScheduleDialog";
import { CancelScheduleDialog } from "./CancelScheduleDialog";
import { EditScheduleDialog } from "./EditScheduleDialog";

import type { Schedule } from "@/models";
import type { RemoveScheduleDialogProps } from "./RemoveScheduleDialog";
import type { CancelScheduleDialogProps } from "./CancelScheduleDialog";

export type ClassScheduleDetailProps = {
  data: Schedule;
  fetchKeys?: string[];
};

export function ClassScheduleDetail({
  data,
  fetchKeys = [],
}: ClassScheduleDetailProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    id,
    startedAt,
    endedAt,
    joinedAt,
    location,
    capacity,
    staffs,
    description,
    cancelBefore,
    numberOfBookings,
  } = data;

  const { id: classId } = useRequireParams(["id", "scheduleId"]);

  const durationInMinute = endedAt.diff(startedAt, ["minutes"]).minutes;

  const scheduleTitle = `${formatDateTimeRange(
    startedAt,
    endedAt
  )} (${durationInMinute} นาที)`;

  // TODO: confirm ui when no joinedAt
  const joinedStart = formatDateTime(joinedAt ?? now().startOf("hour"));

  const staffNames =
    mapOptional(staffs, formatProfiles)
      ?.split(",")
      .map((name) => (
        <span key={name}>
          {name}
          <br />
        </span>
      )) ?? "-";

  const cancelBeforeTime = startedAt.diff(cancelBefore, ["hours"]).hours;

  const status = getScheduleStatus(data);

  const showButton = [ScheduleStatus.Active, ScheduleStatus.Upcoming].includes(
    status
  );

  const openClickEdit: JSX.IntrinsicElements["button"]["onClick"] = (event) => {
    event.stopPropagation();
    searchParams.set("dialog", "schedule-edit");
    setSearchParams(searchParams);
  };

  const onClickRemove: JSX.IntrinsicElements["button"]["onClick"] = (event) => {
    event.stopPropagation();
    searchParams.set("dialog", "schedule-remove");
    setSearchParams(searchParams);
  };

  const onClickCancel: JSX.IntrinsicElements["button"]["onClick"] = () => {
    searchParams.set("dialog", "schedule-cancel");
    setSearchParams(searchParams);
  };

  const dialog = searchParams.get("dialog");
  const navigate = useNavigate();

  function onCloseDialog() {
    searchParams.delete("dialog");
    setSearchParams(searchParams);
  }

  const editDialog = {
    open: dialog === "schedule-edit",
    onClose: onCloseDialog,
    id: id.toString(),
    data,
    fetchKeys,
  };

  const removeDialog: RemoveScheduleDialogProps = {
    open: dialog === "schedule-remove",
    id: data.id.toString(),
    onClose(reason) {
      if (reason === "cancel") {
        onCloseDialog();
        return;
      }
      navigate(`../${classId}`);
    },
  };

  const cancelDialog: CancelScheduleDialogProps = {
    open: dialog === "schedule-cancel",
    id: data.id.toString(),
    onClose: onCloseDialog,
    fetchKeys,
  };

  const scheduleLabel = {
    variant: "subtitle1" as const,
    fontWeight: 500,
    color: "text.disabled",
  };

  return (
    <Stack>
      <Helmet>
        <title>{getDocumentTitle(scheduleTitle)}</title>
      </Helmet>
      <Stack direction="row" mb={2}>
        <DateRangeIcon color="primary" sx={{ mr: 2.5 }} />
        <Typography variant="body1" sx={{ mr: 1.5 }}>
          {scheduleTitle}
        </Typography>
        <ScheduleStatusLabel status={status} mr="auto" />
        {showButton && (
          <>
            <LoadingButton sx={{ mr: 2.5 }} onClick={onClickCancel}>
              ยกเลิกตารางเวลา
            </LoadingButton>
            <Tooltip title="แก้ไข">
              <IconButton onClick={openClickEdit}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
        <Tooltip title="ลบ">
          <IconButton onClick={onClickRemove}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Stack>
      <Box
        display="grid"
        gridTemplateColumns="150px 1fr 150px 1fr"
        gap={3}
        alignItems="baseline"
      >
        <Typography {...scheduleLabel}>วันที่และเวลาเปิดจอง</Typography>
        <Typography variant="body2">{joinedStart}</Typography>
        <Typography {...scheduleLabel}>สถานที่</Typography>
        <Typography variant="body2">{location}</Typography>
        <Typography {...scheduleLabel}>จำนวนผู้จอง</Typography>
        <Typography variant="body2">{`${numberOfBookings}/${capacity} คน`}</Typography>
        <Typography {...scheduleLabel}>ผู้สอน</Typography>
        <Typography variant="body2">{staffNames}</Typography>
        <Typography {...scheduleLabel}>
          ยกเลิกการจอง
          <br />
          (ก่อนเริ่มคลาส)
        </Typography>
        <Typography variant="body2">{`${cancelBeforeTime} ชั่วโมง`}</Typography>
        <Typography {...scheduleLabel}>รายละเอียด</Typography>
        <Typography variant="body2">{description || "-"}</Typography>
      </Box>
      <EditScheduleDialog {...editDialog} />
      <RemoveScheduleDialog {...removeDialog} />
      <CancelScheduleDialog {...cancelDialog} />
    </Stack>
  );
}
