import { useController } from "react-hook-form";
import { RadioGroup } from "@mui/material";

import type { RadioGroupProps } from "@mui/material";
import type { ControllerProps, FieldPath, FieldValues } from "react-hook-form";

export function FormRadioGroup<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  ...props
}: Pick<ControllerProps<TFieldValues, TName>, "name" | "control"> &
  RadioGroupProps) {
  const { field } = useController({
    name,
    control,
  });

  return (
    <RadioGroup value={field.value} onChange={field.onChange} {...props} />
  );
}
