import { FormConfirmDialog } from "@/components/FormConfirmDialog";

import { MemberProfileEditor } from "../../pages/MemberListPage/MemberProfileEditor";

import type { MemberProfileEditorState } from "../../pages/MemberListPage/MemberProfileEditor";
import type { Control } from "react-hook-form";

export function EditMemberEditorDialog({
  open,
  title,
  onClose,
  onSubmit,
  loading,
  control,
}: {
  open: boolean;
  title: string;
  onClose: () => void;
  onSubmit: () => void | PromiseLike<void>;
  loading: boolean;
  control: Control<MemberProfileEditorState>;
}) {
  return (
    <FormConfirmDialog
      control={control}
      title={title}
      loading={loading}
      open={open}
      onClose={onClose}
      onConfirm={onSubmit}
    >
      <MemberProfileEditor control={control} />
    </FormConfirmDialog>
  );
}
