import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";

import { configs } from "@/configs";
import { refetchQueries, setTime } from "@/utils";
import { createFacility } from "@/services/facility";
import { now } from "@/lib/dateTime";

import {
  FacilityEditorDialog,
  useFacilityEditorForm,
} from "../../components/FacilityEditorDialog";

import type { AxiosErrorWithData } from "@/client/api";

export function AddFacilityDialog({
  open: isOpen,
  onClose,
  fetchKeys = [],
}: {
  open: boolean;
  onClose: () => void;
  fetchKeys?: string[];
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, reset } = useFacilityEditorForm();
  const queryClient = useQueryClient();

  const { mutate: add, isLoading } = useMutation(createFacility, {
    onSuccess: async () => {
      enqueueSnackbar("เพิ่มสิ่งอำนวยความสะดวกสำเร็จ", { variant: "success" });
      await refetchQueries({ queryClient, fetchKeys });
      close();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(
        error.response?.data.message ?? configs.unknownErrorMessage,
        { variant: "error" }
      );
    },
  });

  function close() {
    reset();
    onClose();
  }

  const onSubmit = handleSubmit((form) => {
    add({
      name: form.name,
      type: form.type,
      capacity: form.capacity,
      description: form.description ?? "",
      openedAt: setTime(now(), form.openedAt),
      closedAt: setTime(now(), form.closedAt),
      thumbnailUrl: form.images.length > 0 ? form.images[0].url : null,
      memberFee: form.memberFee ?? null,
      guestFee: form.guestFee ?? null,
    });
  });

  const title = "เพิ่มสิ่งอำนวยความสะดวก";

  return (
    <FacilityEditorDialog
      open={isOpen}
      title={title}
      onClose={close}
      onSubmit={onSubmit}
      loading={isLoading}
      control={control}
    />
  );
}
