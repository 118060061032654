import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
} from "@mui/lab";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";

import { DataSection } from "@/components/DataSection";
import { formatDate, formatDateTime } from "@/formatter";
import { FollowUpChangeLog } from "@/models";

type FollowUpChangeLogTimelineProps = {
  changeLogs: FollowUpChangeLog[];
};

export function FollowUpChangeLogTimeline({
  changeLogs,
}: FollowUpChangeLogTimelineProps) {
  return (
    <Card>
      <CardContent>
        <Stack gap={3}>
          <Typography variant="h6">ประวัติการแก้ไข</Typography>
          <Timeline
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}
          >
            {changeLogs.map((changeLog, index) => {
              const { createdAt, updatedBy, followedAt, remark, reason } =
                changeLog;

              const isLastItem = changeLogs.length === index + 1;

              const editReason = isLastItem
                ? []
                : [
                    {
                      label: "เหตุผลในการแก้ไข",
                      value: reason || "-",
                    },
                  ];

              const columns2 = [
                [
                  {
                    data: [
                      {
                        label: "วันที่",
                        value: formatDate(followedAt),
                      },
                      {
                        label: "หมายเหตุ",
                        value: remark || "-",
                      },
                      ...editReason,
                    ],
                  },
                ],
              ];

              return (
                <TimelineItem key={`${createdAt.toISO()}-${index}`}>
                  <TimelineSeparator>
                    <TimelineDot color="primary" />
                    {!isLastItem && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent>
                    <Stack gap={2}>
                      <Stack direction="row" gap={2} alignItems="center">
                        <Typography variant="body1">
                          {formatDateTime(createdAt, "-")}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          โดย {updatedBy}
                          {isLastItem && (
                            <Box component="span" color="error.main">
                              {" "}
                              (สร้าง)
                            </Box>
                          )}
                        </Typography>
                      </Stack>
                      <DataSection
                        columns={columns2}
                        boxProps={{
                          gridTemplateColumns: "120px 1fr",
                          rowGap: 0.5,
                        }}
                      />
                    </Stack>
                  </TimelineContent>
                </TimelineItem>
              );
            })}
          </Timeline>
        </Stack>
      </CardContent>
    </Card>
  );
}
