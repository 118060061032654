import { useController, useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import { Box, FormControlLabel, Radio, Stack, Typography } from "@mui/material";
import { useEffect } from "react";

import { FormNumericTextField } from "@/components/NumericTextField";
import { FormDatePicker } from "@/components/DatePicker";
import { FormTextField } from "@/components/FormTextField";
import { FormRadioGroup } from "@/components/FormRadioGroup";
import { yupResolver } from "@hookform/resolvers/yup";
import { now } from "@/lib/dateTime";
import {
  formatDurationUnit,
  formatPrice,
  formatPurchaseType,
  formatQuotaUnit,
} from "@/formatter";
import { datetime } from "@/lib/yup";
import { PurchaseType, QuotaUnit } from "@/models";

import { FormProductPTAutocomplete } from "../pages/MemberListPage/ProductPTAutocomplete";
import { calculateDiscount } from "../utils";

import type { ControllerProps, UseFormReset } from "react-hook-form";
import type { InferType } from "yup";
import type { DurationObjectUnits } from "luxon";

export type PurchaseProductPTEditorState = InferType<typeof schema>;

export type PurchaseProductPTEditorProps = Pick<
  ControllerProps<PurchaseProductPTEditorState>,
  "control"
> & { reset?: UseFormReset<PurchaseProductPTEditorState> };

export function PurchaseProductPTEditor({
  control,
  reset,
}: PurchaseProductPTEditorProps) {
  const packagePT = useWatch({ control, name: "packagePT" });
  const start = useWatch({ control, name: "dateRange.start" });
  const required = useWatch({ name: "required", control });

  const {
    field: { onChange },
  } = useController({ name: "dateRange.end", control });

  const discountType = useWatch({ name: "discountType", control });
  const discountPercentage = useWatch({ name: "discountPercentage", control });
  const discountBaht = useWatch({ name: "discountBaht", control });
  const {
    field: { onChange: onDiscountPercentChange },
  } = useController({ name: "discountPercentage", control });
  const {
    field: { onChange: onDiscountBahtChange },
  } = useController({ name: "discountBaht", control });

  useEffect(() => {
    if (packagePT && start) {
      const end = start.plus({ ...packagePT.duration }).plus({ days: -1 });
      onChange(end);
    }
  }, [packagePT, start, onChange]);

  useEffect(() => {
    if (!required) reset?.();
  }, [required, reset]);

  useEffect(() => {
    onDiscountPercentChange(undefined);
    onDiscountBahtChange(undefined);
  }, [discountType, onDiscountPercentChange, onDiscountBahtChange]);

  useEffect(() => {
    onDiscountBahtChange(
      calculateDiscount(packagePT?.price ?? 0, discountPercentage ?? 0)
    );
  }, [discountPercentage, onDiscountBahtChange]);

  const disabled = !packagePT || !required;

  const packagePTDuration = packagePT?.duration as DurationObjectUnits;

  const unit = packagePT
    ? (Object.keys(packagePT?.duration)[0] as keyof DurationObjectUnits)
    : "months";
  const duration = packagePTDuration ? packagePTDuration[unit] : 0;

  return (
    <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2.5}>
      <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2.5}>
        <FormProductPTAutocomplete
          label={`ชื่อแพ็กเกจเทรนเนอร์`}
          name="packagePT"
          control={control}
          disabled={!required}
          sx={{ gridColumn: "1/span 2" }}
          required
        />
        <FormDatePicker
          label="วันเริ่มต้น"
          name="dateRange.start"
          control={control}
          disabled={disabled}
        />
        <FormDatePicker
          label="วันสิ้นสุด"
          name="dateRange.end"
          control={control}
          disabled
        />
        <Box display="grid" gridColumn="1/span 2">
          <Stack direction="row" gap={1.25} alignItems="center">
            <Typography variant="labelLarge">เลือกรูปแบบส่วนลด</Typography>
            <FormRadioGroup row name="discountType" control={control}>
              <FormControlLabel
                value="percentage"
                control={<Radio />}
                label="เปอร์เซ็นต์ %"
                disabled={disabled}
              />
              <FormControlLabel
                value="baht"
                control={<Radio />}
                label="บาท"
                disabled={disabled}
              />
            </FormRadioGroup>
          </Stack>
        </Box>
        <FormNumericTextField
          label="ส่วนลด (%)"
          name="discountPercentage"
          control={control}
          disabled={disabled || discountType === "baht"}
        />
        <FormNumericTextField
          label="ราคาส่วนลด (บาท)"
          name="discountBaht"
          control={control}
          disabled={disabled || discountType === "percentage"}
        />
        {/* <FormPersonalTrainerAutocomplete
        fullWidth
        label="ชื่อ Trainer"
        name="trainer"
        control={control}
        disabled={disabled}
        sx={{ gridColumn: "span 2" }}
        required
      /> */}
        <FormTextField
          label="หมายเหตุ"
          name="note"
          control={control}
          multiline
          rows={4}
          sx={{ gridColumn: "1/-1" }}
          disabled={!required}
        />
      </Box>
      <Box
        bgcolor="background.default"
        p={2}
        borderRadius={2}
        border="1px solid #9E9E9E3D"
      >
        <Box
          display="grid"
          gridTemplateColumns="150px 1fr"
          gap={3}
          alignItems="baseline"
        >
          <Typography variant="body1" color="text.disabled" fontWeight={500}>
            ระยะเวลา
          </Typography>
          <Typography variant="body2">
            {duration && duration !== 0
              ? `${duration} ${formatDurationUnit(unit)}`
              : "-"}
          </Typography>
          <Typography variant="body1" color="text.disabled" fontWeight={500}>
            จำนวน
          </Typography>
          <Typography variant="body2">
            {packagePT
              ? `${packagePT.quota ?? "-"} ${formatQuotaUnit(
                  packagePT.quotaUnit as QuotaUnit
                )}`
              : "-"}
          </Typography>
          <Typography variant="body1" color="text.disabled" fontWeight={500}>
            ราคา
          </Typography>
          <Typography variant="body2">
            {packagePT ? formatPrice(packagePT.price) : "-"}
          </Typography>
          <Typography variant="body1" color="text.disabled" fontWeight={500}>
            ราคาหลังหักส่วนลด
          </Typography>
          <Typography variant="body2">
            {discountBaht && packagePT
              ? formatPrice(packagePT.price - (discountBaht ?? 0))
              : "-"}
          </Typography>
          <Typography variant="body1" color="text.disabled" fontWeight={500}>
            สถานที่เข้าใช้บริการ
          </Typography>
          <Stack>
            {packagePT
              ? packagePT.branches.map((branch) => (
                  <li key={branch.id}>{branch.name}</li>
                ))
              : "-"}
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}

export function usePurchaseProductPTEditorForm({ required = true } = {}) {
  return useForm({
    resolver,
    defaultValues: {
      ...defaultValues,
      required,
    },
  });
}

const packagePTSchema = yup.object({
  id: yup.string(),
  name: yup.string(),
  quota: yup.number(),
  duration: yup.object(),
  quotaUnit: yup.string().required(),
  price: yup.number().required(),
  branches: yup
    .array(
      yup.object({
        id: yup.string().required(),
        name: yup.string().required(),
      })
    )
    .required(),
});

const schema = yup.object({
  required: yup.boolean().required(),
  packagePT: packagePTSchema
    .when("required", {
      is: true,
      then: (schema) => schema.required(),
    })
    .default(null) // fix yup not validate when value is undefined
    .nullable()
    .label(`ชื่อ${formatPurchaseType(PurchaseType.ProductPT)}`),
  dateRange: yup.object({
    start: datetime()
      .required()
      .min(now().minus({ years: 1 })),
    end: datetime().required(),
  }),
  discountType: yup.string(),
  note: yup.string().label("หมายเหตุ").max(500),
  // trainer: yup
  //   .object({
  //     id: yup.string(),
  //     name: yup.string(),
  //     lastName: yup.string(),
  //     nickname: yup.string(),
  //   })
  //   .when("packagePT", {
  //     is: (value: unknown) => !!value,
  //     then: (schema) => schema.required(),
  //   })
  //   .default(null) // fix yup not validate when value is undefined
  //   .nullable()
  //   .label("ชื่อ Trainer"),
  discountPercentage: yup
    .number()
    .when("packagePT", {
      is: (value: unknown) => !!value,
      then: (schema) =>
        schema
          .test(
            "maxDigitsAfterDecimal",
            "number field must have 10 digits after decimal or less",
            (number) => /^\d+(\.\d{0,10})?$/.test(number?.toString() ?? "0")
          )
          .min(0)
          .max(100),
    })
    .label("ส่วนลด"),
  discountBaht: yup
    .number()
    .test(
      "maxDigitsAfterDecimal",
      "number field must have 10 digits after decimal or less",
      (number) => /^\d+(\.\d{0,10})?$/.test(number?.toString() ?? "0")
    )
    .label("ส่วนลด")
    .min(0),
});

const resolver = yupResolver(schema);

const defaultValues = {
  dateRange: { start: now().startOf("day"), end: now() },
  discountType: "percentage",
  discountPercentage: 0,
  discountBaht: 0,
} as PurchaseProductPTEditorState;
