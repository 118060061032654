import { useSnackbar } from "notistack";
import { useState } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import { useMutation, useQueryClient } from "react-query";

import { TextEllipsis } from "@/components/TextEllipsis";
import { DateRangeDisplay } from "@/components/DateRangeDisplay";
import { configs } from "@/configs";
import { BookingStatus } from "@/models";
import {
  calculateColumnWidth,
  getBookingStatus,
  mapOptional,
  refetchQueries,
} from "@/utils";
import {
  formatBookingStatus,
  formatBookingStatusColor,
  formatProfile,
} from "@/formatter";

import type { Booking } from "@/models";
import { cancelTrainerBooking } from "@/services/trainer";
import { AxiosErrorWithData } from "@/client/api";

const columnsWidth = [280, 356, 170, 222, 56].map((width) =>
  calculateColumnWidth(width)
);

export function PTReservationTableHeaderRow() {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]}>วันที่และเวลา</TableCell>
      <TableCell width={columnsWidth[1]}>เทรนเนอร์</TableCell>
      <TableCell width={columnsWidth[2]}>สาขา</TableCell>
      <TableCell width={columnsWidth[3]} align="center">
        สถานะ
      </TableCell>
      <TableCell width={columnsWidth[4]} />
    </TableRow>
  );
}

export type PTReservationTableRowProps = {
  data: Booking;
  fetchKeys?: string[];
};

export function PTReservationTableRow({
  data,
  fetchKeys = [],
}: PTReservationTableRowProps) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const {
    id,
    schedule: { startedAt, endedAt, scheduleTrainers, branch },
  } = data;
  const status = getBookingStatus(data);

  const staff = scheduleTrainers?.[0].staff;
  const staffId = staff.id;

  const trainerName = mapOptional(staff.profile, formatProfile) ?? "-";

  const isShowMenu = [
    BookingStatus.Booked,
    BookingStatus.Waiting,
    BookingStatus.Pending,
  ].includes(status);

  const { mutate: cancel } = useMutation(cancelTrainerBooking, {
    onSuccess: async () => {
      enqueueSnackbar("ยกเลิกการจองสำเร็จ", { variant: "success" });
      await refetchQueries({ queryClient, fetchKeys });
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(
        error.response?.data.message ?? configs.unknownErrorMessage,
        { variant: "error" }
      );
    },
  });

  function handleClickCancelBooking() {
    void cancel({ staffId, bookingId: id });
    setAnchorEl(null);
  }

  return (
    <TableRow>
      <TableCell>
        <DateRangeDisplay variant="body2" start={startedAt} end={endedAt} />
      </TableCell>
      <TableCell>
        <TextEllipsis variant="body2" line={3}>
          {trainerName}
        </TextEllipsis>
      </TableCell>
      <TableCell>{branch.name}</TableCell>
      <TableCell aria-label="Status">
        <Typography
          variant="body2"
          align="center"
          fontWeight={500}
          sx={{ color: formatBookingStatusColor(status) }}
        >
          {formatBookingStatus(status)}
        </Typography>
      </TableCell>
      <TableCell sx={{ px: 0 }}>
        {isShowMenu && (
          <Tooltip title="เมนู">
            <IconButton
              aria-label="Menu"
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              <MoreHoriz />
            </IconButton>
          </Tooltip>
        )}
        <Menu
          open={open}
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem onClick={handleClickCancelBooking}>ยกเลิกการจอง</MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
}
