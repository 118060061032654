import { Box, CircularProgress, Typography, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useState, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";

import { DebounceTextField } from "@/components/DebounceTextField";
import { verify } from "@/services/member";
import { refetchQueries } from "@/utils";

const stateConfig = {
  loading: {
    icon: <CircularProgress disableShrink />,
    wording: "กำลังสแกน QR Code และตรวจสอบข้อมูลของสมาชิก",
    color: "text.primary",
  },
  success: {
    icon: <CheckIcon color="success" />,
    wording: "ยืนยันสมาชิกสำเร็จ",
    color: "success.main",
  },
  error: {
    icon: <CloseIcon color="error" />,
    wording: "รหัสอ้างอิงไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง",
    color: "error.main",
  },
  timeout: {
    icon: <CloseIcon color="error" />,
    wording: "ไม่สามารถยืนยันสมาชิกได้ กรุณาลองใหม่อีกครั้ง",
    color: "error.main",
  },
};

type StateType = keyof typeof stateConfig;

function useVerifyMember() {
  return useMutation(verify);
}

export function VerifyMember({ id }: { id: number }) {
  const [state, setState] = useState<StateType>("loading");
  const [refCode] = useState("");

  const { mutate: verify } = useVerifyMember();
  const queryClient = useQueryClient();

  useEffect(() => {
    const handler = setTimeout(() => {
      setState("timeout");
    }, 60000);
    return () => clearTimeout(handler);
  }, []);

  const { icon, color, wording } = stateConfig[state];

  const onChangeRefCode: JSX.IntrinsicElements["input"]["onChange"] = (
    event
  ) => {
    const refCode = event.target.value;
    if (!refCode) return;

    const input = {
      id,
      data: {
        code: refCode,
      },
    };
    verify(input, {
      onSuccess: async () => {
        setState("success");
        await refetchQueries({ queryClient, fetchKeys: ["member", "members"] });
      },
      onError: (error) => {
        console.error(error);
        setState("error");
      },
    });
  };

  return (
    <Stack gap={3}>
      <Box
        display="flex"
        gridTemplateColumns="50px 1fr"
        gap={3}
        alignItems="center"
      >
        {icon}
        <Typography color={color} variant="subtitle1">
          {wording}
        </Typography>
      </Box>
      <DebounceTextField
        value={refCode}
        onChange={onChangeRefCode}
        placeholder="รหัสอ้างอิง"
        autoFocus
      />
    </Stack>
  );
}
