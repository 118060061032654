import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { FormTextField } from "@/components/FormTextField";
import { FormConfirmDialog } from "@/components/FormConfirmDialog";
import { getApiErrorMessage, refetchQueries } from "@/utils";
import { createRemark } from "@/services/remark";
import { useCurrentUser } from "@/features/authentication/contexts/AuthenticationContext";

import type { InferType } from "yup";
import type { Remark } from "@/models";
import type { AxiosErrorWithData } from "@/client/api";

type CustomerRemarkEditorState = InferType<typeof schema>;

type EditCustomerRemarkDialogProps = {
  open: boolean;
  customerId: number;
  data?: Remark;
  onClose: () => void;
  fetchKeys?: string[];
};

export function EditCustomerRemarkDialog({
  open: isOpen,
  customerId,
  data,
  onClose,
  fetchKeys = [],
}: EditCustomerRemarkDialogProps) {
  const { control, handleSubmit, reset } = useCustomerRemarkEditorForm();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { staffId } = useCurrentUser();

  useEffect(() => {
    if (isOpen) reset({ remark: data?.remark || "" });
  }, [data?.remark, isOpen, reset]);

  const { mutate: add, isLoading } = useMutation(createRemark, {
    onSuccess: async () => {
      enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
      await refetchQueries({ queryClient, fetchKeys });
      close();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(getApiErrorMessage(error), { variant: "error" });
    },
  });

  function close() {
    onClose();
    reset();
  }

  const onSubmit = handleSubmit((form) => {
    add({
      customerId,
      staffId,
      remark: form.remark,
    });
  });

  const title = "แก้ไขบันทึกเพิ่มเติม";

  return (
    <FormConfirmDialog
      maxWidth="md"
      control={control}
      title={title}
      loading={isLoading}
      open={isOpen}
      onClose={close}
      onConfirm={onSubmit}
    >
      <FormTextField
        label="เขียนบันทึก"
        name="remark"
        control={control}
        multiline
        rows={4}
        fullWidth
        required
      />
    </FormConfirmDialog>
  );
}

function useCustomerRemarkEditorForm() {
  return useForm({
    resolver,
    defaultValues,
  });
}

const schema = yup.object({
  remark: yup.string().label("บันทึก").required(),
});

const resolver = yupResolver(schema);

const defaultValues = { remark: "" } as CustomerRemarkEditorState;
