import { DataSection } from "@/components/DataSection";
import {
  formatDay,
  formatTimeRange,
  formatTrainerContract,
  sortDay,
} from "@/formatter";
import { getOfficeDay } from "@/utils";

import type { TrainerProfile } from "@/models";

export type TrainerProfileDetailProps = {
  data: TrainerProfile;
};

export function TrainerProfileDetail({ data }: TrainerProfileDetailProps) {
  const {
    youtubeIntroUrl,
    personalTraining,
    contract,
    skills,
    certificates,
    experiences,
    workingDays,
  } = data;

  const officeDay = getOfficeDay(workingDays);

  const officeDayDisplay = officeDay
    .filter((day) => day.enable)
    .slice(0)
    .sort(({ day: d1 }, { day: d2 }) => sortDay(d1, d2))
    .map((day) => ({
      label: formatDay(day.day),
      value:
        day.timeRange &&
        formatTimeRange(day.timeRange.start, day.timeRange.end),
      color: "text.primary",
    }));

  const columns = [
    [
      {
        title: "ข้อมูลการสอน",
        data: [
          { label: "วิดีโอแนะนำตัว", value: youtubeIntroUrl ?? "-" },
          {
            label: "รูปแบบการสอน",
            value: personalTraining
              ? "รับเทรนแบบส่วนตัว"
              : "ไม่รับเทรนแบบส่วนตัว",
          },
          { label: "ประเภท", value: formatTrainerContract(contract) },
          {
            label: "วันและเวลาเข้างาน",
            value: " ",
          },
          ...officeDayDisplay,
        ],
      },
    ],
    [
      {
        title: "ข้อมูลเฉพาะทาง",
        data: [
          { label: "ความเชี่ยวชาญ", value: skills.join("\n") },
          { label: "ใบรับรอง", value: certificates.join("\n") },
          { label: "ประวัติการศึกษา", value: experiences.join("\n") },
        ],
      },
    ],
  ];

  return <DataSection columns={columns} label="" />;
}
