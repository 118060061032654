import * as Sentry from "@sentry/react";
import { clarity } from "clarity-js";

import { CssBaseline, ThemeProvider } from "@mui/material";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import ErrorOutline from "@mui/icons-material/ErrorOutlined";
import InfoOutline from "@mui/icons-material/InfoOutlined";
import ReportProblemOutlined from "@mui/icons-material/ReportProblemOutlined";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { SnackbarProvider } from "notistack";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { QueryClient, QueryClientProvider } from "react-query";

import { MainLayout } from "./components/MainLayout";
import { AuthenticationProvider } from "./features/authentication/contexts/AuthenticationContext";
import { AuthenticatedGuard } from "./features/authentication/guards/AuthenticatedGuard";
import { NonAuthenticatedGuard } from "./features/authentication/guards/NonAuthenticatedGuard";
import { SignInPage } from "./features/authentication/pages/SignInPage";
import { theme } from "./theme";
import { ClassListPage } from "./features/class/pages/ClassListPage";
import { ClassDetailPage } from "./features/class/pages/ClassDetailPage";
import { ClassScheduleDetailPage } from "@/features/class/pages/ClassScheduleDetailPage";
import { SaleListPage } from "./features/sale/pages/SaleListPage/SaleListPage";
import { SaleProfilePage } from "./features/sale/pages/SaleProfilePage/SaleProfilePage";
import { MemberListPage as SaleMemberListPage } from "./features/sale/pages/SaleProfilePage/MemberListPage";
import { MemberProfilePage as SaleMemberProfilePage } from "./features/sale/pages/SaleProfilePage/MemberProfilePage";
import { TrainerListPage } from "./features/trainer/pages/TrainerListPage";
import { TrainerProfilePage } from "./features/trainer/pages/TrainerProfilePage";
import { CategoryListPage } from "./features/category/pages/CategoryListPage";
import { CategoryDetailPage } from "./features/category/pages/CategoryDetailPage";
import { VideoDetailPage } from "@/features/video/pages/VideoDetailPage";
import { ProductAndPackageListPage } from "@/features/product/pages/ProductAndPackageListPage";
import { ProductPTListPage } from "@/features/product/pages/ProductPTListPage";
import { MembershipListPage } from "@/features/product/pages/MembershipListPage";
import { ProductListPage } from "./features/product/pages/ProductListPage/ProductListPage";
import { MembershipDetailPage } from "@/features/product/pages/MembershipDetailPage";
import { ProductPTDetailPage } from "@/features/product/pages/ProductPTDetailPage";
import { ProductDetailPage } from "@/features/product/pages/ProductDetailPage";
import { MemberListPage } from "./features/member/pages/MemberListPage";
import { MemberProfilePage } from "@/features/member/pages/MemberProfilePage";
import { MembershipDetailPage as MembershipDetailInMember } from "./features/member/pages/MemberProfilePage/MembershipDetailPage";
import { PTDetailPage } from "./features/member/pages/MemberProfilePage/PTDetailPage";
import { ClassReservationListPage } from "@/features/member/pages/ClassReservationListPage";
import { TransactionListPage } from "@/features/member/pages/TransactionListPage";
import { StaffListPage } from "./features/staff/pages/StaffListPage/StaffListPage";
import { StaffProfilePage } from "./features/staff/pages/StaffProfilePage/StaffProfilePage";
import { PTReservationListPage } from "./features/member/pages/PTReservationListPage";
import { CheckInListPage } from "./features/check-in/pages/CheckInListPage";
import { ClassSummaryPage } from "./features/dashboard/pages/ClassSummaryPage";
import {
  TrainerSummary,
  TrainerSummaryPage,
  TrainingSummary,
} from "./features/dashboard/pages/TrainerSummaryPage";
import { MemberSummaryPage } from "./features/dashboard/pages/MemberSummaryPage";
import { SalesSummaryPage } from "./features/dashboard/pages/SalesSummaryPage";
import { FitnessDetailPage } from "@/features/fitness/pages/FitnessDetailPage/FitnessDetailPage";
import { BranchDetailPage } from "@/features/fitness/pages/BranchDetailPage/BranchDetailPage";
import { ScheduleDetailPage } from "@/features/trainer/pages/ScheduleDetailPage/ScheduleDetailPage";
import { TrainerMemberProfilePage } from "@/features/trainer/pages/TrainerMemberProfilePage/TrainerMemberProfilePage";
import { CustomerListPage } from "@/features/sale/pages/CustomerListPage/CustomerListPage";
import { CustomerDetailPage } from "@/features/sale/pages/CustomerDetailPage/CustomerDetailPage";
import { SalesListPage } from "./features/sale/pages/SalesListPage/SalesListPage";
import { TrainerCalendar } from "./features/trainer/pages/TrainerProfilePage/TrainerCalendar";
import { ClassHistory } from "./features/trainer/pages/HistoryPage/ClassHistory";
import { PersonalTrainingHistory } from "./features/trainer/pages/HistoryPage/PTHistory";
import { TrainerList } from "./features/trainer/pages/TrainerListPage/TrainerList";
import { PendingBookingList } from "./features/trainer/pages/TrainerListPage/PendingBookingList";
import { BranchList } from "./features/fitness/pages/FitnessDetailPage/BranchList";
import { MemberCardSection } from "./features/fitness/pages/FitnessDetailPage/MemberCardSection";
import { ClassPage } from "./features/class/pages/ClassPage";
import { ClassSchedulesCalendar } from "./features/class/pages/ClassCalendarPage/ClassSchedulesCalendar";
import { ClassScheduleDetailPage as ClassScheduleCalendarDetailPage } from "./features/class/pages/ClassCalendarPage/ClassScheduleDetailPage";
import { OpportunityListPage } from "./features/sale/pages/OpportunityListPage/OpportunityListPage";
import { OpportunityDetailPage } from "./features/sale/pages/OpportunityDetailPage/OpportunityDetailPage";
import { OpportunitySummaryPage } from "./features/dashboard/pages/OpportunitySummaryPage";
import { ReceiptListPage } from "@/features/receipt/pages/ReceiptListPage";
import { FacilityListPage } from "@/features/facility/pages/FacilityListPage";
import { FacilityDetailPage } from "@/features/facility/pages/FacilityDetailPage";
import { TrainingListPage } from "./features/training/pages/TrainingListPage";
import { TrainerTrainingList } from "./features/trainer/pages/TrainerTrainingList";
import { MemberTrainingList } from "./features/member/pages/MemberTrainingList";
import { TrainingDetailPage } from "./features/training/pages/TrainingDetailPage";
import { TrainerTrainingDetailPage } from "./features/trainer/pages/TrainerTrainingDetailPage";
import { MemberTrainingDetailPage } from "./features/member/pages/MemberTrainingDetailPage";
import { FollowUpDetailPage } from "./features/training/pages/FollowUpDetailPage/";
import { TrainerFollowUpDetailPage } from "./features/trainer/pages/TrainerFollowUpDetailPage";
import { MemberFollowUpDetailPage } from "./features/member/pages/MemberFollowUpDetailPage";
import { PermissionGroupList } from "./features/fitness/pages/FitnessDetailPage/PermissionGroupList";

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

if (import.meta.env.VITE_ENV === "production") {
  clarity.consent();
  clarity.start({
    projectId: import.meta.env.VITE_CLARITY_ID as string,
    upload: "https://m.clarity.ms/collect",
    track: true,
    content: true,
  });
}

export function App() {
  return (
    <Sentry.ErrorBoundary>
      <HelmetProvider>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              preventDuplicate
              iconVariant={{
                success: <CheckCircleOutline />,
                error: <ErrorOutline />,
                warning: <ReportProblemOutlined />,
                info: <InfoOutline />,
              }}
              style={{ whiteSpace: "pre-line" }}
            >
              <QueryClientProvider client={queryClient}>
                <AuthenticationProvider>
                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                    <SentryRoutes>
                      <Route
                        path="/auth/sign-in"
                        element={
                          <NonAuthenticatedGuard>
                            <SignInPage />
                          </NonAuthenticatedGuard>
                        }
                      />
                      <Route
                        path=""
                        element={
                          <AuthenticatedGuard>
                            <MainLayout />
                          </AuthenticatedGuard>
                        }
                      >
                        <Route
                          path="/"
                          element={<Navigate to="/check-in" replace />}
                        />
                        <Route path="dashboard">
                          <Route
                            index
                            element={
                              <Navigate to="/dashboard/class-summary" replace />
                            }
                          />
                          <Route
                            path="class-summary"
                            element={<ClassSummaryPage />}
                          />
                          <Route
                            path="trainer-summary"
                            element={<TrainerSummaryPage />}
                          >
                            <Route index element={<TrainerSummary />} />
                            <Route
                              path="training"
                              element={<TrainingSummary />}
                            />
                          </Route>
                          <Route
                            path="member-summary"
                            element={<MemberSummaryPage />}
                          />
                          <Route
                            path="sales-summary"
                            element={<SalesSummaryPage />}
                          />
                          <Route
                            path="opportunity-summary"
                            element={<OpportunitySummaryPage />}
                          />
                        </Route>
                        <Route path="check-in" element={<CheckInListPage />} />
                        <Route path="classes">
                          <Route path="" element={<ClassPage />}>
                            <Route
                              path="calendar"
                              element={<ClassSchedulesCalendar />}
                            />
                            <Route index element={<ClassListPage />} />
                          </Route>
                          <Route
                            path="calendar/:id/schedules/:scheduleId"
                            element={<ClassScheduleCalendarDetailPage />}
                          />
                          <Route path=":id" element={<ClassDetailPage />} />
                          <Route
                            path=":id/schedules/:scheduleId"
                            element={<ClassScheduleDetailPage />}
                          />
                        </Route>
                        <Route path="sales">
                          <Route index element={<SaleListPage />} />
                          <Route path=":saleId" element={<SaleProfilePage />}>
                            <Route index element={<SaleMemberListPage />} />
                            <Route path="sales" element={<SalesListPage />} />
                            <Route
                              path="customers"
                              element={<CustomerListPage />}
                            />
                            <Route
                              path="opportunities"
                              element={<OpportunityListPage />}
                            />
                          </Route>
                          <Route
                            path=":saleId/opportunities/:opportunityId"
                            element={<OpportunityDetailPage />}
                          />
                          <Route
                            path=":saleId/opportunities/:opportunityId/members"
                            element={<SaleMemberProfilePage />}
                          >
                            <Route path=":memberId">
                              <Route
                                index
                                element={<ClassReservationListPage />}
                              />
                              <Route
                                path="pt"
                                element={<PTReservationListPage />}
                              />
                              <Route
                                path="transactions"
                                element={<TransactionListPage />}
                              />
                            </Route>
                          </Route>
                          <Route
                            path=":saleId/member/:memberId"
                            element={<SaleMemberProfilePage />}
                          >
                            <Route
                              index
                              element={<ClassReservationListPage />}
                            />
                            <Route
                              path="pt"
                              element={<PTReservationListPage />}
                            />
                            <Route
                              path="transactions"
                              element={<TransactionListPage />}
                            />
                          </Route>
                          <Route
                            path=":id/customers/:customerId"
                            element={<CustomerDetailPage />}
                          />
                        </Route>
                        <Route path="trainers">
                          <Route path="" element={<TrainerListPage />}>
                            <Route index element={<TrainerList />} />
                            <Route
                              path="confirm-bookings"
                              element={<PendingBookingList />}
                            />
                          </Route>
                          <Route path=":id" element={<TrainerProfilePage />}>
                            <Route index element={<TrainerCalendar />} />
                            <Route
                              path="trainings"
                              element={<TrainerTrainingList />}
                            />
                            <Route
                              path="class-history"
                              element={<ClassHistory />}
                            />
                            <Route
                              path="pt-history"
                              element={<PersonalTrainingHistory />}
                            />
                          </Route>
                          <Route
                            path=":id/trainings/:trainingId"
                            element={<TrainerTrainingDetailPage />}
                          />
                          <Route
                            path=":id/trainings/:trainingId/follow-ups/:followUpId"
                            element={<TrainerFollowUpDetailPage />}
                          />
                          <Route
                            path=":id/schedules/:scheduleId"
                            element={<ScheduleDetailPage />}
                          />
                          <Route
                            path=":id/members/:memberId"
                            element={<TrainerMemberProfilePage />}
                          >
                            <Route
                              index
                              element={<ClassReservationListPage />}
                            />
                            <Route
                              path="pt"
                              element={<PTReservationListPage />}
                            />
                            <Route
                              path="transactions"
                              element={<TransactionListPage />}
                            />
                          </Route>
                        </Route>
                        <Route path="trainings">
                          <Route index element={<TrainingListPage />} />
                          <Route path=":id" element={<TrainingDetailPage />} />
                          <Route
                            path=":id/follow-ups/:followUpId"
                            element={<FollowUpDetailPage />}
                          />
                        </Route>
                        <Route
                          path="products-and-packages"
                          element={<ProductAndPackageListPage />}
                        >
                          <Route index element={<MembershipListPage />} />
                          <Route path="pt" element={<ProductPTListPage />} />
                          <Route
                            path="products"
                            element={<ProductListPage />}
                          />
                        </Route>
                        <Route
                          path="products-and-packages/:id"
                          element={<MembershipDetailPage />}
                        />
                        <Route
                          path="products-and-packages/pt/:id"
                          element={<ProductPTDetailPage />}
                        />
                        <Route
                          path="products-and-packages/products/:id"
                          element={<ProductDetailPage />}
                        />
                        <Route path="members">
                          <Route
                            path=":memberId"
                            element={<MemberProfilePage />}
                          />
                          <Route
                            path=":memberId/membership/:id"
                            element={<MembershipDetailInMember />}
                          />
                          <Route
                            path=":memberId/pt/:id"
                            element={<PTDetailPage />}
                          />
                        </Route>
                        <Route path="categories">
                          <Route index element={<CategoryListPage />} />
                          <Route path=":id" element={<CategoryDetailPage />} />
                          <Route
                            path=":id/video/:videoId"
                            element={<VideoDetailPage />}
                          />
                        </Route>
                        <Route path="members">
                          <Route index element={<MemberListPage />} />
                          <Route
                            path=":memberId"
                            element={<MemberProfilePage />}
                          >
                            <Route
                              index
                              element={<ClassReservationListPage />}
                            />
                            <Route
                              path="pt"
                              element={<PTReservationListPage />}
                            />
                            <Route
                              path="transactions"
                              element={<TransactionListPage />}
                            />
                            <Route
                              path="trainings"
                              element={<MemberTrainingList />}
                            />
                          </Route>
                          <Route
                            path=":memberId/trainings/:trainingId"
                            element={<MemberTrainingDetailPage />}
                          />
                          <Route
                            path=":memberId/trainings/:trainingId/follow-ups/:followUpId"
                            element={<MemberFollowUpDetailPage />}
                          />
                        </Route>
                        <Route path="receipts">
                          <Route index element={<ReceiptListPage />} />
                        </Route>
                        <Route path="staffs">
                          <Route index element={<StaffListPage />} />
                          <Route path=":id" element={<StaffProfilePage />} />
                        </Route>
                        <Route path="fitness">
                          <Route element={<FitnessDetailPage />}>
                            <Route index element={<BranchList />} />
                            <Route
                              path="member-card"
                              element={<MemberCardSection />}
                            />
                            <Route
                              path="permission-group"
                              element={<PermissionGroupList />}
                            />
                          </Route>
                          <Route
                            path="branches/:branchId"
                            element={<BranchDetailPage />}
                          />
                        </Route>
                        <Route path="facilities">
                          <Route index element={<FacilityListPage />} />
                          <Route path=":id" element={<FacilityDetailPage />} />
                        </Route>
                      </Route>
                    </SentryRoutes>
                  </LocalizationProvider>
                </AuthenticationProvider>
              </QueryClientProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </BrowserRouter>
      </HelmetProvider>
    </Sentry.ErrorBoundary>
  );
}
