import { configs } from "@/configs";
import { Video, CreateVideoInput, UpdateVideoInput } from "@/models";
import { api } from "@/client/api";

type GetVideos = {
  data: Video[];
  total: number;
};

export async function getVideos(query = "") {
  const { data } = await api.get<GetVideos>(
    `${configs.apiUrl}/videos?${query}`
  );
  return data;
}

export async function getVideo(id: string) {
  const { data } = await api.get<Video>(`${configs.apiUrl}/videos/${id}`);
  return data;
}

export async function createVideo(body: CreateVideoInput) {
  const { data } = await api.post<Video>(`${configs.apiUrl}/videos`, body);
  return data;
}

export async function updateVideo({ id, data: body }: UpdateVideoInput) {
  const { data } = await api.patch<Video>(
    `${configs.apiUrl}/videos/${id}`,
    body
  );
  return data;
}

export async function deleteVideo(id: string) {
  const { data } = await api.delete<Video>(`${configs.apiUrl}/videos/${id}`);
  return data;
}
