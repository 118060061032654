import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { MenuItem } from "@mui/material";
import * as yup from "yup";

import { FormConfirmDialog } from "@/components/FormConfirmDialog";
import { Section } from "@/components/Section";
import { FormTextField } from "@/components/FormTextField";
import { PermissionGroupType } from "@/models";
import { FormSelect } from "@/components/Select";
import { formatPermissionGroupType } from "@/formatter";

import type { Control } from "react-hook-form";
import type { InferType } from "yup";

export type PermissionGroupEditorState = InferType<typeof schema>;

type PermissionGroupEditorDialogProps = {
  open: boolean;
  title: string;
  onClose: () => void;
  onSubmit: () => void | PromiseLike<void>;
  loading: boolean;
  control: Control<PermissionGroupEditorState>;
  isEdit?: boolean;
};

export function PermissionGroupEditorDialog({
  open,
  title,
  onClose,
  onSubmit,
  loading,
  control,
  isEdit = false,
}: PermissionGroupEditorDialogProps) {
  const typeList = [PermissionGroupType.member, PermissionGroupType.staff];

  const options = [
    ...typeList.map((type) => ({
      label: formatPermissionGroupType(type),
      value: type,
    })),
  ];

  return (
    <FormConfirmDialog
      control={control}
      title={title}
      loading={loading}
      open={open}
      onClose={onClose}
      onConfirm={onSubmit}
    >
      <Section columns={2}>
        <FormTextField
          name="name"
          control={control}
          label="ชื่อสิทธิ์การใช้งานประตู"
          required
        />
        <FormSelect
          name="type"
          control={control}
          label="สิทธิ์ผู้ใช้"
          required
          disabled={isEdit}
        >
          {options.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </FormSelect>
      </Section>
    </FormConfirmDialog>
  );
}

export function usePermissionGroupEditorForm() {
  return useForm({
    resolver,
    defaultValues,
  });
}

const schema = yup.object({
  name: yup.string().label("ชื่อสิทธิ์การใช้งานประตู").required(),
  type: yup
    .string()
    .oneOf(Object.values(PermissionGroupType))
    .label("สิทธิ์ผู้ใช้")
    .required(),
});

const resolver = yupResolver(schema);

const defaultValues = { name: "" } as PermissionGroupEditorState;
