import { useState } from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import {
  PurchaseProductDetail,
  PurchaseProductDetailProps,
} from "@/components/PurchaseProductDetail";

import { Membership, PersonalTrainingQuota, PurchaseType } from "@/models";

type MemberProductListProps =
  | {
      memberId: number;
      type: PurchaseType.Membership;
      data: Membership[];
    }
  | {
      memberId: number;
      type: PurchaseType.ProductPT;
      data: PersonalTrainingQuota[];
    };

export function MemberProductList(props: MemberProductListProps) {
  const {
    detailProps,
    isDisabledPreviousPage,
    onClickPrevious,
    isDisabledNextPage,
    onClickNext,
    pagination,
  } = useMemberProductList(props);

  return (
    <Box width="100%">
      <Stack
        direction="column"
        justifyContent="space-between"
        spacing={2}
        height="100%"
      >
        <Stack direction="row" gap={5} width="100%">
          <PurchaseProductDetail {...detailProps} />
        </Stack>
        <Stack
          direction="row"
          gap={2}
          alignItems="center"
          justifyContent="center"
        >
          <IconButton
            aria-label="previous"
            disabled={isDisabledPreviousPage}
            onClick={onClickPrevious}
          >
            <ChevronLeftIcon />
          </IconButton>
          <Typography variant="subtitle1">{pagination}</Typography>
          <IconButton
            aria-label="next"
            disabled={isDisabledNextPage}
            onClick={onClickNext}
          >
            <ChevronRightIcon />
          </IconButton>
        </Stack>
      </Stack>
    </Box>
  );
}

const mapper = {
  [PurchaseType.Membership]: (data: MemberProductListProps["data"][number]) => {
    return { type: PurchaseType.Membership, data } as {
      type: PurchaseType.Membership;
      data: Membership;
    };
  },
  [PurchaseType.ProductPT]: (data: MemberProductListProps["data"][number]) => {
    return { type: PurchaseType.ProductPT, data } as {
      type: PurchaseType.ProductPT;
      data: PersonalTrainingQuota;
    };
  },
} as const;

function useMemberProductList({
  memberId,
  data,
  type,
}: MemberProductListProps) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const total = data.length || 1;
  const page = currentIndex + 1;

  const detailProps: PurchaseProductDetailProps = {
    memberId,
    ...mapper[type](data[currentIndex]),
  };

  return {
    isDisabledPreviousPage: page === 1,
    onClickPrevious: () => setCurrentIndex(currentIndex - 1),
    isDisabledNextPage: page === total,
    onClickNext: () => setCurrentIndex(currentIndex + 1),
    pagination: `${page}/${total}`,
    detailProps,
  };
}
