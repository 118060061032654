import { FormControlLabel, Switch, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import { useState } from "react";

import { refetchQueries } from "@/utils";
import { ConfirmDialog } from "@/components/ConfirmDialog";
import { Product } from "@/models";
import { formatProductStatus } from "@/formatter";
import { publishProduct } from "@/services/product";
import { configs } from "@/configs";

import type { AxiosErrorWithData } from "@/client/api";

type BlogStatusSwitchProps = {
  data: Product;
  fetchKeys?: string[];
};

export function ProductStatusSwitch({
  data,
  fetchKeys = [],
}: BlogStatusSwitchProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const onChangeStatus = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  const { mutate: publish, isLoading } = useMutation(publishProduct, {
    onSuccess: async () => {
      enqueueSnackbar("อัพเดทสถานะสำเร็จ", { variant: "success" });
      await refetchQueries({ queryClient, fetchKeys });
      onClose();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(
        error.response?.data.message ?? configs.unknownErrorMessage,
        { variant: "error" }
      );
    },
  });

  const { id, publishedAt } = data;
  const isPublished = !!publishedAt;

  const confirmDialog = {
    maxWidth: "xs" as const,
    open: isOpen,
    onClose,
    onConfirm: () =>
      publish({
        id,
        isPublished: !isPublished,
      }),
    title: `คุณต้องการ${formatProductStatus(!isPublished)}หรือไม่`,
    confirmMessage: "ใช่",
    cancelMessage: "ไม่ใช่",
    loading: isLoading,
  };

  return (
    <FormControlLabel
      control={
        <>
          <Switch
            value={id}
            checked={isPublished}
            onChange={onChangeStatus}
            disabled={isOpen}
          />
          <ConfirmDialog {...confirmDialog} />
        </>
      }
      label={
        <Typography variant="body2">
          {formatProductStatus(isPublished)}
        </Typography>
      }
      onClick={(event) => {
        event.stopPropagation();
      }}
    />
  );
}
