import { TableRow, TableCell, Typography } from "@mui/material";

import { calculateColumnWidth } from "@/utils";
import { Schedule, ScheduleStatus } from "@/models";
import { formatScheduleStatus } from "@/formatter";
import { TextEllipsis } from "@/components/TextEllipsis";
import { DateRangeDisplay } from "@/components/DateRangeDisplay";

const columnsWidth = [240, 464, 180, 200].map((width) =>
  calculateColumnWidth(width)
);

export function PersonalTrainingHistoryTableHead() {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]}>วันที่และเวลา</TableCell>
      <TableCell width={columnsWidth[1]}>ชื่อสมาชิก</TableCell>
      <TableCell width={columnsWidth[2]}>เบอร์โทรศัพท์</TableCell>
      <TableCell width={columnsWidth[3]} align="center">
        สถานะ
      </TableCell>
    </TableRow>
  );
}

type PersonalTrainingHistoryTableRowProps = {
  data: Schedule;
};

export function PersonalTrainingHistoryTableRow({
  data,
}: PersonalTrainingHistoryTableRowProps) {
  const { startedAt, endedAt, bookings, cancelledAt } = data;

  const status = cancelledAt
    ? ScheduleStatus.Cancelled
    : ScheduleStatus.Completed;

  const statusColor =
    status === ScheduleStatus.Completed ? "success.main" : "text.disabled";

  return (
    <TableRow hover tabIndex={-1}>
      <TableCell>
        <DateRangeDisplay start={startedAt} end={endedAt} />
      </TableCell>
      <TableCell>
        <TextEllipsis variant="body2">{
          `${bookings[0].member.profile.firstName} ${bookings[0].member.profile.lastName}` /* TODOOOOO: format profile */
        }</TextEllipsis>
      </TableCell>
      <TableCell>{bookings[0].member.profile.phoneNo}</TableCell>
      <TableCell align="center">
        <Typography color={statusColor}>
          {formatScheduleStatus(status)}
        </Typography>
      </TableCell>
    </TableRow>
  );
}
