import { Divider, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { FormImageUploader } from "@/components/ImageUploader";
import { FormTextField } from "@/components/FormTextField";
import { youtubeLink } from "@/lib/yup/validation";

import { FormCategoryAutocomplete } from "./CategoryAutocomplete";

import type { FileForm } from "@/components/ImageUploader";
import type { ControllerProps } from "react-hook-form";
import type { InferType } from "yup";
import { configs } from "@/configs";

export type ClassEditorState = InferType<typeof schema>;

type ClassEditorProps = Pick<ControllerProps<ClassEditorState>, "control">;

export function ClassEditor({ control }: ClassEditorProps) {
  return (
    <Stack>
      <FormImageUploader
        control={control}
        name="images"
        module="classes"
        max={5}
        aspect={configs.aspectRatio.class}
      />
      <Divider sx={{ mt: 2, mb: 5 }} />
      <Stack gap={2.5}>
        <FormTextField
          control={control}
          name="name"
          label="ชื่อคลาส"
          required
        />
        <FormCategoryAutocomplete
          control={control}
          name="categories"
          label="ประเภทกิจกรรม"
          required
        />
        <FormTextField
          control={control}
          name="youtubeLink"
          label="ลิงค์ Youtube"
        />
        <FormTextField
          control={control}
          name="description"
          label="รายละเอียด"
          multiline
          rows={4}
        />
      </Stack>
    </Stack>
  );
}

export function useClassEditorForm() {
  return useForm({
    resolver,
    defaultValues,
  });
}

const schema = yup.object({
  images: FormImageUploader.schema,
  name: yup.string().label("ชื่อคลาส").required(),
  categories: yup
    .array()
    .of(
      yup.object({
        id: yup.number().required(),
        name: yup.string().required(),
        description: yup.string().nullable(),
      })
    )
    .label("ประเภทกิจกรรม")
    .min(1, "ระบุ ${label}")
    .required(),
  youtubeLink: youtubeLink,
  description: yup.string().label("รายละเอียด").max(500),
});

const resolver = yupResolver(schema);

const defaultValues = {
  name: "",
  categories: [],
  youtubeLink: "",
  description: "",
  images: [] as FileForm[],
} as ClassEditorState;
