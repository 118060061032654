import { MenuItem } from "@mui/material";

import { SearchParamsSelect, Select } from "@/components/Select";
import { formatTrainingGoal } from "@/formatter";
import { TrainingGoal } from "@/models";

import type { SelectProps } from "@mui/material";

function getTrainingGoalOptions() {
  const goals = [
    TrainingGoal.BodyComposition,
    TrainingGoal.GeneralHealth,
    TrainingGoal.SkillRelated,
    TrainingGoal.SportPerformance,
    TrainingGoal.Other,
  ];

  return [
    { label: "ทั้งหมด", value: "" },
    ...goals.map((goal) => ({ label: formatTrainingGoal(goal), value: goal })),
  ];
}

type TrainingGoalSelectProps = {
  goal?: TrainingGoal;
  setGoal: (goal: TrainingGoal | undefined) => void;
};

export function TrainingGoalSelect({ goal, setGoal }: TrainingGoalSelectProps) {
  const options = getTrainingGoalOptions();

  const handleChangeGoal: SelectProps["onChange"] = (event) => {
    setGoal(event.target.value as TrainingGoal);
  };

  return (
    <Select
      displayEmpty
      label="เป้าหมายการเทรน"
      value={goal}
      onChange={handleChangeGoal}
      sx={{ width: 220 }}
    >
      {options.map(({ label, value }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </Select>
  );
}

export function TrainingGoalSearchParamsSelect() {
  const options = getTrainingGoalOptions();

  return (
    <SearchParamsSelect
      displayEmpty
      name="goal"
      label="เป้าหมายการเทรน"
      sx={{ width: 220 }}
    >
      {options.map(({ label, value }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </SearchParamsSelect>
  );
}
