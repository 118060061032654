import { Fragment } from "react";
import { Box, CardContent, Stack, Typography } from "@mui/material";

import { MediaViewer } from "@/components/MediaViewer/MediaViewer";
import { formatName } from "@/formatter";
import { mapOptional } from "@/utils";

import { Sale } from "@/models";

export type SaleProfileProps = {
  data: Sale;
};

export function SaleProfile({ data }: SaleProfileProps) {
  const { code, profile, branches } = data;

  const { phoneNo, email, avatarUrl } = profile;

  const name = mapOptional(profile, formatName) ?? "-";

  const mediaList = avatarUrl ? [{ url: avatarUrl }] : [];

  const saleInfo = [
    { label: "โทรศัพท์มือถือ", text: phoneNo },
    { label: "อีเมล", text: email },
  ];

  return (
    <Stack>
      <CardContent>
        <Stack direction="row" gap={2.5}>
          <MediaViewer
            mediaList={mediaList}
            imageWidth={300}
            imageHeight={300}
          />
          <Stack gap={1.5} width="100%">
            <Typography variant="h6" color="primary.main">
              {code}
            </Typography>
            <Typography variant="h6">{name}</Typography>
            <Box
              display="grid"
              gridTemplateColumns="120px 1fr"
              gap={(theme) => `${theme.spacing(2)} ${theme.spacing(5)}`}
              alignItems="baseline"
            >
              {saleInfo.map(({ label, text }) => (
                <Fragment key={`${label} ${text}`}>
                  <Typography variant="subtitle2" color="text.disabled">
                    {label}
                  </Typography>
                  <Typography variant="body2">{text}</Typography>
                </Fragment>
              ))}
              <Fragment key="branches">
                <Typography variant="subtitle2" color="text.disabled">
                  สาขา
                </Typography>
                <Stack>
                  {branches.map((branch) => (
                    <li key={branch.id}>{branch.name}</li>
                  ))}
                </Stack>
              </Fragment>
            </Box>
          </Stack>
        </Stack>
      </CardContent>
    </Stack>
  );
}
