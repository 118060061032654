import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { InferType } from "yup";
import { ControllerProps, useForm } from "react-hook-form";
import { Stack } from "@mui/material";

import { AddressEditor } from "@/components/AddressEditor";
import { FormTextField } from "@/components/FormTextField";
import { Section } from "@/components/Section";
import { googleMapLink } from "@/lib/yup/validation";

export type BranchEditorState = InferType<typeof schema>;

type BranchEditorProps = Pick<ControllerProps<BranchEditorState>, "control">;

export function BranchEditor({ control }: BranchEditorProps) {
  return (
    <Stack gap={5}>
      <Section columns={2} label="">
        <FormTextField
          name="code"
          control={control}
          label="รหัสสาขา"
          required
        />
        <FormTextField
          name="name"
          control={control}
          label="ชื่อสาขา"
          required
        />
      </Section>
      <Section columns={2} label="ข้อมูล">
        <FormTextField
          name="phoneNo"
          control={control}
          label="เบอร์โทรศัพท์"
          required
        />
        <FormTextField name="email" control={control} label="อีเมล" />
      </Section>
      <Section columns={1} label="ที่อยู่ปัจจุบัน">
        <AddressEditor name="address" control={control} />
        <FormTextField
          name="googleMapLink"
          control={control}
          label="Google Map Link"
        />
      </Section>
    </Stack>
  );
}

export function useBranchEditorForm() {
  return useForm({
    resolver,
    defaultValues,
  });
}

const schema = yup.object({
  code: yup.string().label("รหัสสาขา").required(),
  name: yup.string().label("ชื่อสาขา").required(),
  phoneNo: yup.string().label("เบอร์โทรศัพท์").required(),
  email: yup.string().label("อีเมล").email(),
  address: AddressEditor.schema,
  googleMapLink: googleMapLink,
});

const resolver = yupResolver(schema);

const defaultValues = {
  code: "",
  name: "",
  phoneNo: "",
  email: "",
  address: { required: true },
  googleMapLink: "",
} as BranchEditorState;
