import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";

import type { Branch, PermissionDoor } from "@/models";

type PermissionDoorsDialogProps = {
  open: boolean;
  branch?: Pick<Branch, "id" | "name">;
  onClose: () => void;
} & Pick<PermissionDoor, "doors">;

export function PermissionDoorsDialog({
  open: isOpen,
  branch,
  doors,
  onClose,
}: PermissionDoorsDialogProps) {
  const title = "สิทธิ์การใช้งานประตู";

  const isHasDoors = !!doors.length;

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stack gap={3}>
          <Typography variant="h6">สาขา {branch?.name ?? ""}</Typography>
          <Box display="grid" gridTemplateColumns="120px 1fr" gap={2}>
            <Typography variant="body2" color="text.disabled">
              ประตูที่ใช้งานได้
            </Typography>
            {isHasDoors ? (
              <Typography variant="body1">
                {doors.map(({ id, name }) => (
                  <Box key={id} component="li">
                    {name}
                  </Box>
                ))}
              </Typography>
            ) : (
              <Typography variant="body2">
                สาขานี้ยังไม่มีการใช้งานประตู
              </Typography>
            )}
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
