import { useState } from "react";
import * as yup from "yup";
import { Autocomplete, TextField } from "@mui/material";
import { useController } from "react-hook-form";
import { useQuery } from "react-query";

import { useDebounce } from "@/utils";
import { getAvailablePTMembers } from "@/services/trainer";

import type { TextFieldProps } from "@mui/material";
import type { AutocompleteProps } from "@mui/material/Autocomplete/Autocomplete";
import type { ControllerProps, FieldPath, FieldValues } from "react-hook-form";
import { Profile } from "@/models";

export type MemberTrainingAutocompleteValues = {
  id: number;
  code: string;
  profile: Profile;
};

export type MemberTrainingAutocompleteProps = {
  label?: string;
  required?: boolean;
  TextFieldProps?: Partial<TextFieldProps>;
} & Omit<
  AutocompleteProps<MemberTrainingAutocompleteValues, false, false, false>,
  "renderInput" | "options"
>;

const QUERY_KEY = "getAvailablePTMembers";

export function MemberTrainingAutocomplete({
  label,
  required,
  TextFieldProps,
  ...props
}: MemberTrainingAutocompleteProps) {
  const [query, setQuery] = useState("");
  const debouceQuery = useDebounce(query);

  const { data: raw, isFetching } = useQuery([QUERY_KEY, debouceQuery], () =>
    getAvailablePTMembers(`query=${debouceQuery}`)
  );

  const options = (raw?.data ??
    []) as unknown as MemberTrainingAutocompleteValues[];

  return (
    <Autocomplete
      options={options}
      loading={isFetching}
      getOptionLabel={({ code, profile: { firstName, lastName, phoneNo } }) =>
        `${code} ${firstName} ${lastName} (${phoneNo})`
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onInputChange={(_, newQuery, reason) => {
        if (reason === "clear") {
          setQuery("");
        } else if (reason === "input") {
          setQuery(newQuery);
        }
      }}
      renderInput={(params) => (
        <TextField label={label} {...params} {...TextFieldProps} />
      )}
      {...props}
    />
  );
}

export function FormMemberTrainingAutocomplete<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  required,
  ...props
}: Pick<ControllerProps<TFieldValues, TName>, "name" | "control"> &
  Omit<
    MemberTrainingAutocompleteProps,
    "value" | "name" | "TextFieldProps" | "onChange"
  >) {
  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <MemberTrainingAutocomplete
      value={field.value ?? null}
      onChange={(_, newValue) => field.onChange(newValue)}
      TextFieldProps={{
        inputRef: field.ref,
        onBlur: field.onBlur,
        error: fieldState.error?.message ? true : false,
        helperText: fieldState.error?.message,
        required,
      }}
      {...props}
    />
  );
}

const schema = yup
  .object({
    id: yup.number().required(),
    code: yup.string().required(),
    profile: yup
      .object({
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        phoneNo: yup.string().required(),
      })
      .required(),
  })
  .default(null) // fix yup not validate when value is undefined
  .nullable();

MemberTrainingAutocomplete.schema = schema;
