import { api } from "@/client/api";
import { configs } from "@/configs";
import { Staff, StaffInput, UpdateStaffInput } from "@/models";

type GetStaffs = {
  data: Staff[];
  total: number;
};

type EditStaffPrtmissionGroup = {
  staffId: number;
  data: { permissionGroupId: number };
};

export async function getStaffs(query = "") {
  const { data } = await api.get<GetStaffs>(
    `${configs.apiUrl}/staffs?${query}`
  );
  return data;
}

export async function getStaff(id: string) {
  const { data } = await api.get<Staff>(`${configs.apiUrl}/staffs/${id}`);
  return data;
}

export async function getStaffCode() {
  const { data } = await api.get<string>(`${configs.apiUrl}/staffs/code`);
  return data;
}

export async function createStaff(body: StaffInput) {
  const { data } = await api.post<Staff>(`${configs.apiUrl}/staffs`, body);
  return data;
}

export async function updateStaff({ id, data: body }: UpdateStaffInput) {
  const { data } = await api.patch<Staff>(
    `${configs.apiUrl}/staffs/${id}`,
    body
  );
  return data;
}

export async function deleteStaff(id: string) {
  const { data } = await api.delete<Staff>(`${configs.apiUrl}/staffs/${id}`);
  return data;
}

export async function editStaffPermissionGroup({
  staffId,
  data: body,
}: EditStaffPrtmissionGroup) {
  await api.patch(`${configs.apiUrl}/staffs/${staffId}/permission-group`, body);
}
