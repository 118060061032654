import { api } from "@/client/api";
import { configs } from "@/configs";
import { UpdateAvatarProfileInput, Profile } from "@/models";

export async function updateAvatar({
  id,
  data: body,
}: UpdateAvatarProfileInput) {
  const { data } = await api.patch<Profile>(
    `${configs.apiUrl}/profiles/avatar/${id}`,
    body
  );
  return data;
}
