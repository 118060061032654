import { useMutation } from "react-query";
import { useSnackbar } from "notistack";
import { Duration } from "luxon";

import { configs } from "@/configs";
import { createProductMembership } from "@/services/product";

import { useMembershipEditorForm } from "./MembershipEditor";
import { MembershipEditorDialog } from "./MembershipEditorDialog";

export function AddMembershipDialog({
  open: isOpen,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, reset } = useMembershipEditorForm();

  const { mutate: add, isLoading } = useMutation(createProductMembership, {
    onSuccess: () => {
      enqueueSnackbar(`เพิ่มแพ็กเกจสมาชิกสำเร็จ`, { variant: "success" });
      close();
    },
    onError: (error) => {
      console.error(error);
      enqueueSnackbar(configs.unknownErrorMessage, { variant: "error" });
      close();
    },
  });

  function close() {
    reset();
    onClose();
  }

  const onSubmit = handleSubmit((form) => {
    const data = {
      name: form.name,
      price: form.price,
      description: form.description,
      durationIso: Duration.fromObject({
        [form.durationUnit]: form.duration,
      }).toISO(),
      type: form.type,
      branches: form.branches.map((branch) => branch.id),
    };
    add(data);
  });
  const title = `เพิ่มแพ็กเกจสมาชิก`;

  return (
    <MembershipEditorDialog
      open={isOpen}
      title={title}
      onClose={close}
      onSubmit={onSubmit}
      loading={isLoading}
      control={control}
    />
  );
}
