import {
  Box,
  Breadcrumbs,
  Typography,
  Card,
  CircularProgress,
  Link as MuiLink,
  Stack,
  TabsProps,
  Tabs,
  Tab,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import NavigateNext from "@mui/icons-material/NavigateNext";

import { getDocumentTitle, useRequireParams } from "@/utils";
import { SaleProfile } from "./SaleProfile";

import { getSale } from "@/services/sale";

function useSale(id: string) {
  return useQuery("sale", () => getSale(+id));
}

export function SaleProfilePage() {
  const { saleId: id } = useRequireParams(["saleId"]);
  const location = useLocation();
  const navigate = useNavigate();
  const path = location?.pathname ?? "";

  const { data, isFetching } = useSale(id);

  const onChangeTab: TabsProps["onChange"] = (_, value: string) => {
    navigate(value, { replace: true });
  };

  const isLoaded = !isFetching && typeof data !== "undefined";

  const title = "Sale Profile";

  return (
    <Box>
      <Helmet>
        <title>{getDocumentTitle(title)}</title>
      </Helmet>
      <Breadcrumbs
        separator={<NavigateNext fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ mb: 2 }}
      >
        <MuiLink component={Link} color="text.secondary" to="..">
          ฝ่ายขาย
        </MuiLink>
        <Typography color="text.primary">ข้อมูลฝ่ายขาย</Typography>
      </Breadcrumbs>
      <Stack>
        <Card sx={{ mb: 2.5 }}>
          {isLoaded ? (
            <SaleProfile data={data} />
          ) : (
            <Box display="grid" sx={{ placeItems: "center" }} height={1000}>
              <CircularProgress disableShrink />
            </Box>
          )}
        </Card>
        <Box>
          <Tabs value={path} sx={{ mb: 2 }} onChange={onChangeTab}>
            <Tab label="รายชื่อสมาชิกที่ดูแล" value={`/sales/${id}`} />
            <Tab label="ยอดขาย" value={`/sales/${id}/sales`} />
            <Tab label="รายการลูกค้าใหม่" value={`/sales/${id}/customers`} />
            <Tab
              label="รายการเพิ่มยอดขาย"
              value={`/sales/${id}/opportunities`}
            />
          </Tabs>
          <Outlet />
        </Box>
      </Stack>
    </Box>
  );
}
