import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import { useEffect } from "react";

import { refetchQueries } from "@/utils";
import { configs } from "@/configs";
import { updatePermissionGroup } from "@/services/permissionGroup";

import { PermissionGroupEditorDialog } from "../../components/PermissionGroupEditorDialog";
import { usePermissionGroupEditorForm } from "../../components/PermissionGroupEditorDialog";

import type { PermissionGroup } from "@/models";
import type { AxiosErrorWithData } from "@/client/api";

export type EditPermissionGroupDialogProps = {
  open: boolean;
  data?: PermissionGroup;
  onClose: () => void;
  fetchKeys?: string[];
};

export function EditPermissionGroupDialog({
  open: isOpen,
  onClose,
  data,
  fetchKeys = [],
}: EditPermissionGroupDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, reset } = usePermissionGroupEditorForm();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isOpen && data) {
      reset({
        name: data.name,
        type: data.type,
      });
    }
  }, [isOpen, data, reset]);

  function close() {
    onClose();
  }

  const { mutate: update, isLoading } = useMutation(updatePermissionGroup, {
    onSuccess: async () => {
      enqueueSnackbar("แก้ไขสิทธิ์การใช้งานสำเร็จ", { variant: "success" });
      await refetchQueries({ queryClient, fetchKeys });
      close();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(
        error.response?.data.message ?? configs.unknownErrorMessage,
        { variant: "error" }
      );
    },
  });

  const onSubmit = handleSubmit((form) => {
    if (!data) return;

    update({
      id: data.id,
      name: form.name,
    });
  });

  const title = "แก้ไขสิทธิ์การใช้งาน";

  return (
    <PermissionGroupEditorDialog
      isEdit
      open={isOpen}
      title={title}
      onClose={close}
      onSubmit={onSubmit}
      loading={isLoading}
      control={control}
    />
  );
}
