import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useSearchParams } from "react-router-dom";

import { TrainerProfileDetail } from "../../components/TrainerProfileDetail";
import { EditTrainerDialog } from "@/features/staff/components/EditTrainerDialog";
import { MediaViewer } from "@/components/MediaViewer";

import type { Staff } from "@/models";

export type TrainerProfileProps = {
  data: Staff;
};

const QUERY_KEY = "staff";

export function TrainerProfile({ data }: TrainerProfileProps) {
  const { id, trainerProfileData: trainerProfile } = data;
  const { avatarUrl } = trainerProfile;

  const isNoTrainerData = trainerProfile.skills.length === 0;

  const mediaList = avatarUrl ? [{ url: avatarUrl }] : [];

  const [searchParams, setSearchParams] = useSearchParams();
  const dialog = searchParams.get("dialog");

  function onCloseDialog() {
    searchParams.delete("dialog");
    setSearchParams(searchParams, { replace: true });
  }

  function openEditDialog() {
    searchParams.set("dialog", "trainer-edit");
    setSearchParams(searchParams);
  }

  const editDialog = {
    open: dialog === "trainer-edit",
    onClose: onCloseDialog,
    id: id.toString(),
    data: trainerProfile,
    fetchKey: QUERY_KEY,
  };

  return (
    <Card>
      <Stack>
        <CardHeader
          title={
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography variant="h6">ข้อมูลเทรนเนอร์</Typography>
              {isNoTrainerData && (
                <Chip
                  label="ระบุข้อมูลให้ครบถ้วน"
                  sx={{
                    color: "background.paper",
                    backgroundColor: "primary.main",
                  }}
                />
              )}
            </Stack>
          }
          action={
            <Stack direction="row">
              <Tooltip title="แก้ไขสมาชิก">
                <IconButton onClick={openEditDialog}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          }
        />
        <CardContent sx={{ py: 0 }}>
          <MediaViewer
            mediaList={mediaList}
            imageWidth={200}
            imageHeight={300}
          />
        </CardContent>
        <CardContent>
          <TrainerProfileDetail data={trainerProfile} />
          <EditTrainerDialog {...editDialog} />
        </CardContent>
      </Stack>
    </Card>
  );
}
