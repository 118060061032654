import {
  Box,
  Divider,
  FormControlLabel,
  FormControl,
  FormGroup,
  Radio,
  Stack,
  Typography,
  Checkbox,
  MenuItem,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { useWatch } from "react-hook-form";

import { FormTextField } from "@/components/FormTextField";
import { Section } from "@/components/Section";
import { FormDatePicker } from "@/components/DatePicker";
import { FormRadioGroup } from "@/components/FormRadioGroup";

import { formatGender } from "@/formatter";
import { AddressEditor } from "@/components/AddressEditor";
import { FormBranchAutocomplete } from "@/components/BranchAutocomplete";
import { FormSelect } from "@/components/Select";
import { Gender } from "@/models";
import { useCurrentUser } from "@/features/authentication/contexts/AuthenticationContext";

import { StaffCodeFormTextField } from "./StaffCodeFormTextField";

import type { ControllerProps } from "react-hook-form";
import type { InferType } from "yup";

export type BranchesAutocompleteValue = { id: number; name: string };

export type StaffEditorState = InferType<typeof schema>;

type StaffEditorProps = Pick<ControllerProps<StaffEditorState>, "control">;

export function StaffEditor({ control }: StaffEditorProps) {
  const position = useWatch({
    control,
    name: "position",
  });
  const user = useCurrentUser();

  const positions = user.isAdmin
    ? [
        {
          id: 1,
          name: "เจ้าของกิจการ",
        },
        {
          id: 3,
          name: "ผู้จัดการสาขา",
        },
        {
          id: 99,
          name: "พนักงาน",
        },
      ]
    : [
        {
          id: 99,
          name: "พนักงาน",
        },
      ];
  const roles = [
    {
      id: 2,
      name: "ผู้สอน",
    },
    {
      id: 4,
      name: "พนักงานขาย",
    },
  ];
  return (
    <Stack gap={5}>
      <Section columns={1}>
        <FormSelect name="position" control={control} label="ตำแหน่ง" required>
          {positions.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </FormSelect>
        {position && position !== 1 && (
          <FormBranchAutocomplete
            label="สาขา"
            name="branches"
            control={control}
            required
          />
        )}
      </Section>
      {position && (
        <Section columns={1} label="หน้าที่">
          <Controller
            name="roles"
            control={control}
            render={({ field, fieldState: { error } }) => {
              return (
                <FormControl required error={!!error}>
                  <FormGroup row>
                    {roles?.map((role) => {
                      return (
                        <FormControlLabel
                          key={role.id}
                          control={
                            <Checkbox
                              value={role}
                              checked={field.value.includes(role.id)}
                              onChange={(event) => {
                                field.onChange(
                                  event.target.checked
                                    ? field.value.concat(role.id)
                                    : field.value.filter(
                                        (value) => value !== role.id
                                      )
                                );
                              }}
                            />
                          }
                          label={role.name}
                          labelPlacement="end"
                        />
                      );
                    })}
                  </FormGroup>
                </FormControl>
              );
            }}
          />
        </Section>
      )}

      <Section columns={2} label="ข้อมูลส่วนบุคคล">
        <StaffCodeFormTextField control={control} />
        <FormTextField
          name="nationalIdCard"
          control={control}
          label="เลขที่บัตรประชาชน/หนังสือเดินทาง"
          required
        />
        <FormTextField
          name="firstName"
          control={control}
          label="ชื่อ"
          required
        />
        <FormTextField
          name="lastName"
          control={control}
          label="นามสกุล"
          required
        />
        <FormTextField
          name="nickname"
          control={control}
          label="ชื่อเล่น"
          required
        />
        <FormDatePicker
          label="วัน/เดือน/ปีเกิด (ค.ศ.)"
          name="dateOfBirth"
          control={control}
          TextFieldProps={{ required: true, sx: { width: 275 } }}
        />
        <Box sx={{ gridColumn: "1/span 2" }}>
          <Typography variant="labelLarge">เพศ*</Typography>
          <FormRadioGroup row name="gender" control={control} sx={{ mt: 0.75 }}>
            {[Gender.Unspecified, Gender.Male, Gender.Female].map((gender) => (
              <FormControlLabel
                key={gender}
                value={gender}
                control={<Radio />}
                label={formatGender(gender)}
              />
            ))}
          </FormRadioGroup>
        </Box>
        <FormTextField
          name="phone"
          control={control}
          label="เบอร์โทรศัพท์"
          required
        />
        <Controller
          name="staffCode"
          control={control}
          render={() => (
            <FormTextField
              name="email"
              control={control}
              label="อีเมล (ระบบจะส่งไปที่อีเมลนี้ เพื่อตั้งรหัสผ่านใหม่ หากมีการแก้ไข)"
              type="email"
              required
            />
          )}
        />
      </Section>
      <Divider />
      <Section columns={1} label="ที่อยู่ปัจจุบัน">
        <AddressEditor control={control} name="currentAddress" />
      </Section>
      <Divider />
      <Section columns={2} label="ข้อมูลติดต่อฉุกเฉิน">
        <FormTextField
          name="emergencyContact.name"
          control={control}
          label="บุคคลที่สามารถติดต่อกรณีฉุกเฉิน"
        />
        <FormTextField
          name="emergencyContact.phone"
          control={control}
          label="เบอร์โทรศัพท์"
        />
        <FormTextField
          name="emergencyContact.relationship"
          control={control}
          label="ความสัมพันธ์"
        />
      </Section>
    </Stack>
  );
}

export function useStaffEditorForm() {
  return useForm({
    resolver,
    defaultValues,
  });
}

const schema = yup.object({
  staffCode: yup.string(),
  nationalIdCard: yup
    .string()
    .label("เลขที่บัตรประชาชน/หนังสือเดินทาง")
    .required(),
  firstName: yup.string().label("ชื่อ").required(),
  lastName: yup.string().label("นามสกุล").required(),
  nickname: yup.string().label("ชื่อเล่น").required(),
  dateOfBirth: yup.date().label("วัน/เดือน/ปีเกิด (ค.ศ.)").required(),
  gender: yup.string().oneOf(Object.values(Gender)).required(),
  phone: yup.string().label("เบอร์โทรศัพท์").required(),
  email: yup.string().label("อีเมล").email().required(),
  currentAddress: AddressEditor.schema,
  emergencyContact: yup.object({
    name: yup.string(),
    phone: yup.string(),
    relationship: yup.string(),
  }),
  roles: yup
    .array(yup.number().required())
    .default([])
    .when("position", {
      is: 99,
      then: (schema) => schema.required().min(1, "ระบุ Roles"),
      otherwise: (schema) => schema,
    }),
  branches: yup
    .array(
      yup.object({
        id: yup.number().required(),
        name: yup.string().required(),
      })
    )
    .default([])
    .when("position", {
      is: 1,
      then: (schema) => schema,
      otherwise: (schema) => schema.required().min(1, "ระบุ Branches"),
    }),
  position: yup.number().label("ตำแหน่ง").required(),
});
const resolver = yupResolver(schema);

const defaultValues = {
  gender: Gender.Unspecified,
  currentAddress: {
    line: "",
    postcode: "",
  },
  roles: [],
  branches: [] as BranchesAutocompleteValue[],
} as unknown as StaffEditorState;
