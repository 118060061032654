import { Autocomplete as MuiAutocomplete, TextField } from "@mui/material";
import { useController } from "react-hook-form";
import { useQuery } from "react-query";
import * as yup from "yup";

import { getTrainers } from "@/services/trainer";
import { formatProfile } from "@/formatter";
import { parseURLSearchParams } from "@/utils";

import type {
  AutocompleteProps as MuiAutocompleteProps,
  TextFieldProps,
} from "@mui/material";
import type { ControllerProps, FieldPath, FieldValues } from "react-hook-form";
import type { Profile } from "@/models";

export type TrainerTrainingAutocompleteValue = { id: number; profile: Profile };

export type TrainerTrainingAutocompleteProps = {
  label?: string;
  TextFieldProps?: Partial<TextFieldProps>;
} & Omit<
  MuiAutocompleteProps<TrainerTrainingAutocompleteValue, false, false, false>,
  "renderInput" | "options"
>;

export function TrainerTrainingAutocomplete({
  label,
  TextFieldProps,
  ...props
}: TrainerTrainingAutocompleteProps) {
  const query = parseURLSearchParams({
    isPersonalTraining: "true",
    limit: "1000",
  });

  const { data: raw, isFetching } = useQuery(
    ["getTrainingTrainers", query],
    () => getTrainers(query)
  );

  const data = raw?.data ?? [];

  return (
    <MuiAutocomplete
      options={data}
      loading={isFetching}
      getOptionLabel={({ profile }) => formatProfile(profile)}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField label={label} {...params} {...TextFieldProps} />
      )}
      {...props}
    />
  );
}

export function FormTrainerTrainingAutocomplete<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  required = false,
  ...props
}: { required?: boolean } & Pick<
  ControllerProps<TFieldValues, TName>,
  "name" | "control"
> &
  Omit<
    TrainerTrainingAutocompleteProps,
    "value" | "name" | "TextFieldProps" | "onChange"
  >) {
  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <TrainerTrainingAutocomplete
      value={field.value ?? null}
      onChange={(_, newValue) => field.onChange(newValue)}
      TextFieldProps={{
        inputRef: field.ref,
        onBlur: field.onBlur,
        error: fieldState.invalid,
        helperText: fieldState.error?.message,
        required,
      }}
      {...props}
    />
  );
}

const schema = yup
  .object({
    id: yup.number().integer().required(),
    profile: yup
      .object({
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        nickname: yup.string().required(),
      })
      .required(),
  })
  .default(null)
  .nullable();

TrainerTrainingAutocomplete.schema = schema;
