import { api } from "@/client/api";
import { configs } from "@/configs";
import { Booking } from "@/models";
import { parseDateTimeFromPrisma } from "@/utils";

type GetPendingBookings = {
  data: Booking[];
  total: number;
};

export async function getPendingBookings(query = "") {
  const { data } = await api.get<GetPendingBookings>(
    `${configs.apiUrl}/bookings/pending?${query}`
  );
  return parseDateTimeFromPrisma(data) as GetPendingBookings;
}
