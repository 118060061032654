import {
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useNavigate, useSearchParams } from "react-router-dom";

import { TextEllipsis } from "@/components/TextEllipsis";
import {
  formatDate,
  formatOpportunityStatus,
  formatOpportunityStatusColor,
} from "@/formatter";
import { fromISO } from "@/lib/dateTime";
import { CustomerSortType } from "@/models";

import type { MouseEventHandler } from "react";
import type { Opportunity } from "@/models";

const columnsWidth = [
  { xs: 120, lg: 140 },
  { xs: 129, lg: 180 },
  { xs: 232, lg: 331 },
  { xs: 120, lg: 160 },
  { xs: 120, lg: 160 },
  { xs: 223, lg: 373 },
];

export function OpportunityTableRowHeader() {
  return (
    <TableRow>
      <TableCell sx={{ width: columnsWidth[0] }}>
        <SortField />
        วันที่สร้าง
      </TableCell>
      <TableCell sx={{ width: columnsWidth[1] }}>หมายเลขสมาชิก</TableCell>
      <TableCell sx={{ width: columnsWidth[2] }}>ชื่อ</TableCell>
      <TableCell sx={{ width: columnsWidth[3] }}>เบอร์โทรศัพท์</TableCell>
      <TableCell sx={{ width: columnsWidth[4] }}>สถานะ</TableCell>
      <TableCell sx={{ width: columnsWidth[5] }}>หมายเหตุ</TableCell>
    </TableRow>
  );
}

export type OpportunityTableRowProps = {
  data: Opportunity;
};

export function OpportunityTableRow({ data }: OpportunityTableRowProps) {
  const navigate = useNavigate();

  const {
    id,
    member: {
      code,
      profile: { firstName, lastName, phoneNo },
    },
    createdAt,
    status,
    followings,
  } = data;

  const { remark } = followings[followings.length - 1];

  const onClickTableRow: MouseEventHandler<HTMLTableRowElement> = () => {
    navigate(id.toString());
  };

  const date = formatDate(fromISO(createdAt.toString()));

  return (
    <TableRow hover tabIndex={-1} role="link" onClick={onClickTableRow}>
      <TableCell>
        <TextEllipsis variant="body2">{date}</TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis variant="body2">{code}</TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis variant="body2">
          {firstName} {lastName}
        </TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis variant="body2">{phoneNo}</TextEllipsis>
      </TableCell>
      <TableCell>
        <Stack direction="row" gap={1} alignItems="center">
          <CircleIcon
            sx={{
              width: 12,
              height: 12,
              color: formatOpportunityStatusColor(status),
            }}
          />
          <Typography variant="body2">
            {formatOpportunityStatus(status)}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell>
        <Typography noWrap width={columnsWidth[5]}>
          {remark || "-"}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

function SortField() {
  const [searchParams, setSearchParams] = useSearchParams();
  const sortType = searchParams.get("sortType") as CustomerSortType | undefined;
  const setSort = () => {
    searchParams.set(
      "sortType",
      sortType === CustomerSortType.Asc
        ? CustomerSortType.Desc
        : CustomerSortType.Asc
    );

    searchParams.delete("page");
    setSearchParams(searchParams);
  };

  return (
    <IconButton
      sx={{ padding: 0, width: "auto", height: "auto" }}
      onClick={setSort}
    >
      {sortType === CustomerSortType.Asc ? (
        <ArrowDownwardIcon />
      ) : (
        <ArrowUpwardIcon />
      )}
    </IconButton>
  );
}
