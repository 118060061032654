import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import { configs } from "@/configs";
import { updateStaff } from "@/services/staff";

import { useStaffEditorForm } from "./StaffEditor";
import { StaffEditorDialog } from "./StaffEditorDialog";

import type { Staff, StaffInput } from "@/models";

export type EditStaffDialogProps = {
  open: boolean;
  data?: Staff;
  onClose: () => void;
  id: string;
  fetchKey: string;
};

export function EditStaffDialog({
  open: isOpen,
  onClose,
  id,
  data,
  fetchKey,
}: EditStaffDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, reset } = useStaffEditorForm();
  const [isTrainerChange, setIsTrainerChange] = useState(false);
  const [isSaleChange, setIsSaleChange] = useState(false);
  const [openConfirmEdit, setOpenConfirmEdit] = useState(false);

  const { mutate: update, isLoading } = useMutation(updateStaff, {
    onSuccess: async () => {
      enqueueSnackbar("แก้ไขพนักงานสำเร็จ", { variant: "success" });
      await queryClient.refetchQueries(fetchKey);
      close();
    },
    onError: (error) => {
      console.error(error);
      enqueueSnackbar(configs.unknownErrorMessage, {
        variant: "error",
      });
    },
  });
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isOpen && data) {
      const roles = [];
      for (const role of data.roles) {
        if (role.id === configs.role.admin || role.id === configs.role.manager)
          continue;
        roles.push(role.id);
      }
      reset({
        staffCode: data.code,
        nationalIdCard: data.profile.idCard,
        firstName: data.profile.firstName,
        lastName: data.profile.lastName,
        nickname: data.profile.nickname,
        dateOfBirth: data.profile.birthDate,
        gender: data.profile.gender,
        phone: data.profile.phoneNo,
        email: data.profile.email,
        currentAddress: {
          line: data.profile.address ?? "",
          district: data.profile.district ?? "",
          subdistrict: data.profile.subdistrict ?? "",
          province: data.profile.province ?? "",
          postcode: data.profile.postCode ?? "",
        },
        emergencyContact: {
          name: data.profile.emergencyPerson ?? "",
          phone: data.profile.emergencyPhoneNo ?? "",
          relationship: data.profile.emergencyRelation ?? "",
        },
        roles,
        position: data.roles.find((role) => role.id === configs.role.admin)
          ? configs.role.admin
          : data.roles.find((role) => role.id === configs.role.manager)
          ? configs.role.manager
          : 99,
        branches: data.branches,
      });
    }
  }, [isOpen, data, reset]);

  function close() {
    setIsTrainerChange(false);
    setIsSaleChange(false);
    setOpenConfirmEdit(false);
    reset();
    onClose();
  }

  function closeConfirmEdit() {
    setOpenConfirmEdit(false);
  }

  const onSubmit = handleSubmit((form) => {
    const trainerChange =
      data?.roles.find((role) => role.id === configs.role.trainer) &&
      !form.roles.includes(configs.role.trainer);
    const saleChange =
      data?.roles.find((role) => role.id === configs.role.sale) &&
      !form.roles.includes(configs.role.sale);
    if (!openConfirmEdit && (trainerChange || saleChange)) {
      setIsTrainerChange(!!trainerChange);
      setIsSaleChange(!!saleChange);
      setOpenConfirmEdit(true);
      return;
    }
    const body: StaffInput = {
      profile: {
        idCard: form.nationalIdCard,
        firstName: form.firstName,
        lastName: form.lastName,
        nickname: form.nickname,
        birthDate: form.dateOfBirth,
        email: form.email,
        gender: form.gender,
        phoneNo: form.phone,
        emergencyPerson: form.emergencyContact.name,
        emergencyPhoneNo: form.emergencyContact.phone,
        emergencyRelation: form.emergencyContact.relationship,
        address: form.currentAddress.line,
        district: form.currentAddress.district,
        subdistrict: form.currentAddress.subdistrict,
        province: form.currentAddress.province,
        postCode: form.currentAddress.postcode,
      },
      roles:
        form.position !== 99
          ? [...new Set([form.position, ...form.roles])]
          : form.roles,
      branches: form.branches.map((branch) => branch.id),
    };
    update({ id, data: body });
  });

  const title = "แก้ไขพนักงาน";

  return (
    <StaffEditorDialog
      open={isOpen}
      title={title}
      onClose={close}
      onSubmit={onSubmit}
      loading={isLoading}
      control={control}
      isTrainerChange={isTrainerChange}
      isSaleChange={isSaleChange}
      openConfirmEdit={openConfirmEdit}
      closeConfirmEdit={closeConfirmEdit}
    />
  );
}
