import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

import type { PermissionGroup } from "@/models";

type AlertRemovePermissionGroupDialogProps = {
  open: boolean;
  data?: PermissionGroup;
  onClose: () => void;
};

export function AlertRemovePermissionGroupDialog({
  open: isOpen,
  data,
  onClose,
}: AlertRemovePermissionGroupDialogProps) {
  if (!data) return <></>;

  const { name } = data;

  const title = `ไม่สามารถลบสิทธิ์ ${name} ได้`;
  const content = `เนื่องจากมีสมาชิกใช้งานสิทธิ์ ${name} อยู่`;

  return (
    <Dialog onClose={onClose} maxWidth="xs" open={isOpen}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">{content}</Typography>
        <Typography variant="body1">
          กรุณาเปลี่ยนสิทธิ์สมาชิกเหล่านั้นเป็นสิทธิ์อื่น ก่อนทำการลบ
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button color="primary" variant="contained" onClick={onClose}>
          ตกลง
        </Button>
      </DialogActions>
    </Dialog>
  );
}
