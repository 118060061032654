import { Typography, TypographyProps } from "@mui/material";

import { formatDate, formatTimeRange } from "@/formatter";

import { DateTime } from "luxon";

export function DateRangeDisplay({
  start,
  end,
  ...props
}: { start: DateTime; end: DateTime } & TypographyProps) {
  const durationInMinute = end.diff(start, ["minutes"]).minutes;

  const scheduleStart = formatDate(start);
  const scheduleTimeRange = `${formatTimeRange(
    start,
    end
  )} (${durationInMinute} นาที)`;

  return (
    <Typography {...props}>
      {scheduleStart}
      <br />
      {scheduleTimeRange}
    </Typography>
  );
}
