import {
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { TextEllipsis } from "@/components/TextEllipsis";
import { calculateColumnWidth } from "@/utils";
import { formatFacilityType } from "@/formatter";

import type { Facility } from "@/models";
import type { MouseEventHandler } from "react";

const columnsWidth = [280, 276, 184, 156, 130, 56].map((item) =>
  calculateColumnWidth(item)
);

export function FacilityTableRowHeader() {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]}>ชื่อ</TableCell>
      <TableCell width={columnsWidth[1]}>รายละเอียด</TableCell>
      <TableCell width={columnsWidth[2]}>ประเภท</TableCell>
      <TableCell width={columnsWidth[3]} align="center">
        จำนวนที่รอการยืนยัน
      </TableCell>
      <TableCell width={columnsWidth[4]} align="center">
        จำนวนผู้จอง
      </TableCell>
      <TableCell width={columnsWidth[5]} />
    </TableRow>
  );
}

type FacilityTableRowProps = {
  data: Facility;
};

export function FacilityTableRow({ data }: FacilityTableRowProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const { id, name, description, type, currentJoined, pendingCount } = data;
  const idString = id.toString();

  const onClickMenu: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const onClickEdit: MouseEventHandler<HTMLLIElement> = (event) => {
    event.stopPropagation();
    searchParams.set("dialog", "edit");
    searchParams.set("id", idString);
    setSearchParams(searchParams);
    setAnchorEl(null);
  };

  const onClickRemove: MouseEventHandler<HTMLLIElement> = (event) => {
    event.stopPropagation();
    searchParams.set("dialog", "remove");
    searchParams.set("id", idString);
    setSearchParams(searchParams);
    setAnchorEl(null);
  };

  const onClickTableRow: MouseEventHandler<HTMLTableRowElement> = () => {
    navigate(idString);
  };

  return (
    <TableRow hover tabIndex={-1} role="link" onClick={onClickTableRow}>
      <TableCell>
        <TextEllipsis variant="body2">{name}</TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis variant="body2">{description || "-"}</TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis variant="body2">{formatFacilityType(type)}</TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis variant="body2" align="center">
          {pendingCount}
        </TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis variant="body2" align="center">
          {currentJoined ?? 0}
        </TextEllipsis>
      </TableCell>
      <TableCell sx={{ px: 0 }} onClick={(event) => event.stopPropagation()}>
        <Tooltip title="เมนู">
          <IconButton aria-label="Menu" onClick={onClickMenu}>
            <MoreHoriz />
          </IconButton>
        </Tooltip>
        <Menu
          open={open}
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem onClick={onClickEdit} sx={{ width: 175 }}>
            แก้ไข
          </MenuItem>
          <MenuItem onClick={onClickRemove} sx={{ width: 175 }}>
            ลบ
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
}
