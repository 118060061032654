import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
} from "@firebase/storage";

export async function upload(path: string, file: File) {
  const storage = getStorage();
  const storageRef = ref(storage, `${path}/${encodeURIComponent(file.name)}`);
  const metadata = {
    cacheControl: "public, max-age=108000",
  };
  const { ref: fileRef } = await uploadBytes(storageRef, file, metadata);
  return getDownloadURL(fileRef);
}
