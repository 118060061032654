import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";

import { configs } from "@/configs";
import { refetchQueries, setTime } from "@/utils";
import { updateFacility } from "@/services/facility";
import { now } from "@/lib/dateTime";

import {
  FacilityEditorDialog,
  useFacilityEditorForm,
} from "./FacilityEditorDialog";

import type { Facility } from "@/models";
import type { AxiosErrorWithData } from "@/client/api";

export type EditFacilityDialogProps = {
  open: boolean;
  data?: Facility;
  onClose: () => void;
  fetchKeys?: string[];
};

export function EditFacilityDialog({
  open: isOpen,
  onClose,
  data,
  fetchKeys = [],
}: EditFacilityDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, reset } = useFacilityEditorForm();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isOpen && data) {
      const hasFee = !!data.memberFee && !!data.guestFee;

      reset({
        images: data.thumbnailUrl
          ? [{ id: data.thumbnailUrl, url: data.thumbnailUrl }]
          : [],
        name: data.name,
        type: data.type,
        capacity: data.capacity,
        description: data.description ?? "",
        openedAt: data.openedAt.toFormat("HH:mm"),
        closedAt: data.closedAt.toFormat("HH:mm"),
        hasFee: hasFee ? "true" : "false",
        memberFee: data.memberFee ?? 0,
        guestFee: data.guestFee ?? 0,
      });
    }
  }, [isOpen, data, reset]);

  function close() {
    onClose();
  }

  const { mutate: update, isLoading } = useMutation(updateFacility, {
    onSuccess: async () => {
      enqueueSnackbar("แก้ไขข้อมูลสิ่งอำนวยความสะดวกสำเร็จ", {
        variant: "success",
      });
      await refetchQueries({ queryClient, fetchKeys });
      close();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(
        error.response?.data.message ?? configs.unknownErrorMessage,
        { variant: "error" }
      );
    },
  });

  const onSubmit = handleSubmit((form) => {
    if (!data?.id) return;

    const input = {
      id: data.id.toString(),
      name: form.name,
      type: form.type,
      capacity: form.capacity,
      description: form.description ?? "",
      openedAt: setTime(now(), form.openedAt),
      closedAt: setTime(now(), form.closedAt),
      thumbnailUrl: form.images[0]?.url ?? null,
      memberFee: form.memberFee ?? null,
      guestFee: form.guestFee ?? null,
    };

    update(input);
  });

  const title = "แก้ไขข้อมูลสิ่งอำนวยความสะดวก";

  return (
    <FacilityEditorDialog
      open={isOpen}
      title={title}
      onClose={close}
      onSubmit={onSubmit}
      loading={isLoading}
      control={control}
    />
  );
}
