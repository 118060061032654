import * as yup from "yup";
import { InferType } from "yup";
import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { checkIn } from "@/services/checkIn";
import { configs } from "@/configs";

import { FormConfirmDialog } from "@/components/FormConfirmDialog";
import {
  FormMemberProfileAutocomplete,
  MergeFormMemberProfileControl,
} from "@/components/FormMemberProfileAutocomplete";
import { MemberAutocomplete } from "@/components/MemberAutocomplete";

import type { AxiosErrorWithData } from "@/client/api";

export type CheckInDialogState = InferType<typeof schema>;

export type CheckInDialogProps = {
  open: boolean;
  onClose: () => void;
};
export function CheckInDialog({ open, onClose }: CheckInDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate: add, isLoading } = useMutation(checkIn, {
    onSuccess: () => {
      enqueueSnackbar("เช็คอินสำเร็จ", {
        variant: "success",
      });
      void queryClient.refetchQueries("check-ins");
      close();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(
        error.response?.data.message ?? configs.unknownErrorMessage,
        { variant: "error" }
      );
      close();
    },
  });

  const { control, handleSubmit, reset } = useForm({
    resolver,
    defaultValues: {} as CheckInDialogState,
  });

  const onSubmit = handleSubmit((form) => add(form.member.id));

  function close() {
    reset();
    onClose();
  }

  const title = "เช็คอิน";

  return (
    <FormConfirmDialog
      control={control}
      title={title}
      loading={isLoading}
      open={open}
      onClose={close}
      onConfirm={onSubmit}
      confirmMessage="เช็คอิน"
    >
      <FormMemberProfileAutocomplete
        control={control as MergeFormMemberProfileControl}
        queryKey="memberCheckIn"
      />
    </FormConfirmDialog>
  );
}

const schema = yup.object({
  member: MemberAutocomplete.schema
    .label("หมายเลขสมาชิก ชื่อ หรือเบอร์โทรศัพท์")
    .required(),
});

const resolver = yupResolver(schema);
