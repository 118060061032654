import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import { useEffect } from "react";

import { refetchQueries } from "@/utils";
import { configs } from "@/configs";
import { updateFitness } from "@/services/fitness";

import { useFitnessEditorForm } from "./FitnessEditor";
import { FitnessEditorDialog } from "./FitnessEditorDialog";

import type { AxiosErrorWithData } from "@/client/api";
import type { FitnessCenter } from "@/models";

export type EditFitnessDialogProps = {
  open: boolean;
  data?: FitnessCenter;
  onClose: () => void;
  id: number;
  fetchKeys?: string[];
};

export function EditFitnessDialog({
  open: isOpen,
  onClose,
  id,
  data,
  fetchKeys = [],
}: EditFitnessDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, reset } = useFitnessEditorForm();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isOpen && data) {
      reset({
        images: data.logoUrl ? [{ id: data.logoUrl, url: data.logoUrl }] : [],
        type: data.type,
        isVatRegistration: data.isVatRegistration.toString(),
        name: data.name,
        phoneNo: data.phoneNo,
        mobilePhoneNo: data.mobilePhoneNo,
        faxNo: data.faxNo,
        companyName: data.companyName,
        taxNo: data.taxNo,
        address: {
          line: data.address,
          province: data.province,
          postcode: data.postCode,
          district: data.district,
          subdistrict: data.subdistrict,
        },
        bookingLimit: Number(data.bookingLimit),
      });
    }
  }, [isOpen, data, reset]);

  function close() {
    onClose();
  }

  const { mutate: update, isLoading } = useMutation(updateFitness, {
    onSuccess: async () => {
      enqueueSnackbar("แก้ไขข้อมูลฟิตเนสสำเร็จ", { variant: "success" });
      await refetchQueries({ queryClient, fetchKeys });
      close();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(
        error.response?.data.message ?? configs.unknownErrorMessage,
        { variant: "error" }
      );
    },
  });

  const onSubmit = handleSubmit((form) => {
    const input = {
      id,
      name: form.name,
      logoUrl: form.images[0].url,
      type: form.type,
      isVatRegistration: form.isVatRegistration === "true",
      companyName: form.companyName,
      phoneNo: form.phoneNo,
      mobilePhoneNo: form.mobilePhoneNo,
      faxNo: form.faxNo,
      taxNo: form.taxNo,
      address: form.address.line,
      postCode: form.address.postcode,
      province: form.address.province,
      district: form.address.district,
      subdistrict: form.address.subdistrict,
      bookingLimit: form.bookingLimit,
    };

    update(input);
  });

  const title = "แก้ไขข้อมูลฟิตเนส";

  return (
    <FitnessEditorDialog
      open={isOpen}
      title={title}
      onClose={close}
      onSubmit={onSubmit}
      loading={isLoading}
      control={control}
    />
  );
}
