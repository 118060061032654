import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Divider, Stack, Typography } from "@mui/material";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";

import { FormConfirmDialog } from "@/components/FormConfirmDialog";
import { Section } from "@/components/Section";
import { FormTextField } from "@/components/FormTextField";
import { updateTraining } from "@/services/training";
import { mapOptional, refetchQueries } from "@/utils";
import { configs } from "@/configs";
import { ConfirmDialog } from "@/components/ConfirmDialog";
import { now } from "@/lib/dateTime";
import { formatProfile } from "@/formatter";

import { TrainingEditor } from "./TrainingEditor";

import type { InferType } from "yup";
import type { Profile, Training, TrainingGoal } from "@/models";
import type { AxiosErrorWithData } from "@/client/api";

export type EditTrainingEditorState = InferType<typeof schema>;

type EditTrainingDialogProps = {
  open: boolean;
  onClose: () => void;
  training: Training;
  fetchKeys: string[];
};

export function EditTrainingDialog({
  open: isOpen,
  onClose,
  training,
  fetchKeys,
}: EditTrainingDialogProps) {
  const [openDialog, setOpenDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { control, handleSubmit, reset } = useEditTrainingEditorForm();
  const queryClient = useQueryClient();

  const trainer = useWatch({ control, name: "trainer" });
  const isChangeTrainer = trainer?.id && trainer.id !== training.trainer.id;
  const trainerName =
    mapOptional(trainer?.profile as Profile, formatProfile) ?? "-";

  const onUpdatedClose = () => {
    if (pathname.split("/").includes("trainers") && isChangeTrainer) {
      navigate(`/trainers/${training.trainer.id}/trainings`);
    } else {
      onClose();
    }
  };

  const { mutate: update, isLoading } = useMutation(updateTraining, {
    onSuccess: async () => {
      enqueueSnackbar("แก้ไขรายละเอียดการเทรนสำเร็จ", { variant: "success" });
      await refetchQueries({ queryClient, fetchKeys });
      onUpdatedClose();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(
        error.response?.data.message ?? configs.unknownErrorMessage,
        { variant: "error" }
      );
    },
  });

  useEffect(() => {
    if (isOpen && !!training) {
      const { trainer, member, goals } = training;

      reset({
        trainer,
        member,
        detail: training.detail || "-",
        startedAt: training.startedAt,
        endedAt: training.endedAt,
        goals,
      });
    }
  }, [isOpen, reset, training]);

  const onSubmit = async () => {
    if (isChangeTrainer) {
      setOpenDialog(true);
    } else {
      await handleUpdate();
    }
  };

  const handleUpdate = handleSubmit((form) => {
    const { trainer, ...input } = form;

    const newStaffId = isChangeTrainer ? { newStaffId: trainer.id } : {};

    update({
      id: training.id,
      ...newStaffId,
      ...input,
    });
  });

  const changeTrainerConfirmDialog = {
    open: openDialog,
    trainerName,
    isLoading,
    onClose: () => setOpenDialog(false),
    onConfirm: handleUpdate,
  };

  const isStartedAtDisabled = now().startOf("day") >= training.startedAt;

  const title = "แก้ไขเป้าหมายการเทรน";

  return (
    <FormConfirmDialog
      control={control}
      title={title}
      loading={isLoading}
      open={isOpen}
      onClose={onClose}
      onConfirm={onSubmit}
    >
      <Stack gap={3}>
        <TrainingEditor
          isEdit
          control={control}
          isStartedAtDisabled={isStartedAtDisabled}
          latestFollowedAt={training.trainingFollowUps[0].followedAt}
        />
        <Divider />
        <Section columns={1} label="เหตุผลในการแก้ไข">
          <FormTextField
            label="เหตุผล"
            name="reason"
            control={control}
            multiline
            rows={4}
          />
        </Section>
      </Stack>
      <ChangeTrainerConfirmDialog {...changeTrainerConfirmDialog} />
    </FormConfirmDialog>
  );
}

type ChangeTrainerConfirmDialogProps = {
  open: boolean;
  trainerName: string;
  onClose: () => void;
  onConfirm: () => void;
  isLoading: boolean;
};

function ChangeTrainerConfirmDialog({
  open,
  trainerName,
  isLoading,
  onClose,
  onConfirm,
}: ChangeTrainerConfirmDialogProps) {
  return (
    <ConfirmDialog
      maxWidth="xs"
      title="ยืนยันการแก้ไขชื่อเทรนเนอร์"
      loading={isLoading}
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      onCancel={onClose}
    >
      <Typography variant="body2" color="error.main">
        หากคุณยืนยันการแก้ไข ข้อมูลสมาชิกคนนี้จะถูกลบออกจากรายการ
        เป้าหมายการเทรนของคุณ และย้ายข้อมูลไปยังเทรนเนอร์ {trainerName} ทันที
      </Typography>
    </ConfirmDialog>
  );
}

export function useEditTrainingEditorForm() {
  return useForm({
    resolver,
    defaultValues,
  });
}

const schema = yup
  .object({
    reason: yup.string().label("หมายเหตุ"),
  })
  .concat(TrainingEditor.schema);

const resolver = yupResolver(schema);

const defaultValues = {
  goals: [] as TrainingGoal[],
} as EditTrainingEditorState;
