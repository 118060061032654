export class ApplicationException extends Error {
  code = "application/exception";
  message = "Something went wrong";
}

export class WrongPasswordException extends ApplicationException {
  code = "auth/wrong-password";
  message = "อีเมลหรือรหัสผ่านไม่ถูกต้อง";
}

export class UserNotFoundException extends ApplicationException {
  code = "auth/user-not-found";
  message = "ไม่พบอีเมลผู้ใช้งานนี้ในระบบ";
}

export class UserNoPermissionException extends ApplicationException {
  code = "auth/no-permission";
  message = "ผู้ใช้งานนี้ไม่มีสิทธิ์เข้าสู่ระบบ";
}

export class UserNotAuthenticatedException extends ApplicationException {
  code = "auth/not-authenticated";
  message = "Session นี้หมดอายุ โปรดเข้าสู่ระบบใหม่อีกครั้ง";
}

export class TooManyRequestException extends ApplicationException {
  code = "auth/too-many-requests";
  message =
    "บัญชีนี้ถูกระงับเข้าสู่ระบบชั่วคราว เนื่องจากมีการพยายามเข้าสู่ระบบจำนวนหลายครั้ง โปรดลองอีกครั้งในภายหลัง";
}

export class UserExpiredException extends ApplicationException {
  code = "auth/expired";
  message = "บัญชีของคุณหมดอายุ";
}
