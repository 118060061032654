import {
  Card,
  CardContent,
  TableContainer,
  Table,
  TableHead,
  Stack,
} from "@mui/material";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";

import { DataTableBody } from "@/components/DataTableBody";
import { QueryTextField } from "@/components/QueryTextField";
import { ItemsTotal } from "@/components/ItemsTotal";
import { SearchParamsPagination } from "@/components/SearchParamsPagination";
import { getPendingBookings } from "@/services/booking";

import {
  PendingBookingTableRow,
  PendingBookingTableRowHeader,
} from "./PendingBookingTableRow";

const QUERY_KEY = "trainerPendingBookings";

export function PendingBookingList() {
  const [searchParams] = useSearchParams();
  const query = searchParams.toString();

  const { data: raw, isFetching } = useQuery([QUERY_KEY, query], () =>
    getPendingBookings(query)
  );

  const data = raw?.data ?? [];
  const total = raw?.total;

  return (
    <>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Stack>
            <QueryTextField
              sx={{ width: 600, mb: 3 }}
              placeholder="ค้นหาชื่อเทรนเนอร์หรือชื่อสมาชิก"
            />
            <ItemsTotal count={total} />
            <TableContainer sx={{ height: "600px", overflowX: "hidden" }}>
              <Table stickyHeader>
                <TableHead>
                  <PendingBookingTableRowHeader />
                </TableHead>
                <DataTableBody loading={isFetching} data={data}>
                  {data.map((item) => (
                    <PendingBookingTableRow
                      key={item.id}
                      data={item}
                      fetchKey={QUERY_KEY}
                    />
                  ))}
                </DataTableBody>
              </Table>
            </TableContainer>
          </Stack>
        </CardContent>
      </Card>
      <SearchParamsPagination total={total} />
    </>
  );
}
