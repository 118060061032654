import { configs } from "@/configs";
import { api } from "@/client/api";
import { parseDateTimeFromPrisma } from "@/utils";

import type { DateTime } from "luxon";
import type { FollowUp } from "@/models";

type UpdateFollowUpInput = {
  id: string;
  followedAt: DateTime;
  remark?: string;
  reason?: string;
};

export async function updateFollowUp({ id, ...input }: UpdateFollowUpInput) {
  return await api.patch(`${configs.apiUrl}/follow-ups/${id}`, input);
}

export async function getFollowUp(id: string): Promise<FollowUp> {
  const response = await api.get<FollowUp>(
    `${configs.apiUrl}/follow-ups/${id}`
  );
  return parseDateTimeFromPrisma(response.data) as FollowUp;
}
