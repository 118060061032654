import { useState } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import MoreHoriz from "@mui/icons-material/MoreHoriz";

import { TextEllipsis } from "@/components/TextEllipsis";
import { DateRangeDisplay } from "@/components/DateRangeDisplay";
import { calculateColumnWidth, mapOptional } from "@/utils";
import {
  formatBookingStatus,
  formatBookingStatusColor,
  formatProfiles,
} from "@/formatter";
import { BookingStatus } from "@/models";

import type { MemberBooking, Staff } from "@/models";

const columnsWidth = [200, 240, 240, 120, 132, 96, 56].map((width) =>
  calculateColumnWidth(width)
);

export function ClassReservationTableHeaderRow() {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]}>วันที่และเวลา</TableCell>
      <TableCell width={columnsWidth[1]}>ชื่อคลาส</TableCell>
      <TableCell width={columnsWidth[2]}>ผู้สอน</TableCell>
      <TableCell width={columnsWidth[3]}>สถานที่</TableCell>
      <TableCell width={columnsWidth[4]}>สาขา</TableCell>
      <TableCell width={columnsWidth[5]} align="center">
        สถานะ
      </TableCell>
      <TableCell width={columnsWidth[6]} />
    </TableRow>
  );
}

export type ClassReservationTableRowProps = {
  data: MemberBooking;
};
export function ClassReservationTableRow({
  data,
}: ClassReservationTableRowProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    id,
    schedule: {
      trainers,
      startedAt,
      endedAt,
      class: { name: className },
      location,
      branch: { name: branchName },
    },
    status,
  } = data;

  const trainersName =
    mapOptional(
      trainers as unknown as Pick<Staff, "profile">[],
      formatProfiles
    ) ?? "-";

  const isShowMenu = [
    BookingStatus.Booked,
    BookingStatus.Waiting,
    BookingStatus.Absent,
    BookingStatus.Attended,
  ].includes(status);

  const isShowUpdateParticipantMenu = [
    BookingStatus.Attended,
    BookingStatus.Absent,
  ].includes(status);

  const toUpdateParticipantStatus =
    status === BookingStatus.Attended
      ? BookingStatus.Absent
      : BookingStatus.Attended;

  function handleClickCancelBooking() {
    searchParams.set("dialog", "cancelBooking");
    searchParams.set("bookingId", id.toString());
    setSearchParams(searchParams);
    setAnchorEl(null);
  }

  function handleClickUpdateParticipant() {
    searchParams.set("dialog", "updateStatus");
    searchParams.set("bookingId", id.toString());
    setSearchParams(searchParams);
    setAnchorEl(null);
  }

  return (
    <TableRow>
      <TableCell>
        <DateRangeDisplay variant="body2" start={startedAt} end={endedAt} />
      </TableCell>
      <TableCell>{className ?? "-"}</TableCell>
      <TableCell>
        <TextEllipsis variant="body2" line={3}>
          {trainersName}
        </TextEllipsis>
      </TableCell>
      <TableCell aria-label="Location">{location}</TableCell>
      <TableCell aria-label="Branch">{branchName}</TableCell>
      <TableCell aria-label="Status">
        <Typography
          variant="body2"
          align="center"
          fontWeight={500}
          sx={{ color: formatBookingStatusColor(status) }}
        >
          {formatBookingStatus(status)}
        </Typography>
      </TableCell>
      <TableCell sx={{ px: 0 }}>
        {isShowMenu && (
          <Tooltip title="เมนู">
            <IconButton
              aria-label="Menu"
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              <MoreHoriz />
            </IconButton>
          </Tooltip>
        )}
        <Menu
          open={open}
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {isShowUpdateParticipantMenu && (
            <MenuItem onClick={handleClickUpdateParticipant}>
              เปลี่ยนสถานะเป็น{formatBookingStatus(toUpdateParticipantStatus)}
            </MenuItem>
          )}
          <MenuItem onClick={handleClickCancelBooking}>ยกเลิกการจอง</MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
}
