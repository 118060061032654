import { Stack } from "@mui/material";
import { Helmet } from "react-helmet-async";

import { getDocumentTitle, useRequireParams } from "@/utils";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { FollowUpDetail } from "@/features/training/pages/FollowUpDetailPage/FollowUpDetail";

export function MemberFollowUpDetailPage() {
  const { memberId, trainingId, followUpId } = useRequireParams([
    "memberId",
    "trainingId",
    "followUpId",
  ]);

  const title = "รายละเอียดการติดตามผลการเทรน";
  const breadcrumbs = {
    items: [
      { label: "สมาชิก", to: "/members" },
      { label: "ข้อมูลสมาชิก", to: `/members/${memberId}` },
      {
        label: "รายละเอียดเป้าหมายการเทรน",
        to: `/members/${memberId}/trainings/${trainingId}`,
      },
      {
        label: title,
        to: `/members/${memberId}/trainings/${trainingId}/follow-ups/${followUpId}`,
      },
    ],
  };

  return (
    <Stack gap={4}>
      <Helmet>
        <title>{getDocumentTitle(title)}</title>
      </Helmet>
      <Breadcrumbs {...breadcrumbs} />
      <FollowUpDetail id={followUpId} />
    </Stack>
  );
}
