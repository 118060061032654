import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  colors,
  useTheme,
} from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import { ComponentProps, useMemo, useRef, useState } from "react";
import { DateTime } from "luxon";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";

import { mapOptional } from "@/utils";
import { configs } from "@/configs";
import { fromJSDate, now } from "@/lib/dateTime";
import { ClassScheduleDialog } from "@/components/ClassScheduleDialog";
import { ScheduleLabel } from "@/features/trainer/pages/TrainerProfilePage/ScheduleLabel";
import { getAllClassSchedules } from "@/services/class";

type FullCalendarProps = ComponentProps<typeof FullCalendar>;

const thLocale = {
  code: "th",
  week: {
    dow: 0,
    doy: 4,
  },
  buttonText: {
    prev: "ก่อนหน้า",
    next: "ถัดไป",
    prevYear: "ปีก่อนหน้า",
    nextYear: "ปีถัดไป",
    year: "ปี",
    today: "วันนี้",
    month: "เดือน",
    week: "สัปดาห์",
    day: "วัน",
    list: "รายการ",
  },
  weekText: "สัปดาห์",
  allDayText: "เวลา",
  moreLinkText: "เพิ่มเติม",
  noEventsText: "ไม่มีกิจกรรมที่จะแสดง",
};
const headerToolbar = { left: "", center: "", right: "" };
const views: FullCalendarProps["views"] = {
  dayGridMonth: {
    titleFormat: { year: "numeric", month: "long" },
    displayEventTime: false,
  },
  timeGridWeek: {
    titleFormat: {
      year: "numeric",
      month: "long",
      day: "numeric",
    },
    dayHeaderFormat(format) {
      return fromJSDate(format.date.marker).setLocale("th").toFormat("EEE M/d");
    },
  },
  timeGridDay: {
    titleFormat: {
      year: "numeric",
      month: "long",
      day: "numeric",
    },
  },
  listMonth: {
    titleFormat: {
      year: "numeric",
      month: "long",
      day: "numeric",
    },
  },
};
const plugins = [dayGridPlugin, timeGridPlugin, listPlugin];
const viewModes = {
  listMonth: "รายการ",
  dayGridMonth: "เดือน",
  timeGridWeek: "สัปดาห์",
  timeGridDay: "วัน",
} as const;

const QUERY_KEY = "classScheduleCalendar";

export function ClassSchedulesCalendar() {
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const [dateRange, setDateRange] = useState<{
    start: DateTime;
    end: DateTime;
  }>();

  const dateRangeISO = useMemo(
    () =>
      mapOptional(dateRange, ({ start, end }) => ({
        start: start > now() ? start.toISO() : now().toISO(),
        end: end.toISO(),
      })) ?? { start: "", end: "" },
    [dateRange]
  );

  const { start, end } = dateRangeISO;

  const { data, isFetching, isPreviousData } = useQuery(
    [QUERY_KEY, start, end],
    () => getAllClassSchedules({ start, end }),
    { enabled: typeof dateRange !== "undefined", keepPreviousData: true }
  );

  const calendarRef = useRef<FullCalendar>(null);

  const onDatesSet: FullCalendarProps["datesSet"] = ({ start, end }) => {
    setDateRange({ start: fromJSDate(start), end: fromJSDate(end) });
  };

  // const onEventClick: FullCalendarProps["eventClick"] = ({ event }) => {
  //   searchParams.set("dialog", "class-schedule");
  //   searchParams.set("id", event.id);

  //   setSearchParams(searchParams, { replace: true });
  // };

  const events = useMemo(
    () =>
      data?.map((schedule) => {
        const title = `${schedule.class?.name ?? ""} / ${
          schedule.staffs?.[0]?.profile?.firstName ?? ""
        } ${schedule.staffs?.[0]?.profile?.lastName ?? ""} (${
          schedule.numberOfBookings
        }/${schedule.capacity})`;
        return {
          id: schedule.id.toString(),
          color:
            schedule.numberOfBookings >= schedule.capacity
              ? theme.palette.primary.main
              : schedule.capacity - schedule.numberOfBookings < 2
              ? colors.amber[800]
              : theme.palette.success.main,
          title,
          url: `/classes/${schedule.class?.id ?? ""}/schedules/${schedule.id}`,
          start: schedule.startedAt.toISO(),
          end: schedule.endedAt.toISO(),
        };
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const vm = mapOptional(calendarRef.current?.getApi(), (api) => {
    const {
      currentViewType: currentView,
      dateProfile: {
        currentRange: { start, end },
      },
      viewTitle: title,
    } = api.getCurrentData();

    return {
      title,
      disabledToday:
        start.getTime() <= Date.now() && Date.now() <= end.getTime(),
      currentView,
      today: () => api.today(),
      next: () => api.next(),
      previous: () => api.prev(),
    };
  });

  function onCloseDialog() {
    searchParams.delete("dialog");
    searchParams.delete("id");
    setSearchParams(searchParams);
  }

  const classScheduleDialog = {
    open: Boolean(
      searchParams.get("dialog") === "class-schedule" && searchParams.get("id")
    ),
    scheduleId: searchParams.get("id"),
    onClose: onCloseDialog,
  };

  return (
    <Card>
      <CardHeader
        title={
          <Stack direction="row" gap={2.5} alignItems="baseline">
            <ScheduleLabel
              variant="caption"
              color={theme.palette.success.main}
              gap={0}
            >
              จองได้
            </ScheduleLabel>
            <ScheduleLabel variant="caption" color={colors.amber[800]} gap={0}>
              ใกล้เต็ม
            </ScheduleLabel>
            <ScheduleLabel
              variant="caption"
              color={theme.palette.primary.main}
              gap={0}
            >
              จองเต็ม
            </ScheduleLabel>
          </Stack>
        }
      />
      <CardContent>
        <Stack direction="row" justifyContent="space-between">
          <Box>
            <Button
              variant="contained"
              disabled={vm?.disabledToday ?? true}
              onClick={vm?.today}
            >
              วันนี้
            </Button>
            <Tooltip title="ก่อนหน้า">
              <IconButton sx={{ ml: 4 }} onClick={vm?.previous}>
                <ChevronLeft />
              </IconButton>
            </Tooltip>
            <Tooltip title="ถัดไป">
              <IconButton onClick={vm?.next}>
                <ChevronRight />
              </IconButton>
            </Tooltip>
          </Box>
          <Typography variant="h5">{vm?.title}</Typography>
          <ButtonGroup variant="contained" size="large" color="inherit">
            {Object.entries(viewModes).map(([key, title]) => (
              <Button
                key={key}
                sx={{
                  bgcolor: key === vm?.currentView ? "grey.100" : "grey.300",
                }}
                onClick={() => calendarRef.current?.getApi().changeView(key)}
              >
                {title}
              </Button>
            ))}
          </ButtonGroup>
        </Stack>
        {isFetching && !isPreviousData ? (
          <Box display="grid" sx={{ placeItems: "center" }} height={800}>
            <CircularProgress disableShrink />
          </Box>
        ) : (
          <FullCalendar
            ref={calendarRef}
            plugins={plugins}
            initialView="listMonth"
            locale={thLocale}
            headerToolbar={headerToolbar}
            events={events}
            themeSystem="mui"
            datesSet={onDatesSet}
            // eventClick={onEventClick}
            views={views}
            nowIndicator={true}
            timeZone={configs.timezone}
            dayMaxEvents={3}
          />
        )}
      </CardContent>
      <ClassScheduleDialog {...classScheduleDialog} />
    </Card>
  );
}
