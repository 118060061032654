import {
  Box,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { formatPrice } from "@/formatter";
import { EditProductDialog } from "@/features/product/components/EditProductDialog";

import { RemoveProductDialog } from "../../components/RemoveProductDialog";
import { ProductStatusSwitch } from "./ProductStatusSwitch";

import type { Product } from "@/models";
import type { RemoveProductDialogProps } from "../../components/RemoveProductDialog";

export type ProductDetailProps = {
  data: Product;
  fetchKeys?: string[];
};

export function ProductDetail({ data, fetchKeys }: ProductDetailProps) {
  const { id, name, description } = data;
  const { pathname } = useLocation();
  const isInMemberPage = pathname.split("/").some((path) => path === "members");

  const price = formatPrice(data.price);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const dialog = searchParams.get("dialog");

  function openRemoveDialog() {
    searchParams.set("dialog", "product-remove");
    setSearchParams(searchParams);
  }

  function openEditDialog() {
    searchParams.set("dialog", "product-edit");
    setSearchParams(searchParams);
  }

  function onCloseDialog() {
    searchParams.delete("dialog");
    setSearchParams(searchParams);
  }

  const removeDialog: RemoveProductDialogProps = {
    open: dialog === "product-remove",
    onClose(reason) {
      if (reason === "cancel") {
        onCloseDialog();
        return;
      }

      navigate("/products-and-packages/products");
    },
    id: id.toString(),
  };

  const editDialog = {
    open: dialog === "product-edit",
    onClose: onCloseDialog,
    id: id.toString(),
    data,
    fetchKeys,
  };

  return (
    <Stack>
      <CardHeader
        title={name}
        action={
          <Stack direction="row">
            {!isInMemberPage && (
              <>
                <ProductStatusSwitch data={data} fetchKeys={fetchKeys} />
                <Tooltip title="แก้ไข">
                  <IconButton onClick={openEditDialog}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="ลบ">
                  <IconButton onClick={openRemoveDialog}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Stack>
        }
      />
      <CardContent>
        <Box
          display="grid"
          gridTemplateColumns="150px 1fr"
          gap={3}
          alignItems="baseline"
        >
          <Typography variant="body1" color="text.disabled" fontWeight={500}>
            ราคา
          </Typography>
          <Typography variant="body2">{price}</Typography>
          <Typography variant="body1" color="text.disabled" fontWeight={500}>
            รายละเอียด
          </Typography>
          <Typography variant="body2">{description}</Typography>
        </Box>
      </CardContent>
      <EditProductDialog {...editDialog} />
      <RemoveProductDialog {...removeDialog} />
    </Stack>
  );
}
