import {
  Box,
  Breadcrumbs,
  Tab,
  Tabs,
  TabsProps,
  Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Helmet } from "react-helmet-async";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { getDocumentTitle } from "@/utils";

export function ClassPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const path = location?.pathname ?? "";

  const onChangeTab: TabsProps["onChange"] = (_, value: string) => {
    navigate(value, { replace: true });
  };

  return (
    <Box>
      <Helmet>
        <title>{getDocumentTitle("Class")}</title>
      </Helmet>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ mb: 2 }}
      >
        <Typography color="text.primary">คลาส</Typography>
      </Breadcrumbs>
      <Tabs value={path} onChange={onChangeTab} sx={{ mb: 5 }}>
        <Tab label="ตารางคลาส" value="/classes/calendar" sx={{ width: 180 }} />
        <Tab label="จัดการคลาส" value="/classes" sx={{ width: 180 }} />
      </Tabs>
      <Outlet />
    </Box>
  );
}
