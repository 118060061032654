import { Button, ButtonGroup } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import { formatTrainingStatus } from "@/formatter";
import { TrainingStatus } from "@/models";
import { mapOptional } from "@/utils";

export function TrainingStatusButtonGroup() {
  const [searchParams, setSearchParams] = useSearchParams();

  const status = searchParams.get("status") as TrainingStatus | undefined;

  const setStatus = (newStatus: TrainingStatus | null) => () => {
    if (newStatus) {
      searchParams.set("status", newStatus);
    } else {
      searchParams.delete("status");
    }

    searchParams.delete("page");
    setSearchParams(searchParams);
  };

  return (
    <ButtonGroup
      variant="contained"
      size="large"
      color="inherit"
      sx={{ width: "fit-content" }}
    >
      {[
        null,
        TrainingStatus.InProgress,
        TrainingStatus.Success,
        TrainingStatus.Failed,
      ].map((traiingStatus) => (
        <Button
          key={traiingStatus}
          onClick={setStatus(traiingStatus)}
          sx={{ bgcolor: traiingStatus === status ? "grey.100" : "grey.300" }}
        >
          {mapOptional(traiingStatus, formatTrainingStatus) ?? "ทั้งหมด"}
        </Button>
      ))}
    </ButtonGroup>
  );
}
