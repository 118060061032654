import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";

import { configs } from "@/configs";

import type { DialogProps } from "@mui/material";

export type MemberAlertDialogProps = Omit<DialogProps, "onClose"> & {
  onClose?: () => void;
  count: number;
};

export function MemberAlertDialog({
  onClose,
  count,
  title,
  children,
  ...props
}: MemberAlertDialogProps) {
  return (
    <Dialog title={title} onClose={onClose} maxWidth="xs" {...props}>
      <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
        {count > 0
          ? "จำนวนสมาชิกของคุณใกล้ครบกำหนด"
          : "จำนวนสมาชิกปัจจุบันของคุณครบกำหนดแล้ว"}
      </DialogTitle>
      <DialogContent>
        <Stack>
          <Typography variant="body1">
            {count > 0
              ? `เนื่องจากจำนวนสมาชิกปัจจุบันของคุณใกล้ครบตามจำนวนที่กำหนดในแพ็คเกจแล้ว คุณสามารถเพิ่มจำนวนสมาชิกได้ อีก ${count} คน หากต้องการอัพเกรดแพ็คเกจเพื่อรองรับกับ จำนวนสมาชิก`
              : "ไม่สามารถเพิ่มจำนวนสมาชิกได้ เนื่องจากจำนวนสมาชิก ปัจจุบันของคุณครบตามจำนวนที่กำหนดในแพ็คเกจแล้ว หากต้องการอัพเกรดแพ็คเกจเพื่อรองรับกับจำนวนสมาชิก"}
          </Typography>
          <Typography variant="body1" mt={1}>
            <a
              href={configs.aboutUsUrl}
              target="_blank"
              style={{ color: "black" }} rel="noreferrer"
            >
              กรุณาติดต่อทีมงาน FitUP
            </a>
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={onClose}>
          ตกลง
        </Button>
      </DialogActions>
    </Dialog>
  );
}
