import { IconButton, Stack, TableCell, TableRow, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useSearchParams } from "react-router-dom";

import { TextEllipsis } from "@/components/TextEllipsis";
import {
  formatDurationISO,
  formatPrice,
  formatProductType,
  formatQuotaUnit,
} from "@/formatter";
import { calculateColumnWidth } from "@/utils";

import { ProductPT } from "@/models";
import { useCurrentUser } from "@/features/authentication/contexts/AuthenticationContext";

import type { MouseEventHandler } from "react";

const columnsWidth = [210, 301, 102, 92, 140, 143, 96].map((item) =>
  calculateColumnWidth(item)
);

export type ProductPTTableRowProps = {
  data: ProductPT;
};

export function ProductPTTableRowHeader() {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]}>ชื่อ</TableCell>
      <TableCell width={columnsWidth[1]}>รายละเอียด</TableCell>
      <TableCell width={columnsWidth[2]}>จำนวน</TableCell>
      <TableCell width={columnsWidth[3]}>ระยะเวลา</TableCell>
      <TableCell width={columnsWidth[4]}>ราคา</TableCell>
      <TableCell width={columnsWidth[5]}>สาขาที่เข้าใช้บริการ</TableCell>
      <TableCell width={columnsWidth[6]} />
    </TableRow>
  );
}

export function ProductPTTableRow({ data }: ProductPTTableRowProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const currentUser = useCurrentUser();

  const { id, name, description, productPersonalTraining, type } = data;
  const { quota, quotaUnit, durationIso } = productPersonalTraining;
  const price = formatPrice(data.price);
  const duration = formatDurationISO(durationIso);

  const idString = id.toString();

  const onClickRemove: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    searchParams.set("dialog", "pt-remove");
    searchParams.set("id", idString);
    setSearchParams(searchParams);
  };

  const onClickTableRow: MouseEventHandler<HTMLTableRowElement> = () => {
    navigate(idString);
  };

  return (
    <TableRow hover tabIndex={-1} role="link" onClick={onClickTableRow}>
      <TableCell>
        <TextEllipsis variant="body2">{name}</TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis variant="body2">{description}</TextEllipsis>
      </TableCell>
      <TableCell>
        {quota} {formatQuotaUnit(quotaUnit)}
      </TableCell>
      <TableCell>{duration}</TableCell>
      <TableCell>{price}</TableCell>
      <TableCell>{formatProductType(type)}</TableCell>
      <TableCell sx={{ px: 0 }}>
        {currentUser.isAdmin && (
          <Stack direction="row" gap={1}>
            <Tooltip title="ลบ">
              <IconButton onClick={onClickRemove}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        )}
      </TableCell>
    </TableRow>
  );
}
