import { Card, CardContent, Stack, Typography, colors } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";

import { CircularLoading } from "@/components/CircularLoading";
import { formatTrainingGoal } from "@/formatter";
import { TrainingGoal } from "@/models";
import { EmptyResultIcon } from "@/components/Icon";

import type { MemberGoalCountSummary } from "@/models";
import type { AxisConfig, ChartsXAxisProps } from "@mui/x-charts";
import type { MakeOptional } from "@mui/x-date-pickers/internals/models/helpers";
import type { AxisScaleConfig } from "@mui/x-charts/internals";

type CompareGoalCountBarChartProps = {
  data?: MemberGoalCountSummary;
  isFetching: boolean;
};

export function CompareGoalCountBarChart({
  data,
  isFetching,
}: CompareGoalCountBarChartProps) {
  const goals = [
    TrainingGoal.BodyComposition,
    TrainingGoal.GeneralHealth,
    TrainingGoal.SkillRelated,
    TrainingGoal.SportPerformance,
    TrainingGoal.Other,
  ];

  const { labels, successList, failedList } = goals.reduce(
    (memo, goal) => {
      const success = data?.goalCount.success[goal] ?? 0;
      const failed = data?.goalCount.failed[goal] ?? 0;

      memo.labels.push(formatTrainingGoal(goal));
      memo.successList.push(success);
      memo.failedList.push(failed);

      return memo;
    },
    {
      labels: [] as string[],
      successList: [] as number[],
      failedList: [] as number[],
    }
  );

  const isEmpty =
    successList.concat(failedList).reduce((sum, value) => sum + value, 0) === 0;

  return (
    <Card>
      <CardContent>
        <Stack gap={3}>
          <Typography variant="h6">
            เปรียบเทียบเป้าหมายการเทรนสำเร็จและไม่สำเร็จ
          </Typography>
          {isFetching ? (
            <CircularLoading height={400} />
          ) : isEmpty ? (
            <EmptyResultIcon height={400} />
          ) : (
            <BarChart
              height={400}
              series={[
                { data: successList, color: colors.green[500] },
                { data: failedList, color: colors.red[500] },
              ]}
              xAxis={[
                {
                  scaleType: "band",
                  data: labels,
                  categoryGapRatio: 0.6,
                } as MakeOptional<
                  AxisConfig<keyof AxisScaleConfig, any, ChartsXAxisProps>,
                  "id"
                >, // https://github.com/mui/mui-x/issues/10334
              ]}
              yAxis={[{ label: "ครั้ง" }]}
              grid={{ horizontal: true }}
            />
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
