import { useQuery } from "react-query";
import {
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  CircularProgress,
  Link as MuiLink,
  Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";

import { useRequireParams } from "@/utils";
import { getBranch } from "@/services/branch";
import { useCurrentUser } from "@/features/authentication/contexts/AuthenticationContext";

import { BranchDetail } from "./BranchDetail";
import { PermissionDoorList } from "./PermissionDoorList";

const QUERY_KEY = "getBranch";

export function BranchDetailPage() {
  const { branches } = useCurrentUser();
  const { branchId } = useRequireParams(["branchId"]);

  const { data, isFetching } = useQuery([QUERY_KEY], () => getBranch(branchId));

  const isHasBranchPermission = branches.some(
    ({ id }) => id.toString() === branchId
  );

  const isShowPermissionDoorSection =
    isHasBranchPermission && data?.hasDoorAccessControl;

  return (
    <Box>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ mb: 2 }}
      >
        <MuiLink component={Link} color="text.secondary" to="/fitness">
          จัดการฟิตเนส
        </MuiLink>
        <Typography color="text.primary">รายละเอียดสาขา</Typography>
      </Breadcrumbs>
      <Card sx={{ mb: 2.5 }}>
        {!isFetching && data ? (
          <CardContent>
            <BranchDetail data={data} fetchKeys={[QUERY_KEY]} />
          </CardContent>
        ) : (
          <Box display="grid" sx={{ placeItems: "center" }} height={306}>
            <CircularProgress disableShrink />
          </Box>
        )}
      </Card>
      {isShowPermissionDoorSection && <PermissionDoorList branch={data} />}
    </Box>
  );
}
