import { useMutation, useQueryClient } from "react-query";
import { useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { ConfirmDialog } from "@/components/ConfirmDialog";
import { DebounceTextField } from "@/components/DebounceTextField";
import { CircularLoading } from "@/components/CircularLoading";
import { ErrorIcon, ScanQRCodeIcon, SuccessIcon } from "@/components/Icon";
import { checkInByMemberCode } from "@/services/checkIn";
import { configs } from "@/configs";

import type { AxiosErrorWithData } from "@/client/api";

export type AutoCheckInDialogProps = {
  open: boolean;
  onClose: () => void;
};
export function AutoCheckInDialog({ open, onClose }: AutoCheckInDialogProps) {
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
  const [input, setInput] = useState("");
  const [message, setMessage] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const queryClient = useQueryClient();

  const {
    mutate: add,
    isLoading,
    isError,
  } = useMutation(checkInByMemberCode, {
    onSuccess: (data) => {
      const {
        member: {
          code,
          profile: { firstName, lastName },
        },
      } = data;

      void queryClient.refetchQueries("check-ins");
      setMessage(`${code || ""} ${firstName} ${lastName}\nเช็คอินสำเร็จ`);
      setInput("");
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      setMessage(
        `${input}\n${
          error.response?.data.message ?? configs.unknownErrorMessage
        }`
      );
      setInput("");
    },
  });

  useEffect(() => {
    if (input) {
      add(input);
    }
  }, [add, input]);

  useEffect(() => {
    if (open && !isOpenConfirmDialog && !isLoading) {
      inputRef.current?.focus();
    }
  }, [open, isOpenConfirmDialog, isLoading]);

  const handleOpenConfirmDialog = () => setIsOpenConfirmDialog(true);
  const handleCloseConfirmDialog = () => setIsOpenConfirmDialog(false);
  const handleChangeInput: JSX.IntrinsicElements["input"]["onChange"] = (
    event
  ) => setInput(event.target.value);

  function close() {
    handleCloseConfirmDialog();
    setMessage("");
    onClose();
  }

  const title = "โหมดเช็คอินอัตโนมัติ";

  return (
    <Dialog
      disableRestoreFocus
      maxWidth="xs"
      open={open}
      onClose={handleOpenConfirmDialog}
    >
      <DialogTitle>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {title}
          <IconButton size="medium" onClick={handleOpenConfirmDialog}>
            <CloseIcon fontSize="large" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ pt: "24px !important", pb: 7 }}>
        <DebounceTextField
          value={input}
          onChange={handleChangeInput}
          inputRef={inputRef}
          autoFocus
          delay={1000}
          onBlur={() => inputRef.current?.focus()}
          sx={{ position: "fixed", opacity: 0, zIndex: -10 }}
          disabled={isLoading}
        />
        {isLoading ? (
          <CircularLoading height={236} />
        ) : !!message ? (
          <ResultState message={message} isError={isError} />
        ) : (
          <IdleState />
        )}
      </DialogContent>
      <ConfirmDialog
        title="คุณต้องการยกเลิกโหมดเช็คอินอัตโนมัติใช่หรือไม่"
        open={isOpenConfirmDialog}
        onCancel={handleCloseConfirmDialog}
        onConfirm={close}
        maxWidth="xs"
        reverse
        confirmMessage="ใช่"
        cancelMessage="ไม่ใช่"
      />
    </Dialog>
  );
}

function IdleState() {
  return (
    <Stack gap={1} justifyContent="center" alignItems="center">
      <ScanQRCodeIcon />
      <Typography variant="subtitle1" color="text.disabled">
        สแกนคิวอาร์โค้ด เพื่อเช็คอิน
      </Typography>
    </Stack>
  );
}

type ResultStateProps = {
  message: string;
  isError: boolean;
};

function ResultState({ message, isError }: ResultStateProps) {
  const messages = message.split("\n");
  return (
    <Stack height={236} gap={3} justifyContent="center" alignItems="center">
      {isError ? <ErrorIcon /> : <SuccessIcon />}
      <Stack alignItems="center">
        {messages.map((text, index) => (
          <Typography key={`${text}-${index}`} variant="subtitle1">
            {text}
          </Typography>
        ))}
      </Stack>
    </Stack>
  );
}
