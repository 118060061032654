import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";

import { ConfirmDialog } from "@/components/ConfirmDialog";
import { cancelClassSchedule } from "@/services/class";
import { configs } from "@/configs";
import { refetchQueries, useRequireParams } from "@/utils";

import type { AxiosErrorWithData } from "@/client/api";

export type CancelScheduleDialogProps = {
  open: boolean;
  onClose: () => void;
  id: string;
  fetchKeys?: string[];
};

export function CancelScheduleDialog({
  open: isOpen,
  onClose,
  id: scheduleId,
  fetchKeys = [],
}: CancelScheduleDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { id: classId } = useRequireParams(["id"]);
  const queryClient = useQueryClient();

  const { mutate: cancel, isLoading } = useMutation(cancelClassSchedule, {
    onSuccess: async () => {
      enqueueSnackbar("ยกเลิกตารางเวลาสำเร็จ", { variant: "success" });
      await refetchQueries({ queryClient, fetchKeys });
      onClose();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(
        error.response?.data.message ?? configs.unknownErrorMessage,
        { variant: "error" }
      );
    },
  });

  const onConfirm = () => cancel({ classId, scheduleId });

  return (
    <ConfirmDialog
      maxWidth="xs"
      open={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      title="คุณต้องการยกเลิกหรือไม่"
      confirmMessage="ยืนยัน"
      loading={isLoading}
    />
  );
}
