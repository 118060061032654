import { SvgIcon } from "@mui/material";

export function GoalIcon() {
  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Vector">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.4482 0.0585766C16.7356 0.177639 16.9231 0.458128 16.9231 0.769252V1.98909L17.9176 0.994554C18.218 0.694151 18.705 0.694151 19.0054 0.994554C19.3058 1.29496 19.3058 1.78201 19.0054 2.08241L18.0109 3.07694H19.2307C19.5419 3.07694 19.8224 3.26436 19.9414 3.5518C20.0605 3.83924 19.9947 4.1701 19.7747 4.3901L16.6978 7.46702C16.5535 7.61127 16.3578 7.69232 16.1538 7.69232H13.3955L10.7171 10.3707C10.7511 10.4978 10.7692 10.6314 10.7692 10.7692C10.7692 11.6189 10.0804 12.3077 9.23076 12.3077C8.3811 12.3077 7.6923 11.6189 7.6923 10.7692C7.6923 9.91957 8.3811 9.23078 9.23076 9.23078C9.36858 9.23078 9.50216 9.2489 9.62926 9.28289L12.3077 6.60446V3.84617C12.3077 3.64216 12.3887 3.4465 12.533 3.30224L15.6099 0.225325C15.8299 0.00532649 16.1608 -0.0604855 16.4482 0.0585766ZM13.8461 5.066L15.3846 3.52754V2.62634L13.8461 4.16479V5.066ZM14.934 6.15386H15.8352L17.3737 4.6154H16.4725L14.934 6.15386Z"
            fill="currentColor"
          />
          <path
            d="M5.61945 3.97734C7.05376 3.21471 8.69268 2.92572 10.3013 3.1518C10.722 3.21093 11.111 2.91781 11.1701 2.49711C11.2292 2.07641 10.9361 1.68744 10.5154 1.62831C8.58506 1.35702 6.61835 1.7038 4.89719 2.61896C3.17602 3.53413 1.78875 4.97068 0.934216 6.72274C0.0796801 8.4748 -0.198255 10.4524 0.140245 12.3721C0.478745 14.2919 1.4163 16.0552 2.81854 17.4093C4.22078 18.7634 6.01572 19.6389 7.94609 19.9102C9.87646 20.1815 11.8432 19.8347 13.5643 18.9195C15.2855 18.0043 16.6728 16.5678 17.5273 14.8157C18.3818 13.0637 18.6598 11.0861 18.3213 9.16633C18.2475 8.74796 17.8485 8.4686 17.4302 8.54237C17.0118 8.61614 16.7324 9.01511 16.8062 9.43349C17.0883 11.0333 16.8567 12.6813 16.1446 14.1413C15.4324 15.6014 14.2764 16.7985 12.8421 17.5611C11.4078 18.3238 9.76885 18.6128 8.1602 18.3867C6.55156 18.1606 5.05578 17.4311 3.88725 16.3026C2.71871 15.1742 1.93741 13.7048 1.65533 12.105C1.37325 10.5052 1.60486 8.85721 2.31697 7.39716C3.02909 5.93711 4.18514 4.73998 5.61945 3.97734Z"
            fill="currentColor"
          />
          <path
            d="M7.42511 7.37329C8.14226 6.99197 8.96172 6.84748 9.76604 6.96052C10.1867 7.01964 10.5757 6.72653 10.6348 6.30583C10.694 5.88513 10.4009 5.49616 9.98016 5.43703C8.8541 5.27878 7.70686 5.48107 6.70284 6.01491C5.69883 6.54876 4.88959 7.38675 4.39111 8.40878C3.89263 9.43081 3.7305 10.5844 3.92796 11.7043C4.12542 12.8241 4.67233 13.8527 5.4903 14.6426C6.30828 15.4325 7.35532 15.9432 8.48137 16.1014C9.60742 16.2597 10.7547 16.0574 11.7587 15.5236C12.7627 14.9897 13.5719 14.1517 14.0704 13.1297C14.5689 12.1077 14.731 10.9541 14.5336 9.83421C14.4598 9.41583 14.0608 9.13647 13.6424 9.21024C13.2241 9.28401 12.9447 9.68298 13.0185 10.1014C13.1595 10.9012 13.0437 11.7253 12.6877 12.4553C12.3316 13.1853 11.7536 13.7839 11.0364 14.1652C10.3193 14.5465 9.49981 14.691 8.69548 14.578C7.89116 14.4649 7.14327 14.1001 6.559 13.5359C5.97474 12.9717 5.58409 12.237 5.44305 11.4371C5.30201 10.6372 5.41781 9.81322 5.77387 9.0832C6.12993 8.35317 6.70795 7.75461 7.42511 7.37329Z"
            fill="currentColor"
          />
        </g>
      </svg>
    </SvgIcon>
  );
}
