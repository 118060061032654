import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";

import { DataSection } from "@/components/DataSection";
import { PermissionDoorsDialog } from "@/features/fitness/pages/BranchDetailPage/PermissionDoorsDialog";

import { ChangeStaffPermissionDialog } from "./ChangeStaffPermissionDialog";

import type { Staff } from "@/models";

interface StaffPermissionGroupProps {
  data: Staff;
  fetchKeys?: string[];
}

export function StaffPermissionGroup({
  data,
  fetchKeys = [],
}: StaffPermissionGroupProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const dialog = searchParams.get("dialog");
  const id = searchParams.get("id");

  function onCloseDialog() {
    searchParams.delete("dialog");
    setSearchParams(searchParams);
  }

  function openEditDialog() {
    searchParams.set("dialog", "permission-change");
    setSearchParams(searchParams);
  }

  function onClickBranch(branchId: number) {
    searchParams.set("dialog", "branch-door");
    searchParams.set("id", branchId.toString());
    setSearchParams(searchParams);
  }

  const { doorPermission, branches: staffBranches } = data;

  const branches = doorPermission?.branches ?? [];

  const isHasBranch = staffBranches.length > 0;

  const selectedBranch = staffBranches.find(
    (branch) => branch.id.toString() === id
  );
  const selectedBranchWithDoors = branches.find(
    (branch) => branch.id.toString() === id
  );
  const selectedDoors = selectedBranchWithDoors?.doors ?? [];

  const columns = [
    [
      {
        title: "",
        data: [
          { label: "ชื่อสิทธิ์การใช้งานประตู", value: doorPermission?.name },
          {
            label: "สาขา",
            key: doorPermission?.id?.toString() ?? "-",
            value: (
              <Stack gap={2}>
                {isHasBranch
                  ? staffBranches.map(({ id, name }) => (
                      <Typography
                        key={id}
                        variant="body2"
                        onClick={() => onClickBranch(id)}
                        sx={{ cursor: "pointer", textDecoration: "underline" }}
                      >
                        {name}
                      </Typography>
                    ))
                  : "-"}
              </Stack>
            ),
          },
        ],
      },
    ],
  ];

  const permissionDoorsDialog = {
    open: dialog === "branch-door",
    onClose: onCloseDialog,
    doors: selectedDoors,
    branch: selectedBranch,
  };

  const changePermissionDialog = {
    open: dialog === "permission-change",
    onClose: onCloseDialog,
    staff: data,
    fetchKeys,
  };

  return (
    <Card>
      <CardHeader
        title="สิทธิ์การใช้งานประตู"
        action={
          <Tooltip title="แก้ไข">
            <IconButton size="small" onClick={openEditDialog}>
              <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        }
      />
      <CardContent>
        <DataSection columns={columns} />
      </CardContent>
      <PermissionDoorsDialog {...permissionDoorsDialog} />
      <ChangeStaffPermissionDialog {...changePermissionDialog} />
    </Card>
  );
}
