import { MenuItem } from "@mui/material";

import { SearchParamsSelect } from "@/components/Select";
import { formatTrainingStatus } from "@/formatter";
import { TrainingStatus } from "@/models";

import type { SelectOption } from "@/components/Select";

export function TrainingStatusSelect() {
  const statusList = [
    TrainingStatus.InProgress,
    TrainingStatus.Success,
    TrainingStatus.Failed,
  ];

  const options: SelectOption[] = [
    { label: "ทั้งหมด", value: "" },
    ...statusList.map((status) => ({
      label: formatTrainingStatus(status),
      value: status,
    })),
  ];

  return (
    <SearchParamsSelect
      displayEmpty
      name="status"
      label="สถานะ"
      sx={{ width: 220 }}
    >
      {options.map(({ label, value }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </SearchParamsSelect>
  );
}
