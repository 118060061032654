import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers";
import { Button, DialogActions, TextField, useMediaQuery } from "@mui/material";
import { useController } from "react-hook-form";
import DateRangeIcon from "@mui/icons-material/DateRange";

import { mergeRefs } from "@/utils";

import type { DateTime } from "luxon";
import type { DatePickerProps as MuiDatePickerProps } from "@mui/x-date-pickers/DatePicker";
import type { TextFieldProps } from "@mui/material";
import type { ControllerProps, FieldPath, FieldValues } from "react-hook-form";
import type { PickersActionBarProps } from "@mui/x-date-pickers/PickersActionBar";

export type DatePickerProps = {
  TextFieldProps?: Partial<TextFieldProps>;
} & Omit<MuiDatePickerProps<DateTime, DateTime>, "renderInput">;

export function DatePicker({ TextFieldProps, ...props }: DatePickerProps) {
  const isDesktop = useMediaQuery("@media (pointer: fine)");
  const InputProps = isDesktop
    ? {}
    : {
        InputProps: {
          endAdornment: <DateRangeIcon sx={{ fill: "#00000047" }} />,
        },
      };

  return (
    <MuiDatePicker
      inputFormat="d/M/yyyy"
      mask="_/_/____"
      PopperProps={{ placement: "top-start" }}
      renderInput={(params) => (
        <TextField
          {...params}
          {...TextFieldProps}
          {...InputProps}
          inputRef={mergeRefs(params.inputRef, TextFieldProps?.inputRef)}
        />
      )}
      DialogProps={{ sx: { ".MuiPaper-root": { maxWidth: 480 } } }}
      label="เลือกวัน"
      components={isDesktop ? {} : { ActionBar }}
      {...props}
    />
  );
}

export function FormDatePicker<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  onChange,
  label,
  TextFieldProps,
  ...props
}: Pick<ControllerProps<TFieldValues, TName>, "name" | "control"> &
  Pick<
    Partial<DatePickerProps>,
    | "onChange"
    | "TextFieldProps"
    | "disablePast"
    | "label"
    | "disabled"
    | "minDate"
    | "maxDate"
  >) {
  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <DatePicker
      value={field.value ?? null}
      onChange={(date, keyboardInputValue) => {
        field.onChange(date);
        onChange?.(date, keyboardInputValue);
      }}
      TextFieldProps={{
        inputRef: field.ref,
        onBlur: field.onBlur,
        error: fieldState.invalid,
        helperText: fieldState.error?.message,
        label,
        ...TextFieldProps,
      }}
      {...props}
    />
  );
}

const ActionBar = ({ onAccept, onCancel }: PickersActionBarProps) => {
  return (
    <DialogActions>
      <Button onClick={onCancel}>ยกเลิก</Button>
      <Button onClick={onAccept}>ตกลง</Button>
    </DialogActions>
  );
};
