import { FormConfirmDialog } from "@/components/FormConfirmDialog";

import { FitnessEditor } from "./FitnessEditor";

import type { FitnessEditorState } from "./FitnessEditor";
import type { Control } from "react-hook-form";

export function FitnessEditorDialog({
  open,
  title,
  onClose,
  onSubmit,
  loading,
  control,
}: {
  open: boolean;
  title: string;
  onClose: () => void;
  onSubmit: () => void | PromiseLike<void>;
  loading: boolean;
  control: Control<FitnessEditorState>;
}) {
  return (
    <FormConfirmDialog
      control={control}
      title={title}
      loading={loading}
      open={open}
      onClose={onClose}
      onConfirm={onSubmit}
    >
      <FitnessEditor control={control} />
    </FormConfirmDialog>
  );
}
