import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";

import { ConfirmDialog } from "@/components/ConfirmDialog";
import { getMemberStatus, mapOptional } from "@/utils";
import { configs } from "@/configs";
import { banMember } from "@/services/member";

import { Member, MemberStatus } from "@/models";

export type BanMemberDialogProps = {
  open: boolean;
  data?: Member;
  onClose: (reason: "success" | "error" | "cancel") => void;
  fetchKey: string;
};
export function BanMemberDialog({
  open: isOpen,
  data,
  onClose,
  fetchKey,
}: BanMemberDialogProps) {
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: update, isLoading } = useMutation(banMember, {
    onSuccess: () => {
      enqueueSnackbar(`${banText}การใช้งานสมาชิก สำเร็จ`, {
        variant: "success",
      });
      void queryClient.refetchQueries(fetchKey);
      onClose("success");
    },
    onError: (error) => {
      console.error(error);
      enqueueSnackbar(configs.unknownErrorMessage, { variant: "error" });
      onClose("error");
    },
  });
  const queryClient = useQueryClient();

  const status = mapOptional(data, getMemberStatus);
  const isBanned = status === MemberStatus.Ban;

  const operation = isBanned ? "unban" : "ban";
  const banText = operation === "unban" ? "เปิด" : "ระงับ";

  const id = data?.id.toString() ?? "";
  const input = {
    id,
    data: {
      isBan: !isBanned,
    },
  };
  const onConfirm = () => update(input);

  return (
    <ConfirmDialog
      maxWidth="xs"
      open={isOpen}
      onClose={() => onClose("cancel")}
      onConfirm={onConfirm}
      title={`คุณต้องการ${banText}การใช้งานสมาชิกนี้หรือไม่`}
      confirmMessage={`${banText}การใช้งาน`}
      loading={isLoading}
    />
  );
}
