import { Divider, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { FormImageUploader } from "@/components/ImageUploader";
import { FormTextField } from "@/components/FormTextField";
import { youtubeLink } from "@/lib/yup/validation";

import type { FileForm } from "@/components/ImageUploader";
import type { ControllerProps } from "react-hook-form";
import type { InferType } from "yup";
import { configs } from "@/configs";

export type VideoEditorState = InferType<typeof schema>;

type VideoEditorProps = Pick<ControllerProps<VideoEditorState>, "control">;

export function VideoEditor({ control }: VideoEditorProps) {
  return (
    <Stack>
      <FormImageUploader
        control={control}
        name="images"
        module="videos"
        max={1}
        aspect={configs.aspectRatio.video}
      />
      <Divider sx={{ mt: 2, mb: 5 }} />
      <Stack gap={2.5}>
        <FormTextField
          control={control}
          name="name"
          label="ชื่อวิดีโอ"
          required
        />
        <FormTextField
          control={control}
          name="youtubeUrl"
          label="ลิงค์ Youtube"
          required
        />
        <FormTextField
          control={control}
          name="description"
          label="รายละเอียด"
          multiline
          rows={4}
        />
      </Stack>
    </Stack>
  );
}

export function useVideoEditorForm() {
  return useForm({
    resolver,
    defaultValues,
  });
}

const schema = yup.object({
  name: yup.string().label("ชื่อวิดีโอ").required(),
  youtubeUrl: yup.string().required("ระบุลิงค์ Youtube").concat(youtubeLink),
  images: FormImageUploader.schema,
  description: yup.string().label("รายละเอียด").max(500),
});

const resolver = yupResolver(schema);

const defaultValues = {
  name: "",
  youtubeUrl: "",
  description: "",
  images: [] as FileForm[],
} as VideoEditorState;
