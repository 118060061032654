import { Card, CardContent, Stack, Typography } from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";
import { useDrawingArea } from "@mui/x-charts/hooks";
import { styled } from "@mui/material/styles";
import { useState } from "react";

import { CircularLoading } from "@/components/CircularLoading";
import { TrainingStatus } from "@/models";
import {
  formatTrainingStatus,
  formatTrainingStatusPieChartColorMapper,
} from "@/formatter";
import { EmptyResultIcon } from "@/components/Icon";

import type { MemberGoalCountSummary } from "@/models";

type MemberCountPieChartProps = {
  data?: MemberGoalCountSummary;
  isFetching: boolean;
};

export function MemberCountPieChart({
  data,
  isFetching,
}: MemberCountPieChartProps) {
  const [focusIndex, setFocusIndex] = useState<number>();

  const StyledText = styled("text")(() => ({
    textAnchor: "middle",
    dominantBaseline: "central",
  }));

  function PieCenterLabel({
    children,
    heightOffset = 0,
    fontSize = 24,
  }: {
    children: React.ReactNode;
    heightOffset?: number;
    fontSize?: number;
  }) {
    const { width, height, left, top } = useDrawingArea();
    return (
      <StyledText
        x={left + width / 2}
        y={top + height / 2 + heightOffset}
        sx={{ fontSize, fontWeight: 400 }}
      >
        {children}
      </StyledText>
    );
  }

  const memberChartData = [
    TrainingStatus.InProgress,
    TrainingStatus.Success,
    TrainingStatus.Failed,
  ].map((status) => ({
    label: formatTrainingStatus(status),
    value: data?.memberCount[status] ?? 0,
    color: formatTrainingStatusPieChartColorMapper(status),
  }));

  const inProgress = data?.memberCount.inProgress ?? 0;
  const failed = data?.memberCount.failed ?? 0;
  const success = data?.memberCount.success ?? 0;

  const total = failed + inProgress + success;

  const showItem =
    focusIndex !== undefined
      ? memberChartData[focusIndex]
      : { label: "ทั้งหมด", value: total };

  const isEmpty = total === 0;

  return (
    <Card>
      <CardContent>
        <Stack gap={5} mb={3}>
          <Typography variant="h6">
            จำนวนสถานะของ Goal ทั้งหมดตามช่วงเวลาที่เลือก
          </Typography>
          {isFetching ? (
            <CircularLoading height={360} />
          ) : isEmpty ? (
            <EmptyResultIcon height={360} />
          ) : (
            <PieChart
              series={[{ data: memberChartData, innerRadius: 120 }]}
              height={360}
              onHighlightChange={(value) => setFocusIndex(value?.dataIndex)}
            >
              <PieCenterLabel heightOffset={-24}>
                {showItem.label}
              </PieCenterLabel>
              <PieCenterLabel
                heightOffset={16}
                fontSize={34}
              >{`${showItem.value} Goal`}</PieCenterLabel>
            </PieChart>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
