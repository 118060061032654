import { Outlet, useLocation, useNavigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import NavigateNext from "@mui/icons-material/NavigateNext";
import {
  Box,
  Breadcrumbs,
  Card,
  CircularProgress,
  Link as MuiLink,
  Stack,
  Tab,
  Tabs,
  TabsProps,
  Typography,
} from "@mui/material";
import { useQuery } from "react-query";

import { CustomerRemark } from "@/features/member/pages/MemberProfilePage/CustomerRemark";
import { getDocumentTitle, useRequireParams } from "@/utils";
import { getMember } from "@/services/member";

import { MemberProduct } from "./MemberProduct";
import { MemberProfile } from "./MemberProfile";
import { MemberPermissionGroup } from "./MemberPermissionGroup";

export function useMember(id: string, queryKey: string) {
  return useQuery(queryKey, () => getMember(+id));
}

export function MemberProfilePage() {
  const { memberId } = useRequireParams(["memberId"]);
  const location = useLocation();
  const navigate = useNavigate();
  const path = location?.pathname ?? "";

  const onChangeTab: TabsProps["onChange"] = (_, value: string) => {
    navigate(value, { replace: true });
  };

  const queryKey = `member_${memberId}`;

  const { data: profileData, isFetching } = useMember(memberId, queryKey);

  const isLoaded = !isFetching && typeof profileData !== "undefined";
  const title = profileData?.code ?? "";
  const isShowPermission =
    !!profileData?.fitnessCenter?.hasPermissionGroupControl;

  return (
    <Box>
      <Helmet>
        <title>{getDocumentTitle(title)}</title>
      </Helmet>
      <Breadcrumbs
        separator={<NavigateNext fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ mb: 2 }}
      >
        <MuiLink component={Link} color="text.secondary" to="..">
          สมาชิก
        </MuiLink>
        <Typography color="text.primary">ข้อมูลสมาชิก</Typography>
      </Breadcrumbs>
      <Stack spacing={3}>
        {isLoaded ? (
          <>
            <MemberProfile data={profileData} />
            {isShowPermission && (
              <MemberPermissionGroup
                data={profileData}
                fetchKeys={[queryKey]}
              />
            )}
            {profileData.customerId && (
              <CustomerRemark
                data={profileData.latestRemark}
                customerId={profileData.customerId}
                queryKey={`member_${memberId}`}
              />
            )}
          </>
        ) : (
          <Card>
            <Box display="grid" sx={{ placeItems: "center" }} height={1192}>
              <CircularProgress disableShrink />
            </Box>
          </Card>
        )}
        <MemberProduct data={profileData} isLoading={isFetching} />
        <Box>
          <Tabs value={path} sx={{ mb: 2 }} onChange={onChangeTab}>
            <Tab
              label="ประวัติการจองคลาส"
              value={`/members/${memberId}`}
              id="booking"
            />
            <Tab
              label="ประวัติการจองเทรนเนอร์"
              value={`/members/${memberId}/pt`}
            />
            <Tab
              label="ประวัติการชำระเงิน"
              value={`/members/${memberId}/transactions`}
            />
            <Tab
              label="ประวัติเป้าหมายการเทรน"
              value={`/members/${memberId}/trainings`}
            />
          </Tabs>
          <Outlet />
        </Box>
      </Stack>
    </Box>
  );
}
