import { Divider, Stack } from "@mui/material";

import { formatDate, formatDurationObject, formatPrice } from "@/formatter";
import { mapOptional } from "@/utils";
import { Section } from "@/components/Section";
import { DataSection } from "@/components/DataSection";
import { Member } from "@/models";

import { SummaryPriceDetail } from "../../components/SummaryPriceDetail";
import { MemberProfileDetail } from "../../components/MemberProfileDetail";
import { calculateDiscount, calculatePurchaseOrderSummary } from "../../utils";

import type { PurchaseProductPTEditorState } from "../../components/PurchaseProductPTEditor";
import type { MemberProfileEditorState } from "../../pages/MemberListPage/MemberProfileEditor";
import type { PurchaseMembershipEditorState } from "../../components/PurchaseMembershipEditor";
import type { SaleEditorState } from "../../components/SaleAutocompleteEditor";
import type { UseFormGetValues } from "react-hook-form";

//  TODO: refactor
type SummaryProps = {
  getMemberProfileValues: UseFormGetValues<MemberProfileEditorState>;
  getMembershipValues: UseFormGetValues<PurchaseMembershipEditorState>;
  getProductPTValues: UseFormGetValues<PurchaseProductPTEditorState>;
  getSaleValues: UseFormGetValues<SaleEditorState>;
};

export function Summary({
  getMemberProfileValues,
  getMembershipValues,
  getProductPTValues,
  getSaleValues,
}: SummaryProps) {
  const memberProfile = getMemberProfileValues();
  const purchaseMembership = getMembershipValues();
  const purchaseProductPT = getProductPTValues();
  const sale = getSaleValues();

  const data: Member = {
    code: memberProfile.memberId ?? "",
    profile: {
      id: 0,
      avatarUrl: null,
      firstName: memberProfile.firstName,
      lastName: memberProfile.lastName,
      nickname: memberProfile.nickname,
      gender: memberProfile.gender,
      career: memberProfile.career ?? "",
      birthDate: memberProfile.dateOfBirth,
      height: memberProfile.height,
      weight: memberProfile.weight,
      idCard: memberProfile.nationalIdCard,
      maritalStatus: memberProfile.maritalStatus,
      bloodGroup: memberProfile.bloodGroup,
      phoneNo: memberProfile.phone,
      email: memberProfile.email,
      emergencyPerson: memberProfile.emergencyContactName,
      emergencyRelation: memberProfile.relationship,
      emergencyPhoneNo: memberProfile.emergencyContactPhone,
      address: memberProfile.currentAddress.line,
      district: memberProfile.currentAddress.district,
      subdistrict: memberProfile.currentAddress.subdistrict,
      province: memberProfile.currentAddress.province,
      postCode: memberProfile.currentAddress.postcode,
      line: memberProfile.line,
      facebook: memberProfile.facebook,
      contactOther: memberProfile.contactOther,
      channel: memberProfile.channel,
      channelOther: memberProfile.channelOther,
      memberId: memberProfile.channelFriend?.id ?? undefined,
      member: memberProfile.channelFriend
        ? {
            id: memberProfile.channelFriend?.id ?? 0,
            accountId: memberProfile.channelFriend?.accountId ?? "",
            code: memberProfile.channelFriend?.code ?? "",
            profile: {
              firstName: memberProfile.channelFriend?.firstName ?? "",
              lastName: memberProfile.channelFriend?.lastName ?? "",
            },
          }
        : null,
    },
    taxAddress: memberProfile.invoice.address.line,
    taxDistrict: memberProfile.invoice.address.district,
    taxSubdistrict: memberProfile.invoice.address.subdistrict,
    taxProvince: memberProfile.invoice.address.province,
    taxPostCode: memberProfile.invoice.address.postcode,
    taxType: memberProfile.invoice.type,
    taxNo: memberProfile.invoice.no,
    taxBranch: memberProfile.invoice.branch,
    taxName: memberProfile.invoice.name,
    id: 0,
    accountId: undefined,
    banUntil: null,
    membership: null,
    memberships: [],
    personalTraining: null,
    personalTrainingQuota: [],
    branch: undefined,
    staff: undefined,
    customerId: undefined,
    latestRemark: undefined,
  };

  const membershipPrice = purchaseMembership.membership.price;
  const membershipDiscountPercentage =
    purchaseMembership.discountPercentage ?? 0;
  const membershipDiscount = calculateDiscount(
    membershipPrice,
    membershipDiscountPercentage
  );

  const packagePTPrice = purchaseProductPT.packagePT?.price ?? 0;
  const packagePTDiscountPercentage = purchaseProductPT.discountPercentage ?? 0;
  const packagePTDiscount = calculateDiscount(
    packagePTPrice,
    packagePTDiscountPercentage
  );

  const columns = [
    [
      {
        title: "พนักงานขายที่ดูแล",
        data: [{ label: "ชื่อพนักงานขาย", value: sale.sale.name }],
      },
      {
        title: "ชื่อแพ็กเกจสมาชิก",
        data: [
          {
            label: `ชื่อแพ็กเกจ`,
            value: purchaseMembership.membership.name,
          },
          {
            label: "ระยะเวลา",
            value: formatDurationObject(purchaseMembership.membership.duration),
          },
          {
            label: "ราคา",
            value: formatPrice(membershipPrice),
          },
          {
            label: "ส่วนลด",
            value: formatPrice(membershipDiscount),
          },
          {
            label: "วันเริ่มต้น",
            value: formatDate(purchaseMembership.dateRange.start),
          },
          {
            label: "วันสิ้นสุด",
            value: formatDate(purchaseMembership.dateRange.end),
          },
          { label: "หมายเหตุ", value: purchaseMembership.note },
          {
            label: "สาขาที่เข้าใช้บริการ",
            value: (
              <Stack>
                {purchaseMembership.membership.branches.map((branch) => (
                  <li key={branch.id}>{branch.name}</li>
                ))}
              </Stack>
            ),
            key: "branches",
          },
        ],
      },
    ],
    [
      {
        title: "ชื่อแพ็กเกจเทรนเนอร์",
        data: [
          {
            label: `ชื่อแพ็กเกจ`,
            value: purchaseProductPT.packagePT?.name,
          },
          {
            label: "ระยะเวลา",
            value: mapOptional(
              purchaseProductPT.packagePT?.duration,
              formatDurationObject
            ),
          },
          {
            label: "ราคา",
            value: formatPrice(packagePTPrice),
          },
          {
            label: "ส่วนลด",
            value: formatPrice(packagePTDiscount),
          },
          {
            label: "วันเริ่มต้น",
            value: mapOptional(purchaseProductPT.packagePT, () =>
              formatDate(purchaseProductPT.dateRange.start)
            ),
          },
          {
            label: "วันสิ้นสุด",
            value: mapOptional(purchaseProductPT.packagePT, () =>
              formatDate(purchaseProductPT.dateRange.end)
            ),
          },
          // {
          //   label: "ชื่อ Trainer",
          //   value: purchaseProductPT.trainer?.name,
          // },
          { label: "หมายเหตุ", value: purchaseProductPT.note },
          {
            label: "สาขาที่เข้าใช้บริการ",
            value: (
              <Stack>
                {purchaseProductPT.packagePT?.branches.map((branch) => (
                  <li key={branch.id}>{branch.name}</li>
                ))}
              </Stack>
            ),
            key: "branches",
          },
        ],
      },
    ],
  ];

  const summaryPriceDetail = calculatePurchaseOrderSummary(
    purchaseMembership,
    purchaseProductPT
  );

  return (
    <Stack gap={5} px={1}>
      <Section columns={1} label="ข้อมูลส่วนตัว">
        <MemberProfileDetail data={data} />
      </Section>
      <Divider />
      <DataSection columns={columns} label="ข้อมูลการเลือกซื้อ" />
      <Divider />
      <SummaryPriceDetail {...summaryPriceDetail} />
    </Stack>
  );
}
