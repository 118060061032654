export function ErrorIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={64}
      height={64}
      viewBox="0 0 64 64"
      fill="none"
    >
      <path
        d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64Z"
        fill="#C74C3F"
      />
      <path
        d="M34.8718 32.5L45.3011 22.0707C46.233 21.1389 46.233 19.6292 45.3011 18.6989C44.3692 17.767 42.8596 17.767 41.9293 18.6989L31.5 29.1282L21.0707 18.6989C20.1404 17.767 18.6292 17.767 17.6989 18.6989C16.767 19.6308 16.767 21.1404 17.6989 22.0707L28.1282 32.5L17.6989 42.9293C16.767 43.8611 16.767 45.3708 17.6989 46.3011C18.1648 46.767 18.7752 47 19.3856 47C19.996 47 20.6064 46.767 21.0723 46.3011L31.5016 35.8718L41.9308 46.3011C42.3967 46.767 43.0071 47 43.6175 47C44.2279 47 44.8383 46.767 45.3042 46.3011C46.2361 45.3692 46.2361 43.8596 45.3042 42.9293L34.8749 32.5H34.8718Z"
        fill="white"
      />
    </svg>
  );
}
