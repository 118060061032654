import {
  Card,
  CardContent,
  Stack,
  Typography,
  colors,
  useMediaQuery,
} from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";

import { CircularLoading } from "@/components/CircularLoading";
import { formatTrainingGoal } from "@/formatter";
import { TrainingGoal, TrainingStatus } from "@/models";
import { theme } from "@/theme";
import { EmptyResultIcon } from "@/components/Icon";

import type { MemberGoalCountSummary } from "@/models";
import type { AxisConfig, ChartsXAxisProps } from "@mui/x-charts";
import type { MakeOptional } from "@mui/x-date-pickers/internals/models/helpers";
import type { AxisScaleConfig } from "@mui/x-charts/internals";

type GoalCountBarChartProps = {
  data?: MemberGoalCountSummary;
  showDataStatus?: TrainingStatus;
  isFetching: boolean;
  fullWidth?: boolean;
};

export function GoalCountBarChart({
  data,
  isFetching,
  showDataStatus,
  fullWidth,
}: GoalCountBarChartProps) {
  const goals = [
    TrainingGoal.BodyComposition,
    TrainingGoal.GeneralHealth,
    TrainingGoal.SkillRelated,
    TrainingGoal.SportPerformance,
    TrainingGoal.Other,
  ];

  const isInProgresss = showDataStatus === TrainingStatus.InProgress;
  const isSuccess = showDataStatus === TrainingStatus.Success;
  const isFailed = showDataStatus === TrainingStatus.Failed;

  const { labels, dataList } = goals.reduce(
    (memo, goal) => {
      const inProgress = data?.goalCount.inProgress[goal] ?? 0;
      const success = data?.goalCount.success[goal] ?? 0;
      const failed = data?.goalCount.failed[goal] ?? 0;
      const all = inProgress + success + failed;

      const total = isInProgresss
        ? inProgress
        : isSuccess
        ? success
        : isFailed
        ? failed
        : all;

      memo.labels.push(formatTrainingGoal(goal));
      memo.dataList.push(total);

      return memo;
    },
    {
      labels: [] as string[],
      dataList: [] as number[],
    }
  );

  const isDesktop = useMediaQuery(theme.breakpoints.down("xl"));
  const tickLabelStyle =
    isDesktop || fullWidth
      ? {}
      : {
          tickLabelStyle: {
            angle: 17,
            textAnchor: "start" as const,
            fontSize: 12,
          },
        };

  const { label, color } = isInProgresss
    ? {
        label: "เป้าหมายการเทรนแบ่งตามสถานะกำลังเทรน",
        color: colors.lightBlue[500],
      }
    : isSuccess
    ? { label: "เป้าหมายการเทรนแบ่งตามสถานะสำเร็จ", color: colors.green[500] }
    : isFailed
    ? { label: "เป้าหมายการเทรนแบ่งตามสถานะไม่สำเร็จ", color: colors.red[500] }
    : {
        label: "เป้าหมายการเทรนทั้งหมดในช่วงเวลาที่เลือก",
        color: colors.purple[500],
      };

  const isEmpty = dataList.reduce((sum, value) => sum + value, 0) === 0;

  return (
    <Card>
      <CardContent>
        <Stack gap={3}>
          <Typography variant="h6">{label}</Typography>
          {isFetching ? (
            <CircularLoading height={400} />
          ) : isEmpty ? (
            <EmptyResultIcon height={400} />
          ) : (
            <BarChart
              height={400}
              series={[{ data: dataList, type: "bar", color }]}
              xAxis={[
                {
                  scaleType: "band",
                  data: labels,
                  ...tickLabelStyle,
                  categoryGapRatio: 0.6,
                } as unknown as MakeOptional<
                  AxisConfig<keyof AxisScaleConfig, any, ChartsXAxisProps>,
                  "id"
                >, // https://github.com/mui/mui-x/issues/10334,
              ]}
              yAxis={[{ label: "ครั้ง" }]}
              grid={{ horizontal: true }}
            />
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}
