import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { datetime } from "@/lib/yup";
import { Section } from "@/components/Section";
import { FormTextField } from "@/components/FormTextField";
import { FormDatePicker } from "@/components/DatePicker";

import type { InferType } from "yup";
import type { ControllerProps } from "react-hook-form";

export type FollowingEditorState = InferType<typeof schema>;

type FollowingEditorProps = { isSingleForm?: boolean } & Pick<
  ControllerProps<FollowingEditorState>,
  "control"
>;

export function FollowingEditor({
  isSingleForm,
  control,
}: FollowingEditorProps) {
  return (
    <Section columns={2} label="การติดตามครั้งถัดไป">
      <FormDatePicker
        disablePast
        label="วันที่ติดตาม"
        name="following.date"
        control={control}
        TextFieldProps={{
          required: true,
          sx: { gridColumn: isSingleForm ? "1/-1" : "unset" },
        }}
      />
      <FormTextField
        label="หมายเหตุ"
        name="following.remark"
        control={control}
        multiline
        rows={4}
        sx={{ gridColumn: "1/-1" }}
      />
    </Section>
  );
}

export function useFollowingEditorForm() {
  return useForm({
    resolver,
    defaultValues,
  });
}

const schema = yup.object({
  following: yup.object({
    date: datetime().label("วันที่ติดตาม").required(),
    remark: yup.string(),
  }),
});

const resolver = yupResolver(schema);

const defaultValues = {
  following: { remark: "" },
} as FollowingEditorState;

FollowingEditor.schema = schema;
FollowingEditor.defaultValues = defaultValues;
