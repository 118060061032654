import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
} from "@mui/lab";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";

import { DataSection } from "@/components/DataSection";
import {
  formatDateTime,
  formatProfile,
  formatTrainingGoal,
  formatDateRange,
} from "@/formatter";

import type { Profile, Training } from "@/models";

type TrainingChangeLogProps = {
  data: Training;
};

export function TrainingChangeLogList({ data }: TrainingChangeLogProps) {
  const { memberTrainingChangeLogs } = data;
  const currentItem = {
    createdAt: data.updatedAt,
    updatedBy: data.updatedBy,
    goals: data.goals,
    startedAt: data.startedAt,
    endedAt: data.endedAt,
    detail: data.detail,
    reason: data.reason,
    trainer: data.trainer,
  };

  const items = [currentItem, ...memberTrainingChangeLogs];

  return (
    <Card>
      <CardContent>
        <Stack gap={3}>
          <Typography variant="h6">ประวัติการแก้ไข</Typography>
        </Stack>
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
          }}
        >
          {items.map((item, index) => {
            const goalDetail = item.goals
              .map((goal) => formatTrainingGoal(goal))
              .join(", ");

            const isLastItem = items.length === index + 1;
            const reasonItem = isLastItem
              ? []
              : [{ label: "เหตุผลในการแก้ไข", value: item.reason || "-" }];

            const columns = [
              [
                {
                  data: [
                    {
                      label: "ชื่อเทรนเนอร์",
                      value: formatProfile(item.trainer.profile as Profile),
                    },
                    { label: "เป้าหมายการเทรน", value: goalDetail },
                    {
                      label: "ระยะเวลาการเทรน",
                      value: formatDateRange(item.startedAt, item.endedAt),
                    },
                    { label: "รายละเอียด", value: item.detail || "-" },
                    ...reasonItem,
                  ],
                },
              ],
            ];

            return (
              <TimelineItem key={`${item.createdAt.toISO()}-${index}`}>
                <TimelineSeparator>
                  <TimelineDot color="primary" />
                  {!isLastItem && <TimelineConnector />}
                </TimelineSeparator>
                <TimelineContent>
                  <Stack gap={2}>
                    <Stack direction="row" gap={2} alignItems="center">
                      <Typography variant="body1">
                        {formatDateTime(item.createdAt, "-")}
                      </Typography>
                      <Typography variant="caption" color="text.secondary">
                        โดย {item.updatedBy}
                        {isLastItem && (
                          <Box component="span" color="error.main">
                            {" "}
                            (สร้าง)
                          </Box>
                        )}
                      </Typography>
                    </Stack>
                    <DataSection
                      columns={columns}
                      boxProps={{
                        gridTemplateColumns: "120px 1fr",
                        rowGap: 0.5,
                      }}
                    />
                  </Stack>
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline>
      </CardContent>
    </Card>
  );
}
