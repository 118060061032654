import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";
// https://jotai.org/docs/utils/atom-with-storage
export const selectedBranchAtom = atomWithStorage<number | null>(
  "selectedBranch",
  null
);

export const selectedImageSrcAtom = atom<string>("");
export const selectedImageFilenameAtom = atom<string>("");
