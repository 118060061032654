import {
  Box,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { formatDurationISO, formatPrice, formatProductType } from "@/formatter";

import {
  RemoveMembershipDialog,
  RemoveMembershipDialogProps,
} from "../../components/RemoveMembershipDialog";

import type { ProductMembership } from "@/models";

export type MembershipDetailProps = {
  data: ProductMembership;
};

export function MembershipDetail({ data }: MembershipDetailProps) {
  const { id, name, description, productMembership, type, branches } = data;
  const { pathname } = useLocation();
  const isInMemberPage = pathname.split("/").some((path) => path === "members");

  const price = formatPrice(data.price);
  const duration = formatDurationISO(productMembership.durationIso);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const dialog = searchParams.get("dialog");

  function openRemoveDialog() {
    searchParams.set("dialog", "membership-remove");
    setSearchParams(searchParams);
  }

  const removeDialog: RemoveMembershipDialogProps = {
    open: dialog === "membership-remove",
    onClose(reason) {
      if (reason === "cancel") {
        searchParams.delete("dialog");
        setSearchParams(searchParams);
        return;
      }

      navigate("..");
    },
    id: id.toString(),
  };

  return (
    <Stack>
      <CardHeader
        title={name}
        action={
          <Stack direction="row">
            {!isInMemberPage && (
              <Tooltip title="ลบ">
                <IconButton onClick={openRemoveDialog}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        }
      />
      <CardContent>
        <Box
          display="grid"
          gridTemplateColumns="150px 1fr"
          gap={3}
          alignItems="baseline"
        >
          <Typography variant="body1" color="text.disabled" fontWeight={500}>
            ระยะเวลา
          </Typography>
          <Typography variant="body2">{duration}</Typography>
          <Typography variant="body1" color="text.disabled" fontWeight={500}>
            ราคา
          </Typography>
          <Typography variant="body2">{price}</Typography>
          <Typography variant="body1" color="text.disabled" fontWeight={500}>
            สถานที่เข้าใช้บริการ
          </Typography>
          <Stack>
            <Typography variant="body2">{formatProductType(type)}</Typography>
            {branches.map((branch) => (
              <li key={branch.id}>{branch.name}</li>
            ))}
          </Stack>
          <Typography variant="body1" color="text.disabled" fontWeight={500}>
            รายละเอียด
          </Typography>
          <Typography variant="body2">{description}</Typography>
        </Box>
      </CardContent>
      <RemoveMembershipDialog {...removeDialog} />
    </Stack>
  );
}
