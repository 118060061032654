import { api } from "@/client/api";
import { configs } from "@/configs";
import { parseDateTimeFromPrisma } from "@/utils";

import type { Schedule } from "@/models";

type GetScheduleInput = {
  scheduleId: string;
};

export async function getSchedule({ scheduleId }: GetScheduleInput) {
  const response = await api.get(`${configs.apiUrl}/schedules/${scheduleId}`);

  return parseDateTimeFromPrisma(response.data) as Schedule;
}
