import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Stack,
  Table,
  TableContainer,
  TableHead,
  Typography,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useQuery } from "react-query";

import { getDocumentTitle } from "@/utils";

import { SearchParamsPagination } from "@/components/SearchParamsPagination";
import { QueryTextField } from "@/components/QueryTextField";
import { DataTableBody } from "@/components/DataTableBody";

import { CategoryTableRow, CategoryTableRowHeader } from "./CategoryTableRow";
import { RemoveCategoryDialog } from "../../components/RemoveCategoryDialog";
import { AddCategoryDialog } from "../../components/AddCategoryDialog";
import { EditCategoryDialog } from "../../components/EditCategoryDialog";

import { getCategories } from "@/services/category";

const QUERY_KEY = "categories";

export function CategoryListPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.toString();

  const { data: raw, isFetching } = useQuery([QUERY_KEY, query], () =>
    getCategories(`${query}&countVideo=1`)
  );

  const data = raw?.data ?? [];
  const total = raw?.total;

  function add() {
    searchParams.set("dialog", "category-add");
    setSearchParams(searchParams);
  }

  const dialog = searchParams.get("dialog");
  const id = searchParams.get("id") ?? "";

  function onCloseDialog() {
    searchParams.delete("dialog");
    searchParams.delete("id");
    setSearchParams(searchParams, { replace: true });
  }

  const addDialog = {
    open: dialog === "category-add",
    onClose: onCloseDialog,
  };

  const editData = data?.find((item) => item.id.toString() === id);

  const editDialog = {
    open: typeof editData !== "undefined" && dialog === "edit",
    id,
    onClose: onCloseDialog,
    data: editData,
    fetchKey: QUERY_KEY,
  };

  const removeDialog = {
    open: dialog === "remove",
    id,
    onClose: onCloseDialog,
  };

  return (
    <Box>
      <Helmet>
        <title>{getDocumentTitle("ประเภทกิจกรรม")}</title>
      </Helmet>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ mb: 2 }}
      >
        <Typography color="text.primary">ประเภทกิจกรรม</Typography>
      </Breadcrumbs>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap={4}
            mb={5}
          >
            <QueryTextField sx={{ width: 600 }} placeholder="ค้นหาชื่อ" />
            <Button variant="contained" size="large" onClick={add}>
              เพิ่มประเภทกิจกรรม
            </Button>
          </Stack>
          <TableContainer sx={{ height: "600px", overflowX: "hidden" }}>
            <Table stickyHeader>
              <TableHead>
                <CategoryTableRowHeader />
              </TableHead>
              <DataTableBody loading={isFetching} data={data}>
                {data.map((item) => (
                  <CategoryTableRow key={item.id} data={item} />
                ))}
              </DataTableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <SearchParamsPagination total={total} />
      <AddCategoryDialog {...addDialog} />
      <EditCategoryDialog {...editDialog} />
      <RemoveCategoryDialog {...removeDialog} />
    </Box>
  );
}
