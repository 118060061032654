import { IconButton, TableCell, TableRow } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useNavigate, useSearchParams } from "react-router-dom";

import { TextEllipsis } from "@/components/TextEllipsis";
import { calculateColumnWidth, getMemberStatus } from "@/utils";
import { MemberSort, MemberSortType } from "@/models";

import { MemberStatusLabel } from "@/features/member/components/MemberStatusLabel";
import { PackageExpiredDateDisplay } from "@/features/member/pages/MemberListPage/MemberTableRow";

import type { Member } from "@/models";
import type { MouseEventHandler } from "react";

const columnsWidth = [160, 309, 126, 160, 220, 109].map((item) =>
  calculateColumnWidth(item)
);

export type MemberTableRowProps = {
  data: Member;
};

export function MemberTableRowHeader() {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]}>
        <SortField field={MemberSort.Code} />
        หมายเลขสมาชิก
      </TableCell>
      <TableCell width={columnsWidth[1]}>ชื่อ</TableCell>
      <TableCell width={columnsWidth[2]} align="center">
        เบอร์โทรศัพท์
      </TableCell>
      <TableCell width={columnsWidth[3]} align="center">
        <SortField field={MemberSort.Memberships} />
        วันสิ้นสุดสมาชิก
      </TableCell>
      <TableCell width={columnsWidth[4]} align="center">
        <SortField field={MemberSort.PersonalTraining} />
        วันสิ้นสุดแพ็กเกจเทรนเนอร์
      </TableCell>
      <TableCell width={columnsWidth[5]}>สถานะ</TableCell>
    </TableRow>
  );
}

export function MemberTableRow({ data }: MemberTableRowProps) {
  const navigate = useNavigate();
  const status = getMemberStatus(data);

  const { id: memberId, code, profile, membership, personalTraining } = data;

  const id = memberId.toString();

  const { firstName, lastName, phoneNo } = profile;

  const onClickTableRow: MouseEventHandler<HTMLTableRowElement> = () => {
    navigate(`member/${id}`);
  };

  return (
    <TableRow hover tabIndex={-1} role="link" onClick={onClickTableRow}>
      <TableCell>
        <TextEllipsis variant="body2">{code}</TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis variant="body2">
          {firstName} {lastName}
        </TextEllipsis>
      </TableCell>
      <TableCell align="center">
        <TextEllipsis variant="body2">{phoneNo ? phoneNo : "-"}</TextEllipsis>
      </TableCell>
      <TableCell align="center">
        <PackageExpiredDateDisplay
          status={status}
          date={membership?.endedAt.toString()}
        />
      </TableCell>
      <TableCell align="center">
        <PackageExpiredDateDisplay
          status={status}
          date={personalTraining?.endedAt.toString()}
        />
      </TableCell>
      <TableCell>
        <MemberStatusLabel variant="body2" status={status} />
      </TableCell>
    </TableRow>
  );
}

function SortField({ field }: { field: MemberSort }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const sort = searchParams.get("sort") as MemberSort | undefined;
  const sortType = searchParams.get("sortType") as MemberSortType | undefined;
  const setSort = (s: MemberSort) => () => {
    if (s) {
      searchParams.set("sort", s);
      searchParams.set(
        "sortType",
        sortType === MemberSortType.Asc && sort === field
          ? MemberSortType.Desc
          : MemberSortType.Asc
      );
    }

    searchParams.delete("page");
    setSearchParams(searchParams);
  };
  return (
    <IconButton
      sx={{ padding: 0, width: "auto", height: "auto" }}
      onClick={setSort(field)}
    >
      {sort === field && sortType === MemberSortType.Asc ? (
        <ArrowDownwardIcon />
      ) : (
        <ArrowUpwardIcon />
      )}
    </IconButton>
  );
}
