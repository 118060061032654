import { yupResolver } from "@hookform/resolvers/yup";
import { ControllerProps, useController, useForm } from "react-hook-form";
import { InferType } from "yup";

import {
  FormSaleAutocomplete,
  SaleAutocomplete,
} from "../pages/MemberListPage/SaleAutocomplete";

export type SaleEditorState = InferType<typeof schema>;

export type SaleEditorProps = Pick<ControllerProps<SaleEditorState>, "control">;

export function SaleEditor({ control }: SaleEditorProps) {
  const {
    field: { value: isDisable },
  } = useController({ name: "isDisable", control });

  return (
    <FormSaleAutocomplete
      label="ชื่อพนักงานขาย"
      id="salesInput"
      name="sale"
      control={control}
      required
      disabled={isDisable}
    />
  );
}

export function useSaleForm() {
  return useForm({
    resolver,
    defaultValues: { isDisable: false } as SaleEditorState,
  });
}

const schema = SaleAutocomplete.schema;

const resolver = yupResolver(schema);
