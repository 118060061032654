import { Stack } from "@mui/material";
import { Helmet } from "react-helmet-async";

import { Breadcrumbs } from "@/components/Breadcrumbs";
import { TrainingDetail } from "@/features/training/pages/TrainingDetailPage/TrainingDetail";
import { getDocumentTitle, useRequireParams } from "@/utils";

export function MemberTrainingDetailPage() {
  const { memberId, trainingId } = useRequireParams(["memberId", "trainingId"]);

  const title = "รายละเอียดเป้าหมายการเทรน";

  const breadcrumbs = {
    items: [
      { label: "สมาชิก", to: "/members" },
      { label: "ข้อมูลสมาชิก", to: `/members/${memberId}` },
      { label: title, to: `members/${memberId}/trainings/${trainingId}` },
    ],
  };

  return (
    <Stack gap={4}>
      <Helmet>
        <title>{getDocumentTitle(title)}</title>
      </Helmet>
      <Breadcrumbs {...breadcrumbs} />
      <TrainingDetail id={trainingId} />
    </Stack>
  );
}
