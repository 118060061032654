import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";

import { getMemberCards } from "@/services/memberCard";

import { EditMemberCardDialog } from "./EditMemberCardDialog";
import { MemberCard } from "./MemberCard";

const QUERY_KEY = "memberCards";

export function MemberCardSection() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: raw, isFetching } = useQuery([QUERY_KEY], () =>
    getMemberCards()
  );

  const data = raw?.data ?? [];

  function openEditDialog() {
    searchParams.set("dialog", "member-card-edit");
    setSearchParams(searchParams);
  }

  function onCloseDialog() {
    searchParams.delete("dialog");
    setSearchParams(searchParams, { replace: true });
  }

  const dialog = searchParams.get("dialog");
  const cardData = data[0];

  const editDialog = {
    data: cardData,
    open: dialog === "member-card-edit",
    onClose: onCloseDialog,
    fetchKeys: [QUERY_KEY],
  };

  const cardDetails = [
    { label: "ชื่อบัตร", value: cardData?.title },
    { label: "รายละเอียด", value: cardData?.description },
  ];

  return isFetching ? (
    <Card sx={{ display: "grid", placeItems: "center", height: 264 }}>
      <CircularProgress disableShrink />
    </Card>
  ) : (
    <Card sx={{ mb: 3 }}>
      <CardContent>
        <Box display="grid" gridTemplateColumns="340px 1fr" gap={5}>
          <MemberCard data={cardData} />
          <Stack gap={2}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="subtitle1" color="text.secondary">
                ข้อมูลบัตรสมาชิก
              </Typography>
              <Tooltip title="แก้ไข">
                <IconButton onClick={openEditDialog} size="small">
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Stack>
            {cardDetails.map(({ label, value }) => (
              <Stack key={label} direction="row">
                <Typography
                  variant="subtitle2"
                  color="text.disabled"
                  flexShrink={0}
                  width={100}
                >
                  {label}
                </Typography>
                <Typography variant="body2">{value}</Typography>
              </Stack>
            ))}
          </Stack>
        </Box>
      </CardContent>
      <EditMemberCardDialog {...editDialog} />
    </Card>
  );
}
