import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { DateTime } from "luxon";

import { formatTime, sortDay } from "@/formatter";
import { getOfficeDay, mapOptional } from "@/utils";
import { configs } from "@/configs";
import { updateTrainer } from "@/services/trainer";

import { useTrainerEditorForm } from "../pages/StaffProfilePage/TrainerEditor";
import { TrainerEditorDialog } from "../pages/StaffProfilePage/TrainerEditorDialog";

import {
  Day,
  DayShort,
  OfficeDayInput,
  TrainerInput,
  TrainerProfile,
} from "@/models";

export type EditTrainerDialogProps = {
  open: boolean;
  data?: TrainerProfile;
  onClose: () => void;
  id: string;
  fetchKey: string;
};

type WorkingDay = {
  startTime?: string | undefined;
  endTime?: string | undefined;
  day: Day;
  selected: boolean;
};

export function EditTrainerDialog({
  open: isOpen,
  onClose,
  id,
  data,
  fetchKey,
}: EditTrainerDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, reset } = useTrainerEditorForm();

  const { mutate: update, isLoading } = useMutation(updateTrainer, {
    onSuccess: async () => {
      enqueueSnackbar("แก้ไขข้อมูลเทรนเนอร์สำเร็จ", { variant: "success" });
      await queryClient.refetchQueries(fetchKey);
      close();
    },
    onError: (error) => {
      console.error(error);
      enqueueSnackbar(configs.unknownErrorMessage, {
        variant: "error",
      });
    },
  });
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isOpen && data) {
      const officeDay = getOfficeDay(data.workingDays).sort(
        ({ day: d1 }, { day: d2 }) => sortDay(d1, d2)
      );
      const workingDay = officeDay.map(({ day, enable, timeRange }) => ({
        day,
        selected: enable,
        startTime:
          mapOptional(timeRange, (timeRange) => formatTime(timeRange.start)) ??
          "8:00",
        endTime:
          mapOptional(timeRange, (timeRange) => formatTime(timeRange.end)) ??
          "18:00",
      }));

      reset({
        images: data.avatarUrl
          ? [{ id: data.avatarUrl, url: data.avatarUrl }]
          : [],
        videoUrl: data.youtubeIntroUrl ?? undefined,
        type: data.contract,
        isPersonalTraining: data.personalTraining,
        workingDay,
        skills: data.skills.map((description) => ({
          description,
        })),
        certificates: data.certificates.map((description) => ({
          description,
        })),
        experience: data.experiences.map((description) => ({
          description,
        })),
      });
    }
  }, [isOpen, data, reset]);

  function close() {
    onClose();
  }

  function getOfficeDays(workingDay: WorkingDay[]) {
    const officeDay: OfficeDayInput = {
      monStartTime: null,
      monEndTime: null,
      tueStartTime: null,
      tueEndTime: null,
      wedStartTime: null,
      wedEndTime: null,
      thuStartTime: null,
      thuEndTime: null,
      friStartTime: null,
      friEndTime: null,
      satStartTime: null,
      satEndTime: null,
      sunStartTime: null,
      sunEndTime: null,
    };
    for (const day of workingDay) {
      const dayShort = DayShort[day.day];
      const startKey = `${dayShort}StartTime` as keyof OfficeDayInput;
      const endKey = `${dayShort}EndTime` as keyof OfficeDayInput;
      officeDay[startKey] = day.selected
        ? DateTime.fromFormat(day.startTime ?? "", "H:mm").toJSDate()
        : null;
      officeDay[endKey] = day.selected
        ? DateTime.fromFormat(day.endTime ?? "", "H:mm").toJSDate()
        : null;
    }
    return officeDay;
  }

  const onSubmit = handleSubmit((form) => {
    const officeDay = getOfficeDays(form.workingDay);

    const data: TrainerInput = {
      avatarUrl: form.images.map((image) => image.url)[0],
      youtubeIntroUrl: form.videoUrl,
      contract: form.type,
      personalTraining: form.isPersonalTraining,
      skills: form.skills?.map(({ description }) => description) ?? [],
      certificates:
        form.certificates?.map(({ description }) => description) ?? [],
      experiences: form.experience?.map(({ description }) => description) ?? [],
      ...officeDay,
    };

    update({ id, data });
  });

  const title = "แก้ไขข้อมูลเทรนเนอร์";

  return (
    <TrainerEditorDialog
      open={isOpen}
      title={title}
      onClose={close}
      onSubmit={onSubmit}
      loading={isLoading}
      control={control}
    />
  );
}
