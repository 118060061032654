import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";

import { ConfirmDialog } from "@/components/ConfirmDialog";
import { removeClassSchedule } from "@/services/class";
import { configs } from "@/configs";
import { refetchQueries, useRequireParams } from "@/utils";

import type { AxiosErrorWithData } from "@/client/api";

export type RemoveScheduleDialogProps = {
  open: boolean;
  onClose: (reason: "success" | "cancel") => void;
  id: string;
  fetchKeys?: string[];
};

export function RemoveScheduleDialog({
  open: isOpen,
  onClose,
  id: scheduleId,
  fetchKeys = [],
}: RemoveScheduleDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { id: classId } = useRequireParams(["id"]);
  const queryClient = useQueryClient();

  const { mutate: remove, isLoading } = useMutation(removeClassSchedule, {
    onSuccess: async () => {
      enqueueSnackbar("ลบ สำเร็จ", { variant: "success" });
      await refetchQueries({ queryClient, fetchKeys });
      onClose("success");
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(
        error.response?.data.message ?? configs.unknownErrorMessage,
        { variant: "error" }
      );
    },
  });

  const onConfirm = () => remove({ classId, scheduleId });

  return (
    <ConfirmDialog
      maxWidth="xs"
      open={isOpen}
      onClose={() => onClose("cancel")}
      onConfirm={onConfirm}
      title="คุณต้องการลบหรือไม่"
      confirmMessage="ลบ"
      loading={isLoading}
    />
  );
}
