import { useSnackbar } from "notistack";
import { useMutation } from "react-query";

import { ConfirmDialog } from "@/components/ConfirmDialog";
import { deleteProduct } from "@/services/product";
import { configs } from "@/configs";

export type RemoveProductDialogProps = {
  open: boolean;
  onClose: (reason: "success" | "error" | "cancel") => void;
  id: string;
};

function useDeleteProduct() {
  return useMutation(deleteProduct);
}

export function RemoveProductDialog({
  open: isOpen,
  onClose,
  id,
}: RemoveProductDialogProps) {
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: remove, isLoading } = useDeleteProduct();

  const onConfirm = () =>
    remove(id, {
      onSuccess: () => {
        enqueueSnackbar("ลบ สำเร็จ", {
          variant: "success",
        });
        onClose("success");
      },
      onError: (error) => {
        console.error(error);
        enqueueSnackbar(configs.unknownErrorMessage, { variant: "error" });
        onClose("error");
      },
    });

  return (
    <ConfirmDialog
      maxWidth="xs"
      open={isOpen}
      onClose={() => onClose("cancel")}
      onConfirm={onConfirm}
      title="คุณต้องการลบหรือไม่"
      confirmMessage="ลบ"
      loading={isLoading}
    />
  );
}
