import { Box, Stack, Typography } from "@mui/material";
import QRCode from "react-qr-code";

import Arrows from "@/assets/memberCard-arrow.svg";

import type { MemberCard as MemberCardData } from "@/models";

type MemberCardProps = {
  data: Pick<
    MemberCardData,
    "backgroundColor" | "backgroundUrl" | "logoUrl" | "textColor" | "title"
  >;
};

export function MemberCard({ data }: MemberCardProps) {
  return (
    <Stack
      direction="row"
      pt={4}
      pr={2.75}
      pb={2}
      pl={3.75}
      width={340}
      height={216}
      sx={{
        borderRadius: 2,
        color: data.textColor,
        backgroundColor: data.backgroundColor,
        backgroundImage: `url(${data.backgroundUrl ?? Arrows})`,
        backgroundSize: "cover",
        boxShadow:
          "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
      }}
    >
      <Stack flexGrow={1} gap="12.95px">
        {data.logoUrl ? (
          <Box
            component="img"
            src={data.logoUrl}
            width={172}
            height={43}
            sx={{ objectFit: "cover", objectPosition: "center" }}
          />
        ) : (
          <Box height={43} />
        )}
        <Stack marginTop="auto" py={1} gap={1}>
          <Typography
            paddingTop={6}
            letterSpacing={1.51}
            sx={{ textTransform: "uppercase" }}
          >
            {data.title}
          </Typography>
          <Typography fontSize={10}>FIT-00000</Typography>
        </Stack>
      </Stack>
      <Stack justifyContent="flex-end" gap={0.75}>
        <QRCode
          value="https://fitup.health/"
          size={88}
          style={{ padding: 8, borderRadius: 10, backgroundColor: "white" }}
        />
        <Typography fontSize={8} align="center">
          EXP : 30/10/25
        </Typography>
      </Stack>
    </Stack>
  );
}
