import { Stack } from "@mui/material";
import { Helmet } from "react-helmet-async";

import { Breadcrumbs } from "@/components/Breadcrumbs";
import { TrainingDetail } from "@/features/training/pages/TrainingDetailPage/TrainingDetail";
import { getDocumentTitle, useRequireParams } from "@/utils";

export function TrainerTrainingDetailPage() {
  const { id, trainingId } = useRequireParams(["id", "trainingId"]);

  const title = "รายละเอียดเป้าหมายการเทรน";

  const breadcrumbs = {
    items: [
      { label: "เทรนเนอร์", to: "/trainers" },
      {
        label: "รายชื่อเทรนเนอร์",
        to: "/trainers",
      },
      { label: "ข้อมูลเทรนเนอร์", to: `/trainers/${id}` },
      { label: title, to: `trainers/${id}/trainings/${trainingId}` },
    ],
  };

  return (
    <Stack gap={4}>
      <Helmet>
        <title>{getDocumentTitle(title)}</title>
      </Helmet>
      <Breadcrumbs {...breadcrumbs} />
      <TrainingDetail id={trainingId} />
    </Stack>
  );
}
