import { Typography, TypographyProps } from "@mui/material";

export function TextEllipsis({
  sx,
  line = 3,
  ...props
}: TypographyProps & { line?: number }) {
  return (
    <Typography
      {...props}
      sx={{
        ...sx,
        WebkitLineClamp: line.toString(),
        WebkitBoxOrient: "vertical",
        display: "-webkit-box",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
    />
  );
}
