import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useImperativeHandle, forwardRef } from "react";
import { FileUpload, PhotoCamera } from "@mui/icons-material";
import { ListItemIcon, ListItemText } from "@mui/material";
import { useSearchParams } from "react-router-dom";

const BrowseImageOptionMenu = forwardRef((props, ref) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [searchParams, setSearchParams] = useSearchParams();

  useImperativeHandle(ref, () => ({
    open(event: React.MouseEvent<HTMLImageElement | HTMLButtonElement>) {
      setAnchorEl(event.currentTarget);
    },
    close() {
      setAnchorEl(null);
    },
  }));

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openBrowseImageDialog = () => {
    searchParams.set("dialog", "browse-file");
    setSearchParams(searchParams);
    handleClose();
  };

  const openTakePhotoDialog = () => {
    searchParams.set("dialog", "take-photo");
    setSearchParams(searchParams);
    handleClose();
  };

  return (
    <>
      <Menu
        id="browse-option-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={openBrowseImageDialog}>
          <ListItemIcon>
            <FileUpload />
          </ListItemIcon>
          <ListItemText>อัพโหลดจากคอมพิวเตอร์</ListItemText>
        </MenuItem>
        <MenuItem onClick={openTakePhotoDialog}>
          <ListItemIcon>
            <PhotoCamera />
          </ListItemIcon>
          <ListItemText>เลือกจากกล้องถ่ายภาพ</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
});

export default BrowseImageOptionMenu;
