import { Helmet } from "react-helmet-async";
import { Box, Breadcrumbs, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { getDocumentTitle } from "@/utils";
import { DashboardCanvas } from "../../components/DashboardCanvas";

export function MemberSummaryPage() {
  return (
    <Box>
      <Helmet>
        <title>{getDocumentTitle("สรุปข้อมูลสมาชิก")}</title>
      </Helmet>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ mb: 5 }}
      >
        <Typography color="text.primary">สรุปข้อมูลสมาชิก</Typography>
      </Breadcrumbs>
      <DashboardCanvas title="สรุปข้อมูลสมาชิก" type="member" />
    </Box>
  );
}
