import { useNavigate } from "react-router-dom";
import { TableCell, TableRow } from "@mui/material";

import { calculateColumnWidth } from "@/utils";
import { TextLink } from "@/components/TextLink";

import type { Branch } from "@/models";

const columnsWidth = [108, 280, 220, 120, 356].map((width) =>
  calculateColumnWidth(width)
);

export function BranchTableRowHeader() {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]}>รหัสสาขา</TableCell>
      <TableCell width={columnsWidth[1]}>ชื่อ</TableCell>
      <TableCell width={columnsWidth[2]}>อีเมล</TableCell>
      <TableCell width={columnsWidth[3]}>เบอร์โทรศัพท์</TableCell>
      <TableCell width={columnsWidth[4]}>ลิงค์ Google Map</TableCell>
    </TableRow>
  );
}

export type BranchTableRowProps = { data: Branch };

export function BranchTableRow({ data }: BranchTableRowProps) {
  const navigate = useNavigate();

  const { id, code, name, email, phoneNo, googleMapLink } = data;

  const onClickRow: JSX.IntrinsicElements["tr"]["onClick"] = () =>
    navigate(`branches/${id}`);

  return (
    <TableRow hover role="link" onClick={onClickRow}>
      <TableCell>{code}</TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>{email || "-"}</TableCell>
      <TableCell>{phoneNo}</TableCell>
      <TableCell>
        <TextLink
          text={googleMapLink}
          linkSX={{
            width: columnsWidth[4],
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        />
      </TableCell>
    </TableRow>
  );
}
