import { TextField, TextFieldProps } from "@mui/material";
import {
  ControllerProps,
  FieldPath,
  FieldValues,
  useController,
} from "react-hook-form";

export function FormTimeTextField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  onChange,
  ...props
}: Pick<ControllerProps<TFieldValues, TName>, "name" | "control"> &
  Omit<TextFieldProps, "name">) {
  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <TextField
      type="time"
      inputRef={field.ref}
      value={field.value ?? ""}
      onChange={(event) => {
        field.onChange(event);
        onChange?.(event);
      }}
      onBlur={field.onBlur}
      error={fieldState.invalid}
      helperText={fieldState.error?.message}
      {...props}
    />
  );
}
