import { IconButton } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

import { SortType } from "@/models";

export function SortField({ fieldName }: { fieldName: string }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const sort = searchParams.get("sort") as string | undefined;
  const sortType = searchParams.get("sortType") as SortType | undefined;

  const setSort = (newSort: string) => () => {
    if (newSort) {
      searchParams.set("sort", newSort);
      searchParams.set(
        "sortType",
        sortType === SortType.Asc && sort === fieldName
          ? SortType.Desc
          : SortType.Asc
      );
    }

    searchParams.delete("page");
    setSearchParams(searchParams);
  };

  return (
    <IconButton
      sx={{ padding: 0, width: "auto", height: "auto" }}
      onClick={setSort(fieldName)}
    >
      {sort === fieldName && sortType === SortType.Asc ? (
        <ArrowDownwardIcon />
      ) : (
        <ArrowUpwardIcon />
      )}
    </IconButton>
  );
}
