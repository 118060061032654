import { Typography } from "@mui/material";
import { formatMemberStatus, formatMemberStatusColor } from "@/formatter";

import type { TypographyProps } from "@mui/material";
import { MemberStatus } from "@/models";

export function MemberStatusLabel({
  status,
  ...props
}: { status: MemberStatus } & TypographyProps) {
  return (
    <Typography color={formatMemberStatusColor(status)} {...props}>
      {formatMemberStatus(status)}
    </Typography>
  );
}
