import { Divider, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { FormTextField } from "@/components/FormTextField";

import type { FileForm } from "@/components/ImageUploader";
import type { ControllerProps } from "react-hook-form";
import type { InferType } from "yup";
import { FormImageUploader } from "@/components/ImageUploader";
import { configs } from "@/configs";

export type CategoryEditorState = InferType<typeof schema>;

type CategoryEditorProps = Pick<
  ControllerProps<CategoryEditorState>,
  "control"
>;

export function CategoryEditor({ control }: CategoryEditorProps) {
  return (
    <Stack>
      <FormImageUploader
        control={control}
        name="images"
        module="categories"
        max={1}
        aspect={configs.aspectRatio.category}
      />
      <Divider sx={{ mt: 2, mb: 5 }} />
      <Stack gap={2.5}>
        <FormTextField
          control={control}
          name="name"
          label="ชื่อประเภทกิจกรรม"
          required
        />
        <FormTextField
          control={control}
          name="description"
          label="รายละเอียด"
          multiline
          rows={4}
        />
      </Stack>
    </Stack>
  );
}

export function useCategoryEditorForm() {
  return useForm({
    resolver,
    defaultValues,
  });
}

const schema = yup.object({
  images: FormImageUploader.schema,
  name: yup.string().label("ชื่อประเภทกิจกรรม").required(),
  description: yup.string().label("รายละเอียด").max(500),
});

const resolver = yupResolver(schema);

const defaultValues = {
  name: "",
  description: "",
  images: [] as FileForm[],
} as CategoryEditorState;
