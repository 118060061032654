import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";

import { createVideo } from "@/services/video";

import { useVideoEditorForm } from "./VideoEditor";
import { VideoEditorDialog } from "./VideoEditorDialog";
import { Video } from "@/models";
import { configs } from "@/configs";
import { AxiosErrorWithData } from "@/client/api";

export type AddVideoDialogProps = {
  open: boolean;
  onClose: () => void;
  categoryId: string;
};

interface VideoInput extends Omit<Video, "id"> {
  categoryIds: string[];
}

export function AddVideoDialog({
  open: isOpen,
  onClose,
  categoryId,
}: AddVideoDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { control, reset, handleSubmit } = useVideoEditorForm();
  const { mutate: add, isLoading } = useMutation(createVideo, {
    onSuccess: async () => {
      enqueueSnackbar("เพิ่มวิดีโอ สำเร็จ", { variant: "success" });
      await queryClient.refetchQueries("videos");
      close();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(
        error.response?.data.message ?? configs.unknownErrorMessage,
        {
          variant: "error",
        }
      );
    },
  });
  const queryClient = useQueryClient();

  function close() {
    reset();
    onClose();
  }

  const onSubmit = handleSubmit((form) => {
    const data: VideoInput = {
      name: form.name,
      description: form.description ?? "",
      thumbnailUrl:
        form.images.length > 0 ? form.images.map((image) => image.url)[0] : "",
      youtubeUrl: form.youtubeUrl ?? "",
      categoryIds: [categoryId],
    };

    add(data);
  });

  const title = "เพิ่มวิดีโอ";

  return (
    <VideoEditorDialog
      open={isOpen}
      title={title}
      onClose={close}
      onSubmit={onSubmit}
      loading={isLoading}
      control={control}
    />
  );
}
