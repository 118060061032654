import { IconButton, Stack, TableCell, TableRow, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import type { MouseEventHandler } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { TextEllipsis } from "@/components/TextEllipsis";
import { calculateColumnWidth } from "@/utils";
import { CatergoryData } from "@/models";

const columnsWidth = [200, 400, 120, 60, 60].map((item) =>
  calculateColumnWidth(item)
);

export function CategoryTableRowHeader() {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]}>ชื่อ</TableCell>
      <TableCell width={columnsWidth[1]}>รายละเอียด</TableCell>
      <TableCell width={columnsWidth[2]} align="center">
        จำนวนวิดีโอ
      </TableCell>
      <TableCell width={columnsWidth[3]} />
      <TableCell width={columnsWidth[4]} />
    </TableRow>
  );
}

type DataCategory = {
  data: CatergoryData;
};

export function CategoryTableRow({ data }: DataCategory) {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const { id, name, description, videoCount } = data;
  const idString = id.toString();

  const onClickEdit: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    searchParams.set("dialog", "edit");
    searchParams.set("id", idString);
    setSearchParams(searchParams);
  };

  const onClickRemove: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    searchParams.set("dialog", "remove");
    searchParams.set("id", idString);
    setSearchParams(searchParams);
  };

  const onClickTableRow: MouseEventHandler<HTMLTableRowElement> = () => {
    navigate(idString);
  };

  return (
    <TableRow hover tabIndex={-1} role="link" onClick={onClickTableRow}>
      <TableCell>
        <TextEllipsis variant="body2">{name}</TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis variant="body2">{description}</TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis variant="body2" align="center">
          {videoCount ?? 0}
        </TextEllipsis>
      </TableCell>
      <TableCell />
      <TableCell sx={{ px: 0 }}>
        <Stack direction="row" gap={1}>
          <Tooltip title="แก้ไข">
            <IconButton onClick={onClickEdit}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="ลบ">
            <IconButton onClick={onClickRemove}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </TableCell>
    </TableRow>
  );
}
