import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  Table,
  TableContainer,
  TableHead,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useAtom } from "jotai";

import { pickListTableParams } from "@/utils";
import { SearchParamsPagination } from "@/components/SearchParamsPagination";
import { QueryTextField } from "@/components/QueryTextField";
import { DataTableBody } from "@/components/DataTableBody";
import { getClasses } from "@/services/class";
import { useCurrentUser } from "@/features/authentication/contexts/AuthenticationContext";
import { selectedBranchAtom } from "@/atom/global";

import { RemoveClassDialog } from "../../components/RemoveClassDialog";
import { AddClassDialog } from "./AddClassDialog";
import { EditClassDialog } from "../../components/EditClassDialog";
import { ClassTableRow, ClassTableRowHeader } from "./ClassTableRow";

const QUERY_KEY = "classes";

export function ClassListPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedBranch] = useAtom(selectedBranchAtom);
  const query = pickListTableParams(searchParams);
  const { isAdmin } = useCurrentUser();

  const { data: raw, isFetching } = useQuery(
    [QUERY_KEY, query, selectedBranch],
    () => getClasses(query)
  );

  const data = raw?.data ?? [];
  const total = raw?.total ?? 0;

  function add() {
    searchParams.set("dialog", "class-add");
    setSearchParams(searchParams);
  }

  const dialog = searchParams.get("dialog");
  const id = searchParams.get("id") ?? "";

  function onCloseDialog() {
    searchParams.delete("dialog");
    searchParams.delete("id");
    setSearchParams(searchParams, { replace: true });
  }

  const addDialog = {
    open: dialog === "class-add",
    onClose: onCloseDialog,
    fetchKeys: [QUERY_KEY],
  };

  const editData = data.find((item) => item.id === +id);

  const editDialog = {
    open: typeof editData !== "undefined" && dialog === "class-edit",
    id,
    onClose: onCloseDialog,
    data: editData,
    fetchKeys: [QUERY_KEY],
  };

  const removeDialog = {
    open: dialog === "class-remove",
    id,
    onClose: onCloseDialog,
    fetchKeys: [QUERY_KEY],
  };

  return (
    <Box>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap={4}
            mb={5}
          >
            <QueryTextField sx={{ width: 600 }} placeholder="ค้นหาชื่อ" />
            {isAdmin && (
              <Button variant="contained" size="large" onClick={add}>
                เพิ่มคลาส
              </Button>
            )}
          </Stack>
          <TableContainer sx={{ height: "600px", overflowX: "hidden" }}>
            <Table stickyHeader>
              <TableHead>
                <ClassTableRowHeader />
              </TableHead>
              <DataTableBody loading={isFetching} data={data}>
                {data.map((item) => (
                  <ClassTableRow
                    key={item.id}
                    data={item}
                    fetchKeys={[QUERY_KEY]}
                  />
                ))}
              </DataTableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <SearchParamsPagination total={total} />
      <AddClassDialog {...addDialog} />
      <EditClassDialog {...editDialog} />
      <RemoveClassDialog {...removeDialog} />
    </Box>
  );
}
