import {
  Box,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useSearchParams } from "react-router-dom";

import { EditCategoryDialog } from "../../components/EditCategoryDialog";
import {
  RemoveCategoryDialog,
  RemoveCategoryDialogProps,
} from "../../components/RemoveCategoryDialog";

import type { Category } from "@/models";
import { MediaViewer } from "@/components/MediaViewer";

export type CategoryDetailProps = {
  data: Category;
};

const QUERY_KEY = "category-detail";

export function CategoryDetail({ data }: CategoryDetailProps) {
  const { id, name, description, thumbnailUrl } = data;

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const dialog = searchParams.get("dialog");

  const mediaList = thumbnailUrl ? [{ url: thumbnailUrl }] : [];

  function onCloseDialog() {
    searchParams.delete("dialog");
    setSearchParams(searchParams, { replace: true });
  }

  function openEditDialog() {
    searchParams.set("dialog", "edit");
    setSearchParams(searchParams);
  }

  function openRemoveDialog() {
    searchParams.set("dialog", "remove");
    searchParams.set("id", id.toString());
    setSearchParams(searchParams);
  }

  const editDialog = {
    open: dialog === "edit",
    onClose: onCloseDialog,
    id: id.toString(),
    data,
    fetchKey: QUERY_KEY,
  };

  const removeDialog: RemoveCategoryDialogProps = {
    open: dialog === "remove",
    onClose(reason) {
      onCloseDialog();
      if (reason === "success") {
        navigate("..");
      }
    },
    id: id.toString(),
    fetchKeys: [QUERY_KEY],
  };

  return (
    <Stack>
      <CardHeader
        title={name}
        action={
          <Stack direction="row">
            <Tooltip title="แก้ไข">
              <IconButton onClick={openEditDialog}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="ลบ">
              <IconButton onClick={openRemoveDialog}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        }
      />
      <CardContent sx={{ py: 0 }}>
        <MediaViewer mediaList={mediaList} imageWidth={300} imageHeight={200} />
      </CardContent>
      <CardContent>
        <Box
          display="grid"
          gridTemplateColumns="150px 1fr"
          gap={3}
          alignItems="baseline"
        >
          <Typography variant="body1" color="text.disabled" fontWeight={500}>
            ชื่อ
          </Typography>
          <Typography variant="body2">{name}</Typography>
          <Typography variant="body1" color="text.disabled" fontWeight={500}>
            รายละเอียด
          </Typography>
          <Typography variant="body2">{description}</Typography>
        </Box>
      </CardContent>
      <EditCategoryDialog {...editDialog} />
      <RemoveCategoryDialog {...removeDialog} />
    </Stack>
  );
}
