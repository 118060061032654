import { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { FormConfirmDialog } from "@/components/FormConfirmDialog";
import { getApiErrorMessage, refetchQueries } from "@/utils";
import { PermissionGroupType } from "@/models";
import { editMemberPermissionGroup } from "@/services/member";

import {
  ChangePermissionEditor,
  useChangePermissionEditorForm,
} from "../../components/ChangePermissionEditor";

import type { Member } from "@/models";
import type { AxiosErrorWithData } from "@/client/api";

type ChangeMemberPermissionDialogProps = {
  open: boolean;
  member?: Member;
  onClose: () => void;
  fetchKeys?: string[];
};

export function ChangeMemberPermissionDialog({
  open: isOpen,
  member,
  onClose,
  fetchKeys = [],
}: ChangeMemberPermissionDialogProps) {
  const { control, handleSubmit, reset } = useChangePermissionEditorForm();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isOpen && member) {
      reset({ permissionGroupId: member.doorPermission?.id });
    }
  }, [isOpen, member, reset]);

  const { mutate: update, isLoading } = useMutation(editMemberPermissionGroup, {
    onSuccess: async () => {
      enqueueSnackbar("แก้ไขสิทธิ์การใช้งานสำเร็จ", { variant: "success" });
      await refetchQueries({ queryClient, fetchKeys });
      close();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(getApiErrorMessage(error), { variant: "error" });
    },
  });

  function close() {
    onClose();
    reset();
  }

  const onSubmit = handleSubmit((form) => {
    if (!member) return;

    update({ memberId: member?.id, data: form });
  });

  const title = "แก้ไขสิทธิ์การใช้งานประตู";

  return (
    <FormConfirmDialog
      maxWidth="lg"
      control={control}
      title={title}
      loading={isLoading}
      open={isOpen}
      onClose={close}
      onConfirm={onSubmit}
    >
      <ChangePermissionEditor
        type={PermissionGroupType.member}
        control={control}
      />
    </FormConfirmDialog>
  );
}
