import {
  Card,
  CardContent,
  Pagination,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";

import { DataTableBody } from "@/components/DataTableBody";
import { getSumarizeTrainingSummary } from "@/services/dashboard";

import type { DateTime } from "luxon";
import type { PaginationProps } from "@mui/material";

type SummarizeTrainingSummaryTableProps = {
  startedAt: DateTime;
  endedAt: DateTime;
};

export function SummarizeTrainingSummaryTable({
  startedAt,
  endedAt,
}: SummarizeTrainingSummaryTableProps) {
  const [page, setPage] = useState(1);

  const { data: raw, isFetching } = useQuery(
    ["getSumarizeTrainingSummary", startedAt.toISO(), endedAt.toISO(), page],
    () => getSumarizeTrainingSummary({ startedAt, endedAt, page })
  );

  const data = raw?.data ?? [];
  const total = raw?.total ?? 0;

  const columnHeader = { sx: { bgcolor: "background.paper" } };

  const onChangePage: PaginationProps["onChange"] = (_, page: number) =>
    setPage(page);

  const pageCount = Math.ceil(total / 10);

  return (
    <Card>
      <CardContent>
        <Stack gap={3}>
          <Typography variant="h6">สรุปการวัดผลการเทรนในเดือนนี้</Typography>
          <TableContainer sx={{ height: 576, overflowX: "hidden" }}>
            <Table stickyHeader aria-label="summarize training table">
              <TableHead>
                <TableRow>
                  <TableCell width={280} {...columnHeader}>
                    ชื่อเทรนเนอร์
                  </TableCell>
                  <TableCell width={200} {...columnHeader}>
                    สมาชิกที่ต้องวัดผลทั้งหมด
                  </TableCell>
                  <TableCell width={200} {...columnHeader}>
                    สมาชิกที่วัดผลตรงเวลา
                  </TableCell>
                  <TableCell width={200} {...columnHeader}>
                    สมาชิกที่วัดผลล่าช้า
                  </TableCell>
                  <TableCell width={200} {...columnHeader}>
                    สมาชิกที่ยังไม่ได้วัดผล
                  </TableCell>
                </TableRow>
              </TableHead>
              <DataTableBody loading={isFetching} data={data}>
                {data.map(
                  ({ trainer, summary: { onTime, delay, inProgress } }) => {
                    const total = inProgress + onTime + delay;
                    return (
                      <TableRow key={trainer.id}>
                        <TableCell component="th" scope="row">
                          {trainer.name}
                        </TableCell>
                        <TableCell>{total} คน</TableCell>
                        <TableCell>{onTime} คน</TableCell>
                        <TableCell>{delay} คน</TableCell>
                        <TableCell>{inProgress} คน</TableCell>
                      </TableRow>
                    );
                  }
                )}
              </DataTableBody>
            </Table>
          </TableContainer>
          <Pagination
            sx={{ display: "flex", justifyContent: "flex-end" }}
            page={page}
            count={pageCount || 1}
            shape="rounded"
            size="large"
            onChange={onChangePage}
          />
        </Stack>
      </CardContent>
    </Card>
  );
}
