import { useController, useWatch } from "react-hook-form";
import * as yup from "yup";
import {
  Box,
  Divider,
  FormControlLabel,
  MenuItem,
  Radio,
  Stack,
  Typography,
} from "@mui/material";

import { Section } from "@/components/Section";
import { formatChannel, formatGender } from "@/formatter";
import { FormRadioGroup } from "@/components/FormRadioGroup";
import { FormTextField } from "@/components/FormTextField";
import { FormSelect } from "@/components/Select";
import { FormMemberAutocomplete } from "@/components/MemberAutocomplete";
import { Channel, Gender } from "@/models";

import type { ControllerProps } from "react-hook-form";
import type { InferType } from "yup";

export type LeadEditorState = InferType<typeof schema>;

type LeadEditorProps = Pick<ControllerProps<LeadEditorState>, "control">;

export function LeadEditor({ control }: LeadEditorProps) {
  const channel = useWatch({ name: "channel", control });
  const {
    field: { onChange: onChangeFriend },
  } = useController({ name: "channelFriend", control });
  const {
    field: { onChange: onChangeOther },
  } = useController({ name: "channelOther", control });

  const handleChannelChange = () => {
    onChangeFriend(null);
    onChangeOther("");
  };

  const {
    fieldState: { error },
  } = useController({ name: "contact", control });

  const isContactError = !!error;

  const channels = [
    Channel.Facebook,
    Channel.Line,
    Channel.Google,
    Channel.Instagram,
    Channel.Friend,
    Channel.Event,
    Channel.Brochure,
    Channel.Other,
  ];

  return (
    <Stack gap={2.5}>
      <Section columns={2} label="ข้อมูลส่วนบุคคล">
        <Box sx={{ gridColumn: "1/span 2" }}>
          <Typography variant="subtitle2" color="text.secondary">
            เพศ*
          </Typography>
          <FormRadioGroup row name="gender" control={control} sx={{ mt: 0.75 }}>
            {[Gender.Unspecified, Gender.Male, Gender.Female].map((gender) => (
              <FormControlLabel
                key={gender}
                value={gender}
                control={<Radio />}
                label={formatGender(gender)}
              />
            ))}
          </FormRadioGroup>
        </Box>
        <FormTextField
          name="firstName"
          control={control}
          label="ชื่อ"
          required
        />
        <FormTextField name="lastName" control={control} label="นามสกุล" />
        <FormTextField name="nickname" control={control} label="ชื่อเล่น" />
      </Section>
      <Divider />
      <Section
        columns={2}
        label="ช่องทางการติดต่อ*"
        validationLabel={{
          label: "(ระบุข้อมูลอย่างน้อย 1 รายการ)",
          isError: isContactError,
        }}
      >
        <FormTextField
          name="contact.phone"
          control={control}
          label="เบอร์โทรศัพท์"
          error={isContactError}
        />
        <FormTextField
          name="contact.email"
          control={control}
          label="Email"
          error={isContactError}
        />
        <FormTextField
          name="contact.line"
          control={control}
          label="Line"
          error={isContactError}
        />
        <FormTextField
          name="contact.facebook"
          control={control}
          label="Facebook"
          error={isContactError}
        />
        <FormTextField
          name="contact.other"
          control={control}
          label="อื่นๆ"
          error={isContactError}
        />
      </Section>
      <Divider />
      <Section columns={2} label="ช่องทางที่รู้จัก">
        <FormSelect
          name="channel"
          control={control}
          label="ช่องทาง"
          onChange={handleChannelChange}
          required
        >
          {channels.map((channel) => (
            <MenuItem key={channel} value={channel}>
              {formatChannel(channel)}
            </MenuItem>
          ))}
        </FormSelect>
        {channel === Channel.Other && (
          <FormTextField
            name="channelOther"
            control={control}
            label="รายละเอียดเพิ่มเติม"
            required
          />
        )}
        {channel === Channel.Friend && (
          <FormMemberAutocomplete
            label="หมายเลขสมาชิกหรือชื่อ"
            name="channelFriend"
            control={control}
            required
          />
        )}
      </Section>
    </Stack>
  );
}

const schema = yup.object({
  gender: yup.string().oneOf(Object.values(Gender)).required(),
  firstName: yup.string().label("ชื่อ").required(),
  lastName: yup.string().label("นามสกุล"),
  nickname: yup.string().label("ชื่อเล่น"),
  contact: yup
    .object({
      phone: yup.string().label("เบอร์โทรศัพท์"),
      email: yup.string().label("อีเมล"),
      line: yup.string().label("Line"),
      facebook: yup.string().label("Facebook"),
      other: yup.string().label("อื่นๆ"),
    })
    .test({
      test: ({ phone, email, line, facebook, other }) =>
        !!phone || !!email || !!line || !!facebook || !!other,
    }),
  channel: yup
    .string()
    .oneOf(Object.values(Channel))
    .label("ช่องทาง")
    .required(),
  channelOther: yup
    .string()
    .label("รายละเอียดเพิ่มเติม")
    .when("channel", {
      is: Channel.Other,
      then: (schema: yup.StringSchema) => schema.required(),
      otherwise: (schema: yup.StringSchema) => schema,
    }),
  channelFriend: yup
    .object({
      id: yup.number(),
      accountId: yup.string(),
      code: yup.string(),
      firstName: yup.string(),
      lastName: yup.string(),
      phoneNo: yup.string(),
    })
    .nullable()
    .label("หมายเลขสมาชิกหรือชื่อ")
    .when("channel", {
      is: Channel.Friend,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema,
    }),
});

const defaultValues = { gender: Gender.Unspecified } as LeadEditorState;

LeadEditor.schema = schema;
LeadEditor.defaultValues = defaultValues;
