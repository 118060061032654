import { Stack } from "@mui/material";
import { Helmet } from "react-helmet-async";

import { getDocumentTitle, useRequireParams } from "@/utils";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { FollowUpDetail } from "@/features/training/pages/FollowUpDetailPage/FollowUpDetail";

export function TrainerFollowUpDetailPage() {
  const { id, trainingId, followUpId } = useRequireParams([
    "id",
    "trainingId",
    "followUpId",
  ]);

  const title = "รายละเอียดการติดตามผลการเทรน";
  const breadcrumbs = {
    items: [
      { label: "เทรนเนอร์", to: "/trainers" },
      {
        label: "รายชื่อเทรนเนอร์",
        to: "/trainers",
      },
      { label: "ข้อมูลเทรนเนอร์", to: `/trainers/${id}` },
      {
        label: "รายละเอียดเป้าหมายการเทรน",
        to: `/trainers/${id}/trainings/${trainingId}`,
      },
      {
        label: title,
        to: `/trainers/${id}/trainings/${trainingId}/follow-ups/${followUpId}`,
      },
    ],
  };

  return (
    <Stack gap={4}>
      <Helmet>
        <title>{getDocumentTitle(title)}</title>
      </Helmet>
      <Breadcrumbs {...breadcrumbs} />
      <FollowUpDetail id={followUpId} />
    </Stack>
  );
}
