import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Stack,
  Table,
  TableContainer,
  TableHead,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import {
  mapOptional,
  pickListTableParams,
  refetchQueries,
  useRequireParams,
} from "@/utils";
import { DataTableBody } from "@/components/DataTableBody";
import { SearchParamsPagination } from "@/components/SearchParamsPagination";
import { ConfirmDialog } from "@/components/ConfirmDialog";
import { ItemsTotal } from "@/components/ItemsTotal";
import { formatBookingStatus } from "@/formatter";
import { getMemberClassBookings } from "@/services/member";
import {
  cancelClassBooking,
  updateClassParticipantStatus,
} from "@/services/class";
import { configs } from "@/configs";
import { BookingStatus } from "@/models";

import {
  ClassReservationTableHeaderRow,
  ClassReservationTableRow,
} from "./ClassReservationTableRow";

import type { AxiosErrorWithData } from "@/client/api";
import type { MemberBooking } from "@/models";
import type { ConfirmDialogProps } from "@/components/ConfirmDialog";

const QUERY_KEY = "classReservation";

export function ClassReservationListPage() {
  const { memberId } = useRequireParams(["memberId"]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const status = searchParams.get("status") as BookingStatus | undefined;

  const setStatus = (s: BookingStatus | null) => () => {
    if (s) {
      searchParams.set("status", s);
    } else {
      searchParams.delete("status");
    }

    searchParams.delete("page");
    setSearchParams(searchParams, { replace: true });
  };

  const query = pickListTableParams(searchParams);

  const { data: raw, isFetching } = useQuery([QUERY_KEY, query], () =>
    getMemberClassBookings({
      memberId: +memberId,
      query,
    })
  );

  const data: MemberBooking[] = raw?.data ?? [];
  const total = raw?.total ?? 0;

  function onCloseDialog() {
    searchParams.delete("dialog");
    searchParams.delete("bookingId");
    setSearchParams(searchParams, { replace: true });
  }

  function onError(error: AxiosErrorWithData) {
    console.error(error);
    enqueueSnackbar(
      error.response?.data.message ?? configs.unknownErrorMessage,
      { variant: "error" }
    );
  }

  const { mutate: cancel, isLoading: isCancelLoading } = useMutation(
    cancelClassBooking,
    {
      onSuccess: async () => {
        enqueueSnackbar("ยกเลิกการจองสำเร็จ", { variant: "success" });
        await refetchQueries({ queryClient, fetchKeys: [QUERY_KEY] });
        onCloseDialog();
      },
      onError,
    }
  );

  const { mutate: updateStatus, isLoading: isUpdateLoading } = useMutation(
    updateClassParticipantStatus,
    {
      onSuccess: async () => {
        enqueueSnackbar("เปลี่ยนสถานะสำเร็จ", { variant: "success" });
        await refetchQueries({
          queryClient,
          fetchKeys: [QUERY_KEY],
        });
        onCloseDialog();
      },
      onError,
    }
  );

  const dialog = searchParams.get("dialog");
  const bookingId = searchParams.get("bookingId");

  const booking = data.find(({ id }) => id.toString() === bookingId) ?? null;

  const statusToUpdate =
    booking?.status === BookingStatus.Absent
      ? BookingStatus.Attended
      : BookingStatus.Absent;

  const confirmCancelDialog: ConfirmDialogProps = {
    maxWidth: "xs",
    confirmMessage: "ใช่",
    cancelMessage: "ไม่ใช่",
    reverse: true,
    onClose: onCloseDialog,
    open: dialog === "cancelBooking" && !!bookingId,
    loading: isCancelLoading,
    title: "คุณต้องการยกเลิกการจองใช่หรือไม่",
    onConfirm: () => {
      if (!bookingId) {
        return;
      }

      cancel({
        classId: booking?.id.toString() ?? "",
        scheduleId: booking?.schedule.id.toString() ?? "",
        bookingId,
      });
    },
  };

  const confirmUpdateDialog: ConfirmDialogProps = {
    maxWidth: "xs",
    confirmMessage: "ใช่",
    cancelMessage: "ไม่ใช่",
    reverse: true,
    onClose: onCloseDialog,
    open: dialog === "updateStatus" && !!bookingId,
    loading: isUpdateLoading,
    title: `คุณต้องการเปลี่ยนสถานะเป็น "${formatBookingStatus(
      statusToUpdate
    )}" ใช่หรือไม่`,
    onConfirm: () => {
      if (!bookingId) return;

      updateStatus({ bookingId, status: statusToUpdate });
    },
  };

  return (
    <Stack gap={3}>
      <Card>
        <CardContent>
          <Stack>
            <Stack direction="row" justifyContent="space-between" mb={3}>
              <ButtonGroup variant="contained" size="large" color="inherit">
                {[
                  null,
                  BookingStatus.Booked,
                  BookingStatus.Waiting,
                  BookingStatus.Cancelled,
                  BookingStatus.Attended,
                  BookingStatus.Absent,
                ].map((s) => (
                  <Button
                    key={s}
                    onClick={setStatus(s)}
                    sx={{ bgcolor: s === status ? "grey.100" : "grey.300" }}
                  >
                    {mapOptional(s, formatBookingStatus) ?? "ทั้งหมด"}
                  </Button>
                ))}
              </ButtonGroup>
            </Stack>
            <ItemsTotal count={total} />
            <TableContainer sx={{ height: "684px", overflowX: "hidden" }}>
              <Table stickyHeader>
                <TableHead>
                  <ClassReservationTableHeaderRow />
                </TableHead>
                <DataTableBody loading={isFetching} data={data}>
                  {data.map((item) => (
                    <ClassReservationTableRow key={item.id} data={item} />
                  ))}
                </DataTableBody>
              </Table>
            </TableContainer>
          </Stack>
        </CardContent>
      </Card>
      <SearchParamsPagination total={total} />
      <ConfirmDialog {...confirmCancelDialog} />
      <ConfirmDialog {...confirmUpdateDialog} />
    </Stack>
  );
}
