import { Divider, Stack } from "@mui/material";
import { useWatch, type Control } from "react-hook-form";
import * as yup from "yup";

import { Section } from "@/components/Section";
import { FormTextField } from "@/components/FormTextField";
import { FormDatePicker } from "@/components/DatePicker";
import { datetime } from "@/lib/yup";
import { now } from "@/lib/dateTime";

import {
  FormTrainerTrainingAutocomplete,
  TrainerTrainingAutocomplete,
} from "./TrainerTrainingAutocomplete";
import {
  FormMemberTrainingAutocomplete,
  MemberTrainingAutocomplete,
} from "./MemberTrainingAutocomplete";
import { FormTrainingGoalCheckBoxGroup } from "./FormTrainingGoalCheckBoxGroup";

import type { InferType } from "yup";
import type { DateTime } from "luxon";

export type TrainingEditorState = InferType<typeof schema>;

type TrainingEditorProps = {
  control: Control<TrainingEditorState>;
  openByTrainer?: boolean;
  isEdit?: boolean;
  isStartedAtDisabled?: boolean;
  latestFollowedAt?: DateTime;
};

export function TrainingEditor({
  control,
  openByTrainer = false,
  isEdit = false,
  isStartedAtDisabled = false,
  latestFollowedAt,
}: TrainingEditorProps) {
  const startedAt = useWatch({ control, name: "startedAt" });
  const endedAt = useWatch({ control, name: "endedAt" });

  const tomorrow = now().startOf("day").plus({ day: 1 });

  let endedAtMinDate = tomorrow;

  if (latestFollowedAt && latestFollowedAt > tomorrow) {
    endedAtMinDate = latestFollowedAt;
  }
  if (startedAt && startedAt > (latestFollowedAt ?? tomorrow)) {
    endedAtMinDate = startedAt;
  }

  const startedAtMaxDate = (latestFollowedAt ?? endedAt)?.minus({ day: 1 });

  return (
    <Stack gap={3}>
      <Section columns={2} label="ข้อมูลเทรนเนอร์">
        <FormTrainerTrainingAutocomplete
          label="เทรนเนอร์"
          name="trainer"
          control={control}
          required
          disabled={openByTrainer}
        />
      </Section>
      <Divider />
      <Section columns={2} label="ข้อมูลสมาชิก">
        <FormMemberTrainingAutocomplete
          label="หมายเลขสมาชิก ชื่อ หรือเบอร์โทรศัพท์"
          name="member"
          control={control}
          required
          disabled={isEdit}
        />
      </Section>
      <Section columns={1} label="เป้าหมายการเทรน (อย่างน้อย 1 เป้าหมาย*)">
        <FormTrainingGoalCheckBoxGroup
          label="เป้าหมายการเทรน"
          name="goals"
          control={control}
        />
        <FormTextField
          label="รายละเอียดเพิ่มเติม"
          name="detail"
          control={control}
          multiline
          rows={4}
        />
      </Section>
      <Divider />
      <Section columns={4} label="ระยะเวลาการเทรน">
        <FormDatePicker
          disablePast
          label="วันเริ่มต้นการเทรน"
          name="startedAt"
          control={control}
          maxDate={startedAtMaxDate}
          TextFieldProps={{ required: true }}
          disabled={isStartedAtDisabled}
        />
        <FormDatePicker
          label="วันสิ้นสุดการเทรน"
          name="endedAt"
          control={control}
          minDate={endedAtMinDate}
          TextFieldProps={{ required: true }}
        />
      </Section>
    </Stack>
  );
}

const schema = yup.object({
  trainer: TrainerTrainingAutocomplete.schema.label("เทรนเนอร์").required(),
  member: MemberTrainingAutocomplete.schema
    .label("หมายเลขสมาชิก ชื่อ หรือเบอร์โทรศัพท์")
    .required(),
  goals: FormTrainingGoalCheckBoxGroup.schema.min(1),
  detail: yup.string().label("รายละเอียดเพิ่มเติม"),
  startedAt: datetime().required().label("วันเริ่มต้นการเทรน"),
  endedAt: datetime().required().label("วันสิ้นสุดการเทรน"),
});

TrainingEditor.schema = schema;
TrainingEditor.defaultValue = { goals: [] };
