import {
  Box,
  Card,
  CardContent,
  Stack,
  Button,
  TableContainer,
  Table,
  TableHead,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useQuery } from "react-query";

import { QueryTextField } from "@/components/QueryTextField";
import { DataTableBody } from "@/components/DataTableBody";
import { SearchParamsPagination } from "@/components/SearchParamsPagination";
import { ItemsTotal } from "@/components/ItemsTotal";
import { getDocumentTitle } from "@/utils";
import { formatPurchaseType } from "@/formatter";
import { getProductPTs } from "@/services/product";
import { useCurrentUser } from "@/features/authentication/contexts/AuthenticationContext";
import { PurchaseType } from "@/models";

import { RemoveProductPTDialog } from "../../components/RemoveProductPTDialog";
import {
  ProductPTTableRow,
  ProductPTTableRowHeader,
} from "./ProductPTTableRow";
import { AddProductPTDialog } from "./AddProductPTDialog";

const QUERY_KEY = "product-pts";

export function ProductPTListPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentUser = useCurrentUser();
  const query = searchParams.toString();

  const { data: raw, isFetching } = useQuery([QUERY_KEY, query], () =>
    getProductPTs(query)
  );

  const data = raw?.data ?? [];
  const total = raw?.total;

  function add() {
    searchParams.set("dialog", "pt-add");
    setSearchParams(searchParams);
  }

  const dialog = searchParams.get("dialog");
  const id = searchParams.get("id") ?? "";

  function onCloseDialog() {
    searchParams.delete("dialog");
    searchParams.delete("id");
    setSearchParams(searchParams, { replace: true });
  }

  const addDialog = {
    open: dialog === "pt-add",
    onClose: onCloseDialog,
  };

  const removeDialog = {
    open: dialog === "pt-remove",
    id,
    onClose: onCloseDialog,
  };

  return (
    <Box>
      <Helmet>
        <title>
          {getDocumentTitle(formatPurchaseType(PurchaseType.ProductPT))}
        </title>
      </Helmet>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap={4}
            mb={5}
          >
            <QueryTextField sx={{ width: 600 }} placeholder="ค้นหาชื่อ" />
            {currentUser.isAdmin && (
              <Button variant="contained" size="large" onClick={add}>
                เพิ่มแพ็กเกจเทรนเนอร์
              </Button>
            )}
          </Stack>
          <ItemsTotal count={total} />
          <TableContainer sx={{ height: "600px", overflowX: "hidden" }}>
            <Table stickyHeader>
              <TableHead>
                <ProductPTTableRowHeader />
              </TableHead>
              <DataTableBody loading={isFetching} data={data}>
                {data.map((item) => (
                  <ProductPTTableRow key={item.id} data={item} />
                ))}
              </DataTableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <SearchParamsPagination total={total} />
      <AddProductPTDialog {...addDialog} />
      <RemoveProductPTDialog {...removeDialog} />
    </Box>
  );
}
