import {
  Divider,
  FormControlLabel,
  MenuItem,
  Radio,
  Stack,
} from "@mui/material";
import { useController, useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useEffect } from "react";

import { FacilityType } from "@/models";
import { FormSelect } from "@/components/Select";
import { FormTextField } from "@/components/FormTextField";
import { formatFacilityType, formatTime } from "@/formatter";
import { Section } from "@/components/Section";
import { FormNumericTextField } from "@/components/NumericTextField";
import { FormRadioGroup } from "@/components/FormRadioGroup";
import { FormTimeTextField } from "@/components/FormTimeTextField";
import { timeAfter } from "@/lib/yup/validation";
import { now } from "@/lib/dateTime";
import { FormConfirmDialog } from "@/components/FormConfirmDialog";
import { FormImageUploader } from "@/components/ImageUploader";
import { configs } from "@/configs";

import type { Control } from "react-hook-form";
import type { FileForm } from "@/components/ImageUploader";
import type { InferType } from "yup";

export type FacilityEditorState = InferType<typeof schema>;

export function FacilityEditorDialog({
  open,
  title,
  onClose,
  onSubmit,
  loading,
  control,
}: {
  open: boolean;
  title: string;
  onClose: () => void;
  onSubmit: () => void | PromiseLike<void>;
  loading: boolean;
  control: Control<FacilityEditorState>;
}) {
  const types = [
    FacilityType.Pool,
    FacilityType.Court,
    FacilityType.Equipment,
    FacilityType.Sauna,
    FacilityType.MeetingRoom,
    FacilityType.Other,
  ];

  const hasFee = useWatch({ name: "hasFee", control });
  const {
    field: { onChange: onChangeMemberFee },
  } = useController({ name: "memberFee", control });
  const {
    field: { onChange: onChangeGuestFee },
  } = useController({ name: "guestFee", control });

  useEffect(() => {
    if (hasFee === "false") {
      onChangeMemberFee(0);
      onChangeGuestFee(0);
    }
  }, [hasFee, onChangeGuestFee, onChangeMemberFee]);

  return (
    <FormConfirmDialog
      control={control}
      title={title}
      loading={loading}
      open={open}
      onClose={onClose}
      onConfirm={onSubmit}
    >
      <Stack gap={3}>
        <FormTextField
          label="ชื่อสิ่งอำนวยความสะดวก"
          name="name"
          control={control}
          required
        />
        <Divider />
        <Section columns={2} label="รายละเอียดเพิ่มเติม">
          <FormSelect
            label="ประเภทสิ่งอำนวยความสะดวก"
            name="type"
            control={control}
            required
          >
            {types.map((type) => (
              <MenuItem key={type} value={type}>
                {formatFacilityType(type)}
              </MenuItem>
            ))}
          </FormSelect>
          <FormNumericTextField
            label="จำนวนผู้เข้าใช้บริการต่อ 1 ครั้ง"
            name="capacity"
            control={control}
            required
          />
          <FormTextField
            label="รายละเอียด"
            name="description"
            control={control}
            sx={{ gridColumn: "1/-1" }}
          />
          <FormTimeTextField
            control={control}
            name="openedAt"
            label="เวลาเปิด"
            required
            fullWidth
          />
          <FormTimeTextField
            control={control}
            name="closedAt"
            label="เวลาปิด"
            required
            fullWidth
          />
        </Section>
        <FormImageUploader
          control={control}
          name="images"
          module="facilities"
          max={1}
          aspect={configs.aspectRatio.facility}
        />
        <Divider />
        <Section columns={1} label="ค่าบริการเพิ่มเติม">
          <Stack direction="row" gap={3} alignItems="flex-start">
            <FormRadioGroup row name="hasFee" control={control}>
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="ไม่คิดค่าบริการ"
              />
              <FormControlLabel
                value="true"
                control={<Radio />}
                label="คิดค่าบริการ"
              />
            </FormRadioGroup>
            {hasFee === "true" && (
              <>
                <FormTextField
                  label="สำหรับสมาชิก/ครั้ง (บาท)"
                  name="memberFee"
                  control={control}
                />
                <FormTextField
                  label="สำหรับบุคคลภายนอก/ครั้ง (บาท)"
                  name="guestFee"
                  control={control}
                />
              </>
            )}
          </Stack>
        </Section>
      </Stack>
    </FormConfirmDialog>
  );
}

export function useFacilityEditorForm() {
  return useForm({
    resolver,
    defaultValues,
  });
}

const feeSchema = yup.number().when("hasFee", {
  is: "true",
  then: (schema) => schema.required().min(1),
  otherwise: (schema) => schema,
});

const schema = yup.object({
  images: FormImageUploader.schema,
  name: yup.string().required().label("ชื่อสิ่งอำนวยความสะดวก"),
  type: yup
    .string()
    .oneOf(Object.values(FacilityType))
    .required()
    .label("ประเภทสิ่งอำนวยความสะดวก"),
  capacity: yup
    .number()
    .required()
    .min(1)
    .label("จำนวนผู้เข้าใช้บริการต่อ 1 ครั้ง*"),
  description: yup.string().max(500).label("รายละเอียด"),
  openedAt: yup.string().time().required().label("เวลาเปิด"),
  closedAt: timeAfter(
    "openedAt",
    "Close time must be after ${startTime}",
    yup.string().time().required().label("เวลาปิด")
  ),
  hasFee: yup.string().oneOf(["true", "false"]).required(),
  memberFee: feeSchema.label("สำหรับสมาชิก/ครั้ง (บาท)"),
  guestFee: feeSchema.label("สำหรับบุคคลภายนอก/ครั้ง (บาท)"),
});

const resolver = yupResolver(schema);

const defaultValues = {
  name: "",
  description: "",
  hasFee: "false",
  openedAt: formatTime(now().startOf("hour")),
  closedAt: formatTime(now().startOf("hour").plus({ hour: 1 })),
  memberFee: 0,
  guestFee: 0,
  images: [] as FileForm[],
} as FacilityEditorState;
