import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  Table,
  TableContainer,
  TableHead,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { useAtom } from "jotai";
import { useEffect } from "react";

import { getDocumentTitle, pickListTableParams } from "@/utils";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { QueryTextField } from "@/components/QueryTextField";
import { ItemsTotal } from "@/components/ItemsTotal";
import { DataTableBody } from "@/components/DataTableBody";
import { selectedBranchAtom } from "@/atom/global";
import { getTrainings } from "@/services/training";
import { SearchParamsPagination } from "@/components/SearchParamsPagination";
import { SortType, TrainingStatus } from "@/models";

import { TrainingGoalSearchParamsSelect } from "../../components/TrainingGoalSelect";
import { TrainingStatusSelect } from "./TrainingStatusSelect";
import { TrainingTableRow, TrainingTableRowHeader } from "./TrainingTableRow";
import { CreateTrainingCsvDialog } from "./CreateTrainingCsvDialog";
import { AddTrainingButton, AddTrainingDialog } from "../../components";

const QUERY_KEY = `getTrainings`;

export function TrainingListPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedBranch] = useAtom(selectedBranchAtom);
  const query = pickListTableParams(searchParams, ["goal"]);

  const { data: raw, isFetching } = useQuery(
    [QUERY_KEY, query, selectedBranch],
    () => getTrainings(query)
  );

  const data = raw?.data ?? [];
  const total = raw?.total;

  const title = "เป้าหมายการเทรน";
  const breadcrumbItems = [{ label: title, to: "/trainings" }];

  const sort = searchParams.get("sort");
  const dialog = searchParams.get("dialog");

  useEffect(() => {
    if (!sort) {
      searchParams.set("sort", "latestFollowedAt");
      searchParams.set("sortType", SortType.Asc);
      searchParams.set("status", TrainingStatus.InProgress);
      setSearchParams(searchParams, { replace: true });
    }
  }, [searchParams, setSearchParams, sort]);

  const onClickCreateCsv = () => {
    searchParams.set("dialog", "create-csv");
    setSearchParams(searchParams, { replace: true });
  };

  const onCloseDialog = () => {
    searchParams.delete("dialog");
    setSearchParams(searchParams, { replace: true });
  };

  const createCsvDialog = {
    open: dialog === "create-csv",
    onClose: onCloseDialog,
  };

  const addTrainingDialog = {
    open: dialog === "training-add",
    onClose: onCloseDialog,
    fetchKeys: [QUERY_KEY],
  };

  return (
    <Stack>
      <Helmet>
        <title>{getDocumentTitle(title)}</title>
      </Helmet>
      <Breadcrumbs items={breadcrumbItems} sx={{ mb: 5 }} />
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Stack gap={3}>
            <Box display="grid" gridTemplateColumns="1fr 220px 220px" gap={2}>
              <QueryTextField
                placeholder="ค้นหาด้วยชื่อเทรนเนอร์ หรือชื่อสมาชิก"
                sx={{ maxWidth: 612 }}
              />
              <TrainingGoalSearchParamsSelect />
              <TrainingStatusSelect />
            </Box>
            <Stack direction="row" gap={2} justifyContent="flex-end">
              <Button
                variant="contained"
                color="inherit"
                onClick={onClickCreateCsv}
              >
                ดาวน์โหลดผลการเทรน
              </Button>
              <AddTrainingButton />
            </Stack>
          </Stack>
          <Stack>
            <ItemsTotal count={total} />
            <TableContainer sx={{ height: "600px", overflowX: "hidden" }}>
              <Table stickyHeader>
                <TableHead>
                  <TrainingTableRowHeader />
                </TableHead>
                <DataTableBody loading={isFetching} data={data}>
                  {data.map((item) => (
                    <TrainingTableRow key={item.id} data={item} />
                  ))}
                </DataTableBody>
              </Table>
            </TableContainer>
          </Stack>
        </CardContent>
      </Card>
      <SearchParamsPagination total={total} />
      <AddTrainingDialog {...addTrainingDialog} />
      <CreateTrainingCsvDialog {...createCsvDialog} />
    </Stack>
  );
}
