import { api } from "@/client/api";
import { configs } from "@/configs";
import { MemberCard } from "@/models";

type GetMemberCards = {
  data: MemberCard[];
};

type UpdateMemberCardInput = Omit<MemberCard, "fitnessCentersId">;

export async function getMemberCards(): Promise<GetMemberCards> {
  return await api.get(`${configs.apiUrl}/memberCards`);
}

export async function updateMemberCard({
  id,
  ...data
}: UpdateMemberCardInput): Promise<MemberCard> {
  const response = await api.patch(`${configs.apiUrl}/memberCards/${id}`, data);

  return response.data as MemberCard;
}
