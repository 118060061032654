import { Following } from "@/models";
import { api } from "@/client/api";
import { configs } from "@/configs";
import { parseDateTimeFromPrisma, parseISOToPrisma } from "@/utils";

type CreateFollowingInput = Pick<Following, "followAt" | "remark"> & {
  saleId: string;
  customerId: string;
};

type CreateOpportunityFollowingInput = Pick<
  Following,
  "followAt" | "remark"
> & {
  opportunityId: string;
};

export async function createFollowing({
  saleId,
  customerId,
  ...data
}: CreateFollowingInput): Promise<Following> {
  const response = await api.post(
    `${configs.apiUrl}/sales/${saleId}/customers/${customerId}/following`,
    parseISOToPrisma(data)
  );
  return parseDateTimeFromPrisma(response.data) as Following;
}

export async function createOpportunityFollowing({
  opportunityId,
  ...data
}: CreateOpportunityFollowingInput): Promise<Following> {
  const response = await api.post(
    `${configs.apiUrl}/sale/opportunities/${opportunityId}/followings`,
    parseISOToPrisma(data)
  );
  return parseDateTimeFromPrisma(response.data) as Following;
}
