import { Stack, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

import { formatCustomerStatus, formatCustomerStatusColor } from "@/formatter";

import type { CustomerStatus } from "@/models";

type CustomerStatusItemProps = { status: CustomerStatus };

export function CustomerStatusItem({ status }: CustomerStatusItemProps) {
  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <CircleIcon
        sx={{
          width: 12,
          height: 12,
          color: formatCustomerStatusColor(status),
        }}
      />
      <Typography variant="body1">{formatCustomerStatus(status)}</Typography>
    </Stack>
  );
}
