import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";

import { ConfirmDialog } from "@/components/ConfirmDialog";
import { refetchQueries } from "@/utils";
import { removePermissionGroup } from "@/services/permissionGroup";
import { configs } from "@/configs";

import type { AxiosErrorWithData } from "@/client/api";
import type { PermissionGroup } from "@/models";

export type RemovePermissionGroupDialogProps = {
  open: boolean;
  onClose: () => void;
  data?: PermissionGroup;
  fetchKeys?: string[];
};

export function RemovePermissionGroupDialog({
  open: isOpen,
  onClose,
  data,
  fetchKeys = [],
}: RemovePermissionGroupDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate: remove, isLoading } = useMutation(removePermissionGroup, {
    onSuccess: async () => {
      enqueueSnackbar("ลบสิทธิ์การใช้งานสำเร็จ", { variant: "success" });
      await refetchQueries({ queryClient, fetchKeys });
      onClose();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(
        error.response?.data.message ?? configs.unknownErrorMessage,
        { variant: "error" }
      );
    },
  });

  if (!data) return <></>;

  const { id, name } = data;

  const onConfirm = () => remove(id);

  const title = `ยืนยันการลบสิทธิ์${name}`;

  return (
    <ConfirmDialog
      maxWidth="xs"
      open={isOpen}
      onClose={() => onClose()}
      onConfirm={onConfirm}
      title={title}
      confirmMessage="ยืนยัน"
      loading={isLoading}
    />
  );
}
