import { Typography } from "@mui/material";

import { formatBookingStatus, formatBookingStatusColor } from "@/formatter";
import { BookingStatus } from "@/models";

import type { TypographyProps } from "@mui/material";

export function BookingStatusLabel({
  status,
  ...props
}: { status: BookingStatus } & TypographyProps) {
  return (
    <Typography color={formatBookingStatusColor(status)} {...props}>
      {formatBookingStatus(status)}
    </Typography>
  );
}
