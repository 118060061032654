import { api } from "@/client/api";
import { configs } from "@/configs";

import type { PermissionDoor } from "@/models";

type UpdatePermissionDoorInput = {
  branchId: number;
  id: number;
  doorIds: number[];
};

export async function getPermissionDoors(branchId: number) {
  const { data } = await api.get<PermissionDoor[]>(
    `${configs.apiUrl}/branches/${branchId}/permission-doors`
  );
  return data;
}

export async function updatePermissionDoor({
  branchId,
  id,
  ...data
}: UpdatePermissionDoorInput) {
  return await api.put(
    `${configs.apiUrl}/branches/${branchId}/permission-doors/${id}`,
    data
  );
}
