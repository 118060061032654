import { Box, MenuItem, Stack } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useWatch } from "react-hook-form";
import * as yup from "yup";

import { FormTextField } from "@/components/FormTextField";
import { FormNumericTextField } from "@/components/NumericTextField";
import { FormSelect } from "@/components/Select";
import { FormBranchAutocomplete } from "@/components/BranchAutocomplete";
import { useCurrentUser } from "@/features/authentication/contexts/AuthenticationContext";
import * as validation from "@/lib/yup/validation";

import type { ControllerProps } from "react-hook-form";
import type { InferType } from "yup";

export type MembershipEditorState = InferType<typeof schema>;

type MembershipEditorProps = Pick<
  ControllerProps<MembershipEditorState>,
  "control"
>;

export function MembershipEditor({ control }: MembershipEditorProps) {
  const type = useWatch({
    control,
    name: "type",
  });
  const user = useCurrentUser();
  return (
    <Stack gap={2.5}>
      <FormTextField
        id="packageNameInput"
        control={control}
        name="name"
        label="ชื่อแพ็กเกจสมาชิก"
        required
      />
      <Box display="grid" gridTemplateColumns="1fr 1fr 2fr" gap={2.5}>
        <FormNumericTextField
          control={control}
          name="duration"
          label="ระยะเวลา"
          required
        />
        <FormSelect
          control={control}
          name="durationUnit"
          label="หน่วย"
          required
        >
          <MenuItem value="day">วัน</MenuItem>
          <MenuItem value="month">เดือน</MenuItem>
          <MenuItem value="year">ปี</MenuItem>
        </FormSelect>
        <FormNumericTextField
          control={control}
          name="price"
          label="ราคา (บาท)"
          allowZero
          required
        />
      </Box>
      <FormSelect
        control={control}
        name="type"
        label="สาขาที่เข้าใช้บริการ"
        required
      >
        <MenuItem value="only">เฉพาะสาขานี้</MenuItem>
        {user.isAdmin && <MenuItem value="every">ทุกสาขา</MenuItem>}
        {user.branches.length > 1 && <MenuItem value="some">บางสาขา</MenuItem>}
      </FormSelect>
      {type === "some" && (
        <FormBranchAutocomplete
          label="สาขา"
          name="branches"
          control={control}
          required
        />
      )}
      <FormTextField
        control={control}
        name="description"
        label="รายละเอียด"
        multiline
        rows={4}
        required
      />
    </Stack>
  );
}

export function useMembershipEditorForm() {
  return useForm({
    resolver,
    defaultValues,
  });
}

const schema = yup.object({
  name: yup.string().label("ชื่อแพ็กเกจสมาชิก").required(),
  duration: yup.number().label("ระยะเวลา").required().min(1),
  durationUnit: yup
    .string()
    .oneOf(["day", "month", "year"] as const)
    .required(),
  type: yup
    .string()
    .oneOf(["every", "some", "only"] as const)
    .required("ระบุสาขาที่เข้าใช้บริการ"),
  branches: yup
    .array(
      yup.object({
        id: yup.number().required(),
        name: yup.string().required(),
      })
    )
    .default([])
    .when("type", {
      is: "some",
      then: (schema) => schema.required().min(1, "ระบุสาขา"),
      otherwise: (schema) => schema,
    }),
  description: yup.string().label("รายละเอียด").max(500).required(),
  price: validation.price.label("ราคา").required(),
});
const resolver = yupResolver(schema);

const defaultValues = {
  name: "",
  durationUnit: "month",
  description: "",
  price: 0,
} as MembershipEditorState;
