import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Table,
  TableContainer,
  TableHead,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";

import { useRequireParams } from "@/utils";
import { SearchParamsPagination } from "@/components/SearchParamsPagination";
import { QueryTextField } from "@/components/QueryTextField";
import { DataTableBody } from "@/components/DataTableBody";

import { AddVideoDialog } from "../../video/components/AddVideoDialog";
import { EditVideoDialog } from "../../video/components/EditVideoDialog";
import { RemoveVideoDialog } from "../../video/components/RemoveVideoDialog";
import { getVideos } from "@/services/video";
import { VideoTableRowHeader, VideoTableRow } from "./VideoTableRow";

const QUERY_KEY = "videos";

export function VideoList() {
  const { id: categoryId } = useRequireParams(["id"]);
  const [searchParams, setSearchParams] = useSearchParams({
    category: categoryId,
  });
  const query = searchParams.toString();

  const { data: raw, isFetching } = useQuery([QUERY_KEY, query], () =>
    getVideos(query)
  );

  const data = raw?.data ?? [];
  const total = raw?.total;

  function add() {
    searchParams.set("dialog", "video-add");
    setSearchParams(searchParams);
  }

  const dialog = searchParams.get("dialog");
  const id = searchParams.get("id") ?? "";

  function onCloseDialog() {
    searchParams.delete("dialog");
    searchParams.delete("id");
    setSearchParams(searchParams, { replace: true });
  }

  const addDialog = {
    open: dialog === "video-add",
    onClose: onCloseDialog,
    categoryId,
  };

  const editData = data.find((item) => item.id.toString() === id);

  const editDialog = {
    open: typeof editData !== "undefined" && dialog === "video-edit",
    id,
    onClose: onCloseDialog,
    data: editData,
    categoryId,
    fetchKey: QUERY_KEY,
  };

  const removeDialog = {
    open: dialog === "video-remove",
    id,
    onClose: onCloseDialog,
    fetchKey: QUERY_KEY,
  };

  return (
    <Box>
      <Card sx={{ mb: 3 }}>
        <CardHeader title="รายการวิดีโอ" />
        <CardContent>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap={4}
            mb={5}
          >
            <QueryTextField sx={{ width: 600 }} placeholder="ค้นหาชื่อ" />
            <Button variant="contained" size="large" onClick={add}>
              เพิ่มวิดีโอ
            </Button>
          </Stack>
          <TableContainer sx={{ height: "600px", overflowX: "hidden" }}>
            <Table stickyHeader>
              <TableHead>
                <VideoTableRowHeader />
              </TableHead>
              <DataTableBody loading={isFetching} data={data}>
                {data.map((item) => (
                  <VideoTableRow key={item.id} data={item} />
                ))}
              </DataTableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <SearchParamsPagination total={total} />
      <AddVideoDialog {...addDialog} />
      <EditVideoDialog {...editDialog} />
      <RemoveVideoDialog {...removeDialog} />
    </Box>
  );
}
