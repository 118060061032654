import { CircularProgress, IconButton, Tooltip, Box } from "@mui/material";
import Delete from "@mui/icons-material/Delete";

import { TextEllipsis } from "@/components/TextEllipsis";
import { getStorageFileName, useObjectURL } from "@/utils";
import { configs } from "@/configs";
import { UploadModule } from "@/models";

type ImageUploaderItemProps = {
  file?: File;
  url?: string;
  loading?: boolean;
  onRemove?: () => void;
  max?: number;
  module: UploadModule;
};
export function ImageUploaderItem({
  file,
  url,
  loading,
  onRemove,
  module,
}: ImageUploaderItemProps) {
  const objectURL = useObjectURL(file);
  const src = objectURL ?? url ?? "filename";
  const name = file?.name ? file.name : url ? getStorageFileName(url) : "";

  const width = configs.previewSize[module]?.width ?? 75;
  const height = configs.previewSize[module]?.height ?? 50;

  return (
    <Box
      display="grid"
      gridTemplateColumns={`${width}px 1fr 48px`}
      alignItems="center"
      gap={2.5}
      position="relative"
      sx={{ opacity: loading ? 0.5 : 1 }}
    >
      <Box
        component="img"
        src={src}
        width={width}
        height={height}
        borderRadius={1}
        sx={{ objectFit: "cover", objectPosition: "center" }}
      />
      <TextEllipsis variant="caption" color="text.disabled" line={1}>
        {name}
      </TextEllipsis>
      {loading && (
        <Box
          position="absolute"
          sx={{
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "grid",
            placeItems: "center",
          }}
        >
          <CircularProgress disableShrink size={24} />
        </Box>
      )}
      {!loading && onRemove && (
        <Tooltip title="ลบ">
          <IconButton onClick={onRemove}>
            <Delete />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
}
