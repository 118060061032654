import { useController, useForm, useWatch } from "react-hook-form";
import { Fragment, useEffect } from "react";
import {
  Button,
  Divider,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import DeleteIcon from "@mui/icons-material/Delete";

import { FormMembershipAutocomplete } from "@/features/member/pages/MemberListPage/MembershipAutocomplete";
import { FormProductPTAutocomplete } from "@/features/member/pages/MemberListPage/ProductPTAutocomplete";
import { Section } from "@/components/Section";
import { FormSelect } from "@/components/Select";
import { FormTextField } from "@/components/FormTextField";
import { FormNumericTextField } from "@/components/NumericTextField";
import { FormDatePicker } from "@/components/DatePicker";
import { formatPurchaseType } from "@/formatter";
import { datetime } from "@/lib/yup";
import { PurchaseType } from "@/models";

import type { InferType } from "yup";
import type { ControllerProps } from "react-hook-form";

export type InterestEditorState = InferType<typeof schema>;

type InterestEditorProps = Pick<
  ControllerProps<InterestEditorState>,
  "control"
>;

const MAX_INTEREST = 5;

export function InterestEditor({ control }: InterestEditorProps) {
  const products = useWatch({ name: "products", control });
  const percentageChance = useWatch({ name: "percentageChance", control });

  const total = products.reduce(
    (sum, { membership, packagePT }) =>
      sum + (membership?.price ?? 0) + (packagePT?.price ?? 0),
    0
  );
  const estimatePrice = (total * ((percentageChance ?? 0) / 100)).toFixed(2);

  const disableAddButton = products.length === MAX_INTEREST;

  const {
    field: { onChange: onChangeProducts },
  } = useController({ name: "products", control });

  const {
    field: { onChange: onChangeTotal },
  } = useController({ name: "totalChance", control });

  useEffect(() => {
    onChangeTotal(estimatePrice);
  }, [estimatePrice, onChangeTotal]);

  function onChangeType(type: PurchaseType, changeIndex: number) {
    const newProducts = products.map((product, index) => {
      return index === changeIndex
        ? { type, membership: null, packagePT: null }
        : product;
    });

    onChangeProducts(newProducts);
  }

  function onAddInterest() {
    onChangeProducts([
      ...products,
      { type: null, membership: null, packagePT: null },
    ]);
  }

  function onRemoveInterest(index: number) {
    products.splice(index, 1);
    onChangeProducts(products);
  }

  return (
    <Stack gap={4}>
      <Stack gap={2.5}>
        <Stack direction="row" gap={2} alignItems="center">
          <Typography variant="labelLarge" color="text.secondary">
            ความสนใจ
          </Typography>
          <Button
            variant="outlined"
            size="small"
            sx={{ py: 0.5, px: 4.5 }}
            disabled={disableAddButton}
            onClick={onAddInterest}
          >
            เพิ่ม
          </Button>
        </Stack>
        <Section columns={2}>
          {products.map(({ type, membership, packagePT }, index) => (
            <Fragment
              key={`${type}-${
                membership?.name ?? packagePT?.name ?? "no-product"
              }-${index}`}
            >
              <FormSelect
                label="รายการสินค้า"
                name={`products.${index}.type`}
                control={control}
                onChange={(event) =>
                  onChangeType(event.target.value as PurchaseType, index)
                }
                required
              >
                {[PurchaseType.Membership, PurchaseType.ProductPT].map(
                  (purchaseType) => (
                    <MenuItem key={purchaseType} value={purchaseType}>
                      {formatPurchaseType(purchaseType)}
                    </MenuItem>
                  )
                )}
              </FormSelect>
              <Stack direction="row" gap={2.5}>
                {(type === PurchaseType.Membership || !type) && (
                  <FormMembershipAutocomplete
                    fullWidth
                    label="ชื่อแพ็กเกจ"
                    name={`products.${index}.membership`}
                    control={control}
                    disabled={!type}
                    required
                  />
                )}
                {type === PurchaseType.ProductPT && (
                  <FormProductPTAutocomplete
                    fullWidth
                    label={`ชื่อแพ็กเกจ`}
                    name={`products.${index}.packagePT`}
                    disabled={!type}
                    control={control}
                  />
                )}
                <IconButton
                  size="medium"
                  sx={{ visibility: index === 0 ? "hidden" : "visible" }}
                  onClick={() => onRemoveInterest(index)}
                >
                  <DeleteIcon fontSize="large" />
                </IconButton>
              </Stack>
            </Fragment>
          ))}
        </Section>
      </Stack>
      <Divider />
      <Section columns={2} label="โอกาสที่คาดว่าจะปิดได้">
        <FormTextField
          label="จำนวน (%)"
          name="percentageChance"
          control={control}
          required
        />
        <FormNumericTextField
          label="ยอด (บาท)"
          name="totalChance"
          control={control}
          disabled
          required
        />
        <FormDatePicker
          disablePast
          label="วันที่คาดว่าจะปิด"
          name="expectedClosingAt"
          control={control}
          TextFieldProps={{ required: true }}
        />
      </Section>
    </Stack>
  );
}

export function useInterestEditorForm() {
  return useForm({
    resolver,
    defaultValues,
  });
}

const schema = yup.object({
  products: yup
    .array()
    .of(
      yup.object({
        type: yup
          .string()
          .oneOf(Object.values(PurchaseType))
          .required()
          .label("รายการสินค้า"),
        membership: yup
          .object({
            id: yup.number().required(),
            name: yup.string().required(),
            duration: yup.object().required(),
            price: yup.number().required(),
            productId: yup.number().required(),
          })
          .when("type", {
            is: PurchaseType.Membership,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema,
          })
          .nullable()
          .default(null) // fix yup not validate when value is undefined
          .label("ชื่อแพ็กเกจ"),
        packagePT: yup
          .object({
            id: yup.number(),
            name: yup.string(),
            quota: yup.number(),
            duration: yup.object(),
            quotaUnit: yup.string().required(),
            price: yup.number().required(),
            productId: yup.number().required(),
          })
          .when("type", {
            is: PurchaseType.ProductPT,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema,
          })
          .nullable()
          .default(null) // fix yup not validate when value is undefined
          .label("ชื่อแพ็กเกจ"),
      })
    )
    .min(1)
    .required(),
  totalChance: yup.number().required(),
  percentageChance: yup
    .number()
    .integer()
    .min(0)
    .max(100)
    .required()
    .label("จำนวน (%)"),
  expectedClosingAt: datetime().required().label("วันที่คาดว่าจะปิด"),
});

const resolver = yupResolver(schema);

const defaultValues = {
  products: [
    {
      type: undefined as unknown as NonNullable<PurchaseType | undefined>,
      membership: null,
      packagePT: null,
    },
  ],
} as InterestEditorState;

InterestEditor.schema = schema;
InterestEditor.defaultValues = defaultValues;
