import { useSearchParams } from "react-router-dom";
import {
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import { DataSection } from "@/components/DataSection";
import { TextLink } from "@/components/TextLink";
import { useCurrentUser } from "@/features/authentication/contexts/AuthenticationContext";

import { EditBranchDialog } from "./EditBranchDialog";

import type { Branch } from "@/models";

export type BranchDetailProps = {
  data: Branch;
  fetchKeys?: string[];
};

export function BranchDetail({ data, fetchKeys = [] }: BranchDetailProps) {
  const { branches } = useCurrentUser();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    id,
    name,
    phoneNo,
    email,
    address,
    subdistrict,
    district,
    province,
    postCode,
    googleMapLink,
  } = data;

  const columns = [
    [
      {
        title: "ข้อมูล",
        data: [
          { label: "เบอร์โทรศัพท์", value: phoneNo },
          {
            label: "อีเมล",
            value: email,
          },
        ],
      },
      {
        title: "ที่อยู่สาขา",
        data: [
          { label: "ที่อยู่", value: address },
          { label: "แขวง / ตำบล", value: subdistrict },
          { label: "เขต / อำเภอ", value: district },
          { label: "จังหวัด", value: province },
          { label: "รหัสไปรษณีย์", value: postCode },
          {
            label: "ลิงค์ Google Map",
            key: `${id}-${googleMapLink ?? ""}`,
            value: <TextLink text={googleMapLink} />,
          },
        ],
      },
    ],
  ];

  function openEditDialog() {
    searchParams.set("dialog", "edit");
    setSearchParams(searchParams);
  }

  function onCloseDialog() {
    searchParams.delete("dialog");
    setSearchParams(searchParams, { replace: true });
  }

  const canEditBranch = branches.map(({ id }) => id).includes(id);

  const dialog = searchParams.get("dialog");

  const editBranchDialog = {
    open: dialog === "edit",
    id,
    onClose: onCloseDialog,
    data,
    fetchKeys,
  };

  return (
    <Stack>
      <CardHeader
        title={name}
        action={
          canEditBranch && (
            <Stack direction="row">
              <Tooltip title="แก้ไข">
                <IconButton onClick={openEditDialog}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          )
        }
      />
      <CardContent>
        <DataSection columns={columns} label="" />
      </CardContent>
      <EditBranchDialog {...editBranchDialog} />
    </Stack>
  );
}
