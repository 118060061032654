import {
  Box,
  Breadcrumbs,
  Card,
  CircularProgress,
  Link as MuiLink,
  Typography,
} from "@mui/material";
import { useQuery } from "react-query";

import { Helmet } from "react-helmet-async";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link, Outlet } from "react-router-dom";

import { getDocumentTitle, useRequireParams } from "@/utils";

import { VideoDetail } from "./VideoDetail";
import { getCategory } from "@/services/category";
import { getVideo } from "@/services/video";

export function useCategory(id: string) {
  return useQuery("category", () => getCategory(id));
}

export function useVideo(id: string) {
  return useQuery("video", () => getVideo(id));
}

export function VideoDetailPage() {
  const { id, videoId } = useRequireParams(["id", "videoId"]);
  const { data: categoryData } = useCategory(id);
  const { data: videoData, isFetching } = useVideo(videoId);

  const isLoaded = !isFetching && typeof videoData !== "undefined";
  const categoryId = categoryData?.id ?? 1;
  const title = videoData?.name ?? "";

  return (
    <Box>
      <Helmet>
        <title>{getDocumentTitle(title)}</title>
      </Helmet>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ mb: 2 }}
      >
        <MuiLink component={Link} color="text.secondary" to="/categories">
          ประเภทกิจกรรม
        </MuiLink>
        <MuiLink
          component={Link}
          color="text.secondary"
          to={`/categories/${categoryId}`}
        >
          รายละเอียดประเภทกิจกรรม
        </MuiLink>
        <Typography color="text.primary">รายละเอียดวิดีโอ</Typography>
      </Breadcrumbs>
      <Card sx={{ mb: 3 }}>
        {isLoaded ? (
          <VideoDetail data={videoData} />
        ) : (
          <Box display="grid" sx={{ placeItems: "center" }} height={436}>
            <CircularProgress disableShrink />
          </Box>
        )}
      </Card>
      <Outlet />
    </Box>
  );
}
