import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  formatDate,
  formatNumber,
  formatProfile,
  formatTimeRange,
} from "@/formatter";
import { calculateColumnWidth, getScheduleStatus } from "@/utils";
import { ScheduleStatus } from "@/models";

import { ScheduleStatusLabel } from "../../components/ScheduleStatusLabel";

import type { Schedule } from "@/models";

const columnsWidth = [300, 276, 162, 100, 150, 96].map((width) =>
  calculateColumnWidth(width)
);

export function ScheduleTableRowHeader() {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]}>วันและเวลาที่เริ่ม</TableCell>
      <TableCell width={columnsWidth[1]}>ผู้สอน</TableCell>
      <TableCell width={columnsWidth[2]} align="center">
        สถานที่
      </TableCell>
      <TableCell width={columnsWidth[3]} align="center">
        ผู้เข้าร่วม
      </TableCell>
      <TableCell width={columnsWidth[4]} align="center">
        สถานะ
      </TableCell>
      <TableCell width={columnsWidth[5]} />
    </TableRow>
  );
}

export type ScheduleTableRowProps = { data: Schedule };

export function ScheduleTableRow({ data }: ScheduleTableRowProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { id, startedAt, endedAt, capacity, location, numberOfBookings } = data;
  const durationInMinute = endedAt.diff(startedAt, ["minutes"]).minutes;

  const scheduleStart = formatDate(startedAt);
  const scheduleTimeRange = `${formatTimeRange(
    startedAt,
    endedAt
  )} (${durationInMinute} นาที)`;

  const numberOfParticipants = formatNumber(numberOfBookings);
  const navigate = useNavigate();

  const status = getScheduleStatus(data);

  const showEdit = [ScheduleStatus.Active, ScheduleStatus.Upcoming].includes(
    status
  )
    ? "visible"
    : "hidden";

  const onClickEdit: JSX.IntrinsicElements["button"]["onClick"] = (event) => {
    event.stopPropagation();
    searchParams.set("dialog", "schedule-edit");
    searchParams.set("id", id.toString());
    setSearchParams(searchParams);
  };

  const onClickRemove: JSX.IntrinsicElements["button"]["onClick"] = (event) => {
    event.stopPropagation();
    searchParams.set("dialog", "schedule-remove");
    searchParams.set("id", id.toString());
    setSearchParams(searchParams);
  };

  const onClickRow: JSX.IntrinsicElements["tr"]["onClick"] = () => {
    navigate(`schedules/${data.id}`);
  };

  return (
    <TableRow hover role="link" onClick={onClickRow}>
      <TableCell>
        {scheduleStart} {scheduleTimeRange}
      </TableCell>
      <TableCell>
        <Box sx={{ pl: 0 }}>
          {data.staffs?.map((item) => (
            <li key={item.id}>{formatProfile(item.profile)}</li>
          ))}
        </Box>
      </TableCell>
      <TableCell align="center">{location ?? ""}</TableCell>
      <TableCell align="center">
        {numberOfParticipants}/{capacity}
      </TableCell>
      <TableCell align="center">
        <ScheduleStatusLabel status={status} variant="subtitle2" />
      </TableCell>
      <TableCell sx={{ px: 0 }}>
        <Stack direction="row" gap={1}>
          <Tooltip title="แก้ไข" sx={{ visibility: showEdit }}>
            <IconButton onClick={onClickEdit}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="ลบ">
            <IconButton onClick={onClickRemove}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </TableCell>
    </TableRow>
  );
}
