import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";

import {
  ProductEditorDialog,
  useProductEditorForm,
} from "@/features/product/components/ProductEditorDialog";
import { createProduct } from "@/services/product";
import { configs } from "@/configs";
import { refetchQueries } from "@/utils";

export function AddProductDialog({
  open: isOpen,
  onClose,
  fetchKeys = [],
}: {
  open: boolean;
  onClose: () => void;
  fetchKeys?: string[];
}) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { control, handleSubmit, reset } = useProductEditorForm();

  const { mutate: add, isLoading } = useMutation(createProduct, {
    onSuccess: async () => {
      enqueueSnackbar(`เพิ่มสินค้าสำเร็จ`, { variant: "success" });
      await refetchQueries({ queryClient, fetchKeys });
      close();
    },
    onError: (error) => {
      console.error(error);
      enqueueSnackbar(configs.unknownErrorMessage, { variant: "error" });
      close();
    },
  });

  function close() {
    reset();
    onClose();
  }

  const onSubmit = handleSubmit((form) => {
    const data = {
      name: form.name,
      price: form.price,
      description: form.description,
    };
    add(data);
  });
  const title = "เพิ่มสินค้า";

  return (
    <ProductEditorDialog
      open={isOpen}
      title={title}
      onClose={close}
      onSubmit={onSubmit}
      loading={isLoading}
      control={control}
    />
  );
}
