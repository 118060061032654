import {
  Box,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useSearchParams } from "react-router-dom";

import { MediaViewer } from "@/components/MediaViewer";
import { DataSection } from "@/components/DataSection";
import { formatFacilityType, formatNumber, formatTime } from "@/formatter";
import { mapOptional } from "@/utils";

import { RemoveFacilityDialog } from "../../components/RemoveFacilityDialog";
import { EditFacilityDialog } from "../../components/EditFacilityDialog";

import type { Facility } from "@/models";
import type { RemoveFacilityDialogProps } from "../../components/RemoveFacilityDialog";
import type { EditFacilityDialogProps } from "../../components/EditFacilityDialog";

type FacilityDetailProps = {
  data: Facility;
  fetchKeys?: string[];
};

export function FacilityDetail({ data, fetchKeys }: FacilityDetailProps) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { id, name, thumbnailUrl } = data;

  const mediaList = thumbnailUrl ? [{ url: thumbnailUrl }] : [];

  const dialog = searchParams.get("dialog");

  function onCloseDialog() {
    searchParams.delete("dialog");
    setSearchParams(searchParams, { replace: true });
  }

  const openEditDialog = () => {
    searchParams.set("dialog", "edit");
    setSearchParams(searchParams);
  };

  const openRemoveDialog = () => {
    searchParams.set("dialog", "remove");
    setSearchParams(searchParams);
  };

  const editDialog: EditFacilityDialogProps = {
    open: dialog === "edit",
    onClose: onCloseDialog,
    data,
    fetchKeys,
  };

  const removeDialog: RemoveFacilityDialogProps = {
    open: dialog === "remove",
    onClose(reason) {
      onCloseDialog();
      if (reason === "success") {
        navigate("..");
      }
    },
    id: id.toString(),
    fetchKeys,
  };

  const generateFeeDescription = (fee: number | null, description: string) => {
    const priceText = mapOptional(fee, formatNumber) ?? "-";
    return `${priceText} บาท ${description}`;
  };

  const openTime = formatTime(data.openedAt);
  const endTime = formatTime(data.closedAt);

  const columns1 = [
    [
      {
        title: "รายละเอียดเพิ่มเติม",
        data: [
          {
            label: "ประเภทสิ่งอำนวยความสะดวก",
            value: formatFacilityType(data.type),
          },
          { label: "จำนวนผู้ใช้บริการต่อรอบ (คน)", value: data.capacity },
          { label: "รายละเอียดเพิ่มเติม", value: data.description },
          { label: "เวลาเปิด", value: `${openTime} น.` },
          { label: "เวลาปิด", value: `${endTime} น.` },
        ],
      },
    ],
  ];

  const columns2 = [
    [
      {
        title: "ค่าบริการเพิ่มเติม",
        data: [
          {
            key: id.toString(),
            label: "คิดค่าบริการ",
            value: (
              <Stack gap={2}>
                <Typography>
                  {generateFeeDescription(data.memberFee, "สำหรับสมาชิก/ครั้ง")}
                </Typography>
                <Typography>
                  {generateFeeDescription(
                    data.guestFee,
                    "สำหรับบุคคลภายนอก/ครั้ง"
                  )}
                </Typography>
              </Stack>
            ),
          },
        ],
      },
    ],
  ];

  return (
    <>
      <CardHeader
        title={name}
        action={
          <Stack direction="row">
            <Tooltip title="แก้ไข">
              <IconButton onClick={openEditDialog}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="ลบ">
              <IconButton onClick={openRemoveDialog}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        }
      />
      <CardContent sx={{ py: 0 }}>
        <Stack gap={3}>
          <MediaViewer
            mediaList={mediaList}
            imageWidth={459}
            imageHeight={258}
          />
          <Box
            display="grid"
            gridTemplateColumns="1fr 1fr"
            gap={3}
            alignItems="baseline"
          >
            <DataSection columns={columns1} />
            <DataSection
              columns={columns2}
              boxProps={{ gridTemplateColumns: "80px 1fr" }}
            />
          </Box>
        </Stack>
      </CardContent>
      <EditFacilityDialog {...editDialog} />
      <RemoveFacilityDialog {...removeDialog} />
    </>
  );
}
