import { useSnackbar } from "notistack";
import { useMutation } from "react-query";

import { ConfirmDialog } from "@/components/ConfirmDialog";
import { deleteProductMembership } from "@/services/product";
import { configs } from "@/configs";

export type RemoveMembershipDialogProps = {
  open: boolean;
  onClose: (reason: "success" | "error" | "cancel") => void;
  id: string;
};

export function RemoveMembershipDialog({
  open: isOpen,
  onClose,
  id,
}: RemoveMembershipDialogProps) {
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: remove, isLoading } = useMutation(deleteProductMembership, {
    onSuccess: () => {
      enqueueSnackbar("ลบ สำเร็จ", {
        variant: "success",
      });
      onClose("success");
    },
    onError: (error) => {
      console.error(error);
      enqueueSnackbar(configs.unknownErrorMessage, { variant: "error" });
      onClose("error");
    },
  });

  const onConfirm = () => remove(id);

  return (
    <ConfirmDialog
      maxWidth="xs"
      open={isOpen}
      onClose={() => onClose("cancel")}
      onConfirm={onConfirm}
      title="คุณต้องการลบหรือไม่"
      confirmMessage="ลบ"
      loading={isLoading}
    />
  );
}
