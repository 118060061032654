import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";

import { refetchQueries } from "@/utils";
import { configs } from "@/configs";
import { createPermissionGroup } from "@/services/permissionGroup";

import {
  PermissionGroupEditorDialog,
  usePermissionGroupEditorForm,
} from "../../components/PermissionGroupEditorDialog";

import type { AxiosErrorWithData } from "@/client/api";

type AddPermissionGroupDialogProps = {
  open: boolean;
  onClose: () => void;
  fetchKeys?: string[];
};

export function AddPermissionGroupDialog({
  open: isOpen,
  onClose,
  fetchKeys = [],
}: AddPermissionGroupDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, reset } = usePermissionGroupEditorForm();
  const queryClient = useQueryClient();

  const { mutate: add, isLoading } = useMutation(createPermissionGroup, {
    onSuccess: async () => {
      enqueueSnackbar("เพิ่มสิทธิ์การใช้งานสำเร็จ", { variant: "success" });
      await refetchQueries({ queryClient, fetchKeys });
      close();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(
        error.response?.data.message ?? configs.unknownErrorMessage,
        { variant: "error" }
      );
    },
  });

  const title = "เพิ่มสิทธิ์การใช้งาน";

  function close() {
    reset();
    onClose();
  }

  const onSubmit = handleSubmit((form) => {
    add({
      name: form.name,
      type: form.type,
    });
  });

  return (
    <PermissionGroupEditorDialog
      open={isOpen}
      title={title}
      onClose={close}
      onSubmit={onSubmit}
      loading={isLoading}
      control={control}
    />
  );
}
