import * as yup from "yup";
import { useSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";

import { MemberAutocomplete } from "@/components/MemberAutocomplete";
import { FormConfirmDialog } from "@/components/FormConfirmDialog";
import { useRequireParams, refetchQueries } from "@/utils";
import { configs } from "@/configs";
import { createClassBooking } from "@/services/class";
import { FormMemberProfileAutocomplete } from "@/components/FormMemberProfileAutocomplete";

import type { AxiosErrorWithData } from "@/client/api";
import type { MergeFormMemberProfileControl } from "@/components/FormMemberProfileAutocomplete";
import type { InferType } from "yup";

const QUERY = "memberClassBooking";

export type AddBookingDialogState = InferType<typeof schema>;

export function AddBookingDialog({
  open: isOpen,
  onClose,
  fetchKeys = [],
}: {
  open: boolean;
  onClose: () => void;
  fetchKeys?: string[];
}) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { id: classId, scheduleId } = useRequireParams(["id", "scheduleId"]);
  const { control, handleSubmit, reset } = useForm({
    resolver,
    defaultValues: {} as AddBookingDialogState,
  });

  const { mutate: add, isLoading: loadingSubmit } = useMutation(
    createClassBooking,
    {
      onSuccess: async () => {
        enqueueSnackbar("เพิ่มการจองสำเร็จ", { variant: "success" });
        await refetchQueries({ queryClient, fetchKeys });
        close();
      },
      onError: (error: AxiosErrorWithData) => {
        console.error(error);
        enqueueSnackbar(
          error.response?.data.message ?? configs.unknownErrorMessage,
          { variant: "error" }
        );
      },
    }
  );

  function close() {
    onClose();
    reset();
  }

  const onSubmit = handleSubmit((form) => {
    add({ classId, scheduleId, memberId: form.member.id });
  });
  const title = "เพิ่มการจอง";

  return (
    <FormConfirmDialog
      control={control}
      title={title}
      loading={loadingSubmit}
      open={isOpen}
      onClose={close}
      onConfirm={onSubmit}
      confirmMessage="ยืนยัน"
    >
      <FormMemberProfileAutocomplete
        control={control as MergeFormMemberProfileControl}
        queryKey={QUERY}
      />
    </FormConfirmDialog>
  );
}

const schema = yup.object({
  member: MemberAutocomplete.schema
    .label("หมายเลขสมาชิก ชื่อ หรือเบอร์โทรศัพท์")
    .required(),
});

const resolver = yupResolver(schema);
