import { IconButton, Stack, TableCell, TableRow, Tooltip } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import { calculateColumnWidth } from "@/utils";
import { formatNumber, formatPermissionGroupType } from "@/formatter";

import type { PermissionGroup } from "@/models";
import type { MouseEventHandler } from "react";

const columnsWidth = [240, 496, 496, 112].map((width) =>
  calculateColumnWidth(width)
);

export function PermissionGroupTableRowHeader() {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]}>ชื่อสิทธิ์การใช้งานประตู</TableCell>
      <TableCell width={columnsWidth[1]}>สิทธิ์ผู้ใช้</TableCell>
      <TableCell width={columnsWidth[2]}>จำนวนคนใช้สิทธิ์</TableCell>
      <TableCell width={columnsWidth[3]} />
    </TableRow>
  );
}

export type PermissionGroupTableRowProps = { data: PermissionGroup };

export function PermissionGroupTableRow({
  data,
}: PermissionGroupTableRowProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const { id, name, type, usedCount, isDefault } = data;

  const openDialog = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    dialogName: string
  ) => {
    event.stopPropagation();
    searchParams.set("dialog", dialogName);
    searchParams.set("id", id.toString());
    setSearchParams(searchParams);
  };

  const onClickEdit: MouseEventHandler<HTMLButtonElement> = (event) =>
    openDialog(event, "permission-group-edit");

  const onClickRemove: MouseEventHandler<HTMLButtonElement> = (event) =>
    openDialog(event, "permission-group-remove");

  return (
    <TableRow hover>
      <TableCell>{name}</TableCell>
      <TableCell>{formatPermissionGroupType(type)}</TableCell>
      <TableCell>{formatNumber(usedCount)}</TableCell>
      <TableCell>
        <Stack direction="row">
          <Tooltip title="แก้ไข">
            <IconButton onClick={onClickEdit}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          {!isDefault && (
            <Tooltip title="ลบ">
              <IconButton onClick={onClickRemove}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      </TableCell>
    </TableRow>
  );
}
