import * as yup from "yup";

declare module "yup" {
  interface StringSchema {
    time(): this;
  }
}

yup.addMethod(yup.StringSchema, "time", function () {
  return this.test({
    name: "time",
    message: "text must be in format 'HH:mm'",
    test: (value) => /^([0-2]?[0-9]):[0-5]\d$/.test(value),
  });
});
