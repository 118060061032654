import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import { Stack, Typography } from "@mui/material";

import { FormTextField } from "@/components/FormTextField";
import { FormConfirmDialog } from "@/components/FormConfirmDialog";
import { getApiErrorMessage, refetchQueries } from "@/utils";
import { formatTrainingStatus, formatTrainingStatusColor } from "@/formatter";
import { changeTrainingStatus } from "@/services/training";
import { TrainingStatus } from "@/models";

import type { InferType } from "yup";
import type { AxiosErrorWithData } from "@/client/api";

type ChangeTrainingStatusEditorState = InferType<typeof schema>;

type ChangeTrainingStatusDialogProps = {
  trainingId: number;
  status: TrainingStatus;
  open: boolean;
  onClose: () => void;
  fetchKeys?: string[];
};

export function ChangeTrainingStatusDialog({
  trainingId,
  status,
  open: isOpen,
  onClose,
  fetchKeys = [],
}: ChangeTrainingStatusDialogProps) {
  const { control, handleSubmit, reset } = useChangeTrainingStatusEditorForm();

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate: changeStatus, isLoading } = useMutation(
    changeTrainingStatus,
    {
      onSuccess: async () => {
        enqueueSnackbar("เปลี่ยนสถานะสำเร็จ", {
          variant: "success",
        });
        await refetchQueries({ queryClient, fetchKeys });
        close();
      },
      onError: (error: AxiosErrorWithData) => {
        console.error(error);
        enqueueSnackbar(getApiErrorMessage(error), { variant: "error" });
      },
    }
  );

  function close() {
    onClose();
    reset();
  }

  const onSubmit = handleSubmit(({ note }) => {
    changeStatus({
      id: trainingId,
      note,
      isSuccess: status === TrainingStatus.Success,
    });
  });

  const title = "บันทึกสรุปผลการเทรน";

  return (
    <FormConfirmDialog
      maxWidth="md"
      control={control}
      title={title}
      loading={isLoading}
      open={isOpen}
      onClose={close}
      onConfirm={onSubmit}
    >
      <Stack gap={2.5}>
        <Stack direction="row" gap={1}>
          <Typography variant="subtitle2" color="text.secondary">
            ผลการเทรนของสมาชิก :
          </Typography>
          <Typography
            variant="subtitle2"
            color={formatTrainingStatusColor(status)}
          >
            {formatTrainingStatus(status)}
          </Typography>
        </Stack>
        <FormTextField
          label="บันทึกผลการเทรน"
          name="note"
          control={control}
          multiline
          rows={4}
          fullWidth
          required
        />
      </Stack>
    </FormConfirmDialog>
  );
}

function useChangeTrainingStatusEditorForm() {
  return useForm({
    resolver,
    defaultValues,
  });
}

const schema = yup.object({
  note: yup.string().label("บันทึกผลการเทรน").required(),
});

const resolver = yupResolver(schema);

const defaultValues = {
  note: "",
} as ChangeTrainingStatusEditorState;
