import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import { TextEllipsis } from "@/components/TextEllipsis";
import { calculateColumnWidth } from "@/utils";
import {
  formatDateTime,
  formatPrice,
  formatReceiptStatus,
  formatReceiptStatusColor,
} from "@/formatter";
import { fromISO } from "@/lib/dateTime";
import { ReceiptStatus } from "@/models";

import type { Receipt } from "@/models";
import type { UseMutateFunction } from "react-query";
import type { AxiosErrorWithData } from "@/client/api";

const columnsWidth = [150, 120, 326, 212, 140, 80, 56].map((width) =>
  calculateColumnWidth(width)
);

const MAX_PRODUCT_ITEMS = 5;

export function TransactionTableHeaderRow({
  isShowMember = false,
}: {
  isShowMember?: boolean;
}) {
  const name = isShowMember ? "ชื่อสมาชิก" : "ชื่อพนักงานขาย";

  return (
    <TableRow>
      <TableCell width={columnsWidth[0]}>วันที่และเวลา</TableCell>
      <TableCell width={columnsWidth[1]}>เลขที่ใบเสร็จ</TableCell>
      <TableCell width={columnsWidth[2]}>รายการสินค้า</TableCell>
      <TableCell width={columnsWidth[3]}>{name}</TableCell>
      <TableCell width={columnsWidth[4]}>ราคาสุทธิ</TableCell>
      <TableCell width={columnsWidth[5]}>สถานะ</TableCell>
      <TableCell width={columnsWidth[6]}></TableCell>
    </TableRow>
  );
}

export type TransactionTableTableRowProps = {
  data: Receipt;
  generatePDF: UseMutateFunction<string, AxiosErrorWithData, string | number>;
  isShowMember?: boolean;
};

export function TransactionTableRow({
  data,
  generatePDF,
  isShowMember = false,
}: TransactionTableTableRowProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showMore, setShowMore] = useState(false);
  const isMenuOpen = Boolean(anchorEl);
  const [searchParams, setSearchParams] = useSearchParams();

  const { createdAt, status, invoiceNo, receiptItems, total, staffs, member } =
    data;

  const items = receiptItems.map((item) => ({ id: item.id, name: item.title }));

  const handleMenuClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClickPrint = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
    generatePDF(data.id);
  };

  const handleClickCancel = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
    searchParams.set("dialog", "receipt-cancel");
    searchParams.set("cancelReceiptId", data.id.toString());
    data.receiptItems.filter(
      (item) =>
        item.product.productMembershipsId ||
        item.product.productPersonalTrainingsId
    ).length > 0 && searchParams.set("showMsg", "true");
    setSearchParams(searchParams);
  };

  const renderMenu = (
    <Menu
      sx={{ mt: 4 }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleClickPrint}>ดาวน์โหลด</MenuItem>
      {status !== ReceiptStatus.Cancelled && (
        <MenuItem sx={{ color: "error.main" }} onClick={handleClickCancel}>
          ยกเลิกใบเสร็จ
        </MenuItem>
      )}
    </Menu>
  );

  const staffName = `${staffs.profile.firstName} ${staffs.profile.lastName}`;
  const memberName = member
    ? `${member.profile.firstName} ${member.profile.lastName}`
    : "-";

  const displayName = isShowMember ? memberName : staffName;

  return (
    <TableRow>
      <TableCell>{formatDateTime(fromISO(createdAt.toString()))}</TableCell>
      <TableCell>{invoiceNo}</TableCell>
      <TableCell>
        <Box sx={{ pl: 0 }}>
          <>
            {items.map(
              (item, index) =>
                ((!showMore && index < MAX_PRODUCT_ITEMS) || showMore) && (
                  <li key={item.id}>{item.name}</li>
                )
            )}
            {items.length > MAX_PRODUCT_ITEMS && !showMore && (
              <Button size="small" onClick={() => setShowMore(true)}>
                ดูเพิ่มเติม
              </Button>
            )}
          </>
        </Box>
      </TableCell>
      <TableCell>
        <TextEllipsis variant="body2" line={1}>
          {displayName}
        </TextEllipsis>
      </TableCell>
      <TableCell>
        <Typography
          variant="body2"
          fontWeight={500}
          color={
            status === ReceiptStatus.Cancelled
              ? "text.disabled"
              : "text.primary"
          }
        >
          {formatPrice(total)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          variant="body2"
          fontWeight={500}
          sx={{ color: formatReceiptStatusColor(status) }}
        >
          {formatReceiptStatus(status)}
        </Typography>
      </TableCell>
      <TableCell>
        <Stack direction="row" gap={1}>
          <Tooltip title="แก้ไข">
            <IconButton onClick={handleMenuOpen}>
              <MoreHorizIcon />
            </IconButton>
          </Tooltip>
          {renderMenu}
        </Stack>
      </TableCell>
    </TableRow>
  );
}
