import { Typography } from "@mui/material";

import { StaffEditor } from "./StaffEditor";
import { FormConfirmDialog } from "@/components/FormConfirmDialog";
import { ConfirmDialog } from "@/components/ConfirmDialog";

import type { Control } from "react-hook-form";
import type { StaffEditorState } from "./StaffEditor";

export function StaffEditorDialog({
  open,
  title,
  onClose,
  onSubmit,
  closeConfirmEdit,
  loading,
  control,
  isTrainerChange = false,
  isSaleChange = false,
  openConfirmEdit = false,
}: {
  open: boolean;
  title: string;
  onClose: () => void;
  onSubmit: () => void | PromiseLike<void>;
  closeConfirmEdit?: () => void;
  loading: boolean;
  control: Control<StaffEditorState>;
  isTrainerChange?: boolean;
  isSaleChange?: boolean;
  openConfirmEdit?: boolean;
}) {
  return (
    <FormConfirmDialog
      control={control}
      title={title}
      loading={loading}
      open={open}
      onClose={onClose}
      onConfirm={onSubmit}
    >
      <StaffEditor control={control} />
      <ConfirmDialog
        maxWidth="xs"
        open={openConfirmEdit}
        onClose={closeConfirmEdit}
        onConfirm={onSubmit}
        title={"ยืนยันการแก้ไขข้อมูล"}
        confirmMessage={"ยืนยัน"}
        loading={loading}
      >
        {isTrainerChange && (
          <Typography variant="body2" color="primary.main" mb={2}>
            เนื่องจากคุณมีการยกเลิก หน้าที่เทรนเนอร์
            พนักงานคนนี้จะไม่สามารถเข้าถึงข้อมูลส่วนนั้นได้อีก
          </Typography>
        )}
        {isSaleChange && (
          <Typography variant="body2" color="primary.main">
            เนื่องจากคุณมีการยกเลิก หน้าที่พนักงานขาย
            พนักงานคนนี้จะไม่สามารถเข้าถึงข้อมูลส่วนนั้นได้อีก
          </Typography>
        )}
      </ConfirmDialog>
    </FormConfirmDialog>
  );
}
