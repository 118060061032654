import { Stack } from "@mui/material";
import { Helmet } from "react-helmet-async";

import { getDocumentTitle, useRequireParams } from "@/utils";

import { Breadcrumbs } from "@/components/Breadcrumbs";

import { FollowUpDetail } from "./FollowUpDetail";

export function FollowUpDetailPage() {
  const { id, followUpId } = useRequireParams(["id", "followUpId"]);

  const title = "รายละเอียดการติดตามผลการเทรน";
  const breadcrumbs = {
    items: [
      {
        label: "เป้าหมายการเทรน",
        to: "/trainings",
      },
      { label: "รายละเอียดเป้าหมายการเทรน", to: `/trainings/${id}` },
      { label: title, to: `/trainings/${id}/follow-ups/${followUpId}` },
    ],
  };

  return (
    <Stack gap={4}>
      <Helmet>
        <title>{getDocumentTitle(title)}</title>
      </Helmet>
      <Breadcrumbs {...breadcrumbs} />
      <FollowUpDetail id={followUpId} />
    </Stack>
  );
}
