import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  IconButton,
  Tooltip,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";
import Webcam from "react-webcam";

import { configs } from "@/configs";
import { updateAvatar } from "@/services/profile";

export function TakePhotoDialog({
  open: isOpen,
  onClose,
  id,
  fetchKey,
}: {
  open: boolean;
  onClose: (reason: "success" | "error" | "cancel") => void;
  id: number;
  fetchKey: string;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: update, isLoading } = useMutation(updateAvatar);
  const queryClient = useQueryClient();

  const webcamRef = useRef<Webcam>(null);
  const [avatar, setAvatar] = useState("");

  useEffect(() => {
    if (!isOpen) {
      setAvatar("");
      return;
    }
  }, [isOpen]);

  function removePhoto() {
    setAvatar("");
  }

  const capture = useCallback(() => {
    const src = webcamRef.current?.getScreenshot();
    setAvatar(src ?? "");
  }, [webcamRef, setAvatar]);

  const input = {
    id,
    data: { avatar },
  };
  const onConfirm = () => {
    update(input, {
      onSuccess: async () => {
        enqueueSnackbar("เพิ่มรูปถ่ายสำเร็จ", {
          variant: "success",
        });
        await queryClient.refetchQueries(fetchKey);
        onClose("success");
      },
      onError: (error) => {
        console.error(error);
        enqueueSnackbar(configs.unknownErrorMessage, { variant: "error" });
        onClose("error");
      },
    });
  };

  const videoConstraints = {
    width: 500,
    height: 500,
    facingMode: "user",
  };

  const buttonStyle = {
    minWidth: 54,
    width: 54,
    height: 54,
    borderRadius: 64,
    position: "absolute",
    bottom: 30,
  };

  const title = "เพิ่มรูปถ่าย";

  return (
    <Dialog open={isOpen} onClose={() => onClose("cancel")}>
      <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
        {title}
        <Tooltip title="ปิด">
          <IconButton onClick={() => onClose("cancel")} sx={{ ml: "auto" }}>
            <Close />
          </IconButton>
        </Tooltip>
      </DialogTitle>
      <DialogContent sx={{ overflow: "hidden" }}>
        <Stack justifyContent="center" alignItems="center" gap={2.5}>
          {avatar ? <PhotoDisplay /> : <WebcamDisplay />}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" gap={2.5}>
          <Button
            color="inherit"
            variant="contained"
            onClick={() => onClose("cancel")}
          >
            ยกเลิก
          </Button>
          <LoadingButton
            loading={isLoading}
            color="primary"
            variant="contained"
            onClick={onConfirm}
          >
            บันทึก
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );

  function WebcamDisplay() {
    return (
      <>
        <Box className="camera-overlay" />
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          screenshotQuality={1}
          videoConstraints={videoConstraints}
          height={500}
          onUserMediaError={(error) =>
            enqueueSnackbar(String(error) ?? configs.unknownErrorMessage, {
              variant: "error",
            })
          }
        />
        <Button
          title="ถ่ายภาพ"
          onClick={capture}
          color="primary"
          variant="contained"
          sx={buttonStyle}
        >
          <CameraAltIcon />
        </Button>
      </>
    );
  }
  function PhotoDisplay() {
    return (
      <>
        <Box component="img" alt="camera-image" src={avatar} height={500} />
        <Button
          title="ลบ"
          onClick={removePhoto}
          color="primary"
          variant="contained"
          sx={buttonStyle}
        >
          <DeleteIcon />
        </Button>
      </>
    );
  }
}
