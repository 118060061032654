import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";

import { createCategory } from "@/services/category";

import { useCategoryEditorForm } from "./CategoryEditor";
import { CategoryEditorDialog } from "./CategoryEditorDialog";
import { Category } from "@/models";
import { configs } from "@/configs";

type AddCategoryDialogProps = {
  open: boolean;
  onClose: () => void;
};

type CategoryInput = Omit<Category, "id">;

export function AddCategoryDialog({
  open: isOpen,
  onClose,
}: AddCategoryDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { control, reset, handleSubmit } = useCategoryEditorForm();
  const { mutate: add, isLoading } = useMutation(createCategory, {
    onSuccess: async () => {
      enqueueSnackbar("เพิ่มประเภทกิจกรรม สำเร็จ", { variant: "success" });
      await queryClient.refetchQueries("categories");
      close();
    },
    onError: (error) => {
      console.error(error);
      enqueueSnackbar(configs.unknownErrorMessage, {
        variant: "error",
      });
    },
  });
  const queryClient = useQueryClient();

  function close() {
    reset();
    onClose();
  }

  const onSubmit = handleSubmit((form) => {
    const data: CategoryInput = {
      name: form.name,
      description: form.description ?? "",
      thumbnailUrl: form.images.map((image) => image.url)[0],
    };

    add(data);
  });

  const title = "เพิ่มประเภทกิจกรรม";

  return (
    <CategoryEditorDialog
      open={isOpen}
      title={title}
      onClose={close}
      onSubmit={onSubmit}
      loading={isLoading}
      control={control}
    />
  );
}
