import { IconButton, Stack, TableCell, TableRow, Tooltip } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import LinkIcon from "@mui/icons-material/Link";

import { TextEllipsis } from "@/components/TextEllipsis";
import { calculateColumnWidth } from "@/utils";
import { Video } from "@/models";

const columnsWidth = [342, 510, 136, 96].map((item) =>
  calculateColumnWidth(item)
);

export function VideoTableRowHeader() {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]}>ชื่อ</TableCell>
      <TableCell width={columnsWidth[1]}>รายละเอียด</TableCell>
      <TableCell width={columnsWidth[2]} align="center">
        ลิงค์ Youtube
      </TableCell>
      <TableCell width={columnsWidth[3]} />
    </TableRow>
  );
}

type DataVideo = {
  data: Video;
};

export function VideoTableRow({ data }: DataVideo) {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const { id, name, description, youtubeUrl } = data;
  const idString = id.toString();

  const onClickEdit: JSX.IntrinsicElements["button"]["onClick"] = (event) => {
    event.stopPropagation();
    searchParams.set("dialog", "video-edit");
    searchParams.set("id", idString);
    setSearchParams(searchParams);
  };

  const onClickRemove: JSX.IntrinsicElements["button"]["onClick"] = (event) => {
    event.stopPropagation();
    searchParams.set("dialog", "video-remove");
    searchParams.set("id", idString);
    setSearchParams(searchParams);
  };

  const onClickTableRow: JSX.IntrinsicElements["tr"]["onClick"] = () => {
    navigate(`video/${idString}`);
  };

  const onClickYoutubeLink: JSX.IntrinsicElements["a"]["onClick"] = (event) =>
    event.stopPropagation();

  return (
    <TableRow hover tabIndex={-1} role="link" onClick={onClickTableRow}>
      <TableCell>
        <TextEllipsis variant="body2">{name}</TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis variant="body2">{description}</TextEllipsis>
      </TableCell>
      <TableCell align="center">
        <Tooltip title="ลิงค์">
          <IconButton
            component="a"
            href={youtubeUrl}
            target="_blank"
            onClick={onClickYoutubeLink}
          >
            <LinkIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
      <TableCell sx={{ px: 0 }}>
        <Stack direction="row" gap={1}>
          <Tooltip title="แก้ไข">
            <IconButton onClick={onClickEdit}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="ลบ">
            <IconButton onClick={onClickRemove}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </TableCell>
    </TableRow>
  );
}
