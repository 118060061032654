import { DateTime } from "luxon";

import { configs } from "@/configs";
import { formatDate, formatDateTime, formatTime } from "@/formatter";

const zone = configs.timezone;

export function now() {
  return DateTime.now().setZone(zone);
}

export function before15Minutes(date: DateTime) {
  return date.minus({ minutes: 15 }).setZone(zone);
}

export function fromISO(iso: string) {
  return DateTime.fromISO(iso).setZone(zone);
}

export function fromJSDate(date: Date) {
  return DateTime.fromJSDate(date).setZone(zone);
}

export function fromFormat(text: string, format: string) {
  return DateTime.fromFormat(text, format).setZone(zone);
}

// TODO: refactor to transform
export function transformISOToTime(iso: string) {
  return formatTime(fromISO(iso));
}

export function transformISOToDate(iso: string) {
  return formatDate(fromISO(iso));
}

export function transformISOToDateTime(iso: string) {
  return formatDateTime(fromISO(iso));
}
