import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import EditIcon from "@mui/icons-material/Edit";

import { DataSection } from "@/components/DataSection";
import { getFollowUp } from "@/services/followUp";
import { CircularLoading } from "@/components/CircularLoading";
import { formatDate } from "@/formatter";
import { getFollowUpEditSetting, getUpdatedByDetail } from "@/utils";

import { FollowUpChangeLogTimeline } from "./FollowUpChangeLogTimeline";
import { AddFollowUpDialog, EditFollowUpDialog } from "../../components";

import type { FollowUpChangeLog } from "@/models";

const QUERY_KEY = "getFollowUp";

export type FollowUpDetailProps = {
  id: string;
};

export function FollowUpDetail({ id }: FollowUpDetailProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, isFetching } = useQuery(QUERY_KEY, () => getFollowUp(id));

  const updateByDetail = getUpdatedByDetail(data?.updatedBy, data?.updatedAt);

  const onClickEdit = () => handleOpenDialog("follow-up-edit");
  const onClickAdd = () => handleOpenDialog("follow-up-add");

  const handleOpenDialog = (dialog: string) => {
    searchParams.set("dialog", dialog);
    setSearchParams(searchParams, { replace: true });
  };

  if (isFetching || !data)
    return (
      <Card>
        <CircularLoading height={480} />
      </Card>
    );

  const {
    no,
    followedAt,
    remark,
    reason,
    summary,
    updatedAt,
    updatedBy,
    trainingFollowUpChangeLogs,
    memberTraining: training,
    isLastFollowUp,
  } = data;

  const currentItem: FollowUpChangeLog = {
    createdAt: updatedAt,
    updatedBy,
    followedAt,
    remark,
    reason,
  };
  const changeLogs = [currentItem, ...trainingFollowUpChangeLogs];

  const { followedAtColor, isCanFollowUp, isLate, isFollowUpEditable } =
    getFollowUpEditSetting({
      status: training.status,
      endedAt: training.endedAt,
      followedAt,
      isLastFollowUp,
    });

  const columns = [
    [
      {
        data: [
          {
            label: "วันที่",
            value: (
              <Typography variant="body2" color={followedAtColor}>
                {formatDate(followedAt)}
              </Typography>
            ),
            key: `${id} ${followedAt.toISO()}`,
          },
          {
            label: "หมายเหตุ",
            value: remark || "-",
          },
          {
            label: "ผลการติดตาม",
            value: summary || "-",
          },
        ],
      },
    ],
  ];

  const dialog = searchParams.get("dialog");
  const fetchKeys = [QUERY_KEY];

  const onClose = () => {
    searchParams.delete("dialog");
    setSearchParams(searchParams);
  };

  const addFollowUpDialog = {
    open: dialog === "follow-up-add",
    followUpNo: no,
    trainingId: training.id,
    startedAt: followedAt,
    endedAt: training.endedAt,
    onClose,
    fetchKeys,
    isDetailPage: true,
  };

  const editFollowUpDialog = {
    open: dialog === "follow-up-edit",
    id: id ?? "",
    followUpNo: no,
    startedAt: training.startedAt,
    endedAt: training.endedAt,
    followUp: data,
    onClose,
    fetchKeys,
  };

  return (
    <Stack gap={3}>
      <Card>
        <CardHeader
          title={
            <Stack
              direction="row"
              justifyContent="space-between"
              gap={3}
              alignItems="center"
            >
              <Typography variant="h6">{`ติดตามผลครั้งที่ ${no}`}</Typography>
              <Stack direction="row" gap={2} alignItems="center">
                <Typography variant="body2" color="text.secondary">
                  {updateByDetail}
                </Typography>
                {isFollowUpEditable && isLastFollowUp && (
                  <>
                    {!isLate && (
                      <Tooltip title="แก้ไข">
                        <IconButton onClick={onClickEdit}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    {isCanFollowUp && (
                      <Button variant="contained" onClick={onClickAdd}>
                        ติดตามผล
                      </Button>
                    )}
                  </>
                )}
              </Stack>
            </Stack>
          }
        />
        <CardContent>
          <DataSection columns={columns} />
        </CardContent>
      </Card>
      <FollowUpChangeLogTimeline changeLogs={changeLogs} />
      <AddFollowUpDialog {...addFollowUpDialog} />
      <EditFollowUpDialog {...editFollowUpDialog} />
    </Stack>
  );
}
