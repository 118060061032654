import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Stack,
  Tab,
  Tooltip,
  Typography,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import { useQuery } from "react-query";
import EditIcon from "@mui/icons-material/Edit";

import { DataSection } from "@/components/DataSection";
import { getTraining } from "@/services/training";
import { CircularLoading } from "@/components/CircularLoading";
import { TrainingStatus } from "@/models";
import {
  formatDate,
  formatDateTime,
  formatProfile,
  formatTrainingGoal,
} from "@/formatter";
import { now } from "@/lib/dateTime";
import { getUpdatedByDetail } from "@/utils";

import { TrainingStatusSelect } from "./TrainingStatusSelect";
import { TrainingStatusItem } from "./TrainingStatusItem";
import { TrainingChangeLogList } from "./TrainingChangeLogList";
import { FollowUpList } from "./FollowUpList";
import { EditTrainingDialog } from "../../components";

import type { Profile, Training } from "@/models";

const QUERY_KEY = "getTraining";

enum TrainingTab {
  FollowUp = "followUp",
  History = "history",
}

export type TrainingDetailProps = {
  id: string;
};

export function TrainingDetail({ id }: TrainingDetailProps) {
  const [tab, setTab] = useState(TrainingTab.FollowUp);

  const { data, isFetching } = useQuery(QUERY_KEY, () => getTraining(id));

  return isFetching || !data ? (
    <Card>
      <CircularLoading height={480} />
    </Card>
  ) : (
    <Stack gap={3}>
      <TrainerDetailCard data={data} fetchKeys={[QUERY_KEY]} />
      <TabContext value={tab}>
        <Box>
          <TabList onChange={(_, newValue: TrainingTab) => setTab(newValue)}>
            <Tab label="การติดตามผลการเทรน" value={TrainingTab.FollowUp} />
            <Tab
              label="ประวัติการแก้ไขรายละเอียดการเทรน"
              value={TrainingTab.History}
            />
          </TabList>
        </Box>
        <TabPanel value={TrainingTab.FollowUp}>
          <FollowUpList data={data} fetchKeys={[QUERY_KEY]} />
        </TabPanel>
        <TabPanel value={TrainingTab.History}>
          <TrainingChangeLogList data={data} />
        </TabPanel>
      </TabContext>
    </Stack>
  );
}

type TrainerDetailCardProps = {
  data: Training;
  fetchKeys?: string[];
};

function TrainerDetailCard({ data, fetchKeys }: TrainerDetailCardProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const isInProgress = data.status === TrainingStatus.InProgress;

  const updateByDetail = getUpdatedByDetail(data.updatedBy, data.updatedAt);

  const isExceedEnded = now().startOf("day") > data.endedAt;

  const onClickEdit = () => {
    searchParams.set("dialog", "training-edit");
    setSearchParams(searchParams, { replace: true });
  };

  const { trainer, member } = data;

  const columns = [
    [
      {
        title: "ข้อมูลเทรนเนอร์",
        data: [
          {
            label: "ชื่อเทรนเนอร์",
            value: formatProfile(trainer.profile as Profile),
          },
        ],
      },
    ],
    [
      {
        title: "ข้อมูลสมาชิก",
        data: [
          {
            label: "ชื่อสมาชิก",
            value: `${member.code} ${member.profile.firstName} ${member.profile.lastName}`,
          },
        ],
      },
    ],
  ];

  const columns2 = [
    [
      {
        data: [
          {
            label: "ระยะเวลาการเทรน",
            value: `${formatDate(data.startedAt)} - ${formatDate(
              data.endedAt
            )}`,
          },
          {
            label: "เป้าหมายการเทรน",
            key: data.id.toString(),
            value: (
              <Stack gap={1}>
                {data.goals.map((goal) => (
                  <Typography key={goal} component="li">
                    {formatTrainingGoal(goal)}
                  </Typography>
                ))}
              </Stack>
            ),
          },
        ],
      },
    ],
    [
      {
        data: [
          {
            label: "รายละเอียด",
            value: data.detail ?? "-",
          },
        ],
      },
    ],
  ];

  const dialog = searchParams.get("dialog");

  const onCloseDialog = () => {
    searchParams.delete("dialog");
    setSearchParams(searchParams, { replace: true });
  };

  const editTrainingDialog = {
    open: dialog === "training-edit",
    training: data,
    onClose: onCloseDialog,
    fetchKeys: [QUERY_KEY],
  };

  return (
    <Card>
      <CardHeader
        title={
          <Stack direction="row" justifyContent="space-between">
            {isInProgress ? (
              <TrainingStatusSelect
                data={data}
                fetchKeys={fetchKeys}
                FormControlProps={{ sx: { minWidth: 300 } }}
              />
            ) : (
              <TrainingStatusItem status={data.status} />
            )}
            <Stack direction="row" alignItems="center" gap={2}>
              <Typography color="text.secondary">{updateByDetail}</Typography>
              {isInProgress && !isExceedEnded && (
                <Tooltip title="แก้ไข">
                  <IconButton onClick={onClickEdit}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          </Stack>
        }
      />
      <CardContent>
        <Stack gap={5}>
          <DataSection columns={columns} />
          <Stack gap={2}>
            <Typography variant="subtitle1" color="text.secondary">
              ข้อมูลการเทรน
            </Typography>
            <DataSection columns={columns2} />
          </Stack>
          {!!data.finishedAt && !!data.finishedBy && (
            <>
              <Divider />
              <Stack gap={2}>
                <Stack direction="row" gap={2} justifyContent="space-between">
                  <Typography variant="subtitle1">
                    บันทึกสรุปผลการเทรน
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {formatDateTime(data.finishedAt)} โดย {data.finishedBy}
                  </Typography>
                </Stack>
                <Typography variant="body2">{data.summary || "-"}</Typography>
              </Stack>
            </>
          )}
        </Stack>
      </CardContent>
      <EditTrainingDialog {...editTrainingDialog} />
    </Card>
  );
}
