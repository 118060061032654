export function ScanQRCodeIcon() {
  return (
    <svg
      width={200}
      height={200}
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="No Schedule">
        <g id="Group">
          <path
            id="Vector"
            d="M167.505 84.1085C167.505 84.1085 142.871 98.1904 158.927 142.367C173.185 181.601 122.078 186.932 102.207 178.665C76.4667 167.957 67.9799 130.934 45.2899 137.493C22.5999 144.051 12.205 91.2582 45.6759 79.4329C87.3125 64.7229 66.1415 46.9281 72.8014 31.278C77.5814 20.0458 115.304 8.86166 129.441 33.2705C141.336 53.8105 150.177 49.1305 157.707 48.2473C168.567 46.973 187.234 68.9201 167.505 84.1085Z"
            fill="#F5F5F5"
          />
          <g id="Group 1848">
            <path
              id="Vector_2"
              d="M87.8888 64.0293H64.0125V87.9628H87.8888V64.0293ZM84.5715 84.6375H67.3298V67.3545H84.5715V84.6375Z"
              fill="black"
              fillOpacity="0.28"
            />
            <path
              id="Vector_3"
              d="M81.1312 70.8027H70.7698V81.1889H81.1312V70.8027Z"
              fill="black"
              fillOpacity="0.28"
            />
            <path
              id="Vector_4"
              d="M112.111 64.0293V87.9628H135.987V64.0293H112.111ZM132.67 84.6375H115.428V67.3545H132.67V84.6375Z"
              fill="black"
              fillOpacity="0.28"
            />
            <path
              id="Vector_5"
              d="M129.23 70.8027H118.868V81.1889H129.23V70.8027Z"
              fill="black"
              fillOpacity="0.28"
            />
            <path
              id="Vector_6"
              d="M64.0125 135.87H87.8888V111.937H64.0125V135.87ZM67.3298 115.262H84.5715V132.545H67.3298V115.262Z"
              fill="black"
              fillOpacity="0.28"
            />
            <path
              id="Vector_7"
              d="M81.1312 118.71H70.7698V129.096H81.1312V118.71Z"
              fill="black"
              fillOpacity="0.28"
            />
            <path
              id="Vector_8"
              d="M70.7699 91.373H64.0125V94.8103H70.7699V91.373Z"
              fill="black"
              fillOpacity="0.28"
            />
            <path
              id="Vector_9"
              d="M84.5098 94.9743H87.955V98.2657H81.1311V98.7361V101.704V105.13V108.568V108.569H84.5098V108.568H91.4311V105.13H84.5098V101.704H91.4311V105.129H94.8098V101.704V98.7361V98.2657H91.3337V91.5254H87.955V91.537H84.5098V94.9743Z"
              fill="black"
              fillOpacity="0.28"
            />
            <path
              id="Vector_10"
              d="M104.864 101.703V105.141H101.486V108.568H98.107V112.007H94.8101V115.423H91.4314V108.569L94.8101 108.568V105.13H98.1889V101.703H104.864Z"
              fill="black"
              fillOpacity="0.28"
            />
            <path
              id="Vector_11"
              d="M111.84 118.71V115.423V115.272V111.985H108.461V115.272H98.1886V118.71H101.526V122.351H98.1886V118.71H94.8098V122.281H91.3464V125.718H94.8098V129.096H91.3464V135.869H94.8098H98.022H98.1886V129.096H101.526V125.653H104.905V118.71H111.84Z"
              fill="black"
              fillOpacity="0.28"
            />
            <path
              id="Vector_12"
              d="M108.284 129.096V132.533H118.577V135.971H128.965V132.533H121.936V129.096V125.653H118.557V129.096H108.284Z"
              fill="black"
              fillOpacity="0.28"
            />
            <path
              id="Vector_13"
              d="M108.284 132.533H101.568V135.97H108.284V132.533Z"
              fill="black"
              fillOpacity="0.28"
            />
            <path
              id="Vector_14"
              d="M111.906 98.2656H104.864V101.703H111.906V98.2656Z"
              fill="black"
              fillOpacity="0.28"
            />
            <path
              id="Vector_15"
              d="M132.089 101.706V105.098H135.454V108.541H132.075V105.098H128.696V108.541H125.318V105.098H121.963V98.2677L128.659 98.2616V94.9197H125.196V91.4824H132.038V94.9197H132.037V98.2677H135.557V101.706H132.089Z"
              fill="black"
              fillOpacity="0.28"
            />
            <path
              id="Vector_16"
              d="M101.486 108.569V112.006H104.864V108.578H108.243V105.141H104.864V108.569H101.486Z"
              fill="black"
              fillOpacity="0.28"
            />
            <path
              id="Vector_17"
              d="M108.284 125.653H104.905V129.096H108.284V125.653Z"
              fill="black"
              fillOpacity="0.28"
            />
            <path
              id="Vector_18"
              d="M128.696 125.648H125.318V129.091H128.696V125.648Z"
              fill="black"
              fillOpacity="0.28"
            />
            <path
              id="Vector_19"
              d="M115.225 105.141H111.846V108.578H115.225V105.141Z"
              fill="black"
              fillOpacity="0.28"
            />
            <path
              id="Vector_20"
              d="M111.906 98.2655H115.285V94.9242H122.106V91.4863H111.906V94.9242V98.2655Z"
              fill="black"
              fillOpacity="0.28"
            />
            <path
              id="Vector_21"
              d="M67.3912 98.251H64.0125V108.569H67.3912V98.251Z"
              fill="black"
              fillOpacity="0.28"
            />
            <path
              id="Vector_22"
              d="M94.6959 91.5371H94.8099V98.2658H98.1886V94.7253H101.567V91.5371H106.594V91.5254H108.48V77.7496H105.101V81.3206H101.567V84.7074H105.101V88.1503H101.567V84.7074H98.1886V88.1503H94.7125V77.8051H98.0912V64.0293H94.7125V67.4732H91.3337V74.3611H94.7125V77.7496H91.3337V91.5254H94.6959V91.5371Z"
              fill="black"
              fillOpacity="0.28"
            />
            <path
              id="Vector_23"
              d="M132.075 115.384V118.979H128.696V115.384H125.316V108.542H121.96V105.099H118.582V108.579H115.224V118.71H111.84V122.148H114.906V125.654H118.556V122.148H121.935V118.701L121.937 118.706H125.316V118.691L125.317 118.706V122.144H128.696V125.65H132.075V132.497H135.454V115.385H132.075V115.384ZM121.935 115.268H118.604V111.963H121.938L121.935 115.268Z"
              fill="black"
              fillOpacity="0.28"
            />
            <path
              id="Vector_24"
              d="M132.075 108.542H128.697V115.384H132.075V108.542Z"
              fill="black"
              fillOpacity="0.28"
            />
            <path
              id="Vector_25"
              d="M125.318 122.144H121.938V125.65H125.318V122.144Z"
              fill="black"
              fillOpacity="0.28"
            />
            <path
              id="Vector_26"
              d="M108.453 67.4161V64.0293H105.101H102.52H101.568V70.976H105.101V67.4161H108.453Z"
              fill="black"
              fillOpacity="0.28"
            />
            <path
              id="Vector_27"
              d="M105.101 74.3633H101.568V77.7501H105.101V74.3633Z"
              fill="black"
              fillOpacity="0.28"
            />
            <path
              id="Vector_28"
              d="M108.634 70.9756H105.101V74.3624H108.634V70.9756Z"
              fill="black"
              fillOpacity="0.28"
            />
            <path
              id="Vector_29"
              d="M77.5274 105.129H74.1487V108.57H77.5274V105.129Z"
              fill="black"
              fillOpacity="0.28"
            />
            <path
              id="Vector_30"
              d="M77.5272 101.855V91.5371H74.1485V94.8108H70.7698V105.129H74.1485V101.855H77.5272Z"
              fill="black"
              fillOpacity="0.28"
            />
            <path
              id="Vector_31"
              d="M58.3366 73.9402C57.5985 73.9402 56.9998 73.34 56.9998 72.6001V58.3401C56.9998 57.6002 57.5985 57 58.3366 57H72.5621C73.3002 57 73.899 57.6002 73.899 58.3401C73.899 59.08 73.3002 59.6802 72.5621 59.6802H59.6735V72.5996C59.6735 73.3395 59.0748 73.9396 58.3366 73.9396V73.9402Z"
              fill="black"
              fillOpacity="0.28"
            />
            <path
              id="Vector_32"
              d="M141.662 73.9402C140.924 73.9402 140.325 73.34 140.325 72.6001V59.6802H127.437C126.699 59.6802 126.1 59.08 126.1 58.3401C126.1 57.6002 126.699 57 127.437 57H141.662C142.4 57 142.999 57.6002 142.999 58.3401V72.5996C142.999 73.3395 142.4 73.9396 141.662 73.9396V73.9402Z"
              fill="black"
              fillOpacity="0.28"
            />
            <path
              id="Vector_33"
              d="M72.5626 143H58.3366C57.5985 143 56.9998 142.4 56.9998 141.66V127.401C56.9998 126.661 57.5985 126.061 58.3366 126.061C59.0748 126.061 59.6735 126.661 59.6735 127.401V140.32H72.5621C73.3002 140.32 73.899 140.92 73.899 141.66C73.899 142.4 73.3002 143 72.5621 143H72.5626Z"
              fill="black"
              fillOpacity="0.28"
            />
            <path
              id="Vector_34"
              d="M141.662 143H127.437C126.699 143 126.1 142.4 126.1 141.66C126.1 140.92 126.699 140.32 127.437 140.32H140.325V127.401C140.325 126.661 140.924 126.061 141.662 126.061C142.4 126.061 142.999 126.661 142.999 127.401V141.66C142.999 142.4 142.4 143 141.662 143Z"
              fill="black"
              fillOpacity="0.28"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
