import { api } from "@/client/api";
import { configs } from "@/configs";

import type { PermissionGroup } from "@/models";

type CreatePermissionGroupInput = Pick<PermissionGroup, "name" | "type">;

type UpdatePermissionGroupInput = Pick<PermissionGroup, "id" | "name">;

export async function getPermissionGroups(query = "") {
  const { data } = await api.get<PermissionGroup[]>(
    `${configs.apiUrl}/permission-groups?${query}`
  );
  return data;
}

export async function createPermissionGroup(
  data: CreatePermissionGroupInput
): Promise<PermissionGroup> {
  const response = await api.post(`${configs.apiUrl}/permission-groups`, data);
  return response.data as PermissionGroup;
}

export async function updatePermissionGroup({
  id,
  ...data
}: UpdatePermissionGroupInput): Promise<PermissionGroup> {
  const response = await api.patch(
    `${configs.apiUrl}/permission-groups/${id}`,
    data
  );
  return response.data as PermissionGroup;
}

export async function removePermissionGroup(id: number): Promise<void> {
  await api.delete(`${configs.apiUrl}/permission-groups/${id}`);
}
