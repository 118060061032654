import { IconButton, Stack, TableCell, TableRow, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import type { MouseEventHandler } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { TextEllipsis } from "@/components/TextEllipsis";
import { formatDurationISO, formatPrice, formatProductType } from "@/formatter";
import { calculateColumnWidth } from "@/utils";
import { useCurrentUser } from "@/features/authentication/contexts/AuthenticationContext";

import type { ProductMembership } from "@/models";

const columnsWidth = [265, 356, 92, 132, 143, 96].map((item) =>
  calculateColumnWidth(item)
);

export type MembershipTableRowProps = {
  data: ProductMembership;
};

export function MembershipTableRowHeader() {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]}>ชื่อ</TableCell>
      <TableCell width={columnsWidth[1]}>รายละเอียด</TableCell>
      <TableCell width={columnsWidth[2]}>ระยะเวลา</TableCell>
      <TableCell width={columnsWidth[3]}>ราคา</TableCell>
      <TableCell width={columnsWidth[4]}>สาขาที่เข้าใช้บริการ</TableCell>
      <TableCell width={columnsWidth[5]} />
    </TableRow>
  );
}

export function MembershipTableRow({ data }: MembershipTableRowProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const currentUser = useCurrentUser();

  const { id, name, description, productMembership, type } = data;
  const idString = id.toString();
  const price = formatPrice(data.price);
  const duration = formatDurationISO(productMembership.durationIso);

  const onClickRemove: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    searchParams.set("dialog", "membership-remove");
    searchParams.set("id", idString);
    setSearchParams(searchParams);
  };

  const onClickTableRow: MouseEventHandler<HTMLTableRowElement> = () => {
    navigate(idString);
  };

  return (
    <TableRow hover tabIndex={-1} role="link" onClick={onClickTableRow}>
      <TableCell>
        <TextEllipsis variant="body2">{name}</TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis variant="body2">{description}</TextEllipsis>
      </TableCell>
      <TableCell>{duration}</TableCell>
      <TableCell>{price}</TableCell>
      <TableCell>{formatProductType(type)}</TableCell>
      <TableCell sx={{ px: 0 }}>
        {currentUser.isAdmin && (
          <Stack direction="row" gap={1}>
            <Tooltip title="ลบ">
              <IconButton onClick={onClickRemove}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        )}
      </TableCell>
    </TableRow>
  );
}
