import { Typography, Box } from "@mui/material";

import type { PropsWithChildren } from "react";
import type { TypographyProps } from "@mui/material";

export function ScheduleLabel({
  color,
  children,
  variant,
  gap = 1,
  mb,
}: PropsWithChildren<{
  color: string;
  variant?: TypographyProps["variant"];
  mb?: number;
  gap?: number;
}>) {
  return (
    <Box
      display="grid"
      gridTemplateColumns="24px 1fr"
      alignItems="center"
      gap={gap}
      mb={mb}
    >
      <Box
        height={12}
        width={12}
        bgcolor={color}
        borderRadius="50%"
        justifySelf="center"
      />
      <Typography variant={variant}>{children}</Typography>
    </Box>
  );
}
