import { setLocale } from "yup";

const typeTHMap: Record<string, string> = {
  number: "ตัวเลข",
  datetime: "วันที่",
};

export const locales: Parameters<typeof setLocale>[0] = {
  mixed: {
    required: "ระบุ${path}",
    notType: ({
      type,
      path,
      originalValue,
    }: {
      type: string;
      path: string;
      originalValue: unknown;
    }) => {
      return type === "datetime" && originalValue === null
        ? `ระบุ${path}`
        : `กรุณาระบุเป็น${typeTHMap[type] ?? type}`;
    },
  },
  string: {
    min: "${path} ต้องมากกว่า ${min} ตัวอักษร",
    max: "${path} ต้องไม่เกิน ${max} ตัวอักษร",
    email: "ระบุ${path}ให้ถูกต้อง",
  },
  number: {
    integer: "${path} ต้องเป็นจำนวนเต็ม",
    min: "${path} ต้องมากกว่า ${min}",
    max: "${path} ต้องน้อยกว่า ${max}",
  },
  array: {
    min: "ต้องมี${path}อย่างน้อย ${min} ${path}",
    max: "ต้องมี${path}ได้มากสุด ${max} ${path}",
  },
};

export const datetime = {
  min: "${path} must comes after ${min}",
  max: "${path} must comes before ${max}",
};
