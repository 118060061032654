import { useMemo } from "react";
import {
  Box,
  Button,
  ButtonBase,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Stack,
  Table,
  TableContainer,
  TableHead,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { configs } from "@/configs";
import { BookingStatus, ScheduleStatus } from "@/models";
import { formatBookingStatusColor, formatBookingStatus } from "@/formatter";
import {
  getBookingStatus,
  mapOptional,
  pickListTableParams,
  refetchQueries,
  useRequireParams,
} from "@/utils";
import { ConfirmDialog } from "@/components/ConfirmDialog";
import { QueryTextField } from "@/components/QueryTextField";
import { DataTableBody } from "@/components/DataTableBody";
import { cancelClassBooking, getClassBookings } from "@/services/class";

import {
  ReservationTableRow,
  ReservationTableRowHeader,
} from "./ReservationTableRow";
import { AddBookingDialog } from "../pages/ClassScheduleDetailPage/AddBookingDialog";

import type { ConfirmDialogProps } from "@/components/ConfirmDialog";
import type { AxiosErrorWithData } from "@/client/api";

const list = [
  null,
  BookingStatus.Booked,
  BookingStatus.Waiting,
  BookingStatus.Cancelled,
];

export const CLASS_BOOKINGS_QUERY_KEY = "classBooking";
export const CLASS_BOOKINGS_STATUS_QUERY_KEY = "classBookingsStatus";

function useParticipantStats(scheduleId: string) {
  const { id: classId } = useRequireParams(["id"]);

  const { data } = useQuery([CLASS_BOOKINGS_STATUS_QUERY_KEY], () =>
    getClassBookings({ classId, scheduleId })
  );

  return useMemo(
    () =>
      mapOptional(data, ({ total, data }) => ({
        total: total,
        ...data
          .map((data) => getBookingStatus(data))
          .reduce(
            (memo, status) => {
              memo[status]++;

              return memo;
            },
            {
              [BookingStatus.Booked]: 0,
              [BookingStatus.Waiting]: 0,
              [BookingStatus.Cancelled]: 0,
            } as Record<BookingStatus, number>
          ),
      })),
    [data]
  );
}

export function ReservationList({
  scheduleId,
  capacity,
  scheduleStatus,
  fetchKeys = [],
}: {
  scheduleId: string;
  capacity?: number;
  scheduleStatus: ScheduleStatus | null;
  fetchKeys?: string[];
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { id: classId } = useRequireParams(["id"]);
  const query = pickListTableParams(searchParams);
  const status = searchParams.get("status") as BookingStatus | null;

  const onClickStatus: JSX.IntrinsicElements["button"]["onClick"] = (event) => {
    const selected = event.currentTarget.dataset.status;
    if (selected) {
      searchParams.set("status", selected);
    } else {
      searchParams.delete("status");
    }

    setSearchParams(searchParams);
  };
  const statusFilters = mapOptional(useParticipantStats(scheduleId), (stats) =>
    list.map((item) => ({
      label: mapOptional(item, formatBookingStatus) ?? "ทั้งหมด",
      bgcolor:
        item === status
          ? mapOptional(item, formatBookingStatusColor) ?? "primary.main"
          : "grey.100",
      color: item === status ? "white" : "black",
      quantity: item ? stats[item] : stats.total,
      value: item,
    }))
  );
  const seatQuantity = capacity ? `(จำกัด ${capacity} คน)` : "";

  const disabledAddButton = scheduleStatus !== ScheduleStatus.Active;

  const { data: raw, isFetching } = useQuery(
    [CLASS_BOOKINGS_QUERY_KEY, query],
    () => getClassBookings({ classId, scheduleId, params: query })
  );

  const data = raw?.data ?? [];

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { mutate: cancel, isLoading: cancelLoading } = useMutation(
    cancelClassBooking,
    {
      onSuccess: async () => {
        enqueueSnackbar("ยกเลิกการจองสำเร็จ", { variant: "success" });
        await refetchQueries({
          queryClient,
          fetchKeys: [
            ...fetchKeys,
            CLASS_BOOKINGS_QUERY_KEY,
            CLASS_BOOKINGS_STATUS_QUERY_KEY,
          ],
        });
        onCloseDialog();
      },
      onError: (error: AxiosErrorWithData) => {
        console.error(error);
        enqueueSnackbar(
          error.response?.data.message ?? configs.unknownErrorMessage,
          { variant: "error" }
        );
      },
    }
  );

  function addBooking() {
    searchParams.set("dialog", "booking-add");
    setSearchParams(searchParams);
  }

  const dialog = searchParams.get("dialog");
  const bookingId = searchParams.get("bookingId");

  function onCloseDialog() {
    searchParams.delete("dialog");
    searchParams.delete("bookingId");
    setSearchParams(searchParams, { replace: true });
  }

  const addBookingDialog = {
    open: dialog === "booking-add",
    onClose: onCloseDialog,
    fetchKeys: [
      ...fetchKeys,
      CLASS_BOOKINGS_QUERY_KEY,
      CLASS_BOOKINGS_STATUS_QUERY_KEY,
    ],
  };

  const cancelDialog: ConfirmDialogProps = {
    maxWidth: "xs",
    loading: cancelLoading,
    title: "คุณต้องการยกเลิกการจองใช่หรือไม่",
    confirmMessage: "ใช่",
    cancelMessage: "ไม่ใช่",
    onClose: onCloseDialog,
    onConfirm: () => {
      if (!bookingId) {
        return;
      }

      cancel({ classId, scheduleId, bookingId });
    },
    open: dialog === "cancel" && !!bookingId,
  };

  return (
    <Stack gap={3}>
      <Card>
        <CardHeader
          title={<Typography variant="h6">ผู้จอง {seatQuantity}</Typography>}
        />
        <CardContent>
          <Stack gap={3}>
            <Box display="grid" gridTemplateColumns="repeat(4, 1fr)" gap={2.5}>
              {!statusFilters ? (
                <Box
                  display="grid"
                  height={114}
                  sx={{ gridColumn: "1/-1", placeItems: "center" }}
                >
                  <CircularProgress disableShrink />
                </Box>
              ) : (
                statusFilters.map(
                  ({ value, label, bgcolor, color, quantity }) => (
                    <Stack
                      component={ButtonBase}
                      data-status={value}
                      onClick={onClickStatus}
                      key={label}
                      sx={{
                        bgcolor,
                        color,
                        height: 114,
                        p: 2.5,
                        borderRadius: 1,
                        alignItems: "flex-start",
                      }}
                      gap={1.25}
                    >
                      <Typography variant="subtitle2">{label}</Typography>
                      <Typography variant="h4">{quantity}</Typography>
                    </Stack>
                  )
                )
              )}
            </Box>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              gap={4}
              mb={5}
            >
              <QueryTextField sx={{ width: 600 }} placeholder="ค้นหาชื่อ" />
              <Button
                variant="contained"
                size="large"
                onClick={addBooking}
                disabled={disabledAddButton}
              >
                เพิ่มการจอง
              </Button>
            </Stack>
            <TableContainer sx={{ height: "600px", overflowX: "hidden" }}>
              <Table stickyHeader>
                <TableHead>
                  <ReservationTableRowHeader />
                </TableHead>
                <DataTableBody
                  loading={isFetching}
                  data={data}
                  searchKeys={["query", "status"]}
                >
                  {data.map((item) => (
                    <ReservationTableRow key={item.id} data={item} />
                  ))}
                </DataTableBody>
              </Table>
            </TableContainer>
          </Stack>
        </CardContent>
      </Card>
      <ConfirmDialog {...cancelDialog} />
      <AddBookingDialog {...addBookingDialog} />
    </Stack>
  );
}
