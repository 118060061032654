import { api } from "@/client/api";
import { FitnessCenter } from "@/models";
import { configs } from "@/configs";
import { parseDateTimeFromPrisma } from "@/utils";
import { DateTime } from "luxon";

type UpdateFitnessInput = Partial<FitnessCenter> & { id: number };

type FitnessExpired = {
  expiredAt: DateTime;
};

export async function getFitness(id: string) {
  const { data } = await api.get<FitnessCenter>(
    `${configs.apiUrl}/fitness-centers/${id}`
  );
  return data;
}

export async function getFitnessExpired() {
  const { data } = await api.get<FitnessExpired>(
    `${configs.apiUrl}/fitness-centers/check-expired`
  );
  return parseDateTimeFromPrisma(data) as FitnessExpired;
}

export async function updateFitness({
  id,
  ...data
}: UpdateFitnessInput): Promise<FitnessCenter> {
  const response = await api.patch(
    `${configs.apiUrl}/fitness-centers/${id}`,
    data
  );
  return response.data as FitnessCenter;
}
