import {
  Box,
  CardContent,
  CardHeader,
  IconButton,
  Link as MuiLink,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, useSearchParams } from "react-router-dom";

import { MediaViewer } from "@/components/MediaViewer";

import { EditClassDialog } from "../../components/EditClassDialog";
import { RemoveClassDialog } from "../../components/RemoveClassDialog";
import { ClassStatusSwitch } from "../../components/ClassStatusSwitch";

import type { RemoveClassDialogProps } from "../../components/RemoveClassDialog";
import type { Class } from "@/models";
import { CLASS_SCHEDULES_QUERY_KEY } from "@/features/class/pages/ClassDetailPage/ScheduleList";
import { useCurrentUser } from "@/features/authentication/contexts/AuthenticationContext";

export type ClassDetailProps = {
  data: Class;
  fetchKeys?: string[];
};

export function ClassDetail({ data, fetchKeys = [] }: ClassDetailProps) {
  const { name, description, thumbnailUrls, youtubeUrl, categories } = data;
  const id = data.id.toString();

  const videoText = youtubeUrl || "-";

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentUser = useCurrentUser();
  const dialog = searchParams.get("dialog");

  const mediaList = thumbnailUrls.map((thumbnailUrl) => ({
    url: thumbnailUrl,
  }));

  function openEditDialog() {
    searchParams.set("dialog", "class-edit");
    setSearchParams(searchParams);
  }

  function openRemoveDialog() {
    searchParams.set("dialog", "class-remove");
    setSearchParams(searchParams);
  }

  function onCloseDialog() {
    searchParams.delete("dialog");
    setSearchParams(searchParams);
  }

  const editDialog = {
    open: dialog === "class-edit",
    onClose: onCloseDialog,
    data,
    id,
    fetchKeys,
  };

  const removeDialog: RemoveClassDialogProps = {
    open: dialog === "class-remove",
    onClose(reason) {
      if (reason === "cancel") {
        onCloseDialog();
        return;
      }

      navigate("..");
    },
    id,
  };

  const youtubeLink = youtubeUrl
    ? {
        component: youtubeUrl ? MuiLink : Typography,
        target: "_blank",
        href: youtubeUrl,
      }
    : {};

  return (
    <Stack>
      <CardHeader
        title={name}
        action={
          <Stack direction="row">
            <ClassStatusSwitch
              data={data}
              fetchKeys={[...fetchKeys, CLASS_SCHEDULES_QUERY_KEY]}
            />
            {currentUser.isAdmin && (
              <>
                <Tooltip title="แก้ไข">
                  <IconButton onClick={openEditDialog}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="ลบ">
                  <IconButton onClick={openRemoveDialog}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Stack>
        }
      />
      <CardContent sx={{ py: 0 }}>
        <MediaViewer mediaList={mediaList} imageWidth={300} imageHeight={200} />
      </CardContent>
      <CardContent>
        <Box
          display="grid"
          gridTemplateColumns="176px 1fr"
          rowGap={3}
          alignItems="baseline"
        >
          <Typography variant="body1" color="text.disabled" fontWeight={500}>
            ประเภทกิจกรรม
          </Typography>
          <Typography variant="body2">
            {categories.map(({ name }) => name).join(",")}
          </Typography>
          <Typography variant="body1" color="text.disabled" fontWeight={500}>
            ลิงค์ Youtube
          </Typography>
          <Typography {...youtubeLink} variant="body2" color="inherit">
            {videoText}
          </Typography>
          <Typography variant="body1" color="text.disabled" fontWeight={500}>
            รายละเอียด
          </Typography>
          <Typography variant="body2">{description || "-"}</Typography>
        </Box>
      </CardContent>
      <EditClassDialog {...editDialog} />
      <RemoveClassDialog {...removeDialog} />
    </Stack>
  );
}
