import {
  Box,
  Card,
  CardContent,
  Stack,
  TableContainer,
  Table,
  TableHead,
  Breadcrumbs,
  Typography,
  Backdrop,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useMutation, useQuery } from "react-query";
import { useState } from "react";
import { useSnackbar } from "notistack";

import { QueryTextField } from "@/components/QueryTextField";
import { DataTableBody } from "@/components/DataTableBody";
import { SearchParamsPagination } from "@/components/SearchParamsPagination";
import { CancelReceiptDialog } from "@/features/member/pages/TransactionListPage/CancelReceiptDialog";
import { CircularLoading } from "@/components/CircularLoading";
import {
  TransactionTableHeaderRow,
  TransactionTableRow,
} from "@/features/member/pages/TransactionListPage/TransactionTableRow";
import { DatePicker } from "@/components/DatePicker";
import {
  getApiErrorMessage,
  getDocumentTitle,
  pickListTableParams,
} from "@/utils";
import { getReceiptPDF, getReceipts } from "@/services/receipts";
import { now } from "@/lib/dateTime";

import type { DateTime } from "luxon";
import type { AxiosErrorWithData } from "@/client/api";

const QUERY_KEY = "getAllReceipts";

export function ReceiptListPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();

  const today = now().startOf("day");
  const [date, setDate] = useState<DateTime>(today);

  const query = pickListTableParams(searchParams);

  const { data: raw, isFetching } = useQuery(
    [QUERY_KEY, query, date.toISO()],
    () => getReceipts(date, query)
  );

  const { mutate: generatePDF, isLoading: isPDFLoading } = useMutation(
    getReceiptPDF,
    {
      onSuccess: (url) => {
        window.open(url, "_blank");
      },
      onError: (error: AxiosErrorWithData) => {
        console.error(error);
        enqueueSnackbar(getApiErrorMessage(error), { variant: "error" });
      },
    }
  );

  const dialog = searchParams.get("dialog");
  const cancelReceiptId = searchParams.get("cancelReceiptId") ?? "";
  const showMsg = !!searchParams.get("showMsg");

  const data = raw?.data ?? [];
  const total = raw?.total;

  function onCloseDialog() {
    searchParams.delete("dialog");
    searchParams.delete("cancelReceiptId");
    searchParams.delete("showMsg");
    setSearchParams(searchParams, { replace: true });
  }

  const cancelReceiptDialog = {
    open: dialog === "receipt-cancel",
    id: cancelReceiptId,
    onClose: onCloseDialog,
    showMsg,
    fetchKeys: [QUERY_KEY],
  };

  const onChangeDate = (value: DateTime | null) => setDate(value ?? today);

  return (
    <Box>
      <Helmet>
        <title>{getDocumentTitle("Receipts")}</title>
      </Helmet>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Typography color="text.primary">ใบเสร็จ</Typography>
      </Breadcrumbs>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Stack direction="row" alignItems="center" gap={2} mb={5}>
            <QueryTextField
              sx={{ width: 600 }}
              placeholder="ค้นหาเลขที่ใบเสร็จ และชื่อสมาชิก"
            />
            <DatePicker
              onChange={onChangeDate}
              value={date}
              label="วันที่ออกใบเสร็จ"
            />
          </Stack>
          <TableContainer sx={{ height: "600px", overflowX: "hidden" }}>
            <Table stickyHeader>
              <TableHead>
                <TransactionTableHeaderRow isShowMember />
              </TableHead>
              <DataTableBody loading={isFetching} data={data}>
                {data.map((item) => (
                  <TransactionTableRow
                    isShowMember
                    key={item.id}
                    data={item}
                    generatePDF={generatePDF}
                  />
                ))}
              </DataTableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <Backdrop open={isPDFLoading} sx={{ zIndex: 10000 }}>
        <CircularLoading height="100vh" />
      </Backdrop>
      <CancelReceiptDialog {...cancelReceiptDialog} />
      <SearchParamsPagination total={total} />
    </Box>
  );
}
