import { InputAdornment, TextFieldProps } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";

import { DebounceTextField } from "./DebounceTextField";

export function QueryTextField(
  props: Omit<TextFieldProps, "value" | "onChange">
) {
  const [searchParams, setSearchParams] = useSearchParams();

  const query = searchParams.get("query") ?? "";
  const onChangeQuery: JSX.IntrinsicElements["input"]["onChange"] = (event) => {
    if (event.target.value) {
      searchParams.set("query", event.target.value);
    } else {
      searchParams.delete("query");
    }

    searchParams.delete("page");
    setSearchParams(searchParams);
  };

  return (
    <DebounceTextField
      type="search"
      name="query"
      value={query}
      onChange={onChangeQuery}
      placeholder="ค้นหา"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
}
