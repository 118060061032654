import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Stack,
  Table,
  TableContainer,
  TableHead,
} from "@mui/material";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";

import {
  PersonalTrainingHistoryTableHead,
  PersonalTrainingHistoryTableRow,
} from "./PTHistoryTableRow";

import { mapOptional, pickListTableParams, useRequireParams } from "@/utils";
import { BookingType, ScheduleStatus } from "@/models";
import { formatScheduleStatus } from "@/formatter";
import { getScheduleHistory } from "@/services/trainer";
import { DataTableBody } from "@/components/DataTableBody";
import { QueryTextField } from "@/components/QueryTextField";
import { SearchParamsPagination } from "@/components/SearchParamsPagination";

export function PersonalTrainingHistory() {
  const [searchParams, setSearchParams] = useSearchParams();
  const query = pickListTableParams(searchParams);
  const { id: staffId } = useRequireParams(["id"]);

  const { data: raw, isFetching } = useQuery(
    ["CLASS_SCHEDULES_QUERY_KEY", query],
    () =>
      getScheduleHistory({
        query,
        type: BookingType.PersonalTraining,
        staffId,
      })
  );

  const data = raw?.data ?? [];
  const total = raw?.total;

  const status = searchParams.get("status") as ScheduleStatus | undefined;

  const setStatus = (s: ScheduleStatus | null) => () => {
    if (s) {
      searchParams.set("status", s);
    } else {
      searchParams.delete("status");
    }

    searchParams.delete("page");
    setSearchParams(searchParams, { replace: true });
  };

  return (
    <>
      <Card>
        <CardContent>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mb={3}
          >
            <QueryTextField
              sx={{ width: 632 }}
              placeholder="ค้นหาด้วยชื่อสมาชิกหรือเบอร์โทรศัพท์"
            />
            <ButtonGroup variant="contained" size="large" color="inherit">
              {[null, ScheduleStatus.Completed, ScheduleStatus.Cancelled].map(
                (s) => (
                  <Button
                    key={s}
                    onClick={setStatus(s)}
                    sx={{ bgcolor: s === status ? "grey.100" : "grey.300" }}
                  >
                    {mapOptional(s, formatScheduleStatus) ?? "ทั้งหมด"}
                  </Button>
                )
              )}
            </ButtonGroup>
          </Stack>
          <TableContainer sx={{ height: "684px", overflowX: "hidden" }}>
            <Table stickyHeader>
              <TableHead>
                <PersonalTrainingHistoryTableHead />
              </TableHead>
              <DataTableBody loading={isFetching} data={data}>
                {data.map((item) => (
                  <PersonalTrainingHistoryTableRow key={item.id} data={item} />
                ))}
              </DataTableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <SearchParamsPagination total={total} />
    </>
  );
}
