import { Stack, Typography } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

import {
  formatOpportunityStatus,
  formatOpportunityStatusColor,
} from "@/formatter";

import type { OpportunityStatus } from "@/models";

type OpportunityStatusItemProps = { status: OpportunityStatus };

export function OpportunityStatusItem({ status }: OpportunityStatusItemProps) {
  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <CircleIcon
        sx={{
          width: 12,
          height: 12,
          color: formatOpportunityStatusColor(status),
        }}
      />
      <Typography variant="body1">{formatOpportunityStatus(status)}</Typography>
    </Stack>
  );
}
