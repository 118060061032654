import {
  Box,
  Breadcrumbs,
  Card,
  CircularProgress,
  Link as MuiLink,
  Typography,
} from "@mui/material";
import { getDocumentTitle, useRequireParams } from "@/utils";
import { Helmet } from "react-helmet-async";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";

import { getProductMembership } from "@/services/product";

import { MembershipDetail } from "./MembershipDetail";

export function MembershipDetailPage() {
  const { id } = useRequireParams(["id"]);

  const { data, isFetching } = useQuery("product-membership", () =>
    getProductMembership(id)
  );

  const isLoaded = !isFetching && typeof data !== "undefined";

  const title = data?.name ?? "Membership Detail";

  return (
    <Box>
      <Helmet>
        <title>{getDocumentTitle(title)}</title>
      </Helmet>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ mb: 2 }}
      >
        <MuiLink
          component={Link}
          color="text.secondary"
          to="/products-and-packages"
        >
          แพ็กเกจและสินค้า
        </MuiLink>
        <MuiLink
          component={Link}
          color="text.secondary"
          to="/products-and-packages"
        >
          แพ็กเกจสมาชิก
        </MuiLink>
        <Typography color="text.primary">รายละเอียดแพ็กเกจสมาชิก</Typography>
      </Breadcrumbs>
      <Card sx={{ mb: 2.5 }}>
        {isLoaded ? (
          <MembershipDetail data={data} />
        ) : (
          <Box display="grid" sx={{ placeItems: "center" }} height={228}>
            <CircularProgress disableShrink />
          </Box>
        )}
      </Card>
    </Box>
  );
}
