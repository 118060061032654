import { Stack, Typography } from "@mui/material";
import { ComponentType } from "react";

import type { StackProps } from "@mui/material";

export type EmptyResultIconVariant = "search" | "list" | "classNotStart";

const variants: Record<
  EmptyResultIconVariant,
  { Icon: ComponentType; message: string }
> = {
  search: {
    Icon: SearchNotFoundIcon,
    message: "ไม่พบข้อมูล",
  },
  list: {
    Icon: NoResultIcon,
    message: "ไม่มีข้อมูล",
  },
  classNotStart: {
    Icon: NoResultIcon,
    message: "คลาสยังไม่ใกล้ถึงเวลาเริ่ม",
  },
};

export type EmptyResultProps = {
  variant?: EmptyResultIconVariant;
} & StackProps;

export function EmptyResultIcon({
  variant = "list",
  ...props
}: EmptyResultProps) {
  const { Icon, message } = variants[variant];

  return (
    <Stack justifyContent="center" alignItems="center" gap={2} {...props}>
      <Icon />
      <Typography variant="subtitle1" color="text.disabled">
        {message}
      </Typography>
    </Stack>
  );
}

function NoResultIcon() {
  return (
    <svg
      width={200}
      height={200}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.617 90.735s27.693-1.109 33.678-46.631c5.314-40.428 52.013-22.027 65.511-5.911 17.487 20.876 8.165 56.788 30.786 61.325 22.621 4.536 7.849 54.977-26.488 50.149-42.714-6.006-32.38 18.954-45.203 29.518-9.202 7.582-46.936.284-48.193-27.24-1.059-23.161-10.828-23.088-17.753-25.714-9.988-3.788-16.28-31.219 7.662-35.496Z"
        fill="#F5F5F5"
      />
      <path d="M116.25 65.37v17.84h15.67l-15.67-17.84Z" fill="#9E9E9E" />
      <path
        d="M109.15 91.292V63h-35.5c-2.825 0-5.534 1.278-7.53 3.551C64.121 68.825 63 71.91 63 75.125V160h71V91.292h-24.85Z"
        fill="#9E9E9E"
      />
    </svg>
  );
}

function SearchNotFoundIcon() {
  return (
    <svg
      width={200}
      height={200}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.617 90.735s27.693-1.109 33.678-46.631c5.314-40.428 52.013-22.027 65.511-5.911 17.487 20.876 8.165 56.788 30.786 61.325 22.621 4.536 7.849 54.977-26.488 50.149-42.714-6.006-32.38 18.954-45.202 29.518-9.203 7.582-46.937.284-48.194-27.24-1.058-23.161-10.828-23.088-17.753-25.714-9.988-3.788-16.28-31.219 7.662-35.496Z"
        fill="#F5F5F5"
      />
      <path
        d="m131.759 141.29-27.208-26.885-5.388 5.453 27.208 26.885 5.388-5.453Z"
        fill="#E0E0E0"
      />
      <path
        d="m142.152 148.515-21.168-20.917a.3.3 0 0 0-.424.002l-8.012 8.108a.301.301 0 0 0 .003.425l21.168 20.916a.3.3 0 0 0 .424-.002l8.012-8.108a.3.3 0 0 0-.003-.424ZM80.436 127.927c18.718 0 33.893-15.175 33.893-33.893S99.155 60.142 80.436 60.142c-18.718 0-33.892 15.174-33.892 33.892 0 18.718 15.174 33.893 33.892 33.893Z"
        fill="#9E9E9E"
      />
      <path
        d="M80.436 122.396c15.664 0 28.362-12.698 28.362-28.362 0-15.664-12.698-28.362-28.362-28.362-15.664 0-28.362 12.698-28.362 28.362 0 15.664 12.698 28.362 28.362 28.362Z"
        fill="#F5F5F5"
      />
    </svg>
  );
}
