import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import { Typography } from "@mui/material";

import { cancelReceipt } from "@/services/receipts";
import { refetchQueries } from "@/utils";
import { ConfirmDialog } from "@/components/ConfirmDialog";
import { configs } from "@/configs";

import type { AxiosErrorWithData } from "@/client/api";

export type CancelReceiptDialogProps = {
  open: boolean;
  onClose: () => void;
  id: string;
  showMsg: boolean;
  fetchKeys?: string[];
};
export function CancelReceiptDialog({
  open,
  onClose,
  id,
  showMsg,
  fetchKeys = [],
}: CancelReceiptDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate: cancel, isLoading } = useMutation(cancelReceipt, {
    onSuccess: async () => {
      enqueueSnackbar("ยกเลิกใบเสร็จสำเร็จ", {
        variant: "success",
      });
      await refetchQueries({ queryClient, fetchKeys });
      onClose();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(
        error.response?.data.message ?? configs.unknownErrorMessage,
        { variant: "error" }
      );
    },
  });

  const onConfirm = () => cancel(+id);

  const title = "คุณต้องการยกเลิกใบเสร็จนี้หรือไม่";

  return (
    <ConfirmDialog
      reverse
      maxWidth="xs"
      title={title}
      loading={isLoading}
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      confirmMessage="ใช่"
      cancelMessage="ไม่ใช่"
    >
      {showMsg && (
        <Typography variant="body2" color="primary.main">
          หากทำการยกเลิกใบเสร็จนี้อาจส่งผลกระทบกับการเข้าใช้งานฟิตเนส
          และการจองต่างๆ หากมีการจองอยู่แล้ว จะต้องทำยกเลิกการจอง ด้วยตนเอง
        </Typography>
      )}
    </ConfirmDialog>
  );
}
