import { Box, Button, Stack } from "@mui/material";
import { configs } from "@/configs";
import { MediaViewerDialog } from "@/components/MediaViewer/MediaViewerDialog";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";

export type MediaViewerProps = {
  mediaList: { url: string }[];
  imageWidth: number;
  imageHeight: number;
};
export function MediaViewer({
  mediaList,
  imageWidth,
  imageHeight,
}: MediaViewerProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const dialog = searchParams.get("dialog");
  const items = mediaList.map((media) => ({
    src: media.url,
    disabled: false,
  }));

  if (items.length === 0) {
    items.push({ src: configs.defaultImageURL, disabled: true });
  }

  const [currentIndex, setCurrentIndex] = useState(0);

  function openMediaViewerDialog(index: number) {
    searchParams.set("dialog", "mediaViewer");
    setSearchParams(searchParams);
    setCurrentIndex(index);
  }

  const mediaViewerDialog = {
    open: dialog === "mediaViewer",
    mediaList,
    currentIndex,
    onClose: () => {
      searchParams.delete("dialog");
      setSearchParams(searchParams, { replace: true });
    },
    onChangeCurrentIndex: setCurrentIndex,
  };

  const overflow = items.length > 1 && { overflow: "auto" };

  return (
    <Box ml={-5} mr={5} {...overflow}>
      <Stack direction="row" width="max-content" gap={2.5} pl={5}>
        {items.map((item, index) => (
          <Button
            key={item.src}
            sx={{ p: 0 }}
            disabled={item.disabled}
            disableRipple
            onClick={() => openMediaViewerDialog(index)}
          >
            <Box
              component="img"
              src={item.src}
              width={imageWidth}
              height={imageHeight}
              border="1px solid #9E9E9E3D"
              borderRadius={1}
              sx={{
                objectFit: "cover",
                objectPosition: "center",
                "&:hover": {
                  opacity: 0.8,
                },
              }}
            />
          </Button>
        ))}
      </Stack>
      <MediaViewerDialog {...mediaViewerDialog} />
    </Box>
  );
}
