import { api } from "@/client/api";
import { configs } from "@/configs";
import { parseDateTimeFromPrisma, parseISOToPrisma } from "@/utils";

import type { Training, TrainingGoal, TrainingItem } from "@/models";
import { DateTime } from "luxon";
import { now } from "@/lib/dateTime";

export type GetTrainings = {
  data: TrainingItem[];
  total: number;
};

type CreateTrainingsCsvInput = {
  startedAt: DateTime;
  endedAt: DateTime;
};

type CreateTrainingInput = {
  staffId: number;
  memberId: number;
  startedAt: DateTime;
  endedAt: DateTime;
  goals: TrainingGoal[];
  detail?: string | null;
  followUp: {
    followedAt: DateTime;
    remark?: string | null;
  };
};

type ChangeTrainingStatusInput = {
  id: number;
  isSuccess: boolean;
  note: string;
};

type UpdateTrainingInput = Pick<
  CreateTrainingInput,
  "startedAt" | "endedAt" | "goals" | "detail"
> & {
  id: number;
  newStaffId?: number;
  reason?: string;
};

type CreateNewFollowUpInput = {
  trainingId: number;
  summary: string;
  followedAt: DateTime;
  remark?: string;
};

export async function getTrainings(query = "") {
  const { data } = await api.get<GetTrainings>(
    `${configs.apiUrl}/trainings?${query}`
  );
  return parseDateTimeFromPrisma(data) as GetTrainings;
}

export async function createTrainingsCsv(input: CreateTrainingsCsvInput) {
  const res = await api.post(
    `${configs.apiUrl}/trainings/csv`,
    parseISOToPrisma(input)
  );

  const data = new Blob([res.data], { type: "application/csv" });
  const csvUrl = window.URL.createObjectURL(data);
  const tempLink = document.createElement("a");
  tempLink.href = csvUrl;
  tempLink.download = `training_${now().toFormat("yyyy_MM_dd")}.csv`;
  document.body.appendChild(tempLink);
  tempLink.click();
  tempLink.remove();

  return res;
}

export async function createTraining(
  data: CreateTrainingInput
): Promise<Training> {
  const response = await api.post(`${configs.apiUrl}/trainings`, data);

  return parseDateTimeFromPrisma(response.data) as Training;
}

export async function updateTraining({
  id,
  ...input
}: UpdateTrainingInput): Promise<Training> {
  const response = await api.patch(`${configs.apiUrl}/trainings/${id}`, input);

  return parseDateTimeFromPrisma(response.data) as Training;
}

export async function getTraining(id: string) {
  const { data } = await api.get<Training>(`${configs.apiUrl}/trainings/${id}`);
  return parseDateTimeFromPrisma(data) as Training;
}

export async function changeTrainingStatus({
  id,
  ...input
}: ChangeTrainingStatusInput) {
  return await api.post(`${configs.apiUrl}/trainings/${id}/summary`, input);
}

export async function createNewFollowUp({
  trainingId,
  ...input
}: CreateNewFollowUpInput) {
  return await api.post(
    `${configs.apiUrl}/trainings/${trainingId}/follow-ups`,
    input
  );
}
