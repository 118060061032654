import { Box, Link as MuiLink, SxProps, Typography } from "@mui/material";

import type { Variant } from "@mui/material/styles/createTypography";

type TextLinkProps = {
  text?: string | null;
  variant?: Variant;
  linkSX?: SxProps;
};

export function TextLink({ text, variant = "body2", linkSX }: TextLinkProps) {
  const onClick: JSX.IntrinsicElements["a"]["onClick"] = (event) =>
    event.stopPropagation();

  return text ? (
    <Box
      component={MuiLink}
      target="_blank"
      href={text}
      onClick={onClick}
      color="text.primary"
    >
      <Typography
        variant={variant}
        sx={{
          wordBreak: "break-all",
          overflow: "hidden",
          ...linkSX,
        }}
      >
        {text}
      </Typography>
    </Box>
  ) : (
    <Typography variant={variant}>-</Typography>
  );
}
