import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";

import { refetchQueries, setTime, useRequireParams } from "@/utils";
import {
  createClassSchedule,
  CreateClassScheduleInput,
} from "@/services/class";
import { configs } from "@/configs";

import { ScheduleEditorDialog } from "./ScheduleEditorDialog";
import { useScheduleEditorForm } from "./ScheduleEditor";

import type { AxiosErrorWithData } from "@/client/api";

export function AddScheduleDialog({
  open: isOpen,
  onClose,
  fetchKeys = [],
}: {
  open: boolean;
  onClose: () => void;
  fetchKeys?: string[];
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, reset } = useScheduleEditorForm();
  const { id: classId } = useRequireParams(["id"]);
  const queryClient = useQueryClient();

  const { mutate: add, isLoading } = useMutation(createClassSchedule, {
    onSuccess: async () => {
      enqueueSnackbar("เพิ่มตารางเวลาสำเร็จ", { variant: "success" });
      await refetchQueries({ queryClient, fetchKeys });
      close();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(
        error.response?.data.message ?? configs.unknownErrorMessage,
        {
          variant: "error",
        }
      );
    },
  });

  function close() {
    reset();
    onClose();
  }

  const onSubmit = handleSubmit((form) => {
    const startDateTime = setTime(form.startDate, form.startTime);
    const cancelBefore = startDateTime.minus({
      hour: form.cancelBeforeTime,
    });
    const start = startDateTime;
    const end = setTime(form.startDate, form.endTime);
    const joinedAt =
      form.scheduleType === "schedule" && form.openBookDate && form.openBookTime
        ? setTime(form.openBookDate, form.openBookTime)
        : null;

    const input: CreateClassScheduleInput = {
      classId,
      description: form.description ?? "",
      location: form.location,
      capacity: form.capacity,
      startedAt: start,
      endedAt: end,
      joinedAt,
      cancelBefore,
      staffIds: form.trainers.map(({ id }) => id),
    };

    return add(input);
  });

  const title = "เพิ่มตารางเวลา";

  return (
    <ScheduleEditorDialog
      open={isOpen}
      title={title}
      onClose={close}
      onSubmit={onSubmit}
      loading={isLoading}
      control={control}
    />
  );
}
