import {
  Card,
  CardContent,
  Stack,
  Table,
  TableContainer,
  TableHead,
} from "@mui/material";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";

import { pickListTableParams, useRequireParams } from "@/utils";
import { getTrainerTrainings } from "@/services/trainer";
import { DataTableBody } from "@/components/DataTableBody";
import { QueryTextField } from "@/components/QueryTextField";
import { SearchParamsPagination } from "@/components/SearchParamsPagination";
import { ItemsTotal } from "@/components/ItemsTotal";
import { SortType, TrainingStatus } from "@/models";
import {
  AddTrainingButton,
  AddTrainingDialog,
} from "@/features/training/components";

import { TrainingStatusButtonGroup } from "../../components/TrainingStatusButtonGroup";
import {
  TrainerTrainingTableRow,
  TrainerTrainingTableRowHeader,
} from "./TrainerTrainingTableRow";
import { useTrainerProfilePage } from "../TrainerProfilePage";

const QUERY_KEY = "getTrainerTrainings";

export function TrainerTrainingList() {
  const [searchParams, setSearchParams] = useSearchParams();
  const query = pickListTableParams(searchParams);
  const { id: staffId } = useRequireParams(["id"]);
  const { isPersonalTraining, profile } = useTrainerProfilePage();

  const sort = searchParams.get("sort");

  useEffect(() => {
    if (!sort) {
      searchParams.set("sort", "latestFollowedAt");
      searchParams.set("sortType", SortType.Asc);
      searchParams.set("status", TrainingStatus.InProgress);
      setSearchParams(searchParams, { replace: true });
    }
  }, [searchParams, setSearchParams, sort]);

  const { data: raw, isFetching } = useQuery([QUERY_KEY, query], () =>
    getTrainerTrainings({
      query,
      staffId,
    })
  );

  const dialog = searchParams.get("dialog");

  const onCloseDialog = () => {
    searchParams.delete("dialog");
    setSearchParams(searchParams, { replace: true });
  };

  const addTrainingDialog = {
    open: dialog === "training-add",
    onClose: onCloseDialog,
    trainer: { id: +staffId, profile },
    fetchKeys: [QUERY_KEY],
  };

  const data = raw?.data ?? [];
  const total = raw?.total;

  return (
    <>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Stack gap={3}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              gap={2}
            >
              <QueryTextField
                placeholder="ค้นหาด้วยชื่อสมาชิก"
                sx={{ width: "100%", maxWidth: 632 }}
              />
              {isPersonalTraining && <AddTrainingButton />}
            </Stack>
            <TrainingStatusButtonGroup />
            <Stack>
              <ItemsTotal count={total} />
              <TableContainer sx={{ height: "600px", overflowX: "hidden" }}>
                <Table stickyHeader>
                  <TableHead>
                    <TrainerTrainingTableRowHeader />
                  </TableHead>
                  <DataTableBody loading={isFetching} data={data}>
                    {data.map((item) => (
                      <TrainerTrainingTableRow key={item.id} data={item} />
                    ))}
                  </DataTableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
      <SearchParamsPagination total={total} />
      <AddTrainingDialog {...addTrainingDialog} />
    </>
  );
}
