import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";

import { now } from "@/lib/dateTime";
import { refetchQueries, setTime, useRequireParams } from "@/utils";
import { formatProfile, formatTime } from "@/formatter";
import { updateClassSchedule } from "@/services/class";
import { configs } from "@/configs";

import { useScheduleEditorForm } from "../pages/ClassDetailPage/ScheduleEditor";
import { ScheduleEditorDialog } from "../pages/ClassDetailPage/ScheduleEditorDialog";

import type { Schedule } from "@/models";
import type { UpdateClassScheduleInput } from "@/services/class";
import type { AxiosErrorWithData } from "@/client/api";

export type EditScheduleDialogProps = {
  open: boolean;
  data?: Schedule;
  onClose: () => void;
  id: string;
  fetchKeys?: string[];
};

export function EditScheduleDialog({
  open: isOpen,
  onClose,
  id,
  data,
  fetchKeys = [],
}: EditScheduleDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, reset } = useScheduleEditorForm();
  const queryClient = useQueryClient();
  const { id: classId } = useRequireParams(["id"]);

  useEffect(() => {
    if (isOpen && data) {
      const { joinedAt, startedAt, endedAt, cancelBefore } = data;
      const cancelBeforeTime = startedAt.diff(cancelBefore, [
        "hour",
        "minute",
      ]).hours;

      const trainers =
        data.staffs?.map(({ id, profile }) => ({
          id,
          name: formatProfile(profile),
        })) ?? [];

      const openBook = joinedAt ?? now();

      reset({
        id,
        scheduleType: joinedAt ? "schedule" : "now",
        openBookDate: openBook.startOf("day"),
        openBookTime: formatTime(openBook.startOf("hour")),
        startDate: startedAt.startOf("day"),
        startTime: formatTime(startedAt),
        endTime: formatTime(endedAt),
        location: data.location,
        capacity: data.capacity,
        trainers,
        description: data.description,
        cancelBeforeTime,
      });
      return;
    }
  }, [id, isOpen, data, reset]);

  function close() {
    onClose();
  }

  const { mutate: update, isLoading } = useMutation(updateClassSchedule, {
    onSuccess: async () => {
      enqueueSnackbar("บันทึกตารางเวลาสำเร็จ", { variant: "success" });
      await refetchQueries({ queryClient, fetchKeys });
      close();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(
        error.response?.data.message ?? configs.unknownErrorMessage,
        { variant: "error" }
      );
    },
  });

  const onSubmit = handleSubmit((form) => {
    const startedAt = setTime(form.startDate, form.startTime);
    const endedAt = setTime(form.startDate, form.endTime);
    const cancelBefore = startedAt.minus({
      hour: form.cancelBeforeTime,
    });
    const joinedAt =
      form.scheduleType === "schedule" && form.openBookDate && form.openBookTime
        ? setTime(form.openBookDate, form.openBookTime)
        : null;

    const input: UpdateClassScheduleInput = {
      classId,
      scheduleId: id,
      description: form.description ?? "",
      location: form.location,
      capacity: form.capacity,
      startedAt,
      endedAt,
      cancelBefore,
      staffIds: form.trainers.map(({ id }) => id),
      joinedAt,
    };

    return update(input);
  });

  const title = "แก้ไขตารางเวลา";

  return (
    <ScheduleEditorDialog
      open={isOpen}
      title={title}
      onClose={close}
      onSubmit={onSubmit}
      loading={isLoading}
      control={control}
    />
  );
}
