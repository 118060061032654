import {
  Button,
  Card,
  CardContent,
  Stack,
  Table,
  TableContainer,
  TableHead,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";

import { getPermissionGroups } from "@/services/permissionGroup";
import { DataTableBody } from "@/components/DataTableBody";

import {
  PermissionGroupTableRow,
  PermissionGroupTableRowHeader,
} from "./PermissionGroupTableRow";

import { AddPermissionGroupDialog } from "./AddPermissionGroupDialog";
import { EditPermissionGroupDialog } from "./EditPermissionGroupDialog";
import { RemovePermissionGroupDialog } from "./RemovePermissionGroupDialog";
import { AlertRemovePermissionGroupDialog } from "./AlertRemovePermissionGroupDialog";

const QUERY_KEY = "getPermissionGroups";

export function PermissionGroupList() {
  const [searchParams, setSearchParams] = useSearchParams();

  const dialog = searchParams.get("dialog");
  const permissionGroupId = searchParams.get("id");

  const { data: raw, isFetching } = useQuery([QUERY_KEY], () =>
    getPermissionGroups()
  );

  function add() {
    searchParams.set("dialog", "permission-group-add");
    setSearchParams(searchParams);
  }

  const data = raw ?? [];
  const selected = data.find(({ id }) => id.toString() === permissionGroupId);
  const isUsed = !!selected && selected.usedCount > 0;

  function onCloseDialog() {
    searchParams.delete("dialog");
    searchParams.delete("id");
    setSearchParams(searchParams, { replace: true });
  }

  const addDialog = {
    open: dialog === "permission-group-add",
    onClose: onCloseDialog,
    fetchKeys: [QUERY_KEY],
  };

  const editDialog = {
    data: selected,
    open: dialog === "permission-group-edit",
    onClose: onCloseDialog,
    fetchKeys: [QUERY_KEY],
  };

  const removeDialog = {
    data: selected,
    open: dialog === "permission-group-remove" && !isUsed,
    onClose: onCloseDialog,
    fetchKeys: [QUERY_KEY],
  };

  const alertDialog = {
    data: selected,
    open: dialog === "permission-group-remove" && isUsed,
    onClose: onCloseDialog,
  };

  return (
    <Card>
      <CardContent>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap={3}
          mb={5}
        >
          <Typography variant="h6">สิทธิ์การใช้งาน</Typography>
          <Button variant="contained" size="large" onClick={add}>
            เพิ่มสิทธิ์การใช้งาน
          </Button>
        </Stack>
        <TableContainer sx={{ height: "600px", overflowX: "hidden" }}>
          <Table stickyHeader>
            <TableHead>
              <PermissionGroupTableRowHeader />
            </TableHead>
            <DataTableBody
              loading={isFetching}
              data={data}
              searchKeys={["query"]}
            >
              {data.map((item) => (
                <PermissionGroupTableRow key={item.id} data={item} />
              ))}
            </DataTableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <AddPermissionGroupDialog {...addDialog} />
      <EditPermissionGroupDialog {...editDialog} />
      <RemovePermissionGroupDialog {...removeDialog} />
      <AlertRemovePermissionGroupDialog {...alertDialog} />
    </Card>
  );
}
