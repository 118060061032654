import * as yup from "yup";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { Control, useController, useForm, useWatch } from "react-hook-form";
import { Box, MenuItem, Typography } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "react-query";

import { FormConfirmDialog } from "@/components/FormConfirmDialog";
import { FormDatePicker } from "@/components/DatePicker";
import { FormTimeTextField } from "@/components/FormTimeTextField";
import { FormSelect } from "@/components/Select";
import { datetime } from "@/lib/yup/datetime";
import { fromFormat, now } from "@/lib/dateTime";
import { MemberAutocomplete } from "@/components/MemberAutocomplete";
import { setTime } from "@/utils";
import { configs } from "@/configs";
import { createTrainerBooking } from "@/services/trainer";
import { FormMemberProfileAutocomplete } from "@/components/FormMemberProfileAutocomplete";

import type { AxiosErrorWithData } from "@/client/api";
import type { QueryKey } from "react-query";
import type { MergeFormMemberProfileControl } from "@/components/FormMemberProfileAutocomplete";

const QUERY = "memberTrainerBooking";

type AddTrainerScheduleDialogProps = {
  open: boolean;
  onClose: (reason: "success" | "error" | "cancel") => void;
  trainerId: string;
  queryKey: QueryKey;
};

export function AddTrainerScheduleDialog({
  open,
  onClose,
  trainerId,
  queryKey,
}: AddTrainerScheduleDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { control, handleSubmit, reset } = useForm({
    defaultValues,
    resolver,
  });

  const { mutate: add, isLoading: loadingAdd } = useMutation(
    createTrainerBooking,
    {
      onSuccess: async () => {
        enqueueSnackbar("เพิ่มการนัดหมายสำเร็จ", { variant: "success" });
        // TODO: refactor
        await queryClient.refetchQueries(queryKey);
        close("success");
      },
      onError: (error: AxiosErrorWithData) => {
        console.error(error);
        enqueueSnackbar(
          error.response?.data.message ?? configs.unknownErrorMessage,
          { variant: "error" }
        );
      },
    }
  );

  function close(reason: "success" | "error" | "cancel") {
    reset();
    onClose(reason);
  }

  const onSubmit = handleSubmit((form) => {
    return add({
      startedAt: setTime(form.date, form.start),
      endedAt: setTime(form.date, form.end),
      memberId: form.member.id,
      staffId: +trainerId,
    });
  });
  const title = "เพิ่มการนัดหมาย";

  return (
    <FormConfirmDialog
      maxWidth="lg"
      control={control}
      title={title}
      loading={loadingAdd}
      open={open}
      onClose={() => close("cancel")}
      onConfirm={onSubmit}
    >
      <Box
        display="grid"
        gridTemplateColumns="220px 220px auto 220px 1fr"
        alignItems="center"
        gap={2.5}
        mb={5}
        position="absolute"
        zIndex={2000}
        bgcolor="background.paper"
        width="calc(100% - 48px)"
        height={104}
        mt={-1}
      >
        <FormDatePicker
          control={control}
          name="date"
          label="วันที่"
          TextFieldProps={{ required: true }}
          disablePast
        />
        <FormTimeTextField
          control={control}
          name="start"
          label="เวลาเริ่ม"
          required
          fullWidth
        />
        <Typography>-</Typography>
        <FormEndSelect control={control} />
        <FormSelect
          control={control}
          name="duration"
          label="ระยะเวลา"
          fullWidth
        >
          {[1, 2, 3].map((value) => (
            <MenuItem key={value} value={value}>
              {value} ชั่วโมง
            </MenuItem>
          ))}
        </FormSelect>
      </Box>
      <FormMemberProfileAutocomplete
        control={control as MergeFormMemberProfileControl} // TODO: remove cast type
        queryKey={QUERY}
        mt={12}
      />
    </FormConfirmDialog>
  );
}

const timeFormat = "HH:mm";

const defaultValues = {
  date: now(),
  start: "10:00",
  end: "11:00",
  duration: 1,
} as AddTrainerScheduleState;

const schema = yup.object({
  member: MemberAutocomplete.schema
    .label("หมายเลขสมาชิก ชื่อ หรือเบอร์โทรศัพท์")
    .required(),
  date: datetime().required().default(now()),
  start: yup.string().time().required().default("10:00"),
  end: yup.string().time().required().default("11:00"),
  duration: yup.number().required().default(1),
});

const resolver = yupResolver(schema);

export type AddTrainerScheduleState = yup.InferType<typeof schema>;

function FormEndSelect({
  control,
}: {
  control?: Control<AddTrainerScheduleState>;
}) {
  const duration = useWatch({ control, name: "duration" });
  const start = useWatch({ control, name: "start" });
  const {
    field: { onChange, value },
  } = useController({ control, name: "end" });

  useEffect(() => {
    onChange(
      fromFormat(start, timeFormat)
        .startOf("minute")
        .plus({ hour: duration })
        .toFormat(timeFormat)
    );
  }, [duration, start, onChange]);

  return (
    <FormTimeTextField
      control={control}
      value={value}
      name="end"
      label="เวลาสิ้นสุด"
      required
      fullWidth
      disabled
    />
  );
}
