import { FormConfirmDialog } from "@/components/FormConfirmDialog";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import * as validation from "@/lib/yup/validation";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Stack } from "@mui/material";

import { FormTextField } from "@/components/FormTextField";
import { FormNumericTextField } from "@/components/NumericTextField";

import type { Control } from "react-hook-form";

export type ProductEditorState = yup.InferType<typeof schema>;

type ProductEditorDialogProps = {
  open: boolean;
  title: string;
  onClose: () => void;
  onSubmit: () => void | PromiseLike<void>;
  loading: boolean;
  control: Control<ProductEditorState>;
};

export function ProductEditorDialog({
  open,
  title,
  onClose,
  onSubmit,
  loading,
  control,
}: ProductEditorDialogProps) {
  return (
    <FormConfirmDialog
      control={control}
      title={title}
      loading={loading}
      open={open}
      onClose={onClose}
      onConfirm={onSubmit}
    >
      <Stack gap={2.5}>
        <FormTextField
          control={control}
          name="name"
          label="ชื่อสินค้า"
          required
        />
        <FormNumericTextField
          control={control}
          name="price"
          label="ราคา (บาท)"
          allowZero
          required
        />
        <FormTextField
          control={control}
          name="description"
          label="รายละเอียด"
          multiline
          rows={4}
        />
      </Stack>
    </FormConfirmDialog>
  );
}

export function useProductEditorForm() {
  return useForm({ resolver, defaultValues });
}

const schema = yup.object({
  name: yup.string().label("ชื่อสินค้า").required(),
  description: yup.string().label("รายละเอียด").max(500),
  price: validation.price.label("ราคา (บาท)").required(),
});

const resolver = yupResolver(schema);

const defaultValues = {
  price: 0,
} as ProductEditorState;
