import { TableRow, TableCell, Typography } from "@mui/material";

import { calculateColumnWidth } from "@/utils";
import { Schedule, ScheduleStatus } from "@/models";
import { formatScheduleStatus } from "@/formatter";
import { TextEllipsis } from "@/components/TextEllipsis";
import { DateRangeDisplay } from "@/components/DateRangeDisplay";

const columnsWidth = [240, 464, 180, 200].map((width) =>
  calculateColumnWidth(width)
);

export function ClassHistoryTableHead() {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]}>วันที่และเวลา</TableCell>
      <TableCell width={columnsWidth[1]}>ชื่อคลาส</TableCell>
      <TableCell width={columnsWidth[2]}>สถานที่</TableCell>
      <TableCell width={columnsWidth[3]} align="center">
        สถานะ
      </TableCell>
    </TableRow>
  );
}

type ClassHistoryTableRowProps = {
  data: Schedule;
};

export function ClassHistoryTableRow({ data }: ClassHistoryTableRowProps) {
  const {
    startedAt,
    endedAt,
    class: classDetail,
    location,
    cancelledAt,
  } = data;

  const status = cancelledAt
    ? ScheduleStatus.Cancelled
    : ScheduleStatus.Completed;

  const statusColor =
    status === ScheduleStatus.Completed ? "success.main" : "text.disabled";

  return (
    <TableRow hover tabIndex={-1}>
      <TableCell>
        <DateRangeDisplay start={startedAt} end={endedAt} />
      </TableCell>
      <TableCell>
        <TextEllipsis variant="body2">{classDetail?.name}</TextEllipsis>
      </TableCell>
      <TableCell>{location}</TableCell>
      <TableCell align="center">
        <Typography color={statusColor}>
          {formatScheduleStatus(status)}
        </Typography>
      </TableCell>
    </TableRow>
  );
}
