import { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

import { FormConfirmDialog } from "@/components/FormConfirmDialog";
import { getApiErrorMessage, refetchQueries } from "@/utils";
import { PermissionGroupType } from "@/models";
import { editStaffPermissionGroup } from "@/services/staff";
import {
  ChangePermissionEditor,
  useChangePermissionEditorForm,
} from "@/features/member/components/ChangePermissionEditor";

import type { Staff } from "@/models";
import type { AxiosErrorWithData } from "@/client/api";

type ChangeStaffPermissionDialogProps = {
  open: boolean;
  staff?: Staff;
  onClose: () => void;
  fetchKeys?: string[];
};

export function ChangeStaffPermissionDialog({
  open: isOpen,
  staff,
  onClose,
  fetchKeys = [],
}: ChangeStaffPermissionDialogProps) {
  const { control, handleSubmit, reset } = useChangePermissionEditorForm();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isOpen && staff) {
      reset({ permissionGroupId: staff.doorPermission?.id });
    }
  }, [isOpen, staff, reset]);

  const { mutate: update, isLoading } = useMutation(editStaffPermissionGroup, {
    onSuccess: async () => {
      enqueueSnackbar("แก้ไขสิทธิ์การใช้งานสำเร็จ", { variant: "success" });
      await refetchQueries({ queryClient, fetchKeys });
      close();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(getApiErrorMessage(error), { variant: "error" });
    },
  });

  function close() {
    onClose();
    reset();
  }

  const onSubmit = handleSubmit((form) => {
    if (!staff) return;

    update({ staffId: staff?.id, data: form });
  });

  const title = "แก้ไขสิทธิ์การใช้งานประตู";

  return (
    <FormConfirmDialog
      maxWidth="lg"
      control={control}
      title={title}
      loading={isLoading}
      open={isOpen}
      onClose={close}
      onConfirm={onSubmit}
    >
      <ChangePermissionEditor
        type={PermissionGroupType.staff}
        control={control}
      />
    </FormConfirmDialog>
  );
}
