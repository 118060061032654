import { useController } from "react-hook-form";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from "@mui/material";
import * as yup from "yup";

import { TrainingGoal } from "@/models";
import { formatTrainingGoal } from "@/formatter";

import type { ControllerProps, FieldPath, FieldValues } from "react-hook-form";

export function FormTrainingGoalCheckBoxGroup<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  label,
}: Pick<ControllerProps<TFieldValues, TName>, "name" | "control"> & {
  label: string;
}) {
  const { field, fieldState } = useController({
    name,
    control,
  });
  const values = field.value as TrainingGoal[];

  const goals = [
    TrainingGoal.BodyComposition,
    TrainingGoal.GeneralHealth,
    TrainingGoal.SkillRelated,
    TrainingGoal.SportPerformance,
    TrainingGoal.Other,
  ];

  return (
    <FormControl
      component="fieldset"
      variant="standard"
      error={!!fieldState.error}
    >
      <FormGroup row>
        {goals.map((goal) => {
          const goalLabel = formatTrainingGoal(goal);

          const handleChange = (
            event: React.ChangeEvent<HTMLInputElement>,
            newGoal: TrainingGoal
          ) => {
            const newGoals = event.target.checked
              ? [...values, newGoal]
              : values.filter((value) => value !== newGoal);

            field.onChange(newGoals);
            field.onBlur();
          };

          return (
            <FormControlLabel
              key={goal}
              control={
                <Checkbox
                  checked={values.includes(goal)}
                  onChange={(event) => handleChange(event, goal)}
                  name={goalLabel}
                />
              }
              label={goalLabel}
            />
          );
        })}
      </FormGroup>
      {fieldState.error && <FormHelperText>ระบุ{label}</FormHelperText>}
    </FormControl>
  );
}

FormTrainingGoalCheckBoxGroup.schema = yup
  .array()
  .of(yup.string().oneOf(Object.values(TrainingGoal)).required())
  .default([]);
