import { TableCell, TableRow } from "@mui/material";

import { TextEllipsis } from "@/components/TextEllipsis";
import { calculateColumnWidth } from "@/utils";
import { formatDate, formatPrice } from "@/formatter";
import { fromISO } from "@/lib/dateTime";
import { PackageItem } from "./MemberVerifyDialog";

const columnsWidth = [228, 172, 152].map((item) => calculateColumnWidth(item));

export function MemberPackageTableRowHeader() {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]}>ประเภท</TableCell>
      <TableCell width={columnsWidth[1]}>วันสิ้นสุด</TableCell>
      <TableCell width={columnsWidth[2]}>ราคา(บาท)</TableCell>
    </TableRow>
  );
}

type Package = {
  key: number;
  data: PackageItem;
};

export function MemberPackageTableRow({ data }: Package) {
  const { name, expireDate, price } = data;

  return (
    <TableRow>
      <TableCell>
        <TextEllipsis variant="body2">{name}</TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis variant="body2">
          {formatDate(fromISO(expireDate))}
        </TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis variant="body2">{formatPrice(price)}</TextEllipsis>
      </TableCell>
    </TableRow>
  );
}
