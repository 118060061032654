import {
  Autocomplete as MuiAutocomplete,
  Checkbox,
  TextField,
} from "@mui/material";
import * as React from "react";
import { useController } from "react-hook-form";
import { useQuery } from "react-query";

import { getAvailableTrainers } from "@/services/trainer";

import type {
  AutocompleteProps as MuiAutocompleteProps,
  TextFieldProps,
} from "@mui/material";
import type { ControllerProps, FieldPath, FieldValues } from "react-hook-form";
import type { DateTime } from "luxon";
import { formatProfile } from "@/formatter";

export type TrainerAutocompleteValue = { id: number; name: string };

export type TrainerAutocompleteProps = {
  start: DateTime;
  end: DateTime;
  excludeScheduleId?: string;
  label?: string;
  TextFieldProps?: Partial<TextFieldProps>;
} & Omit<
  MuiAutocompleteProps<TrainerAutocompleteValue, true, false, false>,
  "renderInput" | "options"
>;

export function TrainerAutocomplete({
  start,
  end,
  excludeScheduleId,
  label,
  TextFieldProps,
  ...props
}: TrainerAutocompleteProps) {
  const { data, isFetching } = useQuery(
    ["availableTrainers", start.toISO(), end.toISO(), excludeScheduleId],
    () => getAvailableTrainers({ start, end, excludeScheduleId })
  );

  const options =
    data?.map(({ id, profile }) => ({ id, name: formatProfile(profile) })) ??
    [];

  return (
    <MuiAutocomplete
      multiple
      disableCloseOnSelect
      options={options}
      loading={isFetching}
      getOptionLabel={({ name }) => name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, option, { selected }) => {
        return (
          <li {...props} key={option.id}>
            <Checkbox checked={selected} />
            {option.name}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField label={label} {...params} {...TextFieldProps} />
      )}
      {...props}
    />
  );
}

export function FormTrainerAutocomplete<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  required = false,
  ...props
}: { required?: boolean } & Pick<
  ControllerProps<TFieldValues, TName>,
  "name" | "control"
> &
  Omit<
    TrainerAutocompleteProps,
    "value" | "name" | "TextFieldProps" | "onChange"
  >) {
  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <TrainerAutocomplete
      value={field.value}
      onChange={(_, newValue) => field.onChange(newValue)}
      TextFieldProps={{
        inputRef: field.ref,
        onBlur: field.onBlur,
        error: fieldState.invalid,
        helperText: fieldState.error?.message,
        required,
      }}
      {...props}
    />
  );
}
