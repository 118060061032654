import { Box, MenuItem, Stack } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { ControllerProps, useForm, useWatch } from "react-hook-form";
import * as yup from "yup";

import { FormTextField } from "@/components/FormTextField";
import { FormNumericTextField } from "@/components/NumericTextField";
import { FormSelect } from "@/components/Select";
import { FormBranchAutocomplete } from "@/components/BranchAutocomplete";
import { formatQuotaUnit } from "@/formatter";
import * as validation from "@/lib/yup/validation";
import { QuotaUnit } from "@/models";
import { useCurrentUser } from "@/features/authentication/contexts/AuthenticationContext";

import type { InferType } from "yup";

export type ProductPTEditorState = InferType<typeof schema>;

type ProductPTEditorProps = Pick<
  ControllerProps<ProductPTEditorState>,
  "control"
>;

export function ProductPTEditor({ control }: ProductPTEditorProps) {
  const type = useWatch({
    control,
    name: "type",
  });
  const user = useCurrentUser();
  return (
    <Stack gap={2.5}>
      <Box display="grid" gridTemplateColumns="repeat(4, 1fr)" gap={2.5}>
        <FormTextField
          sx={{ gridColumn: "1 / span 2" }}
          control={control}
          name="name"
          label="ชื่อแพ็กเกจเทรนเนอร์"
          required
        />
        <FormNumericTextField
          control={control}
          name="quota"
          label="จำนวน"
          required
        />
        <FormSelect control={control} name="quotaUnit" label="หน่วย" required>
          {[QuotaUnit.hour, QuotaUnit.times].map((key) => (
            <MenuItem key={key} value={key}>
              {formatQuotaUnit(key)}
            </MenuItem>
          ))}
        </FormSelect>
        <FormNumericTextField
          control={control}
          name="duration"
          label="ระยะเวลา"
          required
        />
        <FormSelect
          control={control}
          name="durationUnit"
          label="หน่วย"
          required
        >
          <MenuItem value="day">วัน</MenuItem>
          <MenuItem value="month">เดือน</MenuItem>
          <MenuItem value="year">ปี</MenuItem>
        </FormSelect>
        <FormNumericTextField
          sx={{ gridColumn: "3 / span 2" }}
          control={control}
          name="price"
          label="ราคา (บาท)"
          allowZero
          required
        />
      </Box>
      <FormSelect
        control={control}
        name="type"
        label="สาขาที่เข้าใช้บริการ"
        required
      >
        <MenuItem value="only">เฉพาะสาขานี้</MenuItem>
        {user.isAdmin && <MenuItem value="every">ทุกสาขา</MenuItem>}
        {user.branches.length > 1 && <MenuItem value="some">บางสาขา</MenuItem>}
      </FormSelect>
      {type === "some" && (
        <FormBranchAutocomplete
          label="สาขา"
          name="branches"
          control={control}
          required
        />
      )}
      <FormTextField
        control={control}
        name="description"
        label="รายละเอียด"
        multiline
        rows={4}
        required
      />
    </Stack>
  );
}

export function useProductPTEditorForm() {
  return useForm({
    resolver,
    defaultValues,
  });
}

const schema = yup.object({
  name: yup.string().label("ชื่อแพ็กเกจเทรนเนอร์").required(),
  duration: yup
    .number()
    .label("ระยะเวลา")
    .required()
    .min(1, "${label}ต้องมากกว่า ${min}"),
  durationUnit: yup
    .string()
    .oneOf<"day" | "month" | "year">(["day", "month", "year"])
    .required(),
  quota: yup
    .number()
    .label("จำนวน")
    .required()
    .min(0, "${label}ต้องมากกว่า ${min}"),
  quotaUnit: yup.string().oneOf(Object.values(QuotaUnit)).required(),
  type: yup
    .string()
    .oneOf(["every", "some", "only"] as const)
    .required("ระบุสาขาที่เข้าใช้บริการ"),
  branches: yup
    .array(
      yup.object({
        id: yup.number().required(),
        name: yup.string().required(),
      })
    )
    .default([])
    .when("type", {
      is: "some",
      then: (schema) => schema.required().min(1, "ระบุสาขา"),
      otherwise: (schema) => schema,
    }),
  description: yup.string().label("รายละเอียด").max(500).required(),
  price: validation.price.label("ราคา").required(),
});
const resolver = yupResolver(schema);

const defaultValues = {
  durationUnit: "month",
  description: "",
  quotaUnit: QuotaUnit.hour,
  price: 0,
} as ProductPTEditorState;
