import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Stack,
  Table,
  TableContainer,
  TableHead,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useAtom } from "jotai";

import { mapOptional, pickListTableParams } from "@/utils";
import { formatScheduleStatus } from "@/formatter";
import { SearchParamsPagination } from "@/components/SearchParamsPagination";
import { DataTableBody } from "@/components/DataTableBody";
import { getClassSchedules } from "@/services/class";
import { ScheduleStatus } from "@/models";
import { selectedBranchAtom } from "@/atom/global";

import { ScheduleTableRow, ScheduleTableRowHeader } from "./ScheduleTableRow";
import { AddScheduleDialog } from "./AddScheduleDialog";
import { RemoveScheduleDialog } from "../../components/RemoveScheduleDialog";
import { EditScheduleDialog } from "../../components/EditScheduleDialog";

export const CLASS_SCHEDULES_QUERY_KEY = "classSchedules";

export function ScheduleList({
  classId,
  isPublish,
}: {
  classId: string;
  isPublish: boolean;
}) {
  const [selectedBranch] = useAtom(selectedBranchAtom);
  const [searchParams, setSearchParams] = useSearchParams();
  const query = pickListTableParams(searchParams);

  const { data: raw, isFetching } = useQuery(
    [CLASS_SCHEDULES_QUERY_KEY, query, selectedBranch],
    () => getClassSchedules(query, classId)
  );

  const status = searchParams.get("status") as ScheduleStatus | undefined;
  const setStatus = (s: ScheduleStatus | null) => () => {
    if (s) {
      searchParams.set("status", s);
    } else {
      searchParams.delete("status");
    }

    searchParams.delete("page");
    setSearchParams(searchParams);
  };

  const data = raw?.data ?? [];
  const total = raw?.total ?? 0;
  const dialog = searchParams.get("dialog");

  const id = searchParams.get("id") ?? "";
  const editData = data.find((item) => item.id === +id);

  function add() {
    searchParams.set("dialog", "schedule-add");
    setSearchParams(searchParams);
  }

  function onCloseDialog() {
    searchParams.delete("dialog");
    setSearchParams(searchParams, { replace: true });
  }

  const addDialog = {
    open: dialog === "schedule-add",
    onClose: onCloseDialog,
    fetchKeys: [CLASS_SCHEDULES_QUERY_KEY],
  };

  const editDialog = {
    open: typeof editData !== "undefined" && dialog === "schedule-edit",
    id,
    onClose: onCloseDialog,
    data: editData,
    fetchKeys: [CLASS_SCHEDULES_QUERY_KEY],
  };

  const removeDialog = {
    open: dialog === "schedule-remove",
    id,
    onClose: onCloseDialog,
    fetchKeys: [CLASS_SCHEDULES_QUERY_KEY],
  };

  return (
    <>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap={2}
            mb={5}
          >
            <Typography variant="h6">ตารางเวลา</Typography>
            <Stack direction="row" gap={2}>
              <ButtonGroup variant="contained" size="large" color="inherit">
                {[
                  null,
                  ScheduleStatus.Active,
                  ScheduleStatus.Upcoming,
                  ScheduleStatus.Completed,
                  ScheduleStatus.Cancelled,
                ].map((s) => (
                  <Button
                    key={s}
                    onClick={setStatus(s)}
                    sx={{ bgcolor: s === status ? "grey.100" : "grey.300" }}
                  >
                    {mapOptional(s, formatScheduleStatus) ?? "ทั้งหมด"}
                  </Button>
                ))}
              </ButtonGroup>
              <Button
                variant="contained"
                size="large"
                onClick={add}
                disabled={!isPublish}
              >
                เพิ่มตารางเวลา
              </Button>
            </Stack>
          </Stack>
          <TableContainer sx={{ height: "600px", overflowX: "hidden" }}>
            <Table stickyHeader>
              <TableHead>
                <ScheduleTableRowHeader />
              </TableHead>
              <DataTableBody
                loading={isFetching}
                data={data}
                searchKeys={["query", "status"]}
              >
                {data.map((item) => (
                  <ScheduleTableRow key={item.id} data={item} />
                ))}
              </DataTableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <SearchParamsPagination total={total} />
      <AddScheduleDialog {...addDialog} />
      <EditScheduleDialog {...editDialog} />
      <RemoveScheduleDialog {...removeDialog} />
    </>
  );
}
