import { api } from "@/client/api";
import { configs } from "@/configs";
import { Customer, Following } from "@/models";
import { parseDateTimeFromPrisma, parseISOToPrisma } from "@/utils";

type GetCustomersInput = {
  query?: string;
  saleId: string;
};

type GetCustomers = {
  data: Customer[];
  total: number;
};

type GetCustomerInput = {
  saleId: string;
  customerId: string;
};

type CreateCustomerInput = Omit<
  Customer,
  | "id"
  | "createdAt"
  | "status"
  | "followings"
  | "customerStatusStamps"
  | "customerCloseStamps"
  | "customerUnqualifiedStamps"
  | "friend"
  | "latestRemark"
  | "productMembershipId"
  | "productMembership"
  | "productPersonalTrainingId"
  | "productPersonalTraining"
  | "chance"
  | "productTotal"
  | "productCloseAt"
  | "purchaseProductMembership"
  | "purchaseProductPersonalTraining"
  | "purchaseTotal"
  | "purchaseAt"
  | "member"
> & {
  following: Omit<Following, "createdAt">;
  saleId: string;
};

export async function getCustomers({ saleId, query = "" }: GetCustomersInput) {
  const { data } = await api.get<GetCustomers>(
    `${configs.apiUrl}/sales/${saleId}/customers?${query}`
  );
  return parseDateTimeFromPrisma(data) as GetCustomers;
}

export async function getCustomer({
  saleId,
  customerId,
}: GetCustomerInput): Promise<Customer> {
  const response = await api.get(
    `${configs.apiUrl}/sales/${saleId}/customers/${customerId}`
  );
  return parseDateTimeFromPrisma(response.data) as Customer;
}

export async function createCustomer({
  saleId,
  ...data
}: CreateCustomerInput): Promise<Customer> {
  const response = await api.post(
    `${configs.apiUrl}/sales/${saleId}/customers`,
    parseISOToPrisma(data)
  );
  return parseDateTimeFromPrisma(response.data) as Customer;
}
