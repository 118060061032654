import {
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";

import { TextEllipsis } from "@/components/TextEllipsis";
import { calculateColumnWidth } from "@/utils";
import {
  formatDate,
  formatFacilityBookingStatus,
  formatFacilityBookingStatusColor,
  formatTimeRange,
} from "@/formatter";

import type { FacilityBooking } from "@/models";
import type { MouseEventHandler } from "react";

const columnsWidth = [290, 234, 184, 184, 120, 72].map((item) =>
  calculateColumnWidth(item)
);

export type FacilityBookingTableRowProps = {
  data: FacilityBooking;
};

export function FacilityBookingTableRowHeader() {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]}>ชื่อ</TableCell>
      <TableCell width={columnsWidth[1]}>เลขสมาชิก</TableCell>
      <TableCell width={columnsWidth[2]}>วันที่จอง</TableCell>
      <TableCell width={columnsWidth[3]}>เวลาที่จอง</TableCell>
      <TableCell width={columnsWidth[4]} align="center">
        สถานะการจอง
      </TableCell>
      <TableCell width={columnsWidth[5]}></TableCell>
    </TableRow>
  );
}

export function FacilityBookingTableRow({
  data,
}: FacilityBookingTableRowProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const open = Boolean(anchorEl);

  const {
    id,
    startedAt,
    endedAt,
    status,
    members: {
      code,
      profile: { firstName, lastName },
    },
  } = data;

  const onClickMenu: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const onClickSeeMore: MouseEventHandler<HTMLLIElement> = (event) => {
    event.stopPropagation();
    searchParams.set("dialog", "booking-detail");
    searchParams.set("booking-id", id.toString());
    setSearchParams(searchParams);
    setAnchorEl(null);
  };

  return (
    <TableRow>
      <TableCell>
        <TextEllipsis variant="body2">{`${firstName} ${lastName}`}</TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis variant="body2">{code}</TextEllipsis>
      </TableCell>
      <TableCell>{formatDate(startedAt)}</TableCell>
      <TableCell>{formatTimeRange(startedAt, endedAt)}</TableCell>
      <TableCell
        align="center"
        sx={{ color: formatFacilityBookingStatusColor(status) }}
      >
        {formatFacilityBookingStatus(status)}
      </TableCell>
      <TableCell>
        <Tooltip title="เมนู">
          <IconButton aria-label="Menu" onClick={onClickMenu}>
            <MoreHoriz />
          </IconButton>
        </Tooltip>
        <Menu
          open={open}
          onClose={() => setAnchorEl(null)}
          anchorEl={anchorEl}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem onClick={onClickSeeMore} sx={{ width: 175 }}>
            ดูข้อมูลเพิ่มเติม
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
}
