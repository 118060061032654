import { useFieldArray, Controller, ControllerProps } from "react-hook-form";
import { Fragment } from "react";
import { FormControl, FormControlLabel, Typography, Box } from "@mui/material";
import * as yup from "yup";

import { FormCheckbox } from "@/components/FormCheckbox";
import { formatDay } from "@/formatter";
import { FormTimeSelect } from "@/components/TimeSelect";
import { timeAfter } from "@/lib/yup/validation";

import type { TrainerEditorState } from "@/features/staff/pages/StaffProfilePage/TrainerEditor";
import { Day } from "@/models";

export function TrainerWorkingDayEditor({
  control,
}: Pick<ControllerProps<TrainerEditorState>, "control">) {
  const { fields } = useFieldArray({ control, name: "workingDay" });

  return (
    <Box
      display="grid"
      gridTemplateColumns="140px 1fr 8px 1fr"
      columnGap={1}
      rowGap={2.5}
    >
      {fields.map((field, index) => {
        return (
          <Fragment key={field.id}>
            <FormControl sx={{ width: 160, justifyContent: "center" }}>
              <FormControlLabel
                control={
                  <FormCheckbox
                    name={`workingDay.${index}.selected`}
                    control={control}
                  />
                }
                label={formatDay(field.day)}
                labelPlacement="end"
              />
            </FormControl>
            <Controller
              name={`workingDay.${index}.selected`}
              control={control}
              render={({ field: { value } }) => (
                <Fragment>
                  <FormTimeSelect
                    label="เวลาเข้างาน"
                    id={`workingDay.${index}.startTime`}
                    name={`workingDay.${index}.startTime`}
                    control={control}
                    disabled={!value}
                  />
                  <Typography variant="body1" mt={1.75}>
                    -
                  </Typography>
                  <FormTimeSelect
                    label="เวลาออกงาน"
                    id={`workingDay.${index}.endTime`}
                    name={`workingDay.${index}.endTime`}
                    control={control}
                    disabled={!value}
                  />
                </Fragment>
              )}
            />
          </Fragment>
        );
      })}
    </Box>
  );
}

const schema = yup.object({
  day: yup.string().oneOf(Object.values(Day)).required(),
  selected: yup.boolean().required(),
  startTime: yup
    .string()
    .label("เวลาเข้างาน")
    .when("selected", {
      is: true,
      then: (schema) => schema.required().time(),
    }) as yup.StringSchema,
  endTime: yup
    .string()
    .label("เวลาออกงาน")
    .when("selected", {
      is: true,
      then: (schema) =>
        timeAfter(
          "startTime",
          "End time must be after ${startTime}",
          schema.required().time()
        ),
    }) as yup.StringSchema,
});

TrainerWorkingDayEditor.schema = yup.array().of(schema).required();

const dayList = [
  Day.Monday,
  Day.Tuesday,
  Day.Wednesday,
  Day.Thursday,
  Day.Friday,
  Day.Saturday,
  Day.Sunday,
];

const defaultValue = {
  selected: false,
  startTime: "10:00",
  endTime: "18:00",
};

TrainerWorkingDayEditor.defaultValue = dayList.map((day) => ({
  ...defaultValue,
  day,
}));
