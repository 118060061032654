import { useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import { calculateColumnWidth } from "@/utils";
import { formatPermissionGroupType } from "@/formatter";

import type { PermissionDoor } from "@/models";
import type { MouseEventHandler } from "react";

const columnsWidth = [240, 240, 378, 226].map((width) =>
  calculateColumnWidth(width)
);

export function PermissionDoorTableRowHeader() {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]}>ชื่อสิทธิ์การใช้งานประตู</TableCell>
      <TableCell width={columnsWidth[1]}>สิทธิ์ผู้ใช้</TableCell>
      <TableCell width={columnsWidth[2]}>ประตูที่ใช้งานได้</TableCell>
      <TableCell width={columnsWidth[3]} align="center" />
    </TableRow>
  );
}

export type PermissionDoorTableRowProps = { data: PermissionDoor };

export function PermissionDoorTableRow({ data }: PermissionDoorTableRowProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const { name, type, doors } = data;

  const hasDoor = doors.length > 0;
  const hasSeeMore = doors.length > 5;

  const limitedDoors = doors.slice(0, 5);

  const openDialog = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    dialogName: string
  ) => {
    event.stopPropagation();
    searchParams.set("dialog", dialogName);
    searchParams.set("id", data.id.toString());
    setSearchParams(searchParams);
  };

  const handleEdit: MouseEventHandler<HTMLButtonElement> = (event) =>
    openDialog(event, "permission-door-edit");

  const onClickSeeMore: MouseEventHandler<HTMLButtonElement> = (event) =>
    openDialog(event, "permission-door-see-more");

  return (
    <TableRow hover>
      <TableCell>{name}</TableCell>
      <TableCell>{formatPermissionGroupType(type)}</TableCell>
      <TableCell>
        {hasDoor
          ? limitedDoors.map(({ id, name }) => (
              <Box key={id} component="li">
                {name}
              </Box>
            ))
          : "-"}
        {hasSeeMore && (
          <Button size="small" onClick={onClickSeeMore}>
            ดูเพิ่มเติม
          </Button>
        )}
      </TableCell>
      <TableCell align="center">
        {hasDoor ? (
          <Tooltip title="แก้ไข">
            <IconButton onClick={handleEdit}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Button variant="outlined" onClick={handleEdit}>
            เลือกประตู
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
}
