import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Stack } from "@mui/material";
import * as yup from "yup";

import { PermissionGroupType } from "@/models";

import { FormPermissionGroupSelect } from "./PermissionGroupSelect";

import type { InferType } from "yup";
import type { Control } from "react-hook-form";

type ChangePermissionEditorProps = {
  type: PermissionGroupType;
  control: Control<ChangePermissionEditorState>;
};

export type ChangePermissionEditorState = InferType<typeof schema>;

export function ChangePermissionEditor({
  type,
  control,
}: ChangePermissionEditorProps) {
  return (
    <Stack>
      <FormPermissionGroupSelect
        required
        type={type}
        label="ชื่อสิทธิ์การใช้งานประตู"
        name="permissionGroupId"
        control={control}
      />
    </Stack>
  );
}

export function useChangePermissionEditorForm() {
  return useForm({
    resolver,
    defaultValues: {} as ChangePermissionEditorState,
  });
}

const schema = yup.object({
  permissionGroupId: FormPermissionGroupSelect.schema,
});

const resolver = yupResolver(schema);
