import { useState } from "react";
import {
  IconButton,
  Link,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import type { IconButtonProps } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import { calculateColumnWidth, getBookingStatus } from "@/utils";
import { BookingStatus } from "@/models";
import { transformISOToDateTime } from "@/lib/dateTime";

import { BookingStatusLabel } from "./BookingStatusLabel";

import type { Booking } from "@/models";

const columnsWidth = [180, 242, 140, 232, 140, 100, 47].map((width) =>
  calculateColumnWidth(width)
);

export function ReservationTableRowHeader() {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]} align="center">
        วันที่และเวลาที่จอง
      </TableCell>
      <TableCell width={columnsWidth[1]}>ชื่อ</TableCell>
      <TableCell width={columnsWidth[2]}>ชื่อเล่น</TableCell>
      <TableCell width={columnsWidth[3]}>อีเมล</TableCell>
      <TableCell width={columnsWidth[4]}>เบอร์โทร</TableCell>
      <TableCell width={columnsWidth[5]} align="center">
        สถานะ
      </TableCell>
      <TableCell width={columnsWidth[6]} />
    </TableRow>
  );
}

export type ParticipantTableRowProps = { data: Booking };

export function ReservationTableRow({ data }: ParticipantTableRowProps) {
  const {
    member: {
      profile: { nickname, firstName, lastName, email, phoneNo },
    },
    createdAt,
  } = data;
  const status = getBookingStatus(data);
  const name = `${firstName} ${lastName}`;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const onClickMenu: IconButtonProps["onClick"] = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onClose = () => {
    setAnchorEl(null);
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const onClickCancel = () => {
    searchParams.set("dialog", "cancel");
    searchParams.set("bookingId", data.id.toString());
    setSearchParams(searchParams);
    onClose();
  };
  const showMenu = status !== BookingStatus.Cancelled;

  return (
    <TableRow>
      <TableCell align="center">
        {transformISOToDateTime(createdAt.toString())}
      </TableCell>
      <TableCell>
        <Link
          color="inherit"
          target="_blank"
          href={`/members/${data.member.id}?status=BOOKED#booking`}
        >
          {name}
        </Link>
      </TableCell>
      <TableCell>{nickname}</TableCell>
      <TableCell>{email}</TableCell>
      <TableCell>{phoneNo}</TableCell>
      <TableCell align="center">
        <BookingStatusLabel variant="subtitle2" status={status} />
      </TableCell>
      <TableCell sx={{ px: 0 }} align="center">
        {showMenu && (
          <>
            <Tooltip title="เมนู">
              <IconButton onClick={onClickMenu}>
                <MoreHoriz />
              </IconButton>
            </Tooltip>
            <Menu anchorEl={anchorEl} open={open} onClose={onClose}>
              <MenuItem onClick={onClickCancel}>ยกเลิกการจอง</MenuItem>
            </Menu>
          </>
        )}
      </TableCell>
    </TableRow>
  );
}
