import {
  Box,
  IconButton,
  Link as MuiLink,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Fragment, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

import {
  formatDate,
  formatDurationISO,
  formatName,
  formatQuotaUnit,
} from "@/formatter";

import { EditProductDialog } from "@/features/member/pages/MemberProfilePage/EditProductDialog";
import { Membership, PersonalTrainingQuota, PurchaseType } from "@/models";
import { mapOptional } from "@/utils";

export type PurchaseProductDetailProps =
  | {
      memberId: number;
      type: PurchaseType.Membership;
      data: Membership | null;
    }
  | {
      memberId: number;
      type: PurchaseType.ProductPT;
      data: PersonalTrainingQuota | null;
    };

const configMap: Record<
  PurchaseProductDetailProps["type"],
  {
    title: string;
    defaultValue: {
      label: string;
      value: string;
    }[];
  }
> = {
  [PurchaseType.Membership]: {
    title: "แพ็กเกจสมาชิก",
    defaultValue: [
      {
        label: "ชื่อสมาชิก",
        value: "-",
      },
      {
        label: "ระยะเวลา",
        value: "-",
      },
      {
        label: "วันเริ่มต้น - สิ้นสุด",
        value: "-",
      },
      {
        label: "หมายเหตุ",
        value: "-",
      },
    ],
  },
  [PurchaseType.ProductPT]: {
    title: "แพ็กเกจเทรนเนอร์",
    defaultValue: [
      {
        label: "ชื่อแพ็กเกจ",
        value: "-",
      },
      {
        label: "ระยะเวลา",
        value: "-",
      },
      {
        label: "จำนวน (คงเหลือ)",
        value: "-",
      },
      {
        label: "วันเริ่มต้น - สิ้นสุด",
        value: "-",
      },
      {
        label: "หมายเหตุ",
        value: "-",
      },
    ],
  },
};

export function PurchaseProductDetail({
  memberId,
  data,
  type,
}: PurchaseProductDetailProps) {
  const [product, setProduct] = useState<
    Membership | PersonalTrainingQuota | null
  >(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { title, defaultValue } = configMap[type];

  const dialog = searchParams.get("dialog");

  function onCloseDialog() {
    searchParams.delete("dialog");
    setSearchParams(searchParams);
  }

  function openEditDialog() {
    setProduct(data);
    searchParams.set("dialog", "product-edit");
    setSearchParams(searchParams);
  }

  const columns = !data
    ? defaultValue
    : type === PurchaseType.Membership
    ? [
        {
          label: "ชื่อแพ็กเกจ",
          value: (
            <Stack direction="row" justifyContent="space-between">
              <MuiLink
                component={Link}
                color="inherit"
                underline="always"
                to={`/members/${memberId}/membership/${data.product.id}`}
              >
                {data.product.name || "-"}
              </MuiLink>
              <Tooltip title="แก้ไข" sx={{ mt: -1.5 }}>
                <IconButton size="small" onClick={openEditDialog}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          ),
        },
        {
          label: "ระยะเวลา",
          value:
            formatDurationISO(data.product.productMembership.durationIso) ||
            "-",
        },
        {
          label: "วันเริ่มต้น - สิ้นสุด",
          value:
            data.startedAt && data.endedAt
              ? `${formatDate(data.startedAt)} - ${formatDate(data.endedAt)}`
              : "-",
        },
        {
          label: "ชื่อพนักงานขาย",
          value: mapOptional(data.receipt?.staffs.profile, formatName) ?? "-",
        },
        {
          label: "หมายเหตุ",
          value: data.remark || "-",
        },
      ]
    : type === PurchaseType.ProductPT
    ? [
        {
          label: "ชื่อแพ็กเกจ",
          value: (
            <Stack direction="row" justifyContent="space-between">
              <MuiLink
                component={Link}
                color="inherit"
                underline="always"
                to={`/members/${memberId}/pt/${data.product.id}`}
              >
                {data.product.name || "-"}
              </MuiLink>
              <Tooltip title="แก้ไข" sx={{ mt: -1.5 }}>
                <IconButton size="small" onClick={openEditDialog}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          ),
        },
        {
          label: "ระยะเวลา",
          value:
            formatDurationISO(
              data.product.productPersonalTraining.durationIso
            ) || "-",
        },
        {
          label: "จำนวน (คงเหลือ)",
          value: `${
            data.product.productPersonalTraining.quota - data.useCount // TODO: refactor
          } / ${data.product.productPersonalTraining.quota} ${formatQuotaUnit(
            data.product.productPersonalTraining.quotaUnit
          )}`,
        },
        {
          label: "วันเริ่มต้น - สิ้นสุด",
          value:
            data.startedAt && data.endedAt
              ? `${formatDate(data.startedAt)} - ${formatDate(data.endedAt)}`
              : "-",
        },
        {
          label: "ชื่อพนักงานขาย",
          value: mapOptional(data.receipt?.staffs.profile, formatName) ?? "-",
        },
        {
          label: "หมายเหตุ",
          value: data.remark || "-",
        },
      ]
    : [];

  const editProductDialog = {
    open: dialog === "product-edit",
    type,
    memberId,
    onClose: onCloseDialog,
    fetchKeys: [`member_${memberId}`],
  };

  return (
    <Box
      display="grid"
      gridTemplateColumns="150px 1fr"
      gap={(theme) => `${theme.spacing(2, 5)}`}
      alignItems="baseline"
      width="100%"
    >
      <Typography
        variant="subtitle1"
        color="text.secondary"
        sx={{ gridColumn: "1/-1" }}
      >
        {title}
      </Typography>
      {columns.map(({ label, value }) => (
        <Fragment key={`${label}`}>
          <Typography variant="subtitle2" color="text.disabled">
            {label}
          </Typography>
          {typeof value === "string" ? (
            <Typography variant="body2">{value}</Typography>
          ) : (
            value
          )}
        </Fragment>
      ))}
      {product && <EditProductDialog {...editProductDialog} data={product} />}
    </Box>
  );
}
