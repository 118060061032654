import { EmptyResultIcon } from "@/components/Icon";
import {
  CircularProgress,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";

import type { PropsWithChildren } from "react";
import { useSearchParams } from "react-router-dom";

export type DataTableBodyProps<TData> = {
  loading: boolean;
  data: TData[];
  searchKeys?: string[];
};
export function DataTableBody<TData>({
  children,
  loading,
  data,
  searchKeys = ["query"],
}: PropsWithChildren<DataTableBodyProps<TData>>) {
  const [searchParams] = useSearchParams();
  const isSearching = Array.from(searchParams.entries()).some(
    ([key, value]) => searchKeys.includes(key) && value
  );
  const isShowLoading = loading;
  const isShowEmpty = !isShowLoading && data.length === 0;
  const variant = data.length === 0 && isSearching ? "search" : "list";

  return (
    <TableBody>
      {!isShowLoading && children}
      {isShowLoading && (
        <TableRow sx={{ height: "500px" }}>
          <TableCell colSpan={99} sx={{ border: 0, textAlign: "center" }}>
            <CircularProgress disableShrink />
          </TableCell>
        </TableRow>
      )}
      {isShowEmpty && (
        <TableRow sx={{ height: "500px" }}>
          <TableCell colSpan={99} sx={{ border: 0, textAlign: "center" }}>
            <EmptyResultIcon variant={variant} />
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
}
