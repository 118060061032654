import {
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DateTime } from "luxon";

import { TextEllipsis } from "@/components/TextEllipsis";
import { calculateColumnWidth } from "@/utils";
import {
  formatCustomerStatus,
  formatCustomerStatusColor,
  formatDate,
} from "@/formatter";
import { Customer, CustomerSort, CustomerSortType } from "@/models";
import { fromISO } from "@/lib/dateTime";

import type { MouseEventHandler } from "react";

const columnsWidth = [140, 340, 230, 216, 418].map((item) =>
  calculateColumnWidth(item)
);

export type CustomerTableRowProps = {
  data: Customer;
  dailyTask?: boolean;
};

export function CustomerTableRowHeader({ dailyTask }: { dailyTask?: boolean }) {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]}>
        <SortField field={CustomerSort.CreatedAt} />
        {dailyTask ? "วันที่ติดตาม" : "วันที่สร้าง"}
      </TableCell>
      <TableCell width={columnsWidth[1]}>ชื่อ</TableCell>
      <TableCell width={columnsWidth[2]} align="center">
        เบอร์โทรศัพท์
      </TableCell>
      <TableCell width={columnsWidth[3]}>สถานะ</TableCell>
      <TableCell width={columnsWidth[4]}>หมายเหตุ</TableCell>
    </TableRow>
  );
}

export function CustomerTableRow({ data, dailyTask }: CustomerTableRowProps) {
  const navigate = useNavigate();

  const {
    id: customerId,
    firstName,
    lastName,
    phoneNo,
    createdAt,
    status,
    followings,
  } = data;

  const id = customerId.toString();

  const onClickTableRow: MouseEventHandler<HTMLTableRowElement> = () => {
    navigate(id);
  };

  const followUpDateStyles: { color?: string } = {};

  if (dailyTask) {
    if (DateTime.now() >= createdAt) {
      followUpDateStyles.color = "primary.main";
    }
  }

  return (
    <TableRow hover tabIndex={-1} role="link" onClick={onClickTableRow}>
      <TableCell>
        <TextEllipsis variant="body2" {...followUpDateStyles}>
          {formatDate(fromISO(createdAt.toString()))}
        </TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis variant="body2">
          {firstName} {lastName}
        </TextEllipsis>
      </TableCell>
      <TableCell align="center">
        <TextEllipsis variant="body2">{phoneNo ? phoneNo : "-"}</TextEllipsis>
      </TableCell>
      <TableCell align="center">
        <Stack direction="row" gap={1} alignItems="center">
          <CircleIcon
            sx={{
              width: 12,
              height: 12,
              color: formatCustomerStatusColor(status),
            }}
          />
          <Typography variant="body2">
            {formatCustomerStatus(status)}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell>
        <Typography>
          {followings?.[followings.length - 1]?.remark ?? "-"}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

function SortField({ field }: { field: CustomerSort }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const sort = searchParams.get("sort") as CustomerSort | undefined;
  const sortType = searchParams.get("sortType") as CustomerSortType | undefined;
  const setSort = (s: CustomerSort) => () => {
    if (s) {
      searchParams.set("sort", s);
      searchParams.set(
        "sortType",
        sortType === CustomerSortType.Asc && sort === field
          ? CustomerSortType.Desc
          : CustomerSortType.Asc
      );
    }

    searchParams.delete("page");
    setSearchParams(searchParams);
  };
  return (
    <IconButton
      sx={{ padding: 0, width: "auto", height: "auto" }}
      onClick={setSort(field)}
    >
      {sort === field && sortType === CustomerSortType.Asc ? (
        <ArrowDownwardIcon />
      ) : (
        <ArrowUpwardIcon />
      )}
    </IconButton>
  );
}
