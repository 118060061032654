import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";

import { FormConfirmDialog } from "@/components/FormConfirmDialog";
import { createOpportunityFollowing } from "@/services/following";
import { getApiErrorMessage, refetchQueries } from "@/utils";

import {
  FollowingEditor,
  useFollowingEditorForm,
} from "../../components/FollowingEditor";

import type { AxiosErrorWithData } from "@/client/api";

export type AddOpportunityFollowingDialogProps = {
  open: boolean;
  onClose: () => void;
  opportunityId: string;
  fetchKeys?: string[];
};

export function AddOpportunityFollowingDialog({
  open: isOpen,
  onClose,
  opportunityId,
  fetchKeys = [],
}: AddOpportunityFollowingDialogProps) {
  const { control, handleSubmit, reset } = useFollowingEditorForm();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate: add, isLoading } = useMutation(createOpportunityFollowing, {
    onSuccess: async () => {
      enqueueSnackbar("เพิ่มการติดตามสำเร็จ", { variant: "success" });
      await refetchQueries({ queryClient, fetchKeys });
      close();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(getApiErrorMessage(error), { variant: "error" });
    },
  });

  function close() {
    onClose();
    reset();
  }

  const onSubmit = handleSubmit((form) => {
    add({
      followAt: form.following.date,
      remark: form.following.remark ?? null,
      opportunityId,
    });
  });

  const title = "เพิ่มการติดตาม";

  return (
    <FormConfirmDialog
      maxWidth="sm"
      control={control}
      title={title}
      loading={isLoading}
      open={isOpen}
      onClose={close}
      onConfirm={onSubmit}
    >
      <FollowingEditor isSingleForm control={control} />
    </FormConfirmDialog>
  );
}
