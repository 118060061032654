import { Button } from "@mui/material";
import { useSearchParams } from "react-router-dom";

export function AddTrainingButton() {
  const [searchParams, setSearchParams] = useSearchParams();

  const onClickAddTraining = () => {
    searchParams.set("dialog", "training-add");
    setSearchParams(searchParams, { replace: true });
  };

  return (
    <Button variant="contained" onClick={onClickAddTraining}>
      เพิ่มเป้าหมายการเทรน
    </Button>
  );
}
