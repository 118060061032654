import { Stack } from "@mui/material";

import { formatGender } from "@/formatter";
import { transformISOToDate } from "@/lib/dateTime";
import { mapOptional, mapPosition, mapRoles } from "@/utils";
import { DataSection } from "@/components/DataSection";

import type { Staff } from "@/models";

export type StaffProfileDetailProps = {
  data: Staff;
};

export function StaffProfileDetail({ data }: StaffProfileDetailProps) {
  const { code, profile, roles: staffRoles, branches } = data;
  const {
    idCard,
    firstName,
    lastName,
    nickname,
    birthDate,
    gender,
    phoneNo,
    email,
    emergencyPerson,
    emergencyPhoneNo,
    emergencyRelation,
    address,
    district,
    subdistrict,
    province,
    postCode,
  } = profile;

  const roles = mapRoles(staffRoles);
  const position = mapPosition(staffRoles);

  const columns = [
    [
      {
        title: "ข้อมูลส่วนบุคคล",
        data: [
          { label: "หมายเลขสมาชิก", value: code },
          {
            label: "เลขที่บัตรประชาชน/หนังสือเดินทาง",
            value: idCard,
          },
          { label: "ชื่อ", value: firstName },
          { label: "นามสกุล", value: lastName },
          { label: "ชื่อเล่น", value: nickname },
          {
            label: "วัน/เดือน/ปีเกิด",
            value: mapOptional(birthDate.toString(), transformISOToDate),
          },
          { label: "เพศ", value: mapOptional(gender, formatGender) },
          { label: "เบอร์โทรศัพท์", value: phoneNo },
          { label: "อีเมล", value: email },
        ],
      },
      {
        title: "สิทธิ์การใช้งาน",
        data: [
          { label: "ตำแหน่ง", value: position },
          { label: "หน้าที่", value: roles },
          {
            label: "สาขา",
            value: (
              <Stack>
                {branches.map((branch) => (
                  <li key={branch.id}>{branch.name}</li>
                ))}
              </Stack>
            ),
            key: "branches",
          },
        ],
      },
    ],
    [
      {
        title: "ที่อยู่ปัจจุบัน",
        data: [
          { label: "ที่อยู่", value: address },
          { label: "แขวง / ตำบล", value: subdistrict },
          { label: "เขต / อำเภอ", value: district },
          { label: "จังหวัด", value: province },
          { label: "รหัสไปรษณีย์", value: postCode },
        ],
      },
      {
        title: "ข้อมูลติดต่อฉุกเฉิน",
        data: [
          {
            label: "บุคคลที่สามารถติดต่อกรณีฉุกเฉิน",
            value: emergencyPerson,
          },
          { label: "ความสัมพันธ์", value: emergencyRelation },
          { label: "เบอร์โทรศัพท์", value: emergencyPhoneNo },
        ],
      },
    ],
  ];

  return <DataSection columns={columns} label="" />;
}
