import { useSearchParams } from "react-router-dom";
import {
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import { formatFitnessType, formatFitnessVatRegistration } from "@/formatter";
import { DataSection } from "@/components/DataSection";
import { MediaViewer } from "@/components/MediaViewer";
import { useCurrentUser } from "@/features/authentication/contexts/AuthenticationContext";
import defaultLogo from "@/assets/default_fitness_logo.svg";

import { EditFitnessDialog } from "./EditFitnessDialog";

import type { FitnessCenter } from "@/models";

export type FitnessDetailProps = {
  data: FitnessCenter;
  fetchKeys?: string[];
};

export function FitnessDetail({ data, fetchKeys = [] }: FitnessDetailProps) {
  const { isAdmin, fitnessCentersId } = useCurrentUser();
  const [searchParams, setSearchParams] = useSearchParams();

  function openEditDialog() {
    searchParams.set("dialog", "fitness-edit");
    setSearchParams(searchParams);
  }

  function onCloseDialog() {
    searchParams.delete("dialog");
    setSearchParams(searchParams, { replace: true });
  }

  const dialog = searchParams.get("dialog");

  const editDialog = {
    open: dialog === "fitness-edit",
    onClose: onCloseDialog,
    data,
    id: Number(fitnessCentersId),
    fetchKeys,
  };

  const {
    name,
    logoUrl,
    type,
    isVatRegistration,
    companyName,
    phoneNo,
    mobilePhoneNo,
    faxNo,
    address,
    province,
    postCode,
    district,
    subdistrict,
    taxNo,
    bookingLimit,
  } = data;

  const columns = [
    [
      {
        title: "ข้อมูลธุรกิจ",
        data: [
          { label: "ประเภทธุรกิจ", value: formatFitnessType(type) },
          {
            label: "จดภาษีมูลค่าเพิ่ม",
            value: formatFitnessVatRegistration(isVatRegistration),
          },
        ],
      },

      {
        title: "การจองคลาส",
        data: [
          {
            label: "จำนวนสูงสุดที่จองได้",
            value:
              Number(bookingLimit) === 0 ? "ไม่จำกัด" : Number(bookingLimit),
          },
        ],
      },
      {
        title: "ข้อมูลผู้ติดต่อ",
        data: [
          { label: "เบอร์สำนักงาน", value: phoneNo },
          { label: "เบอร์มือถือ", value: mobilePhoneNo },
          { label: "เบอร์โทรสาร", value: faxNo },
        ],
      },
    ],
    [
      {
        title: "รายละเอียดธุรกิจ",
        data: [
          { label: "ชื่อบริษัท", value: companyName },
          { label: "เลขประจำตัวผู้เสียภาษี", value: taxNo },
          { label: "ที่อยู่", value: address },
          { label: "แขวง / ตำบล", value: subdistrict },
          { label: "เขต / อำเภอ", value: district },
          { label: "จังหวัด", value: province },
          { label: "รหัสไปรษณีย์", value: postCode },
        ],
      },
    ],
  ];

  return (
    <Stack>
      <CardHeader
        title={name}
        action={
          isAdmin && (
            <Stack direction="row">
              <Tooltip title="แก้ไข">
                <IconButton onClick={openEditDialog}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          )
        }
      />
      <CardContent sx={{ py: 0 }}>
        <MediaViewer
          mediaList={[{ url: logoUrl ?? defaultLogo }]}
          imageWidth={200}
          imageHeight={200}
        />
      </CardContent>
      <CardContent>
        <DataSection columns={columns} label="" />
      </CardContent>
      <EditFitnessDialog {...editDialog} />
    </Stack>
  );
}
