import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { Stack, Typography } from "@mui/material";

import { FormTextField } from "@/components/FormTextField";
import { FormConfirmDialog } from "@/components/FormConfirmDialog";
import { Section } from "@/components/Section";
import { FormDatePicker } from "@/components/DatePicker";
import { ConfirmDialog } from "@/components/ConfirmDialog";
import { getApiErrorMessage, refetchQueries } from "@/utils";
import { editMembership, editPT } from "@/services/member";
import { datetime } from "@/lib/yup/datetime";
import { PurchaseType } from "@/models";

import type { InferType } from "yup";
import type { Membership, PersonalTrainingQuota } from "@/models";
import type { AxiosErrorWithData } from "@/client/api";

type ProductEditorState = InferType<typeof schema>;

type EditProductDialogProps = {
  open: boolean;
  memberId: number;
  type: PurchaseType;
  data: Membership | PersonalTrainingQuota;
  onClose: () => void;
  fetchKeys?: string[];
};

export function EditProductDialog({
  open: isOpen,
  memberId,
  type,
  data,
  onClose,
  fetchKeys = [],
}: EditProductDialogProps) {
  const [isAcceptDialogOpen, setIsAcceptDialogOpen] = useState(false);
  const { control, handleSubmit, reset, getValues } = useProductEditorForm();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isOpen) {
      reset({
        remark: data.remark || "",
        startedAt: data.startedAt,
        endedAt: data.endedAt,
      });
    }
  }, [data, isOpen, reset]);

  const { mutate: edit, isLoading } = useMutation(
    type === PurchaseType.Membership ? editMembership : editPT,
    {
      onSuccess: async () => {
        enqueueSnackbar("บันทึกข้อมูลสำเร็จ", { variant: "success" });
        await refetchQueries({ queryClient, fetchKeys });
        setIsAcceptDialogOpen(false);
        close();
      },
      onError: (error: AxiosErrorWithData) => {
        console.error(error);
        enqueueSnackbar(getApiErrorMessage(error), { variant: "error" });
      },
    }
  );

  function close() {
    onClose();
    reset();
  }

  const onSubmit = handleSubmit(() => {
    setIsAcceptDialogOpen(true);
  });

  const title =
    type === PurchaseType.Membership
      ? "แก้ไขแพ็กเกจสมาชิก"
      : "แก้ไขแพ็กเกจเทรนเนอร์";

  return (
    <>
      <FormConfirmDialog
        maxWidth="sm"
        control={control}
        title={title}
        loading={isLoading}
        open={isOpen}
        onClose={close}
        onConfirm={onSubmit}
      >
        <Stack gap={2.5}>
          <Stack direction="row" gap={2}>
            <Typography variant="subtitle2" color="text.secondary">
              ชื่อแพ็กเกจ
            </Typography>
            <Typography variant="subtitle2">{data.product.name}</Typography>
          </Stack>
          <Section columns={2}>
            <FormDatePicker
              label="วันเริ่มต้น"
              name="startedAt"
              control={control}
            />
            <FormDatePicker
              label="วันสิ้นสุด"
              name="endedAt"
              control={control}
            />
          </Section>
          <FormTextField
            label="หมายเหตุ"
            name="remark"
            control={control}
            multiline
            rows={4}
            fullWidth
          />
        </Stack>
      </FormConfirmDialog>
      <ConfirmDialog
        title={
          type === PurchaseType.Membership
            ? "ยืนยันการแก้ไขแพ็กเกจสมาชิก"
            : "ยืนยันการแก้ไขแพ็กเกจเทรนเนอร์"
        }
        confirmMessage="ตกลง"
        maxWidth="xs"
        open={isAcceptDialogOpen}
        loading={isLoading}
        onConfirm={() =>
          edit({
            memberId,
            id: data.id,
            data: {
              remark: getValues().remark ?? null,
              startedAt: getValues().startedAt.toJSDate(),
              endedAt: getValues().endedAt.toJSDate(),
            },
          })
        }
        onCancel={() => setIsAcceptDialogOpen(false)}
      >
        <Typography variant="body2" color="primary.main">
          {type === PurchaseType.Membership
            ? "การแก้ไขแพ็คเกจสมาชิกอาจส่งผลกระทบกับการเข้าใช้งานฟิตเนส และการจองต่างๆ หากมีการจองอยู่แล้ว จะต้องทำยกเลิกการจอง ด้วยตนเอง"
            : "การแก้ไขแพ็คเกจเทรนเนอร์อาจส่งผลกระทบกับการจองต่างๆ หากมีการจองอยู่แล้ว จะต้องทำยกเลิกการจอง ด้วยตนเอง"}
        </Typography>
      </ConfirmDialog>
    </>
  );
}

function useProductEditorForm() {
  return useForm({
    resolver,
    defaultValues,
  });
}

const schema = yup.object({
  startedAt: datetime().label("วันเริ่มต้น").required(),
  endedAt: datetime().label("วันสิ้นสุด").required(),
  remark: yup.string().label("หมายเหตุ"),
});

const resolver = yupResolver(schema);

const defaultValues = { remark: "" } as ProductEditorState;
