import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import type { DialogProps } from "@mui/material";

export type ConfirmDialogProps = Omit<DialogProps, "onClose"> & {
  onClose?: () => void;
  onCancel?: () => void;
  onConfirm?: () => void;
  confirmMessage?: string;
  cancelMessage?: string;
  loading?: boolean;
  blocker?: boolean;
  reverse?: boolean;
};

export function ConfirmDialog({
  loading,
  onConfirm,
  onClose,
  onCancel = onClose,
  title,
  children,
  cancelMessage = "ยกเลิก",
  confirmMessage = "บันทึก",
  blocker,
  reverse = false,
  ...props
}: ConfirmDialogProps) {
  return (
    <Dialog title={title} onClose={onClose} {...props}>
      <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
        {title}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {reverse ? (
          <>
            {onConfirm && (
              <LoadingButton
                loading={loading}
                color="inherit"
                variant="contained"
                onClick={onConfirm}
              >
                {confirmMessage}
              </LoadingButton>
            )}
            {onCancel && (
              <Button color="primary" variant="contained" onClick={onCancel}>
                {cancelMessage}
              </Button>
            )}
          </>
        ) : (
          <>
            {onCancel && (
              <Button color="inherit" variant="contained" onClick={onCancel}>
                {cancelMessage}
              </Button>
            )}
            {onConfirm && (
              <LoadingButton
                loading={loading}
                color="primary"
                variant="contained"
                onClick={onConfirm}
              >
                {confirmMessage}
              </LoadingButton>
            )}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
