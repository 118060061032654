import { Helmet } from "react-helmet-async";
import { Box, Breadcrumbs, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { getDocumentTitle } from "@/utils";
import { DashboardCanvas } from "../../components/DashboardCanvas";

export function OpportunitySummaryPage() {
  return (
    <Box>
      <Helmet>
        <title>{getDocumentTitle("วิเคราะห์ยอดขาย")}</title>
      </Helmet>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ mb: 5 }}
      >
        <Typography color="text.primary">วิเคราะห์ยอดขาย</Typography>
      </Breadcrumbs>
      <DashboardCanvas title="วิเคราะห์ยอดขาย" type="opportunity" />
    </Box>
  );
}
