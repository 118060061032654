export function SuccessIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={64}
      height={64}
      viewBox="0 0 64 64"
      fill="none"
    >
      <path
        d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64Z"
        fill="#2E7D32"
      />
      <path
        d="M26.279 46.2228C25.593 46.2228 24.9378 45.9321 24.4876 45.4216L17.4651 37.482C16.611 36.5151 16.7189 35.0558 17.7087 34.22C18.6985 33.3857 20.1924 33.4911 21.048 34.458L26.1588 40.2349L42.857 18.6947C43.6464 17.6767 45.131 17.4764 46.1732 18.2475C47.2154 19.0185 47.4205 20.4688 46.6311 21.4869L28.1661 45.3041C27.7344 45.8598 27.0699 46.1972 26.3546 46.2198C26.3284 46.2198 26.3037 46.2198 26.2775 46.2198L26.279 46.2228Z"
        fill="white"
      />
    </svg>
  );
}
