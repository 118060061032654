import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import { UseMutateFunction } from "react-query";

import { calculateColumnWidth } from "@/utils";
import {
  formatDate,
  formatPrice,
  formatReceiptStatus,
  formatReceiptStatusColor,
} from "@/formatter";
import {
  CustomerSort,
  CustomerSortType,
  Receipt,
  ReceiptStatus,
} from "@/models";
import { fromISO } from "@/lib/dateTime";
import { AxiosErrorWithData } from "@/client/api";

const columnsWidth = [150, 148, 318, 212, 120, 80, 56].map((item) =>
  calculateColumnWidth(item)
);

const MAX_PRODUCT_ITEMS = 5;

export type SalesTableRowProps = {
  data: Receipt;
  generatePDF: UseMutateFunction<string, AxiosErrorWithData, string | number>;
};

export function SalesTableRowHeader() {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]}>
        <SortField field={CustomerSort.CreatedAt} />
        วันที่และเวลา
      </TableCell>
      <TableCell width={columnsWidth[1]}>เลขที่ใบเสร็จ</TableCell>
      <TableCell width={columnsWidth[2]}>รายการสินค้า</TableCell>
      <TableCell width={columnsWidth[3]}>ชื่อสมาชิก</TableCell>
      <TableCell width={columnsWidth[4]}>ราคาสุทธิ</TableCell>
      <TableCell width={columnsWidth[5]}>สถานะ</TableCell>
      <TableCell width={columnsWidth[6]}></TableCell>
    </TableRow>
  );
}

export function SalesTableRow({ data, generatePDF }: SalesTableRowProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showMore, setShowMore] = useState(false);
  const isMenuOpen = Boolean(anchorEl);
  const [searchParams, setSearchParams] = useSearchParams();

  const { member, createdAt, receiptItems, invoiceNo, status, total } = data;

  const initialValue = 0;

  const items = receiptItems.map((item) => ({ id: item.id, name: item.title }));

  const handleMenuClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClickPrint = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
    generatePDF(data.id);
  };

  const handleClickCancel = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
    searchParams.set("dialog", "receipt-cancel");
    searchParams.set("cancelReceiptId", data.id.toString());
    data.receiptItems.filter(
      (item) =>
        item.product.productMembershipsId ||
        item.product.productPersonalTrainingsId
    ).length > 0 && searchParams.set("showMsg", "true");
    setSearchParams(searchParams);
  };

  const renderMenu = (
    <Menu
      sx={{ mt: 4 }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleClickPrint}>ดาวน์โหลด</MenuItem>
      {status !== ReceiptStatus.Cancelled && (
        <MenuItem sx={{ color: "error.main" }} onClick={handleClickCancel}>
          ยกเลิกใบเสร็จ
        </MenuItem>
      )}
    </Menu>
  );

  const memberName = member
    ? `${member.profile.firstName} ${member.profile.lastName}`
    : "-";

  return (
    <TableRow hover tabIndex={-1} role="link">
      <TableCell>{formatDate(fromISO(createdAt.toString()))}</TableCell>
      <TableCell>{invoiceNo}</TableCell>
      <TableCell>
        <Box sx={{ pl: 0 }}>
          <>
            {items.map(
              (item, index) =>
                ((!showMore && index < MAX_PRODUCT_ITEMS) || showMore) && (
                  <li key={item.id}>{item.name}</li>
                )
            )}
            {items.length > MAX_PRODUCT_ITEMS && !showMore && (
              <Button size="small" onClick={() => setShowMore(true)}>
                ดูเพิ่มเติม
              </Button>
            )}
          </>
        </Box>
      </TableCell>
      <TableCell>{memberName}</TableCell>
      <TableCell>{formatPrice(total)}</TableCell>
      <TableCell>
        <Typography
          variant="body2"
          fontWeight={500}
          sx={{ color: formatReceiptStatusColor(status) }}
        >
          {formatReceiptStatus(status)}
        </Typography>
      </TableCell>
      <TableCell>
        <Stack direction="row" gap={1}>
          <Tooltip title="แก้ไข">
            <IconButton onClick={handleMenuOpen}>
              <MoreHorizIcon />
            </IconButton>
          </Tooltip>
          {renderMenu}
        </Stack>
      </TableCell>
    </TableRow>
  );
}

function SortField({ field }: { field: CustomerSort }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const sort = searchParams.get("sort") as CustomerSort | undefined;
  const sortType = searchParams.get("sortType") as CustomerSortType | undefined;
  const setSort = (s: CustomerSort) => () => {
    if (s) {
      searchParams.set("sort", s);
      searchParams.set(
        "sortType",
        sortType === CustomerSortType.Asc && sort === field
          ? CustomerSortType.Desc
          : CustomerSortType.Asc
      );
    }

    searchParams.delete("page");
    setSearchParams(searchParams);
  };
  return (
    <IconButton
      sx={{ padding: 0, width: "auto", height: "auto" }}
      onClick={setSort(field)}
    >
      {sort === field && sortType === CustomerSortType.Asc ? (
        <ArrowDownwardIcon />
      ) : (
        <ArrowUpwardIcon />
      )}
    </IconButton>
  );
}
