import {
  Card,
  CardContent,
  Pagination,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";

import { DataTableBody } from "@/components/DataTableBody";
import { getTrainerTrainingSummary } from "@/services/dashboard";
import { TrainingGoalSelect } from "@/features/training/components/TrainingGoalSelect";

import type { DateTime } from "luxon";
import type { PaginationProps } from "@mui/material";
import type { TrainingGoal } from "@/models";

type TrainerTrainingSummaryTableProps = {
  startedAt: DateTime;
  endedAt: DateTime;
};

export function TrainerTrainingSummaryTable({
  startedAt,
  endedAt,
}: TrainerTrainingSummaryTableProps) {
  const [page, setPage] = useState(1);
  const [goal, setGoal] = useState<TrainingGoal>();

  const { data: raw, isFetching } = useQuery(
    [
      "getTrainerTrainingSummary",
      startedAt.toISO(),
      endedAt.toISO(),
      goal,
      page,
    ],
    () => getTrainerTrainingSummary({ startedAt, endedAt, goal, page })
  );

  const data = raw?.data ?? [];
  const total = raw?.total ?? 0;

  const columnHeader = { sx: { bgcolor: "background.paper" } };

  const onChangePage: PaginationProps["onChange"] = (_, page: number) =>
    setPage(page);

  const pageCount = Math.ceil(total / 10);

  return (
    <Card>
      <CardContent>
        <Stack gap={3}>
          <Stack
            direction="row"
            gap={3}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">
              สรุปสถานะการเทรนของเทรนเนอร์ในเดือนนี้
            </Typography>
            <TrainingGoalSelect goal={goal} setGoal={setGoal} />
          </Stack>

          <TableContainer sx={{ height: 576, overflowX: "hidden" }}>
            <Table stickyHeader aria-label="trainer training table">
              <TableHead>
                <TableRow>
                  <TableCell width={246} {...columnHeader}>
                    ชื่อเทรนเนอร์
                  </TableCell>
                  <TableCell width={196} {...columnHeader}>
                    เป้าหมายทั้งหมด
                  </TableCell>
                  <TableCell width={196} {...columnHeader}>
                    สมาชิกที่บรรลุเป้าหมาย
                  </TableCell>
                  <TableCell width={200} {...columnHeader}>
                    สมาชิกที่ไม่บรรลุเป้าหมาย
                  </TableCell>
                  <TableCell width={246} {...columnHeader}>
                    สมาชิกที่มีสถานะกำลังเทรน
                  </TableCell>
                </TableRow>
              </TableHead>
              <DataTableBody loading={isFetching} data={data}>
                {data.map(
                  ({ trainer, summary: { inProgress, success, failed } }) => {
                    const total = inProgress + success + failed;
                    return (
                      <TableRow key={trainer.id}>
                        <TableCell component="th" scope="row">
                          {trainer.name}
                        </TableCell>
                        <TableCell>{total} คน</TableCell>
                        <TableCell>{success} คน</TableCell>
                        <TableCell>{failed} คน</TableCell>
                        <TableCell>{inProgress} คน</TableCell>
                      </TableRow>
                    );
                  }
                )}
              </DataTableBody>
            </Table>
          </TableContainer>
          <Pagination
            sx={{ display: "flex", justifyContent: "flex-end" }}
            page={page}
            count={pageCount || 1}
            shape="rounded"
            size="large"
            onChange={onChangePage}
          />
        </Stack>
      </CardContent>
    </Card>
  );
}
