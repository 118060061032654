import {
  Box,
  Breadcrumbs,
  Stack,
  Link as MuiLink,
  Typography,
} from "@mui/material";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import { CustomerRemark } from "@/features/member/pages/MemberProfilePage/CustomerRemark";
import { CircularLoading } from "@/components/CircularLoading";
import { useRequireParams } from "@/utils";
import { getCustomer } from "@/services/customer";

import { CustomerDetail } from "./CustomerDetail";
import { CustomerFollowing } from "./CustomerFollowing";
import { getSale } from "@/services/sale";

const QUERY_KEY = "getCustomer";

export function CustomerDetailPage() {
  const { id: saleId, customerId } = useRequireParams(["id", "customerId"]);

  const { data, isFetching } = useQuery(QUERY_KEY, () =>
    getCustomer({ saleId, customerId })
  );

  const { data: saleData, isFetching: isSaleFetching } = useQuery(
    ["getSaleForCustomer"],
    () => getSale(+saleId)
  );

  const salePath = `/sales/${saleId}`;

  const isLoading = isFetching || isSaleFetching || !data || !saleData;

  if (isLoading) {
    return <CircularLoading height="calc(100vh - 182px)" />;
  }

  const customerRemark = {
    data: data.latestRemark,
    customerId: data.id,
    queryKey: QUERY_KEY,
  };
  const customerFollowing = {
    saleId,
    data,
    queryKey: QUERY_KEY,
  };

  return (
    <Stack gap={4}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <MuiLink component={Link} color="text.secondary" to="..">
          ฝ่ายขาย
        </MuiLink>
        <MuiLink component={Link} color="text.secondary" to={salePath}>
          ข้อมูลฝ่ายขาย
        </MuiLink>
        <MuiLink
          component={Link}
          color="text.secondary"
          to={`${salePath}/customers`}
        >
          การจัดการลูกค้า
        </MuiLink>
        <Typography color="text.primary">รายละเอียดลูกค้า</Typography>
      </Breadcrumbs>
      <Box display="grid" gridTemplateColumns="2fr 1fr" gap={2}>
        <Stack gap={2}>
          <CustomerDetail data={data} />
          <CustomerRemark {...customerRemark} />
        </Stack>
        <CustomerFollowing {...customerFollowing} />
      </Box>
    </Stack>
  );
}
