import { Fragment } from "react";
import { Box, CardContent, Stack, Typography } from "@mui/material";

import { MediaViewer } from "@/components/MediaViewer/MediaViewer";
import {
  formatDay,
  formatName,
  formatTimeRange,
  formatTrainerContract,
  sortDay,
} from "@/formatter";
import { getOfficeDay, mapOptional } from "@/utils";

import { Trainer } from "@/models";

export type TrainerProfileProps = {
  data: Trainer;
};

export function TrainerProfile({ data }: TrainerProfileProps) {
  const { code, profile, trainerProfileData: trainerProfile, branches } = data;

  const { avatarUrl, phoneNo, email } = profile;

  const { contract, personalTraining } = trainerProfile;

  const officeDay = getOfficeDay(trainerProfile.workingDays).filter(
    ({ enable }) => enable
  );

  const name = mapOptional(profile, formatName) ?? "-";

  const mediaList = avatarUrl ? [{ url: avatarUrl }] : [];

  const trainerInfo = [
    { label: "โทรศัพท์มือถือ", text: phoneNo },
    { label: "อีเมล", text: email },
    {
      label: "รูปแบบการสอน",
      text: personalTraining ? "รับเทรนแบบส่วนตัว" : "ไม่รับเทรนแบบส่วนตัว",
    },
    { label: "ประเภท", text: formatTrainerContract(contract) },
  ];

  // TODO: refactor use component
  const officeDayInfo = {
    label: "วันและเวลาเข้างาน",
    children:
      officeDay.length > 0 ? (
        <Box
          display="grid"
          gridTemplateColumns="80px 1fr"
          rowGap={2}
          columnGap={1.25}
        >
          {officeDay
            .sort(({ day: d1 }, { day: d2 }) => sortDay(d1, d2))
            .map((day) => (
              <Fragment key={day.day}>
                <Typography variant="body2">{formatDay(day.day)}</Typography>
                <Typography variant="body2">
                  {day.timeRange &&
                    formatTimeRange(day.timeRange.start, day.timeRange.end)}
                </Typography>
              </Fragment>
            ))}
        </Box>
      ) : (
        <Typography variant="body2">-</Typography>
      ),
  };

  return (
    <Stack>
      <CardContent>
        <Stack direction="row" gap={2.5}>
          <MediaViewer
            mediaList={mediaList}
            imageWidth={300}
            imageHeight={300}
          />
          <Stack gap={1.5} width="100%">
            <Typography variant="h6" color="primary.main">
              {code}
            </Typography>
            <Typography variant="h6">{name}</Typography>
            <Box
              display="grid"
              gridTemplateColumns="120px 1fr"
              gap={(theme) => `${theme.spacing(2)} ${theme.spacing(5)}`}
              alignItems="baseline"
            >
              {trainerInfo.map(({ label, text }) => (
                <Fragment key={`${label} ${text}`}>
                  <Typography variant="subtitle2" color="text.disabled">
                    {label}
                  </Typography>
                  <Typography variant="body2">{text}</Typography>
                </Fragment>
              ))}
              <Fragment key="branches">
                <Typography variant="subtitle2" color="text.disabled">
                  สาขา
                </Typography>
                <Stack>
                  {branches.map((branch) => (
                    <li key={branch.id}>{branch.name}</li>
                  ))}
                </Stack>
              </Fragment>
            </Box>
          </Stack>
          <Stack gap={1.5} width="100%">
            <Typography variant="subtitle2" color="text.disabled">
              {officeDayInfo.label}
            </Typography>
            {officeDayInfo.children}
          </Stack>
        </Stack>
      </CardContent>
    </Stack>
  );
}
