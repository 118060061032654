import { Helmet } from "react-helmet-async";
import { Box, Breadcrumbs, Tab, Tabs, Typography } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import { getDocumentTitle } from "@/utils";

import type { TabsProps } from "@mui/material";

export function TrainerSummaryPage() {
  const location = useLocation();
  const navigate = useNavigate();

  const path = location?.pathname ?? "";

  const onChangeTab: TabsProps["onChange"] = (_, value: string) => {
    navigate(value, { replace: true });
  };

  return (
    <Box>
      <Helmet>
        <title>{getDocumentTitle("สรุปข้อมูลเทรนเนอร์")}</title>
      </Helmet>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ mb: 5 }}
      >
        <Typography color="text.primary">สรุปข้อมูลเทรนเนอร์</Typography>
      </Breadcrumbs>
      <Tabs value={path} onChange={onChangeTab} sx={{ mb: 3 }}>
        <Tab
          label="ข้อมูลเทรนเนอร์"
          value={"/dashboard/trainer-summary"}
          sx={{ width: 180 }}
        />
        <Tab
          label="เป้าหมายการเทรน"
          value={"/dashboard/trainer-summary/training"}
          sx={{ width: 180 }}
        />
      </Tabs>
      <Outlet />
    </Box>
  );
}
