import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Divider, MenuItem, Stack } from "@mui/material";

import { FormImageUploader } from "@/components/ImageUploader";
import { AddressEditor } from "@/components/AddressEditor";
import { Section } from "@/components/Section";
import { FormSelect } from "@/components/Select";
import { formatFitnessType, formatFitnessVatRegistration } from "@/formatter";
import { FormTextField } from "@/components/FormTextField";

import { FitnessType } from "@/models";

import type { InferType } from "yup";
import type { ControllerProps } from "react-hook-form";
import type { FileForm } from "@/components/ImageUploader";
import { configs } from "@/configs";
import { FormNumericTextField } from "@/components/NumericTextField";

export type FitnessEditorState = InferType<typeof schema>;

type FitnessEditorProps = Pick<ControllerProps<FitnessEditorState>, "control">;

export function FitnessEditor({ control }: FitnessEditorProps) {
  return (
    <Stack gap={5}>
      <FormImageUploader
        control={control}
        name="images"
        module="fitness"
        max={1}
        aspect={configs.aspectRatio.logo}
      />
      <Divider />
      <Section columns={2} label="ข้อมูลธุรกิจ">
        <FormSelect name="type" label="ประเภทธุรกิจ" control={control} required>
          {[
            FitnessType.Company,
            FitnessType.LimitedPartnership,
            FitnessType.NaturalPerson,
          ].map((type) => (
            <MenuItem key={type} value={type}>
              {formatFitnessType(type)}
            </MenuItem>
          ))}
        </FormSelect>
        <FormSelect
          name="isVatRegistration"
          label="จดภาษีมูลค่าเพิ่ม"
          control={control}
          required
        >
          {["true", "false"].map((isVatRegistration) => (
            <MenuItem key={isVatRegistration} value={isVatRegistration}>
              {formatFitnessVatRegistration(isVatRegistration === "true")}
            </MenuItem>
          ))}
        </FormSelect>
        <FormTextField
          name="name"
          control={control}
          label="ชื่อธุรกิจ"
          required
          sx={{ gridColumn: "1/-1" }}
        />
      </Section>
      <Section columns={2} label="ข้อมูลผู้ติดต่อ">
        <FormTextField name="phoneNo" control={control} label="เบอร์สำนักงาน" />
        <FormTextField
          name="mobilePhoneNo"
          control={control}
          label="เบอร์มือถือ"
        />
        <FormTextField name="faxNo" control={control} label="เบอร์โทรสาร" />
      </Section>
      <Section columns={2} label="รายละเอียดธุรกิจ">
        <FormTextField
          name="companyName"
          control={control}
          label="ชื่อบริษัท"
          required
        />
        <FormTextField
          name="taxNo"
          control={control}
          label="เลขประจำตัวผู้เสียภาษี"
          required
        />
        <AddressEditor gridColumn="1/-1" name="address" control={control} />
      </Section>
      <Section columns={2} label="การจองคลาส">
        <FormNumericTextField
          name="bookingLimit"
          control={control}
          allowZero={true}
          label="จำนวนสูงสุดที่จองได้ (ใส่ 0 หากไม่จำกัดการจอง)"
          required
          inputProps={{ min: 0, max: 99 }}
        />
      </Section>
    </Stack>
  );
}

export function useFitnessEditorForm() {
  return useForm({
    resolver,
    defaultValues,
  });
}

const schema = yup.object({
  images: FormImageUploader.schema.label("รูปภาพ").min(1),
  name: yup.string().label("ชื่อธุรกิจ").required(),
  type: yup
    .string()
    .oneOf(Object.values(FitnessType))
    .label("ประเภทธุรกิจ")
    .required(),
  isVatRegistration: yup.string().label("จดภาษีมูลค่าเพิ่ม").required(),
  companyName: yup.string().required(),
  phoneNo: yup.string().nullable(),
  mobilePhoneNo: yup.string().nullable(),
  faxNo: yup.string().nullable(),
  taxNo: yup.string().label("เลขประจำตัวผู้เสียภาษี").required(),
  address: AddressEditor.schema,
  bookingLimit: yup
    .number()
    .nullable()
    .min(0, "ไม่สามารถติดลบได้")
    .max(99, "สูงสุดไม่เกิน 99")
    .required(),
});

const resolver = yupResolver(schema);

const defaultValues = {
  name: "",
  type: FitnessType.Company,
  isVatRegistration: "true",
  images: [] as FileForm[],
} as FitnessEditorState;
