import {
  Autocomplete as MuiAutocomplete,
  Checkbox,
  TextField,
} from "@mui/material";
import * as React from "react";
import { useController } from "react-hook-form";

import { useCurrentUser } from "@/features/authentication/contexts/AuthenticationContext";

import type {
  AutocompleteProps as MuiAutocompleteProps,
  TextFieldProps,
} from "@mui/material";
import type { ControllerProps, FieldPath, FieldValues } from "react-hook-form";

export type BranchAutocompleteValue = { id: number; name: string };

export type BranchAutocompleteProps = {
  label?: string;
  TextFieldProps?: Partial<TextFieldProps>;
} & Omit<
  MuiAutocompleteProps<BranchAutocompleteValue, true, false, false>,
  "renderInput" | "options"
>;

export function BranchAutocomplete({
  label,
  TextFieldProps,
  ...props
}: BranchAutocompleteProps) {
  const user = useCurrentUser();
  const options = user.branches;

  return (
    <MuiAutocomplete
      multiple
      disableCloseOnSelect
      options={options}
      getOptionLabel={({ name }) => name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, option, { selected }) => {
        return (
          <li {...props} key={option.id}>
            <Checkbox checked={selected} />
            {option.name}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField label={label} {...params} {...TextFieldProps} />
      )}
      {...props}
    />
  );
}

export function FormBranchAutocomplete<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  required = false,
  ...props
}: { required?: boolean } & Pick<
  ControllerProps<TFieldValues, TName>,
  "name" | "control"
> &
  Omit<
    BranchAutocompleteProps,
    "value" | "name" | "TextFieldProps" | "onChange"
  >) {
  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <BranchAutocomplete
      value={field.value}
      onChange={(_, newValue) => field.onChange(newValue)}
      TextFieldProps={{
        inputRef: field.ref,
        onBlur: field.onBlur,
        error: fieldState.invalid,
        helperText: fieldState.error?.message,
        required,
      }}
      {...props}
    />
  );
}
