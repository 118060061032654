import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  Typography,
} from "@mui/material";

import { DataSection } from "@/components/DataSection";
import {
  formatChannel,
  formatCustomerStatusStamp,
  formatDate,
  formatDateTime,
  formatProfileWithCode,
  formatGender,
  formatPrice,
} from "@/formatter";
import { mapOptional } from "@/utils";
import { Channel, CustomerStatus } from "@/models";

import { CustomerStatusItem } from "./CustomerStatusItem";

import type {
  Customer,
  CustomerCloseStamp,
  CustomerUnqualifiedStamp,
} from "@/models";

type CustomerDetailProps = { data: Customer };

export function CustomerDetail({ data }: CustomerDetailProps) {
  const { status, channel } = data;

  const isMember = status === CustomerStatus.Member;

  const isShowDescriptionChannel = [Channel.Other, Channel.Friend].includes(
    channel
  );

  const columns = [
    [
      {
        title: "ข้อมูลลูกค้า",
        data: [
          { label: "เลขสมาชิก", value: data.member?.code },
          { label: "ชื่อ", value: data.firstName },
          { label: "นามสกุล", value: data.lastName },
          { label: "ชื่อเล่น", value: data.nickname },
          { label: "เพศ", value: mapOptional(data.gender, formatGender) },
          { label: "เบอร์โทรศัพท์", value: data.phoneNo },
          { label: "อีเมล", value: data.email },
          { label: "Line", value: data.line },
          { label: "Facebook", value: data.facebook },
          { label: "อื่นๆ", value: data.contactOther },
        ],
      },
    ],
    [
      {
        title: "ช่องทางที่รู้จัก",
        data: [
          {
            label: "ช่องทาง",
            key: channel,
            value: (
              <Stack gap={1}>
                <Typography variant="body2">
                  {formatChannel(channel)}
                </Typography>
                {isShowDescriptionChannel && (
                  <Typography variant="body2">
                    {channel === Channel.Other
                      ? data.channelOther
                      : mapOptional(data.friend, ({ code, profile }) =>
                          formatProfileWithCode(profile, code)
                        )}
                  </Typography>
                )}
              </Stack>
            ),
          },
        ],
      },
      {
        title: "ข้อมูลอื่นๆ",
        data: [
          {
            label: "บันทึกเวลา",
            key: `${data.id}-status-stamps`,
            value: (
              <Stack gap={1}>
                {data.customerStatusStamps.map((statusStamp, index) => (
                  <Typography
                    key={`${statusStamp.createdAt.toISO()}-${index}`}
                    variant="body2"
                    display="list-item"
                    ml={2}
                  >
                    {formatCustomerStatusStamp(statusStamp)}
                  </Typography>
                ))}
              </Stack>
            ),
          },
        ],
      },
    ],
  ];

  const isCloseStamp = !!data.customerCloseStamps.length;
  const isUnqualifiedStamp = !!data.customerUnqualifiedStamps.length;
  const isProspect = !!data.productMembershipId;

  return (
    <Card>
      <CardHeader title={<CustomerStatusItem status={status} />} />
      <CardContent>
        <Stack gap={4}>
          <DataSection columns={columns} />
          {isProspect && <ProspectDetail data={data} />}
          {isMember && <PurchaseDetail data={data} />}
          {isCloseStamp && (
            <NegativeStamp
              data={data.customerCloseStamps}
              status={CustomerStatus.Close}
            />
          )}
          {isUnqualifiedStamp && (
            <NegativeStamp
              data={data.customerUnqualifiedStamps}
              status={CustomerStatus.Unqualified}
            />
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}

type NegativeStampProps = {
  data: CustomerCloseStamp[] | CustomerUnqualifiedStamp[];
  status: CustomerStatus.Close | CustomerStatus.Unqualified;
};

function NegativeStamp({ data, status }: NegativeStampProps) {
  const titleMapper = {
    [CustomerStatus.Close]: "เหตุผลที่ปฏิเสธการขาย",
    [CustomerStatus.Unqualified]: "เหตุผลที่ไม่ตรงเป้าหมาย",
  };
  const title = titleMapper[status];

  return (
    <>
      <Divider />
      <Stack gap={2}>
        <Typography variant="subtitle1" color="error.main">
          {title}
        </Typography>
        {data.map(({ remark, createdAt }, index) => (
          <Stack key={`${createdAt.toISO()}-${index}`} gap={0.5}>
            <Typography variant="caption" color="text.secondary">
              {formatDateTime(createdAt, "-")}
            </Typography>
            <Typography variant="body2">{remark}</Typography>
          </Stack>
        ))}
      </Stack>
    </>
  );
}

type ProspectDetailProps = { data: Customer };

function ProspectDetail({ data }: ProspectDetailProps) {
  const columns = [
    [
      {
        title: "ความสนใจ",
        data: [
          {
            label: "แพ็กเกจสมาชิก",
            value: data.productMembership?.products[0].name || "-",
          },
          {
            label: "แพ็กเกจเทรนเนอร์",
            value: data.productPersonalTraining?.products[0].name || "-",
          },
        ],
      },
    ],
    [
      {
        title: "โอกาสที่คาดว่าจะปิดได้",
        data: [
          {
            label: "จำนวน",
            value: mapOptional(data.chance, (chance) => `${chance} %`) || "-",
          },
          {
            label: "ยอด",
            value: mapOptional(data.productTotal, formatPrice) || "-",
          },
          {
            label: "วันที่คาดว่าจะปิด",
            value: mapOptional(data.productCloseAt, formatDate) || "-",
          },
        ],
      },
    ],
  ];

  return (
    <>
      <Divider />
      <DataSection columns={columns} />
    </>
  );
}

type PurchaseDetailProps = { data: Customer };

function PurchaseDetail({ data }: PurchaseDetailProps) {
  const columns = [
    [
      {
        title: "สินค้าที่ซื้อ",
        data: [
          {
            label: "แพ็กเกจสมาชิก",
            value: data.purchaseProductMembership?.products?.[0].name || "-",
          },
          {
            label: "แพ็กเกจเทรนเนอร์",
            value:
              data.purchaseProductPersonalTraining?.products?.[0].name || "-",
          },
        ],
      },
    ],
    [
      {
        title: "ยอดที่ปิดได้",
        data: [
          {
            label: "ยอด",
            value: mapOptional(data.purchaseTotal, formatPrice) || "-",
          },
          {
            label: "วันที่ปิดการขาย",
            value: mapOptional(data.purchaseAt, formatDate) || "-",
          },
        ],
      },
    ],
  ];

  return (
    <>
      <Divider />
      <DataSection columns={columns} />
    </>
  );
}
