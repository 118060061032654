import { api } from "@/client/api";
import { configs } from "@/configs";
import {
  parseDateTimeFromPrisma,
  parseISOToPrisma,
  parseURLSearchParams,
} from "@/utils";

import type {
  Schedule,
  TimeRange,
  Trainer,
  TrainerProfile,
  UpdateTrainerInput,
  Booking,
  BookingType,
  MemberItem,
} from "@/models";
import type { DateTime } from "luxon";
import type { GetTrainings } from "./training";

type GetTrainers = {
  data: Trainer[];
  total: number;
};

type GetScheduleHistory = {
  data: Schedule[];
  total: number;
};

type GetTrainerSchedulesInput = {
  id: string;
  start: string;
  end: string;
};

type GetTrainerScheduleInput = {
  trainerId: string;
  scheduleId: string;
};

type CreateTrainerBookingInput = {
  startedAt: DateTime;
  endedAt: DateTime;
  memberId: number;
  staffId: number;
};

type CancelTrainerBookingInput = {
  staffId?: number;
  bookingId: number;
};

type AcceptTrainerBookingInput = {
  trainerId: number;
  bookingId: number;
};
type DenyTrainerBookingInput = {
  trainerId: number;
  bookingId: number;
};

type GetAvailableTrainersInput = {
  excludeScheduleId?: string;
} & TimeRange;

type GetScheduleHistoryInput = {
  staffId: string;
  type: BookingType;
  query?: string;
};

type GetTrainerTrainingsInput = {
  query?: string;
  staffId: string;
};

type GetAvailablePTMembers = {
  data: MemberItem[];
  total: number;
};

export async function getTrainers(query = "") {
  const { data } = await api.get<GetTrainers>(
    `${configs.apiUrl}/trainers?${query}`
  );
  return data;
}

export async function getTrainer(id: string) {
  const { data } = await api.get<Trainer>(`${configs.apiUrl}/trainers/${id}`);
  return data;
}

export async function getTrainerSchedules({
  id,
  start,
  end,
}: GetTrainerSchedulesInput) {
  const response = await api.get(
    `${configs.apiUrl}/trainers/${id}/schedules?${parseURLSearchParams({
      start,
      end,
    })}`
  );

  return parseDateTimeFromPrisma(response.data) as Schedule[];
}

export async function getTrainerSchedule({
  trainerId,
  scheduleId,
}: GetTrainerScheduleInput) {
  const response = await api.get(
    `${configs.apiUrl}/trainers/${trainerId}/schedules/${scheduleId}`
  );

  return parseDateTimeFromPrisma(response.data) as Schedule;
}

export async function updateTrainer({ id, data: body }: UpdateTrainerInput) {
  const { data } = await api.patch<TrainerProfile>(
    `${configs.apiUrl}/trainers/${id}`,
    body
  );
  return data;
}

export async function getAvailableTrainers({
  start,
  end,
  excludeScheduleId,
}: GetAvailableTrainersInput) {
  const param: Record<string, string> = {
    start: start.toISO() ?? "",
    end: end.toISO() ?? "",
  };

  if (excludeScheduleId) {
    param["excludeScheduleId"] = excludeScheduleId;
  }

  const { data } = await api.get<Trainer[]>(
    `${configs.apiUrl}/trainers/available?${parseURLSearchParams(param)}`
  );

  return data;
}

export async function createTrainerBooking({
  startedAt,
  endedAt,
  memberId,
  staffId,
}: CreateTrainerBookingInput): Promise<Booking> {
  const response = await api.post(
    `${configs.apiUrl}/trainers/${staffId}/bookings`,
    parseISOToPrisma({
      startedAt,
      endedAt,
      membersId: memberId,
    })
  );

  return parseDateTimeFromPrisma(response.data) as Booking;
}

export async function cancelTrainerBooking({
  staffId,
  bookingId,
}: CancelTrainerBookingInput): Promise<void> {
  if (!staffId) {
    return;
  }

  await api.patch(
    `${configs.apiUrl}/trainers/${staffId}/bookings/${bookingId}/cancel`
  );
}

export async function acceptTrainerBooking({
  trainerId,
  bookingId,
}: AcceptTrainerBookingInput) {
  await api.patch(
    `${configs.apiUrl}/trainers/${trainerId}/bookings/${bookingId}/confirm`
  );
}

export async function denyTrainerBooking({
  trainerId,
  bookingId,
}: DenyTrainerBookingInput) {
  await api.patch(
    `${configs.apiUrl}/trainers/${trainerId}/bookings/${bookingId}/cancel`
  );
}

export async function getScheduleHistory({
  staffId,
  type,
  query = "",
}: GetScheduleHistoryInput): Promise<GetScheduleHistory> {
  const { data } = await api.get<GetScheduleHistory>(
    `${configs.apiUrl}/trainers/${staffId}/schedules/history?${query}&type=${type}`
  );

  return parseDateTimeFromPrisma(data) as GetScheduleHistory;
}

export async function getTrainerTrainings({
  staffId,
  query = "",
}: GetTrainerTrainingsInput) {
  const { data } = await api.get<GetTrainings>(
    `${configs.apiUrl}/trainers/${staffId}/trainings?${query}`
  );
  return parseDateTimeFromPrisma(data) as GetTrainings;
}

export async function getAvailablePTMembers(query = "") {
  const { data } = await api.get<GetAvailablePTMembers>(
    `${configs.apiUrl}/trainers/members?${query}`
  );
  return parseDateTimeFromPrisma(data) as GetAvailablePTMembers;
}
