import {
  Autocomplete as MuiAutocomplete,
  Checkbox,
  TextField,
} from "@mui/material";
import * as React from "react";
import { useController } from "react-hook-form";
import { useQuery } from "react-query";

import { getCategories } from "@/services/category";

import type {
  AutocompleteProps as MuiAutocompleteProps,
  TextFieldProps,
} from "@mui/material";
import type { ControllerProps, FieldPath, FieldValues } from "react-hook-form";
import type { Category } from "@/models";

// TODO: use CategoryAutocompleteOption
export type CategoryAutocompleteValue = Category;

export type CategoryAutocompleteProps = {
  label?: string;
  TextFieldProps?: Partial<TextFieldProps>;
} & Omit<
  MuiAutocompleteProps<CategoryAutocompleteValue, true, false, false>,
  "renderInput" | "options"
>;

export function CategoryAutocomplete({
  label,
  TextFieldProps,
  ...props
}: CategoryAutocompleteProps) {
  const query = "?limit:100";
  const { data: raw, isFetching } = useQuery(["categories", query], () =>
    getCategories(query)
  );

  return (
    <MuiAutocomplete
      multiple
      disableCloseOnSelect
      options={raw?.data ?? []}
      loading={isFetching}
      getOptionLabel={({ name }) => name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, option, { selected }) => {
        return (
          <li {...props} key={option.id}>
            <Checkbox checked={selected} />
            {option.name}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField label={label} {...params} {...TextFieldProps} />
      )}
      {...props}
    />
  );
}

export function FormCategoryAutocomplete<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  required = false,
  ...props
}: { required?: boolean } & Pick<
  ControllerProps<TFieldValues, TName>,
  "name" | "control"
> &
  Omit<
    CategoryAutocompleteProps,
    "value" | "name" | "TextFieldProps" | "onChange"
  >) {
  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <CategoryAutocomplete
      value={field.value}
      onChange={(_, newValue) => field.onChange(newValue)}
      TextFieldProps={{
        inputRef: field.ref,
        onBlur: field.onBlur,
        error: fieldState.invalid,
        helperText: fieldState.error?.message,
        required,
      }}
      {...props}
    />
  );
}
