import { api } from "@/client/api";
import { configs } from "@/configs";
import { Receipt, Staff } from "@/models";

type GetSales = {
  data: Staff[];
  total: number;
};

type GetReceipts = {
  data: Receipt[];
  membershipTotal: number;
  ptTotal: number;
  productTotal: number;
  total: number;
};

export async function getSales(query = "") {
  const { data } = await api.get<GetSales>(`${configs.apiUrl}/sales?${query}`);
  return data;
}

export async function getSale(id: number) {
  const { data } = await api.get<Staff>(`${configs.apiUrl}/sales/${id}`);
  return data;
}

// TODO: change url
export async function getSaleByBranch(branchId: number) {
  const data = await api.get<Staff[]>(
    `${configs.apiUrl}/sales/branch/${branchId}`
  );
  return data;
}

export async function getSalesList(id: number, query = "") {
  const { data } = await api.get<GetReceipts>(
    `${configs.apiUrl}/sales/${id}/sales?${query}`
  );
  return data;
}
