import { ReactNode } from "react";
import { Box, Typography, Stack } from "@mui/material";

type SectionProps = {
  columns: number;
  label?: string;
  validationLabel?: { label: string; isError: boolean };
  children: ReactNode;
};

export function Section({
  columns,
  label,
  validationLabel,
  children,
}: SectionProps) {
  return (
    <Box
      display="grid"
      gridTemplateColumns={`repeat(${columns}, 1fr)`}
      gap={2.5}
    >
      {(label || validationLabel) && (
        <Stack direction="row" gap={2} sx={{ gridColumn: "1/-1" }}>
          {label && <Typography variant="labelLarge">{label}</Typography>}
          {validationLabel && (
            <Typography
              variant="caption"
              sx={{
                color: validationLabel.isError
                  ? "error.main"
                  : "text.secondary",
              }}
            >
              {validationLabel.label}
            </Typography>
          )}
        </Stack>
      )}
      {children}
    </Box>
  );
}
