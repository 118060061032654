import { ClassBranch } from "@/models";

export function getPublished(
  branches: ClassBranch[],
  selectedBranch?: number | null
): boolean {
  if (!selectedBranch) return false;
  const branch = branches.filter(({ id }) => id === selectedBranch)?.[0];

  return branch ? !!branch.publishedAt : false;
}
