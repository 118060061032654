import {
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MouseEventHandler, useState } from "react";
import { useSnackbar } from "notistack";

import { calculateColumnWidth, mapPosition, mapRoles } from "@/utils";
import { sendPasswordResetEmail } from "@/lib/firebase/auth";
import { useCurrentUser } from "@/features/authentication/contexts/AuthenticationContext";
import { configs } from "@/configs";

import type { Staff } from "@/models";

const columnsWidth = [112, 280, 250, 120, 141, 133, 48].map((item) =>
  calculateColumnWidth(item)
);

export function StaffTableRowHeader() {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]}>รหัสประจำตัว</TableCell>
      <TableCell width={columnsWidth[1]}>ชื่อ</TableCell>
      <TableCell width={columnsWidth[2]}>อีเมล</TableCell>
      <TableCell width={columnsWidth[3]}>เบอร์โทรศัพท์</TableCell>
      <TableCell width={columnsWidth[4]}>ตำแหน่ง</TableCell>
      <TableCell width={columnsWidth[5]}>หน้าที่</TableCell>
      <TableCell width={columnsWidth[6]} />
    </TableRow>
  );
}

type DataStaff = {
  data: Staff;
};

export function StaffTableRow({ data }: DataStaff) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useCurrentUser();
  const { id, accountId, code: staffCode, profile, roles: staffRoles } = data;
  const { firstName, lastName, email, phoneNo: phone } = profile;
  const position = mapPosition(staffRoles);
  const roles = mapRoles(staffRoles);
  const idString = id.toString();
  const isMenuOpen = Boolean(anchorEl);

  const onClickTableRow: MouseEventHandler<HTMLTableRowElement> = () => {
    navigate(idString);
  };

  const onClickEdit = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    searchParams.set("dialog", "edit");
    searchParams.set("id", idString);
    setSearchParams(searchParams);
  };

  const onClickRemove = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    searchParams.set("dialog", "remove");
    searchParams.set("id", idString);
    setSearchParams(searchParams);
  };

  const onClickSendEmail = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    sendPasswordResetEmail(email)
      .then(() =>
        enqueueSnackbar("ส่งรีเซ็ตรหัสผ่านสำเร็จ", { variant: "success" })
      )
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(configs.unknownErrorMessage, {
          variant: "error",
        });
      });
  };

  const handleMenuClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const isAdmin = staffRoles.find((role) => role.id === 1);
  const adminChecker = isAdmin ? currentUser.isAdmin : true;

  const isShowRemoveButton =
    currentUser.id === accountId ? false : adminChecker;

  const renderMenu = (
    <Menu
      sx={{ mt: 4 }}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={onClickSendEmail}>รีเซ็ตรหัสผ่าน</MenuItem>
      {adminChecker && <MenuItem onClick={onClickEdit}>แก้ไข</MenuItem>}
      {isShowRemoveButton && <MenuItem onClick={onClickRemove}>ลบ</MenuItem>}
    </Menu>
  );

  return (
    <TableRow hover tabIndex={-1} role="link" onClick={onClickTableRow}>
      <TableCell>
        <Typography variant="body2">{staffCode}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          {firstName} {lastName}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{email}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{phone}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{position}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{roles}</Typography>
      </TableCell>
      <TableCell sx={{ px: 0 }}>
        <Stack direction="row" gap={1}>
          <Tooltip title="แก้ไข">
            <IconButton onClick={handleMenuOpen}>
              <MoreHorizIcon />
            </IconButton>
          </Tooltip>
          {renderMenu}
        </Stack>
      </TableCell>
    </TableRow>
  );
}
