import { Stack } from "@mui/material";
import { Helmet } from "react-helmet-async";

import { getDocumentTitle, useRequireParams } from "@/utils";

import { Breadcrumbs } from "@/components/Breadcrumbs";
import { TrainingDetail } from "./TrainingDetail";

export function TrainingDetailPage() {
  const { id } = useRequireParams(["id"]);

  const title = "รายละเอียดเป้าหมายการเทรน";
  const breadcrumbs = {
    items: [
      {
        label: "เป้าหมายการเทรน",
        to: "/trainings",
      },
      { label: title, to: `trainings/${id}` },
    ],
    sx: { mb: 4 },
  };

  return (
    <Stack>
      <Helmet>
        <title>{getDocumentTitle(title)}</title>
      </Helmet>
      <Breadcrumbs {...breadcrumbs} />
      <TrainingDetail id={id} />
    </Stack>
  );
}
