import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  Divider,
  FormControl,
  FormControlLabel,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";

import { FormImageUploader } from "@/components/ImageUploader";
import { FormTextField } from "@/components/FormTextField";
import { youtubeLink } from "@/lib/yup/validation";
import { FormSelect } from "@/components/Select";
import { FormCheckbox } from "@/components/FormCheckbox";
import { formatTrainerContract } from "@/formatter";
import { Section } from "@/components/Section";

import { TrainerWorkingDayEditor } from "./TrainerWorkingDayEditor";
import { TrainerExperienceEditor } from "./TrainerExperienceEditor";

import type { InferType } from "yup";
import type { ControllerProps } from "react-hook-form";
import { TrainerContract } from "@/models";
import { configs } from "@/configs";

export type TrainerEditorState = InferType<typeof schema>;

export type TrainerEditorProps = Pick<
  ControllerProps<TrainerEditorState>,
  "control"
>;

export function TrainerEditor({ control }: TrainerEditorProps) {
  return (
    <Stack gap={5}>
      <FormImageUploader
        control={control}
        name="images"
        module="trainers"
        max={1}
        aspect={configs.aspectRatio.trainer}
      />
      <Section columns={2} label="ข้อมูลเทรนเนอร์">
        <FormTextField
          name="videoUrl"
          control={control}
          label="วิดีโอแนะนำตัว (ลิงค์ Youtube)"
          sx={{ gridColumn: "1/span 2" }}
        />
        <FormSelect name="type" control={control} label="ประเภท" required>
          {Object.values(TrainerContract).map((type) => (
            <MenuItem key={type} value={type}>
              {formatTrainerContract(type)}
            </MenuItem>
          ))}
        </FormSelect>
        <FormControl sx={{ width: 200 }}>
          <Typography variant="labelLarge">รูปแบบการสอน</Typography>
          <FormControlLabel
            control={
              <FormCheckbox name="isPersonalTraining" control={control} />
            }
            label="รับสอนแบบส่วนตัว"
            labelPlacement="end"
          />
        </FormControl>
        <Section columns={1} label="วันและเวลาเข้างาน">
          <TrainerWorkingDayEditor control={control} />
        </Section>
      </Section>
      <Divider />
      <Section columns={1} label="ข้อมูลเฉพาะทาง">
        <TrainerExperienceEditor
          name="skills"
          control={control}
          label="ความเชี่ยวชาญ"
          disableYear
        />
        <TrainerExperienceEditor
          name="certificates"
          control={control}
          label="ใบรับรอง"
          disableYear
        />
        <TrainerExperienceEditor
          name="experience"
          control={control}
          label="ประวัติการศึกษา"
          disableYear
        />
      </Section>
    </Stack>
  );
}

export function useTrainerEditorForm() {
  return useForm({
    resolver,
    // TODO: remove cast type
    defaultValues: defaultValues as TrainerEditorState,
  });
}

const schema = yup.object({
  images: FormImageUploader.schema,
  videoUrl: youtubeLink,
  type: yup
    .string()
    .label("ประเภท")
    .oneOf(Object.values(TrainerContract))
    .required(),
  isPersonalTraining: yup.boolean().required(),
  workingDay: TrainerWorkingDayEditor.schema,
  skills: TrainerExperienceEditor.schema,
  certificates: TrainerExperienceEditor.schema,
  experience: TrainerExperienceEditor.schema,
});

const resolver = yupResolver(schema);

const defaultValues = {
  images: [],
  workingDay: TrainerWorkingDayEditor.defaultValue,
  skills: [],
} as DeepPartial<TrainerEditorState>;

export type DeepPartial<T> =
  | T
  | (T extends Array<infer U>
      ? DeepPartial<U>[]
      : T extends Map<infer K, infer V>
      ? Map<DeepPartial<K>, DeepPartial<V>>
      : T extends Set<infer M>
      ? Set<DeepPartial<M>>
      : T extends object
      ? { [K in keyof T]?: DeepPartial<T[K]> }
      : T);
