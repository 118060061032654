import { Navigate } from "react-router-dom";
import { useAuthentication } from "../contexts/AuthenticationContext";

export function AuthenticatedGuard({ children }: { children: JSX.Element }) {
  const [{ isAuthenticated }] = useAuthentication();

  if (!isAuthenticated) {
    return <Navigate to="/auth/sign-in" />;
  }

  return children;
}
