import { useMemo } from "react";
import {
  Box,
  ButtonBase,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Stack,
  Table,
  TableContainer,
  TableHead,
  Typography,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { configs } from "@/configs";
import { BookingStatus } from "@/models";
import { formatBookingStatus, formatParticipantStatusColor } from "@/formatter";
import { mapOptional, refetchQueries } from "@/utils";
import { ConfirmDialog } from "@/components/ConfirmDialog";
import { DataTableBody } from "@/components/DataTableBody";
import { EmptyResultIcon } from "@/components/Icon";
import {
  getClassParticipants,
  updateClassParticipantStatus,
} from "@/services/class";

import {
  ParticipantTableRow,
  ParticipantTableRowHeader,
} from "./ParticipantTableRow";

import type { ConfirmDialogProps } from "@/components/ConfirmDialog";
import type { AxiosErrorWithData } from "@/client/api";

const list = [null, BookingStatus.Attended, BookingStatus.Absent];
const CLASS_PARTICIPANTS_QUERY_KEY = "classParticipants";

function useParticipantStats(scheduleId: string) {
  const { data } = useQuery([CLASS_PARTICIPANTS_QUERY_KEY], () =>
    getClassParticipants({ scheduleId })
  );

  return useMemo(
    () =>
      mapOptional(data, ({ total, data }) => ({
        total: total,
        ...data.reduce(
          (memo, { status }) => {
            memo[status]++;

            return memo;
          },
          {
            [BookingStatus.Attended]: 0,
            [BookingStatus.Absent]: 0,
          } as Record<BookingStatus, number>
        ),
      })),
    [data]
  );
}

export function ParticipantList({
  scheduleId,
  participantCount,
  isClassStart = true,
}: {
  scheduleId: string;
  isClassStart?: boolean;
  participantCount: number;
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const status = searchParams.get("participantStatus") as BookingStatus | null;

  const onClickStatus: JSX.IntrinsicElements["button"]["onClick"] = (event) => {
    const selected = event.currentTarget.dataset.status;
    if (selected) {
      searchParams.set("participantStatus", selected);
    } else {
      searchParams.delete("participantStatus");
    }

    setSearchParams(searchParams);
  };
  const statusFilters = mapOptional(useParticipantStats(scheduleId), (stats) =>
    list.map((item) => ({
      label: mapOptional(item, formatBookingStatus) ?? "ทั้งหมด",
      bgcolor:
        item === status
          ? mapOptional(item, formatParticipantStatusColor) ?? "primary.main"
          : "grey.100",
      color: item === status ? "white" : "black",
      quantity: item ? stats[item] : stats.total,
      value: item,
    }))
  );

  const query = status ? `status=${status}` : "";

  const { data: raw, isFetching } = useQuery(
    [CLASS_PARTICIPANTS_QUERY_KEY, query],
    () => getClassParticipants({ scheduleId, params: query })
  );

  const data = raw?.data ?? [];

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const { mutate: update, isLoading: updateLoading } = useMutation(
    updateClassParticipantStatus,
    {
      onSuccess: async () => {
        enqueueSnackbar("เปลี่ยนสถานะสำเร็จ", { variant: "success" });
        await refetchQueries({
          queryClient,
          fetchKeys: [CLASS_PARTICIPANTS_QUERY_KEY],
        });
        onCloseDialog();
      },
      onError: (error: AxiosErrorWithData) => {
        console.error(error);
        enqueueSnackbar(
          error.response?.data.message ?? configs.unknownErrorMessage,
          { variant: "error" }
        );
      },
    }
  );

  const dialog = searchParams.get("dialog");
  const bookingId = searchParams.get("bookingId");
  const statusToUpdate = searchParams.get("statusToUpdate") as
    | BookingStatus.Attended
    | BookingStatus.Absent
    | null;

  function onCloseDialog() {
    searchParams.delete("dialog");
    searchParams.delete("bookingId");
    searchParams.delete("statusToUpdate");
    setSearchParams(searchParams, { replace: true });
  }

  const statusToUpdateLabel =
    mapOptional(statusToUpdate, formatBookingStatus) ?? "";

  const updateStatusDialog: ConfirmDialogProps = {
    maxWidth: "xs",
    loading: updateLoading,
    title: `คุณต้องการเปลี่ยนสถานะเป็น "${statusToUpdateLabel}" ใช่หรือไม่`,
    confirmMessage: "ใช่",
    cancelMessage: "ไม่ใช่",
    reverse: true,
    onClose: onCloseDialog,
    onConfirm: () => {
      if (!bookingId || !statusToUpdate) {
        return;
      }

      update({ bookingId, status: statusToUpdate });
    },
    open: dialog === "updateStatus" && !!bookingId && !!statusToUpdate,
  };

  return (
    <Stack gap={3}>
      <Card>
        {isClassStart && (
          <CardHeader
            title={
              <Typography variant="h6">
                รายการผู้เข้าร่วม {participantCount} คน
              </Typography>
            }
          />
        )}
        <CardContent>
          {isClassStart ? (
            <Stack gap={3}>
              <Box
                display="grid"
                gridTemplateColumns="repeat(4, 1fr)"
                gap={2.5}
              >
                {!statusFilters ? (
                  <Box
                    display="grid"
                    height={114}
                    sx={{ gridColumn: "1/-1", placeItems: "center" }}
                  >
                    <CircularProgress disableShrink />
                  </Box>
                ) : (
                  statusFilters.map(
                    ({ value, label, bgcolor, color, quantity }) => (
                      <Stack
                        component={ButtonBase}
                        data-status={value}
                        onClick={onClickStatus}
                        key={label}
                        sx={{
                          bgcolor,
                          color,
                          height: 114,
                          p: 2.5,
                          borderRadius: 1,
                          alignItems: "flex-start",
                        }}
                        gap={1.25}
                      >
                        <Typography variant="subtitle2">{label}</Typography>
                        <Typography variant="h4">{quantity}</Typography>
                      </Stack>
                    )
                  )
                )}
              </Box>
              <TableContainer sx={{ height: "600px", overflowX: "hidden" }}>
                <Table stickyHeader>
                  <TableHead>
                    <ParticipantTableRowHeader />
                  </TableHead>
                  <DataTableBody
                    loading={isFetching}
                    data={data}
                    searchKeys={["query", "status"]}
                  >
                    {data.map((item) => (
                      <ParticipantTableRow key={item.bookingId} data={item} />
                    ))}
                  </DataTableBody>
                </Table>
              </TableContainer>
            </Stack>
          ) : (
            <Box display="grid" sx={{ placeItems: "center" }} height={440}>
              <EmptyResultIcon variant="classNotStart" />
            </Box>
          )}
        </CardContent>
      </Card>
      <ConfirmDialog {...updateStatusDialog} />
    </Stack>
  );
}
