import { TableCell, TableRow } from "@mui/material";
import type { MouseEventHandler } from "react";
import { useNavigate } from "react-router-dom";

import { TextEllipsis } from "@/components/TextEllipsis";
import { calculateColumnWidth } from "@/utils";

import type { Sale } from "@/models";

const columnsWidth = [158, 362, 340, 224].map((item) =>
  calculateColumnWidth(item)
);

export function SaleTableRowHeader() {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]}>รหัสประจำตัว</TableCell>
      <TableCell width={columnsWidth[1]}>ชื่อ</TableCell>
      <TableCell width={columnsWidth[2]}>อีเมล</TableCell>
      <TableCell width={columnsWidth[3]}>เบอร์โทรศัพท์</TableCell>
    </TableRow>
  );
}

type DataSale = {
  data: Sale;
};

export function SaleTableRow({ data }: DataSale) {
  const navigate = useNavigate();

  const { id, code, profile } = data;
  const { firstName, lastName, email, phoneNo: phoneNumber } = profile;

  const onClickTableRow: MouseEventHandler<HTMLTableRowElement> = () => {
    navigate(id.toString());
  };

  return (
    <TableRow hover tabIndex={-1} role="link" onClick={onClickTableRow}>
      <TableCell>
        <TextEllipsis variant="body2">{code}</TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis variant="body2">
          {firstName} {lastName}
        </TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis variant="body2">{email}</TextEllipsis>
      </TableCell>
      <TableCell>{phoneNumber}</TableCell>
    </TableRow>
  );
}
