import {
  Button,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";

import { TextEllipsis } from "@/components/TextEllipsis";
import { calculateColumnWidth, getFollowUpEditSetting } from "@/utils";
import { formatDate } from "@/formatter";

import type { FollowUpItem, TrainingStatus } from "@/models";
import type { DateTime } from "luxon";

const columnsWidth = [152, 112, 312, 312, 196].map((item) =>
  calculateColumnWidth(item)
);

export type FollowUpTableRowProps = {
  followUpNo: number;
  data: FollowUpItem;
  isLastFollowUp: boolean;
  training: { status: TrainingStatus; endedAt: DateTime };
  fetchKeys: string[];
};

export function FollowUpTableRowHeader({
  isEditable,
}: {
  isEditable: boolean;
}) {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]} align="center">
        ติดตามผลครั้งที่
      </TableCell>
      <TableCell width={columnsWidth[1]}>วันที่</TableCell>
      <TableCell width={columnsWidth[2]}>หมายเหตุ</TableCell>
      <TableCell width={columnsWidth[3]}>ผลการติดตาม</TableCell>
      {isEditable && <TableCell width={columnsWidth[4]}></TableCell>}
    </TableRow>
  );
}

export function FollowUpTableRow({
  followUpNo,
  data,
  isLastFollowUp,
  training,
}: FollowUpTableRowProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const { id, followedAt, remark, summary } = data;

  const { followedAtColor, isCanFollowUp, isLate, isFollowUpEditable } =
    getFollowUpEditSetting({
      status: training.status,
      endedAt: training.endedAt,
      followedAt,
      isLastFollowUp,
    });

  const idStr = id.toString();

  const onClickTableRow = () => navigate(`follow-ups/${idStr}`);

  const onClickEdit = (event: React.MouseEvent<HTMLElement>) =>
    openDialog(event, "follow-up-edit");

  const onClickAdd = (event: React.MouseEvent<HTMLElement>) =>
    openDialog(event, "follow-up-add");

  const openDialog = (event: React.MouseEvent<HTMLElement>, dialog: string) => {
    event.stopPropagation();
    searchParams.set("dialog", dialog);
    searchParams.set("id", idStr);
    setSearchParams(searchParams, { replace: true });
  };

  return (
    <TableRow hover tabIndex={-1} role="link" onClick={onClickTableRow}>
      <TableCell align="center">{followUpNo}</TableCell>
      <TableCell sx={{ color: followedAtColor }}>
        {formatDate(followedAt)}
      </TableCell>
      <TableCell>
        <TextEllipsis line={2}>{remark || "-"}</TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis line={2}>{summary || "-"}</TextEllipsis>
      </TableCell>
      {isFollowUpEditable && (
        <TableCell sx={{ minWidth: 196 }}>
          {isLastFollowUp && (
            <Stack direction="row" gap={3} alignItems="center">
              {isCanFollowUp && (
                <Button variant="contained" onClick={onClickAdd}>
                  ติดตามผล
                </Button>
              )}
              {!isLate && (
                <Tooltip title="แก้ไข" sx={{ ml: "auto" }}>
                  <IconButton onClick={onClickEdit}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          )}
        </TableCell>
      )}
    </TableRow>
  );
}
