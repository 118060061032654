import { Typography } from "@mui/material";

import { formatScheduleStatus } from "@/formatter";
import { ScheduleStatus } from "@/models";

import type { TypographyProps } from "@mui/material";

const colorMapper = {
  [ScheduleStatus.Active]: "info.main",
  [ScheduleStatus.Upcoming]: "warning.main",
  [ScheduleStatus.Completed]: "success.main",
  [ScheduleStatus.Cancelled]: "text.disabled",
};

export function ScheduleStatusLabel({
  status,
  ...props
}: { status: ScheduleStatus } & TypographyProps) {
  const color = colorMapper[status];

  return (
    <Typography color={color} {...props}>
      {formatScheduleStatus(status)}
    </Typography>
  );
}
