import { useState } from "react";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { isFirebaseError, sendPasswordResetEmail } from "@/lib/firebase/auth";
import { FormTextField } from "@/components/FormTextField";
import { configs } from "@/configs";

type ForgotPasswordDialogProps = {
  open: boolean;
  onClose: () => void;
};

const resolver = yupResolver(
  yup.object({
    email: yup
      .string()
      .label("อีเมล")
      .email("รูปแบบอีเมลไม่ถูกต้อง")
      .required("ระบุอีเมล"),
  })
);

export function ForgotPasswordDialog({
  open,
  onClose,
}: ForgotPasswordDialogProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { control, reset, handleSubmit } = useForm({
    resolver: resolver,
    defaultValues: { email: "" },
  });

  const onSubmit = handleSubmit((data) => {
    setIsLoading(true);

    sendPasswordResetEmail(data.email)
      .then(() => {
        enqueueSnackbar("ส่งรีเซ็ตรหัสผ่านไปยังอีเมล สำเร็จ", {
          variant: "success",
        });
        handleClose();
      })
      .catch((error) => {
        if (isFirebaseError(error)) {
          if (error.code === "auth/user-not-found")
            enqueueSnackbar("ไม่พบอีเมลผู้ใช้งานนี้ในระบบ", {
              variant: "error",
            });
          else
            enqueueSnackbar(configs.unknownErrorMessage, {
              variant: "error",
            });
        }
      })
      .finally(() => setIsLoading(false));
  });

  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <Dialog maxWidth="sm" open={open} onClose={handleClose}>
      <DialogTitle
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        ลืมรหัสผ่าน
        <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon color="disabled" fontSize="large" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography color="text.secondary" mb={3}>
          กรุณากรอกอีเมลของคุณเพื่อใช้ ในการรีเซตรหัสผ่าน
        </Typography>
        <FormTextField name="email" label="อีเมล" control={control} fullWidth />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <LoadingButton
          variant="contained"
          sx={{ width: 116 }}
          loading={isLoading}
          onClick={onSubmit}
        >
          ส่ง
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
