import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";

import { useClassEditorForm } from "@/features/class/pages/ClassListPage/ClassEditor";
import { ClassEditorDialog } from "@/features/class/pages/ClassListPage/ClassEditorDialog";

import { createClass } from "@/services/class";
import { configs } from "@/configs";
import { AxiosErrorWithData } from "@/client/api";
import { refetchQueries } from "@/utils";

export function AddClassDialog({
  open: isOpen,
  onClose,
  fetchKeys = [],
}: {
  open: boolean;
  onClose: () => void;
  fetchKeys?: string[];
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, reset } = useClassEditorForm();
  const queryClient = useQueryClient();

  const { mutate: add, isLoading } = useMutation(createClass, {
    onSuccess: async () => {
      enqueueSnackbar("เพิ่มคลาสสำเร็จ", { variant: "success" });
      await refetchQueries({ queryClient, fetchKeys });
      close();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(
        error.response?.data.message ?? configs.unknownErrorMessage,
        { variant: "error" }
      );
    },
  });

  function close() {
    reset();
    onClose();
  }

  const onSubmit = handleSubmit((form) => {
    add({
      name: form.name,
      description: form.description ?? "",
      categoryIds: form.categories.map(({ id }) => id),
      youtubeUrl: form.youtubeLink ?? "",
      thumbnailUrls: form.images.map((image) => image.url),
    });
  });
  const title = "เพิ่มคลาส";

  return (
    <ClassEditorDialog
      open={isOpen}
      title={title}
      onClose={close}
      onSubmit={onSubmit}
      loading={isLoading}
      control={control}
    />
  );
}
