import { api } from "@/client/api";
import { configs } from "@/configs";

import type { Door } from "@/models";

export async function getDoors(branchId: number) {
  const { data } = await api.get<Door[]>(
    `${configs.apiUrl}/branches/${branchId}/doors`
  );
  return data;
}
