import {
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  CircularProgress,
  Link as MuiLink,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { useEffect } from "react";
import NavigateNext from "@mui/icons-material/NavigateNext";

import { getScheduleStatus, mapOptional, useRequireParams } from "@/utils";
import { ClassScheduleDetail } from "@/features/class/components/ClassScheduleDetail";
import {
  CLASS_BOOKINGS_QUERY_KEY,
  CLASS_BOOKINGS_STATUS_QUERY_KEY,
  ReservationList,
} from "@/features/class/components/ReservationList";
import { getSchedule } from "@/services/schedule";

const QUERY_KEY = "classSchedule";

export function ClassScheduleDetailPage() {
  const { scheduleId } = useRequireParams(["scheduleId"]);

  const { data, isFetching } = useQuery(QUERY_KEY, () =>
    getSchedule({ scheduleId })
  );

  const isLoaded = !isFetching && data;

  const status = mapOptional(data, getScheduleStatus);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <Box>
      <Breadcrumbs
        separator={<NavigateNext fontSize="small" />}
        aria-label="breadcrumb"
        sx={{ mb: 2 }}
      >
        <MuiLink component={Link} color="text.secondary" to="/classes/calendar">
          คลาส
        </MuiLink>
        <MuiLink component={Link} color="text.secondary" to="/classes/calendar">
          ตารางคลาส
        </MuiLink>
        <Typography color="text.primary">รายละเอียดตารางเวลา</Typography>
      </Breadcrumbs>
      <Card sx={{ mb: 2.5 }}>
        {isLoaded ? (
          <CardContent>
            <ClassScheduleDetail
              data={data}
              // TODO: refactor fetch keys
              fetchKeys={[
                QUERY_KEY,
                CLASS_BOOKINGS_QUERY_KEY,
                CLASS_BOOKINGS_STATUS_QUERY_KEY,
              ]}
            />
          </CardContent>
        ) : (
          <Box display="grid" sx={{ placeItems: "center" }} height={306}>
            <CircularProgress disableShrink />
          </Box>
        )}
      </Card>
      <ReservationList
        scheduleId={scheduleId}
        scheduleStatus={status}
        capacity={data?.capacity}
        fetchKeys={[QUERY_KEY]}
      />
    </Box>
  );
}
