import {
  ControllerProps,
  FieldPath,
  FieldValues,
  useController,
} from "react-hook-form";
import { Checkbox, CheckboxProps } from "@mui/material";

export function FormCheckbox<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  name,
  control,
  ...props
}: Pick<ControllerProps<TFieldValues, TName>, "name" | "control"> &
  CheckboxProps) {
  const { field } = useController({
    name,
    control,
  });

  return (
    <Checkbox
      checked={!!field.value}
      onBlur={field.onBlur}
      inputRef={field.ref}
      onChange={field.onChange}
      {...props}
    />
  );
}
