import {
  formatBloodGroup,
  formatChannel,
  formatGender,
  formatHeight,
  formatMaritalStatus,
  formatPersonalTaxType,
  formatProfile,
  formatWeight,
} from "@/formatter";
import { transformISOToDate } from "@/lib/dateTime";
import { mapOptional } from "@/utils";
import { DataSection } from "@/components/DataSection";

import { Channel, Member, PersonalTax } from "@/models";

export type MemberProfileDetailProps = {
  data: Member;
};

export function MemberProfileDetail({ data }: MemberProfileDetailProps) {
  const {
    code,
    profile,
    taxAddress,
    taxBranch,
    taxDistrict,
    taxName,
    taxNo,
    taxPostCode,
    taxProvince,
    taxSubdistrict,
    taxType,
    branch,
    staff,
  } = data;

  const {
    idCard,
    firstName,
    lastName,
    nickname,
    gender,
    birthDate,
    bloodGroup,
    height,
    weight,
    maritalStatus,
    career,
    phoneNo,
    email,
    address,
    district,
    subdistrict,
    province,
    postCode,
    emergencyPerson,
    emergencyPhoneNo,
    emergencyRelation,
    line,
    facebook,
    contactOther,
    channel,
    channelOther,
    member,
  } = profile;

  const nameAddress =
    taxType === PersonalTax.JuristicPerson
      ? [
          { label: "ชื่อบริษัท", value: taxName },
          { label: "สาขา", value: taxBranch },
        ]
      : [{ label: "ชื่อ - นามสกุล", value: taxName }];

  const columns = [
    [
      {
        title: "ข้อมูลส่วนบุคคล",
        data: [
          { label: "หมายเลขสมาชิก", value: code },
          {
            label: "เลขที่บัตรประชาชน/หนังสือเดินทาง",
            value: idCard,
          },
          { label: "ชื่อ", value: firstName },
          { label: "นามสกุล", value: lastName },
          { label: "ชื่อเล่น", value: nickname },
          {
            label: "วัน/เดือน/ปีเกิด",
            value: transformISOToDate(birthDate.toString()),
          },
          { label: "เพศ", value: formatGender(gender) },
          {
            label: "กรุ๊ปเลือด",
            value: mapOptional(bloodGroup, formatBloodGroup),
          },
          { label: "ส่วนสูง", value: mapOptional(height, formatHeight) },
          { label: "น้ำหนัก", value: mapOptional(weight, formatWeight) },
          {
            label: "สถานภาพ",
            value: mapOptional(maritalStatus, formatMaritalStatus),
          },
          { label: "อาชีพ", value: career },
          { label: "เบอร์โทรศัพท์", value: phoneNo },
          { label: "อีเมล", value: email },
          { label: "Line", value: line },
          { label: "Facebook", value: facebook },
          { label: "อื่นๆ", value: contactOther },
        ],
      },
      {
        title: "ช่องทางที่รู้จัก",
        data: [
          { label: "ช่องทาง", value: formatChannel(channel) },
          {
            label: "",
            value:
              channel === Channel.Friend
                ? `${member?.code ?? ""} ${member?.profile.firstName ?? ""} ${
                    member?.profile.lastName ?? ""
                  }`
                : channel === Channel.Other
                ? channelOther
                : " ",
          },
        ],
      },
    ],
    [
      {
        title: "ที่อยู่ปัจจุบัน",
        data: [
          { label: "ที่อยู่", value: address },
          { label: "แขวง / ตำบล", value: subdistrict },
          { label: "เขต / อำเภอ", value: district },
          { label: "จังหวัด", value: province },
          { label: "รหัสไปรษณีย์", value: postCode },
        ],
      },
      {
        title: "ใบกับกำภาษีเต็มรูปแบบ",
        data: [
          {
            label: "รูปแบบใบกำกับภาษี",
            value: mapOptional(taxType, formatPersonalTaxType),
          },
          {
            label: "เลขที่ใบกำกับภาษี",
            value: taxNo,
          },
          ...nameAddress,
          { label: "ที่อยู่", value: taxAddress },
          { label: "แขวง / ตำบล", value: taxSubdistrict },
          { label: "เขต / อำเภอ", value: taxDistrict },
          { label: "จังหวัด", value: taxProvince },
          { label: "รหัสไปรษณีย์", value: taxPostCode },
        ],
      },
      {
        title: "ข้อมูลติดต่อฉุกเฉิน",
        data: [
          {
            label: "บุคคลที่สามารถติดต่อกรณีฉุกเฉิน",
            value: emergencyPerson,
          },
          { label: "ความสัมพันธ์", value: emergencyRelation },
          { label: "เบอร์โทรศัพท์", value: emergencyPhoneNo },
        ],
      },
    ],
  ];

  if (branch) {
    columns[0].unshift(
      {
        title: "สาขาที่สมัคร",
        data: [{ label: "ชื่อสาขา", value: branch?.name }],
      },
      {
        title: "พนักงานขายที่ดูแล",
        data: [
          {
            label: "ชื่อพนักงานขาย",
            value: mapOptional(staff?.profile, formatProfile),
          },
        ],
      }
    );
  }
  return <DataSection columns={columns} label="" />;
}
