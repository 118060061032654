import { useSnackbar } from "notistack";
import { useEffect } from "react";

import { useCategoryEditorForm } from "./CategoryEditor";
import { CategoryEditorDialog } from "./CategoryEditorDialog";

import { updateCategory } from "@/services/category";
import { useMutation, useQueryClient } from "react-query";

import { configs } from "@/configs";
import { CreateCategoryInput } from "@/models";

export type EditCategoryDialogProps = {
  open: boolean;
  data?: CreateCategoryInput;
  onClose: () => void;
  id: string;
  fetchKey: string;
};

export function EditCategoryDialog({
  open: isOpen,
  onClose,
  id,
  data,
  fetchKey,
}: EditCategoryDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, reset } = useCategoryEditorForm();

  const { mutate: update, isLoading } = useMutation(updateCategory, {
    onSuccess: async () => {
      enqueueSnackbar("แก้ไข ประเภทกิจกรรม สำเร็จ", {
        variant: "success",
      });
      await queryClient.refetchQueries(fetchKey);
      close();
    },
    onError: (error) => {
      console.error(error);
      enqueueSnackbar(configs.unknownErrorMessage, {
        variant: "error",
      });
    },
  });
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isOpen && data) {
      reset({
        name: data.name,
        description: data.description,
        images: data.thumbnailUrl ? [{ id: "1", url: data.thumbnailUrl }] : [],
      });
    }
  }, [isOpen, data, reset]);

  function close() {
    reset();
    onClose();
  }

  const onSubmit = handleSubmit((form) => {
    const data: CreateCategoryInput = {
      name: form.name,
      description: form.description ?? "",
      thumbnailUrl: form.images.map((image) => image.url)[0],
    };

    update({ id, data });
  });

  const title = "แก้ไข ​ประเภทกิจกรรม";

  return (
    <CategoryEditorDialog
      open={isOpen}
      title={title}
      onClose={close}
      onSubmit={onSubmit}
      loading={isLoading}
      control={control}
    />
  );
}
