import { Duration, DurationObjectUnits } from "luxon";

export function fromDurationISO(duration: string): Duration {
  return Duration.fromISO(duration);
}

export function fromDurationObject(object: DurationObjectUnits): Duration {
  return Duration.fromObject(object);
}

export function transformDurationISOtoObject(iso: string): DurationObjectUnits {
  return fromDurationISO(iso).toObject();
}
