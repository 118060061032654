import {
  Card,
  CardContent,
  Stack,
  Table,
  TableContainer,
  TableHead,
} from "@mui/material";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";

import { pickListTableParams, useRequireParams } from "@/utils";
import { DataTableBody } from "@/components/DataTableBody";
import { QueryTextField } from "@/components/QueryTextField";
import { SearchParamsPagination } from "@/components/SearchParamsPagination";
import { ItemsTotal } from "@/components/ItemsTotal";
import { TrainingStatusButtonGroup } from "@/features/trainer/components";
import { getMemberTrainings } from "@/services/member";
import { SortType, TrainingStatus } from "@/models";

import {
  MemberTrainingTableRowHeader,
  MemberTrainingTableRow,
} from "./MemberTrainingTableRow";

const QUERY_KEY = "getMemberTrainings";

export function MemberTrainingList() {
  const [searchParams, setSearchParams] = useSearchParams();
  const query = pickListTableParams(searchParams);
  const { memberId } = useRequireParams(["memberId"]);

  const sort = searchParams.get("sort");

  useEffect(() => {
    if (!sort) {
      searchParams.set("sort", "latestFollowedAt");
      searchParams.set("sortType", SortType.Asc);
      searchParams.set("status", TrainingStatus.InProgress);
      setSearchParams(searchParams, { replace: true });
    }
  }, [searchParams, setSearchParams, sort]);

  const { data: raw, isFetching } = useQuery([QUERY_KEY, query], () =>
    getMemberTrainings({
      query,
      memberId,
    })
  );

  const data = raw?.data ?? [];
  const total = raw?.total;

  return (
    <>
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Stack gap={3}>
            <QueryTextField placeholder="ค้นหาด้วยชื่อเทรนเนอร์" />
            <TrainingStatusButtonGroup />
            <Stack>
              <ItemsTotal count={total} />
              <TableContainer sx={{ height: "600px", overflowX: "hidden" }}>
                <Table stickyHeader>
                  <TableHead>
                    <MemberTrainingTableRowHeader />
                  </TableHead>
                  <DataTableBody loading={isFetching} data={data}>
                    {data.map((item) => (
                      <MemberTrainingTableRow key={item.id} data={item} />
                    ))}
                  </DataTableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
      <SearchParamsPagination total={total} />
    </>
  );
}
