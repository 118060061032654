import { useWatch } from "react-hook-form";

import { SummaryPriceDetail } from "./SummaryPriceDetail";
import { calculatePurchaseOrderSummary } from "../utils";

import type { PurchaseMembershipEditorState } from "./PurchaseMembershipEditor";
import type { PurchaseProductPTEditorState } from "./PurchaseProductPTEditor";
import type { Control } from "react-hook-form";

type SummaryPriceDetailProps = {
  membershipControl: Control<PurchaseMembershipEditorState>;
  packagePTControl: Control<PurchaseProductPTEditorState>;
};

export function FormSummaryPriceDetail({
  membershipControl,
  packagePTControl,
}: SummaryPriceDetailProps) {
  const packagePTPrice =
    useWatch({
      name: "packagePT.price",
      control: packagePTControl,
    }) ?? 0;
  const membershipPrice =
    useWatch({
      name: "membership.price",
      control: membershipControl,
    }) ?? 0;
  const packageDiscount =
    useWatch({
      name: "discountBaht",
      control: packagePTControl,
    }) ?? 0;
  const membershipDiscount =
    useWatch({
      name: "discountBaht",
      control: membershipControl,
    }) ?? 0;

  const summaryPriceDetail = calculatePurchaseOrderSummary(
    {
      membership: { price: membershipPrice },
      discountBaht: membershipDiscount,
    },
    {
      packagePT: { price: packagePTPrice },
      discountBaht: packageDiscount,
    }
  );

  return <SummaryPriceDetail {...summaryPriceDetail} />;
}
