import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import { TrainingStatus } from "@/models";
import { now } from "@/lib/dateTime";
import { configs } from "@/configs";

import { TrainingStatusItem } from "./TrainingStatusItem";
import { ChangeTrainingStatusDialog } from "./ChangeTrainingStatusDialog";

import type { FormControlProps, SelectChangeEvent } from "@mui/material";
import type { Training } from "@/models";

type TrainingStatusSelectProps = {
  data: Training;
  FormControlProps?: FormControlProps;
  fetchKeys?: string[];
};

export function TrainingStatusSelect({
  data,
  FormControlProps,
  fetchKeys,
}: TrainingStatusSelectProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const dialog = searchParams.get("dialog");
  const status = searchParams.get("status") as TrainingStatus;

  function onCloseDialog() {
    searchParams.delete("dialog");
    setSearchParams(searchParams);
  }

  const changeTrainingStatusDialog = {
    trainingId: data.id,
    status,
    open: dialog === "change-training-status",
    onClose: onCloseDialog,
    fetchKeys,
  };

  const onChangeStatus = (event: SelectChangeEvent) => {
    searchParams.set("dialog", "change-training-status");
    searchParams.set("status", event.target.value);
    setSearchParams(searchParams);
  };

  const items = [
    TrainingStatus.InProgress,
    TrainingStatus.Success,
    TrainingStatus.Failed,
  ];

  const changeStatusDateTime = now()
    .endOf("day")
    .plus({ days: configs.changeTrainingStatusDays });
  const disabled = data.endedAt > changeStatusDateTime || !!data.finishedAt;

  return (
    <FormControl disabled={disabled} {...FormControlProps}>
      <InputLabel>สถานะ</InputLabel>
      <Select label="สถานะ" value={data.status} onChange={onChangeStatus}>
        {items.map((item) => (
          <MenuItem
            key={item}
            value={item}
            disabled={item === data.status}
            sx={{ py: 1.5, px: 2 }}
          >
            <TrainingStatusItem status={item} />
          </MenuItem>
        ))}
      </Select>
      <ChangeTrainingStatusDialog {...changeTrainingStatusDialog} />
    </FormControl>
  );
}
