import { yupResolver } from "@hookform/resolvers/yup";
import { Divider, Stack } from "@mui/material";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import * as yup from "yup";

import { FormDatePicker } from "@/components/DatePicker";
import { FormConfirmDialog } from "@/components/FormConfirmDialog";
import { FormTextField } from "@/components/FormTextField";
import { Section } from "@/components/Section";
import { configs } from "@/configs";
import { datetime } from "@/lib/yup";
import { createNewFollowUp } from "@/services/training";
import { refetchQueries } from "@/utils";
import { now } from "@/lib/dateTime";

import type { AxiosErrorWithData } from "@/client/api";

import type { InferType } from "yup";

export type AddFollowUpEditorState = InferType<typeof schema>;

type AddFollowUpDialogProps = {
  open: boolean;
  followUpNo: number;
  trainingId: number;
  startedAt?: DateTime;
  endedAt: DateTime;
  onClose: () => void;
  fetchKeys: string[];
  isDetailPage?: boolean;
};

export function AddFollowUpDialog({
  open: isOpen,
  followUpNo,
  trainingId,
  startedAt,
  endedAt,
  onClose,
  fetchKeys,
  isDetailPage = false,
}: AddFollowUpDialogProps) {
  const { control, handleSubmit, reset } = useAddFollowUpEditorForm();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate: add, isLoading } = useMutation(createNewFollowUp, {
    onSuccess: async () => {
      enqueueSnackbar("บันทึกผลการติดตามสำเร็จ", { variant: "success" });
      await refetchQueries({ queryClient, fetchKeys });
      close();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(
        error.response?.data.message ?? configs.unknownErrorMessage,
        { variant: "error" }
      );
    },
  });

  const close = () => {
    reset();
    onClose();
  };

  const onSubmit = handleSubmit((form) => {
    add({ trainingId, ...form });
  });

  const tomorrow = now().plus({ day: 1 });
  const minDate =
    startedAt && startedAt > now() ? startedAt.plus({ day: 1 }) : tomorrow;

  const title = `ผลการติดตามครั้งที่ ${followUpNo}`;

  return (
    <FormConfirmDialog
      control={control}
      title={title}
      loading={isLoading}
      open={isOpen}
      onClose={close}
      onConfirm={onSubmit}
    >
      <Stack gap={3}>
        <FormTextField
          label="รายละเอียดผลการติดตาม"
          name="summary"
          control={control}
          multiline
          rows={4}
          required
        />
        <Divider />
        <Section columns={1} label="การติดตามผลครั้งถัดไป">
          <FormDatePicker
            label="วันที่ติดตามผล"
            name="followedAt"
            control={control}
            minDate={minDate}
            maxDate={endedAt}
            TextFieldProps={{ required: true }}
          />
          <FormTextField
            label="หมายเหตุ"
            name="remark"
            control={control}
            multiline
            rows={4}
          />
        </Section>
      </Stack>
    </FormConfirmDialog>
  );
}

export function useAddFollowUpEditorForm() {
  return useForm({
    resolver,
    defaultValues,
  });
}

const schema = yup.object({
  summary: yup.string().required().label("รายละเอียดผลการติดตาม"),
  followedAt: datetime().required().label("วันที่ติดตามผล"),
  remark: yup.string().label("หมายเหตุ"),
});

const resolver = yupResolver(schema);

const defaultValues = {} as AddFollowUpEditorState;
