import { useSnackbar } from "notistack";
import { useMutation } from "react-query";
import { Typography } from "@mui/material";

import { ConfirmDialog } from "@/components/ConfirmDialog";
import { configs } from "@/configs";

import { deleteStaff } from "@/services/staff";

import type { AxiosErrorWithData } from "@/client/api";

export type RemoveStaffDialogProps = {
  open: boolean;
  onClose: (reason: "success" | "error" | "cancel") => void;
  id: string;
};

export function RemoveStaffDialog({
  open: isOpen,
  id,
  onClose,
}: RemoveStaffDialogProps) {
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: remove, isLoading } = useMutation(deleteStaff, {
    onSuccess: () => {
      enqueueSnackbar("ลบ สำเร็จ", {
        variant: "success",
      });
      onClose("success");
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(
        error.response?.data.message ?? configs.unknownErrorMessage,
        { variant: "error" }
      );
      onClose("error");
    },
  });

  function onConfirm() {
    remove(id);
  }

  return (
    <ConfirmDialog
      maxWidth="xs"
      open={isOpen}
      onClose={() => onClose("cancel")}
      onConfirm={onConfirm}
      title="ยืนยันการลบข้อมูลพนักงาน"
      confirmMessage="ลบ"
      loading={isLoading}
    >
      <Typography variant="body2" color="primary.main">
        ถ้าต้องการลบพนักงาน คุณต้องทำการยกเลิกกิจกรรมหรือ
      </Typography>
      <Typography variant="body2" color="primary.main">
        ย้ายข้อมูลสมาชิกที่เกี่ยวข้องกับพนักงานคนนี้ก่อน
      </Typography>
      <Typography variant="body2" color="primary.main" mt={2}>
        หากคุณยืนยันการลบ ข้อมูลพนักงานคนนี้จะถูกลบถาวร
      </Typography>
    </ConfirmDialog>
  );
}
