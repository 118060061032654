import { TableCell, TableRow } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { TextEllipsis } from "@/components/TextEllipsis";
import { calculateColumnWidth, getTrainingLateColor } from "@/utils";
import {
  formatDate,
  formatTrainingGoal,
  formatTrainingStatus,
  formatTrainingStatusColor,
} from "@/formatter";
import { SortField } from "@/components/SortField";

import type { TrainingItem } from "@/models";

const columnsWidth = [200, 200, 200, 196, 196, 92].map((item) =>
  calculateColumnWidth(item)
);

export type TrainerTrainingTableRowProps = {
  data: TrainingItem;
};

export function TrainerTrainingTableRowHeader() {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]}>ชื่อสมาชิก</TableCell>
      <TableCell width={columnsWidth[1]}>เบอร์โทรศัพท์</TableCell>
      <TableCell width={columnsWidth[2]}>เป้าหมายการเทรน</TableCell>
      <TableCell width={columnsWidth[3]}>
        <SortField fieldName="latestFollowedAt" />
        วันที่ติดตามผล
      </TableCell>
      <TableCell width={columnsWidth[4]}>
        <SortField fieldName="endedAt" />
        วันสิ้นสุดการเทรน
      </TableCell>
      <TableCell width={columnsWidth[5]} align="center">
        สถานะ
      </TableCell>
    </TableRow>
  );
}

export function TrainerTrainingTableRow({
  data,
}: TrainerTrainingTableRowProps) {
  const navigate = useNavigate();

  const {
    id,
    memberPhone,
    memberName,
    latestFollowedAt,
    endedAt,
    status,
    goals,
  } = data;

  const onClickTableRow = () => navigate(id.toString());
  const goalDetail = goals.map((goal) => formatTrainingGoal(goal)).join(",");

  const followUpColor = getTrainingLateColor(latestFollowedAt, status);
  const endedAtColor = getTrainingLateColor(endedAt, status);

  return (
    <TableRow hover tabIndex={-1} role="link" onClick={onClickTableRow}>
      <TableCell>
        <TextEllipsis line={2}>{memberName}</TextEllipsis>
      </TableCell>
      <TableCell>{memberPhone}</TableCell>
      <TableCell>
        <TextEllipsis line={2}>{goalDetail}</TextEllipsis>
      </TableCell>
      <TableCell sx={{ color: followUpColor }}>
        {formatDate(latestFollowedAt)}
      </TableCell>
      <TableCell sx={{ color: endedAtColor }}>{formatDate(endedAt)}</TableCell>
      <TableCell
        align="center"
        sx={{ color: formatTrainingStatusColor(status) }}
      >
        {formatTrainingStatus(status)}
      </TableCell>
    </TableRow>
  );
}
