import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";

import { createCustomer } from "@/services/customer";
import { getApiErrorMessage, refetchQueries } from "@/utils";

import {
  FollowingLeadEditorDialog,
  useFollowingLeadEditorForm,
} from "../../components/FollowingLeadEditorDialog";

import type { AxiosErrorWithData } from "@/client/api";

type AddLeadDialogProps = {
  open: boolean;
  onClose: () => void;
  fetchKeys?: string[];
  saleId: string;
};

export function AddLeadDialog({
  open,
  onClose,
  fetchKeys = [],
  saleId,
}: AddLeadDialogProps) {
  const { control, handleSubmit, reset } = useFollowingLeadEditorForm();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate: add, isLoading } = useMutation(createCustomer, {
    onSuccess: async () => {
      enqueueSnackbar("เพิ่มผู้ที่สนใจสำเร็จ", { variant: "success" });
      await refetchQueries({ queryClient, fetchKeys });
      close();
    },
    onError: (error: AxiosErrorWithData) => {
      console.error(error);
      enqueueSnackbar(getApiErrorMessage(error), { variant: "error" });
    },
  });

  function close() {
    reset();
    onClose();
  }

  const onSubmit = handleSubmit((form) => {
    add({
      email: form.contact.email || null,
      firstName: form.firstName,
      lastName: form.lastName || null,
      nickname: form.nickname || null,
      gender: form.gender,
      phoneNo: form.contact.phone || "",
      line: form.contact.line ?? null,
      facebook: form.contact.facebook || null,
      contactOther: form.contact.other || null,
      channel: form.channel,
      channelOther: form.channelOther || null,
      channelFriend: form.channelFriend?.id || null,
      following: {
        followAt: form.following.date,
        remark: form.following.remark || null,
      },
      saleId,
    });
  });

  const title = "เพิ่มผู้ที่สนใจ";

  return (
    <FollowingLeadEditorDialog
      control={control}
      title={title}
      isLoading={isLoading}
      open={open}
      close={close}
      onSubmit={onSubmit}
    />
  );
}
