import { configs } from "@/configs";
import { api } from "@/client/api";
import { parseURLSearchParams } from "@/utils";

import type { DateTime } from "luxon";
import type {
  MemberGoalCountSummary,
  SummarizeTrainingSummary,
  TrainerTrainingSummary,
  TrainingGoal,
} from "@/models";

type DateTimeRangeInput = {
  startedAt: DateTime;
  endedAt: DateTime;
};

type GetTrainerTrainingSummaryInput = {
  page: number;
  goal?: TrainingGoal;
} & DateTimeRangeInput;

type GetSummarizeTrainingSummaryInput = {
  page: number;
} & DateTimeRangeInput;

export async function getDashboardUrl(type = "") {
  const { data } = await api.get<{
    data: string;
  }>(`${configs.apiUrl}/dashboard/${type}`);
  return data;
}

export async function getMemberGoalCountSummary({
  startedAt,
  endedAt,
}: DateTimeRangeInput) {
  const params = parseURLSearchParams({
    startedAt: startedAt.toISO(),
    endedAt: endedAt.toISO(),
  });

  const { data } = await api.get<MemberGoalCountSummary>(
    `${configs.apiUrl}/dashboard/trainings/member-goal-count-summary?${params}`
  );

  return data;
}

export async function getTrainerTrainingSummary({
  startedAt,
  endedAt,
  goal,
  page,
}: GetTrainerTrainingSummaryInput) {
  const input = {
    startedAt: startedAt.toISO(),
    endedAt: endedAt.toISO(),
    page: page.toString(),
    limit: "10",
  } as Record<string, string>;

  if (goal) {
    input["goal"] = goal;
  }

  const params = parseURLSearchParams(input);

  const { data } = await api.get<TrainerTrainingSummary>(
    `${configs.apiUrl}/dashboard/trainings/trainer-training-summary?${params}`
  );

  return data;
}

export async function getSumarizeTrainingSummary({
  startedAt,
  endedAt,
  page,
}: GetSummarizeTrainingSummaryInput) {
  const params = parseURLSearchParams({
    startedAt: startedAt.toISO(),
    endedAt: endedAt.toISO(),
    page: page.toString(),
    limit: "10",
  });

  const { data } = await api.get<SummarizeTrainingSummary>(
    `${configs.apiUrl}/dashboard/trainings/summarize-training-summary?${params}`
  );

  return data;
}
