import { debounce, TextField, TextFieldProps } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

export function DebounceTextField({
  value,
  onChange = () => null,
  delay = 200,
  ...props
}: TextFieldProps & { delay?: number }) {
  const [local, setLocal] = useState(value);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceOnChange = useCallback(debounce(onChange, delay), [onChange]);

  useEffect(() => {
    setLocal(value);
  }, [value]);

  return (
    <TextField
      value={local}
      onChange={(event) => {
        setLocal(event.target.value);
        debounceOnChange(event);
      }}
      {...props}
    />
  );
}
