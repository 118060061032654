import { TableCell, TableRow } from "@mui/material";
import type { MouseEventHandler } from "react";
import { useNavigate } from "react-router-dom";

import { TextEllipsis } from "@/components/TextEllipsis";
import { calculateColumnWidth } from "@/utils";

import { formatTrainerContract } from "@/formatter";
import { Trainer } from "@/models";

const columnsWidth = [114, 250, 228, 160, 180].map((item) =>
  calculateColumnWidth(item)
);

export function TrainerTableRowHeader() {
  return (
    <TableRow>
      <TableCell width={columnsWidth[0]}>รหัสประจำตัว</TableCell>
      <TableCell width={columnsWidth[1]}>ชื่อ</TableCell>
      <TableCell width={columnsWidth[2]}>อีเมล</TableCell>
      <TableCell width={columnsWidth[3]}>เบอร์โทรศัพท์</TableCell>
      <TableCell width={columnsWidth[4]}>ประเภท</TableCell>
    </TableRow>
  );
}

type DataTrainer = {
  data: Trainer;
};

export function TrainerTableRow({ data }: DataTrainer) {
  const navigate = useNavigate();

  const { id, code, profile, trainerProfileData: trainerProfile } = data;
  const { firstName, lastName, email, phoneNo: phoneNumber } = profile;
  const { contract } = trainerProfile;

  const onClickTableRow: MouseEventHandler<HTMLTableRowElement> = () => {
    navigate(id.toString());
  };

  return (
    <TableRow hover tabIndex={-1} role="link" onClick={onClickTableRow}>
      <TableCell>
        <TextEllipsis variant="body2">{code}</TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis variant="body2">
          {firstName} {lastName}
        </TextEllipsis>
      </TableCell>
      <TableCell>
        <TextEllipsis variant="body2">{email}</TextEllipsis>
      </TableCell>
      <TableCell>{phoneNumber}</TableCell>
      <TableCell>{formatTrainerContract(contract)}</TableCell>
    </TableRow>
  );
}
